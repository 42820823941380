import React, { useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { showToast } from "services/utils/Status";
import { customAPI } from "services/utils/CustomAPI";
export const DeleteGroup = ({
  openDialog,
  setOpenDialogBox,
  editUserGroup,
  setGetUpdatedData,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleClosePopup = () => {
    setOpenDialogBox(false);
  };

  const deleteUserGroup = async () => {
    setButtonLoader(true);
    const response = await customAPI(`${domain}user-group-details/`, "PUT", {
      group_id: editUserGroup.id,
      isdeleted: true,
    });
    if (response.ok) {
      handleClosePopup(false);
      showToast("error", "Successfully deleted user group");
      setGetUpdatedData(true);
      setButtonLoader(false);
    } else {
      setButtonLoader(false);
    }
  };

  return (
    <GlobalDialogBox
      open={openDialog}
      title="Delete User Group"
      handleCloseDialog={handleClosePopup}
    >
      <Mui.Grid container>
        <Mui.Grid item>
          <Mui.Typography sx={{ fontSize: 22, paddingLeft: 2 }}>
            Are you sure you want to remove
            <b style={{ color: themes.redColor }}>
              ${editUserGroup.name}?
            </b>{" "}
            <br></br>
            <span style={{ color: "red" }}>Note : </span>Deleting this user
            group will also remove product access & permissions for its members
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{ padding: 1, justifyContent: "flex-end" }}
        spacing={2}
      >
        <Mui.Grid item>
          <CustomButton
            actionFuntion={() => handleClosePopup()}
            actionName="No"
            typeName="submit"
          />
        </Mui.Grid>
        <Mui.Grid item>
          <CustomButton
            actionFuntion={() => deleteUserGroup()}
            actionName="Yes"
            typeName="submit"
            disableAction={openDialog && buttonLoader ? buttonLoader : false}
          />
        </Mui.Grid>
      </Mui.Grid>
    </GlobalDialogBox>
  );
};
