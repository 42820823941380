import React, { useState } from "react";
import { Leaves } from "./Leaves";
import { Permissions } from "./Permission";
import { Loans } from "./Loan";
import { MyZoneOverTime } from "./MyZoneOverTime";
import { Expense } from "./Expense";
import { SettingsMenu } from "./SettingsMenu";
import { AdvanceSalary } from "./AdvanceSalary";
import { WeekOff } from "./WeekOff";
import { Grid, Paper } from "@mui/material";
import { useSelector } from "react-redux";

export const MyZoneApply = () => {
  const { approveSettingsScreens } = useSelector(
    (state) => state.teamZoneReducer
  );
  const [currentScreen, setCurrentScreen] = useState(approveSettingsScreens);
  const [loanScreen, setLoanScreen] = useState(true);

  const renderComponent = () => {
    switch (currentScreen) {
      case "Leaves":
        return <Leaves />;
      case "Permissions":
        return <Permissions />;
      case "Over time":
        return <MyZoneOverTime />;
      case "Loans":
        return <Loans setLoanScreen={setLoanScreen} />;
      case "Advance Salary":
        return <AdvanceSalary />;
      case "Expense":
        return <Expense />;
      case "Week Off":
        return <WeekOff />;

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          xs={4}
          sm={3}
          md={2}
          lg={2}
          xl={1.5}
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          <SettingsMenu
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            loanScreen={loanScreen}
            setLoanScreen={setLoanScreen}
          />
        </Grid>
        <Grid xs={8} sm={9} md={10} lg={10} xl={10.5} sx={{ padding: 1 }}>
          <Paper
            sx={{
              boxShadow: 3,
              // height: "93vh",
              height: currentScreen === "Loans" && loanScreen ? "100%" : "93vh",
            }}
          >
            {renderComponent()}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
