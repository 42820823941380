import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Apply } from "./Apply";
import { History } from "./History";
import { ActiveLoans } from "./ActiveLoans";
import { themes } from "services/constants";
import ToggleButton from "@mui/material/ToggleButton";
import { setGlobalSearchValue } from "services/Redux/userToken";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "react-toastify/dist/ReactToastify.css";
import * as Mui from "@mui/material";
import { ViewLoan } from "./ViewLoan";

export const Loans = ({ setLoanScreen }) => {
  const [selectedComponent, setSelectedComponent] = React.useState("Apply");
  const [alignment, setAlignment] = useState("Apply Loan");
  const [viewLoanDetails, setViewLoanDetails] = useState({});
  const [viewLoan, setViewLoan] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (event, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };
  const renderComponent = () => {
    switch (selectedComponent) {
      case "history":
        return (
          <History
            setViewLoanDetails={setViewLoanDetails}
            setViewLoan={setViewLoan}
          />
        );
      case "Apply":
        return <Apply />;
      case "ActiveLoans":
        return (
          <ActiveLoans
            setViewLoanDetails={setViewLoanDetails}
            setViewLoan={setViewLoan}
          />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {viewLoan ? null : (
        <Mui.Grid
          container
          sx={{
            paddingTop: 2,
            paddingLeft: 3,
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Mui.Grid xs={4.4}>
            <Mui.Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: 500,
                height: 55,
                position: "relative",
                overflow: "hidden",
              }}
            >
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleChange}
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  borderRadius: "30px",
                  backgroundColor: themes.lightBrown,
                }}
              >
                <ToggleButton
                  value="Apply Loan"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,
                    borderRadius: "30px",
                    fontWeight: 600,
                    border: "none",
                    color:
                      alignment === "Apply Loan"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      alignment === "Apply Loan"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                      borderRadius: "30px",
                      "&.Mui-selected": {
                        borderRadius: "30px",
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        alignment === "Apply Loan"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        alignment === "Apply Loan"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                    "&:focus": {
                      backgroundColor:
                        alignment === "Apply Loan"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        alignment === "Apply Loan"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => {
                    setSelectedComponent("Apply");
                    dispatch(setGlobalSearchValue(""));
                    setLoanScreen(true);
                  }}
                >
                  Apply
                </ToggleButton>
                <ToggleButton
                  value="ActiveLoans"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,
                    fontWeight: 600,
                    border: "none",
                    borderRadius: "30px",
                    color:
                      alignment === "ActiveLoans"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      alignment === "ActiveLoans"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                      borderRadius: "30px",
                      "&.Mui-selected": {
                        borderRadius: "30px",
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        alignment === "ActiveLoans"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        alignment === "ActiveLoans"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => {
                    setSelectedComponent("ActiveLoans");
                    dispatch(setGlobalSearchValue(""));
                    setLoanScreen(false);
                  }}
                >
                  Active Loans
                </ToggleButton>
                <ToggleButton
                  value="History"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,
                    fontWeight: 600,
                    border: "none",
                    borderRadius: "30px",
                    color:
                      alignment === "History"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      alignment === "History"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                      borderRadius: "30px",
                      "&.Mui-selected": {
                        borderRadius: "30px",
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        alignment === "History"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        alignment === "History"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => {
                    setSelectedComponent("history");
                    dispatch(setGlobalSearchValue(""));
                    setLoanScreen(false);
                  }}
                >
                  History
                </ToggleButton>
              </ToggleButtonGroup>
            </Mui.Box>
          </Mui.Grid>
        </Mui.Grid>
      )}
      {viewLoan ? (
        <ViewLoan
          viewLoanDetails={viewLoanDetails}
          setViewLoan={setViewLoan}
          selectedComponent={selectedComponent}
        />
      ) : (
        renderComponent()
      )}
    </React.Fragment>
  );
};
