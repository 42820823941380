export const loanHistory = [
  { name: "Date", key: "applied_date" },
  //   { name: "Employee Name" },
  { name: "Loan Amount", key: "principal_amount" },
  { name: "Tenure", key: "tenure_months" },
  { name: "Interest", key: "interest_rate" },
  { name: "Total Amount", key: "monthly_installment" },
  { name: "Loan Type", key: "loan_type" },
  { name: "Interest Type", key: "interest_type" },
  { name: "Status", key: "status" },
  { name: "Action" },
];

export const viewTableData = [
  { name: "Payment Date", key: "payment_month" },
  { name: "Interest", key: "interest" },
  { name: "Installment Amount", key: "principal" },
  { name: "Total Amount", key: "amount" },
  { name: "Status", key: "status" },
  { name: "Balance", key: "remaining_balance" },
];

export const loanTypeArray = [
  {
    label: "Personal Loan",
  },
  {
    label: "Home Loan",
  },
  {
    label: "Vehicle Loan",
  },
  {
    label: "Education Loan",
  },
  {
    label: "Medical Loan",
  },
];

export const interestType = [
  {
    label: "Flat Rate",
  },
  {
    label: "Reducing Balance",
  },
];

export const tenureType = [
  {
    label: "Monthly",
  },
  {
    label: "Weekly",
  },
  {
    label: "Biweekly",
  },
];

export const myZoneLeaveHeader = [
  { name: "", key: "" },
  { name: "Leave Type", key: "leave_type" },
  { name: "Start Date", key: "from_date" },
  { name: "End Date", key: "to_date" },
  { name: "Businees Days", key: "business_days" },
  { name: "Status", key: "status" },
];

export const myZonePermissionHeader = [
  { name: "", key: "" },
  { name: "Date", key: "date" },
  { name: "Start Time", key: "start_time" },
  { name: "End Time", key: "end_time" },
  { name: "Duration", key: "total_time" },
  { name: "Status", key: "status" },
];

export const advanceSalaryHeader = [
  { name: "Date", key: "repayment_date" },
  { name: "Advance Type", key: "advance_type" },
  { name: "Advance Amount", key: "amount" },
  { name: "Reason for Cash", key: "description" },
  { name: "Status", key: "status" },
];

export const expenseHeader = [
  { name: "Date", key: "date_incurred" },
  { name: "Expense Type", key: "expense_type" },
  { name: "Expense Amount", key: "amount" },
  { name: "Reason For Expense", key: "description" },
  { name: "Status", key: "status" },
];

export const dayoffAttendance = [
  {
    key: "shift_grade_name",
    name: "Employee ID",
  },
  {
    key: "date",
    name: "Employee Name",
  },
  {
    key: "shift",
    name: "Department",
  },
  {
    key: "check_in_time",
    name: "Applied Date",
  },
  {
    key: "type",
    name: "Type",
  },
  {
    key: "status",
    name: "Status",
  },
  {
    key: "",
    name: "Action",
  },
];

export const weekOffType = [
  {
    id: 1,
    label: "Weekly",
  },
  {
    id: 2,
    label: "On Duty",
  },
  {
    id: 2,
    label: "Field Duty",
  },
];
