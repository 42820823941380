import React, { useState } from "react";
import * as Mui from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageSideBar } from "../PageSideBar";
import { themes } from "services/constants";
import { BasicDetailsForm } from "./BasicDetailsForm";
import { PersonalDetailsForm } from "./PersonalDetailsForm";
import { ContactDetailsForm } from "./ContactDetailsForm";
import { BankDetailsForm } from "./BankDetailsForm";
import { SalaryDetails } from "./SalaryDetails";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { showToast } from "services/utils/Status";

export const AddNewEmployee = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [existingUserDetails, setExistingUserDetails] = useState({
    emergency_contacts: [
      {
        contact_name: "",
        relation_type: "",
        contact_number: "",
      },
      {
        contact_name: "",
        relation_type: "",
        contact_number: "",
      },
    ],
  });
  const [updateBasicDetailsData, setUpdateBasicDetailsData] = useState(false);

  const openDialogBox = () => {
    showToast("success", "Employee registered successfully");
    dispatch(setGlobalSearchValue(""));
    history.push("/admin/teamzone/employee");
  };

  const visualComponents = () => {
    switch (pageNo) {
      case 1:
        return (
          <BasicDetailsForm
            setPageNo={setPageNo}
            existingUserDetails={existingUserDetails}
            updateBasicDetailsData={updateBasicDetailsData}
            setUpdateBasicDetailsData={setUpdateBasicDetailsData}
            setExistingUserDetails={setExistingUserDetails}
            openDialogBox={openDialogBox}
          />
        );
      case 2:
        return (
          <PersonalDetailsForm
            setPageNo={setPageNo}
            existingUserDetails={existingUserDetails}
            setUpdateBasicDetailsData={setUpdateBasicDetailsData}
            setExistingUserDetails={setExistingUserDetails}
            openDialogBox={openDialogBox}
          />
        );
      case 3:
        return (
          <ContactDetailsForm
            setPageNo={setPageNo}
            existingUserDetails={existingUserDetails}
            setExistingUserDetails={setExistingUserDetails}
            openDialogBox={openDialogBox}
          />
        );
      case 4:
        return (
          <BankDetailsForm
            setPageNo={setPageNo}
            existingUserDetails={existingUserDetails}
            setExistingUserDetails={setExistingUserDetails}
            openDialogBox={openDialogBox}
          />
        );
      case 5:
        return (
          <SalaryDetails
            setPageNo={setPageNo}
            existingUserDetails={existingUserDetails}
            setExistingUserDetails={setExistingUserDetails}
            openDialogBox={openDialogBox}
          />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <BackArrow
          actionFunction={() => {
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/teamzone/employee");
          }}
        />{" "}
        &nbsp;
        <Mui.Typography
          sx={{
            fontSize: 22,
            paddingLeft: 1,
            fontWeight: "bold",
            paddingTop: 1,
          }}
        >
          Employee Onboarding / New Employee
        </Mui.Typography>
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Grid
          item
          xs={5}
          sm={4}
          md={4}
          lg={3}
          sx={{ borderRight: `3px solid ${themes.colorShadow}`, paddingTop: 8 }}
        >
          <PageSideBar pageNo={pageNo} />
        </Mui.Grid>
        <Mui.Grid item xs={7} sm={8} md={8} lg={9} sx={{ paddingLeft: 2 }}>
          {visualComponents()}
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
