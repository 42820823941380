import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Image from "../assets/img/brand/of.jpg";
import { getRoutesByRole } from "routes";

function Auth() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);
   const { productAccess } = useSelector((state) => state.tokenReducer);
  const routes = useMemo(() => getRoutesByRole(productAccess), [productAccess]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-white");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-white");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = () => {
    return routes.map((prop, key) => {
      // if (prop.collapse) {
      //   return getRoutes(prop.views);
      // }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundImage: `url(${Image})`,
          }}
        ></div>
        <AuthNavbar />
        <Switch style={{ marginBottom: 100 }}>
          {getRoutes()}
          <Redirect from="*" to="/auth/login" />
        </Switch>
      </div>
    </>
  );
}

export default Auth;
