import React, { useState } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { onlyNumberRegex } from "services/constants/patterns";
import { showToast } from "services/utils/Status";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { customAPI } from "services/utils/CustomAPI";
export const Apply = () => {
  const { domain, userDetails } = useSelector((state) => state.tokenReducer);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { result: users } = useFetch(
    `${domain}list/employee?user_id=${userDetails.id}`
  );
  const today = dayjs().format("YYYY-MM-DD");
  const [descriptions, setDescriptions] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [selectedExpenseType, setSelectedExpenseType] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const { result: expenseData } = useFetch(`${domain}expense-types/`);

  const onSubmit = async (data) => {
    setButtonLoader(true);
    const response = await customAPI(`${domain}expense/`, "POST", {
      user: data.user_id,
      action: "Approved",
      amount: data.expense_amount,
      date_incurred: data.from_date,
      expense_type: data.expenseId,
      description: data.descriptions,
    });
    if (response.ok) {
      showToast(
        "success",
        `You created ${data.userName} expense on ${data.from_date
          .split("-")
          .reverse()
          .join("-")} and it has been approved.`
      );
      clearAllData();
      setStartDate(today);
      setButtonLoader(false);
    }
    setButtonLoader(false);
  };

  const clearAllData = () => {
    setDescriptions("");
    setSelectedUserName("");
    setStartDate(today);
    setSelectedExpenseType("");
    setExpenseAmount("");
    reset();
  };

  const updateUserName = (event, data) => {
    if (data) {
      setValue("userName", data?.label);
      setValue("user_id", data?.user_id);
      setSelectedUserName(data?.label);
    } else {
      setValue("userName", null);
      setSelectedUserName(null);
    }
  };

  const updateAdvanceAmount = (event) => {
    if (
      event.target.value.length <= 8 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setExpenseAmount(event.target.value);
      setValue("expense_amount", event.target.value);
    }
  };

  const updateExpenseType = (event, data) => {
    if (data) {
      setValue("expense_type", data?.label);
      setValue("expenseId", data?.id);
      setSelectedExpenseType(data?.label);
    }
  };

  const updateDesciptions = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("descriptions", event.target.value);
      setDescriptions(event.target.value);
    }
  };

  const validateStartDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (selectedDate < currentDate) {
      return "Date cannot be in the past.";
    }
    return true;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid
        container
        sx={{
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <Mui.Grid
          container
          sx={{
            padding: 1,
            backgroundColor: themes.primary,
            borderRadius: "10px 10px 0 0",
            border: `2px solid ${themes.sidebarDivider}`,
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography
            sx={{
              fontSize: 24,
              fontWeight: 800,
              font: "bold",
              textAlign: "center",
            }}
          >
            Apply Expense
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: -1,
            border: `2px solid ${themes.sidebarDivider}`,
            padding: 2,
            borderRadius: "0 0 10px 10px",
          }}
        >
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  fontWeight: "bold",
                  paddingTop: 1,
                  paddingBottom: 1,
                }}
              >
                Employee Name
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <CustomAutoComplete
                dataList={users?.map((obj) => ({
                  ...obj,
                  label: obj.user_name,
                }))}
                selectedValue={selectedUserName}
                rules={{
                  ...register("userName", {
                    required: "User name is required",
                  }),
                }}
                updateDataFunction={updateUserName}
                placeHolder="Select employee name"
                errorMessage={
                  errors?.userName?.message ? errors?.userName?.message : ""
                }
                textBoxError={errors.userName ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  color: themes.headLine,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                Expense Type
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <CustomAutoComplete
                dataList={expenseData?.map((obj) => ({
                  ...obj,
                  label: obj?.type,
                }))}
                rules={{
                  ...register("expense_type", {
                    required: "Expense type is required",
                  }),
                }}
                selectedValue={selectedExpenseType}
                updateDataFunction={updateExpenseType}
                placeHolder="Select the expense type"
                errorMessage={
                  errors?.expense_type?.message
                    ? errors?.expense_type?.message
                    : ""
                }
                textBoxError={errors?.expense_type ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  paddingTop: 1,
                  paddingBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Expense Amount
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <CustomTextBox
                selectedValue={expenseAmount}
                rules={{
                  ...register("expense_amount", {
                    required: "Expense amount is required",
                  }),
                }}
                updateDataFunction={updateAdvanceAmount}
                placeHolder="Enter expense amount"
                errorMessage={
                  errors?.expense_amount?.message
                    ? errors?.expense_amount?.message
                    : ""
                }
                textBoxError={errors.expense_amount ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  paddingTop: 1,
                  paddingBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Date
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField
                fullWidth
                size="small"
                type="date"
                id="from_date"
                value={startDate}
                {...register("from_date", {
                  required: "Date is required.",
                  validate: validateStartDate,
                })}
                error={errors.from_date}
                helperText={errors.from_date && errors.from_date.message}
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
              />
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  paddingTop: 1,
                  paddingBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Description
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <CustomTextBox
                type="multiline"
                selectedValue={descriptions}
                rules={{
                  ...register("descriptions", {
                    required: "Description is required",
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else if (value?.trim().length > 255 && value !== "") {
                          return "Maximum length is 255 characters";
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateDesciptions}
                placeHolder="Enter descriptions"
                errorMessage={
                  errors?.descriptions?.message
                    ? errors?.descriptions?.message
                    : ""
                }
                textBoxError={errors.descriptions ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionName="Clear"
                typeName="button"
                actionFuntion={clearAllData}
              />
            </Mui.Grid>
            <Mui.Grid item>
              <CustomButton
                actionName="Apply"
                typeName="submit"
                disableAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </form>
  );
};
