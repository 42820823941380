import { Card, Typography } from "@mui/material";
import { useDrop } from "react-dnd";
import { themes } from "services/constants";
import DraggableCard from "./DraggableCard";

const DroppableColumn = ({ status, tasks, moveCard }) => {
  const [, ref] = useDrop({
    accept: "CARD",
    drop: (item) => {
      moveCard(item.task, status);
    },
  });

  return (
    <Card
      ref={ref}
      sx={{
        width: "150%",
        boxShadow: 5,
        borderRadius: 1,
        padding: 2,
        backgroundColor: themes.formBackground,
        minHeight: "400px",
        marginRight: 3,
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {status}
      </Typography>
      {tasks.map((task) => (
        <DraggableCard key={task.id} task={task} />
      ))}
    </Card>
  );
};

export default DroppableColumn;
