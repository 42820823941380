import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import * as Mui from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Alert,
  Button,
  ButtonGroup,
  Avatar,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { Loader } from "components/Loader";
import "react-toastify/dist/ReactToastify.css";
import { themes } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";
import { useFetch } from "services/hooks/useFetch";

export const ViewHistory = ({ historyData, onClose }) => {
  const { token, domain } = useSelector((state) => state.tokenReducer);
  const { result: year } = useFetch(`${domain}leave-years/`);
  const [showDetails, setShowDetails] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [leavePage, setLeavePage] = useState(0);
  const [leaverowsPerPage, setLeaveRowsPerPage] = useState(10);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const apiUrl =
        historyData.title === "Leave"
          ? `${domain}leavesummary/?user_id=${
              historyData.user_id
            }&start_date=${year[0].start_date
              .split("-")
              .reverse()
              .join("-")}&end_date=${year[0].end_date
              .split("-")
              .reverse()
              .join("-")}`
          : `${domain}permisson_request/?user_id=${
              historyData.user_id
            }&start_date=${year[0].start_date
              .split("-")
              .reverse()
              .join("-")}&end_date=${year[0].end_date
              .split("-")
              .reverse()
              .join("-")}`;
      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          if (historyData.title === "Leave") {
            setData(res.leave_count);
            showToast("error", res.message);
          } else {
            setData(res);
          }
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);

        setLoading(false);
      }
    };
    if (year?.length !== 0) {
      fetchData();
    }
  }, [token, domain, historyData.id, historyData, year?.length, year]);

  const handleClose = (event) => {
    if (event.target.tagName === "svg") {
      setShowDetails(false);
      onClose();
    }
  };

  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  return (
    <div>
      <Dialog open={showDetails} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent>
          {loading ? (
            <Mui.Grid>
              <Loader info="Loading..." />
            </Mui.Grid>
          ) : (
            <>
              <Mui.Grid container>
                <Mui.Grid xs={0.7}>
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                    }}
                    alt={historyData.user_name?.charAt(0).toUpperCase()}
                    src={historyData.image_url}
                  />
                </Mui.Grid>
                <Mui.Grid xs={3} sx={{ paddingTop: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    {historyData?.username}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    {historyData?.team_name}
                  </Typography>
                </Mui.Grid>
                <Mui.Grid xs={8.2}></Mui.Grid>
                <Mui.Grid xs={0.1}>
                  <DialogActions style={{ pointerEvents: "auto" }}>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </DialogActions>
                </Mui.Grid>
              </Mui.Grid>
              <br />
              {historyData.title === "Leave" ? (
                <Mui.Grid
                  container
                  sx={{ border: `2px solid ${themes.sidebarDivider}` }}
                >
                  <ButtonGroup
                    variant="text"
                    aria-label="text button group"
                    className="button-container"
                    id="button-container"
                  >
                    <Button color="primary" className="button ">
                      Total - {data?.total_leave || 0} days
                    </Button>
                    <Button color="warning" className="button ">
                      Availed - {data?.completed_leave || 0} days
                    </Button>
                    <Button color="error" className="button ">
                      Balance - {data?.available_leave || 0} days
                    </Button>
                    <Button color="secondary" className="button ">
                      PLANNED - {data?.planned_leave || 0} days
                    </Button>
                  </ButtonGroup>
                </Mui.Grid>
              ) : null}
              <TableContainer
                sx={{
                  overflowY: "auto",
                  marginTop: 1,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                className="w-96 border  rounded "
              >
                {historyData.title === "Leave" ? (
                  <Table>
                    <TableHead
                      sx={{
                        backgroundColor: themes.primary,
                        position: "sticky",
                        top: -1,
                        zIndex: 10,
                      }}
                    >
                      <TableRow>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"}>
                            Leave Type
                          </Typography>
                        </TableCell>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"}> From</Typography>
                        </TableCell>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"}>To</Typography>
                        </TableCell>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"}>
                            Description
                          </Typography>
                        </TableCell>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"} align="center">
                            Status
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.leave_requests
                        .slice(
                          leavePage * leaverowsPerPage,
                          leavePage * leaverowsPerPage + leaverowsPerPage
                        )
                        .map((leave) => (
                          <TableRow key={leave.id}>
                            <TableCell className="column-cell" align="center">
                              {leave.leave_type}
                            </TableCell>
                            <TableCell className="column-cell" align="center">
                              {leave.from_date}
                            </TableCell>
                            <TableCell className="column-cell" align="center">
                              {leave.to_date}
                            </TableCell>
                            <TableCell className="column-cell" align="center">
                              {leave.description}
                            </TableCell>
                            <TableCell className="column-cell" align="center">
                              {leave.status}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Table>
                    <TableHead
                      sx={{
                        backgroundColor: themes.primary,
                        position: "sticky",
                        top: -1,
                        zIndex: 10,
                      }}
                    >
                      <TableRow>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"}>Date</Typography>
                        </TableCell>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"}>From</Typography>
                        </TableCell>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"}>To</Typography>
                        </TableCell>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"}>Duration</Typography>
                        </TableCell>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"}>
                            Description
                          </Typography>
                        </TableCell>
                        <TableCell className="column-cell" align="center">
                          <Typography fontWeight={"bold"} align="center">
                            Status
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        .slice(
                          leavePage * leaverowsPerPage,
                          leavePage * leaverowsPerPage + leaverowsPerPage
                        )
                        .map((permission) => (
                          <TableRow key={permission.id}>
                            <TableCell className="column-cell" align="center">
                              {permission.date}
                            </TableCell>
                            <TableCell className="column-cell" align="center">
                              {permission.start_time}
                            </TableCell>
                            <TableCell className="column-cell" align="center">
                              {permission.end_time}
                            </TableCell>
                            <TableCell className="column-cell" align="center">
                              {permission.total_time}
                            </TableCell>
                            <TableCell className="column-cell" align="center">
                              {permission.description}
                            </TableCell>
                            <TableCell className="column-cell" align="center">
                              {permission.status}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
                {data?.length === 0 && loading === false ? (
                  <Alert sx={{ marginTop: 3 }} severity="info">
                    No data available
                  </Alert>
                ) : null}
              </TableContainer>

              <TablePagination
                className="custom-pagination"
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={
                  historyData.title === "Leave"
                    ? data.leave_requests.length
                    : data.length
                }
                rowsPerPage={leaverowsPerPage}
                page={leavePage}
                onPageChange={handleChangeLeavePage}
                onRowsPerPageChange={handleChangeLeaveRowsPerPage}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
