import React, { useState } from "react";
import * as Mui from "@mui/material";
import { Box } from "@material-ui/core";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import moment from "moment";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { CustomButton } from "components/CustomComponents/CustomButton";

const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Mui.IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: themes.blackColor }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

export const Daily = ({
  loading,
  dailyShiftList,
  shiftData,
  updateDailyShift,
}) => {
  const [page, setLeavePage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(25);
  const { userDetails } = useSelector((state) => state.tokenReducer);
  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };
  const [userShiftsDet, setUserShiftsDet] = useState([]);
  const [curShiftDetail, setCurShiftDetail] = useState([]);
  const [shiftName, setShiftName] = useState("");
  const [shiftId, setShiftId] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  const checkDateWithMoment = (inputDate) => {
    const givenDate = moment(inputDate, "DD-MM-YYYY");
    const currentDate = moment();
    if (
      givenDate.isSameOrAfter(currentDate, "day") ||
      userDetails.role === "Admin"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateIndividualShift = async () => {
    const shift_assignments = [
      {
        shift_id: shiftId ? shiftId : curShiftDetail?.shift_id,
        date: moment(curShiftDetail?.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
        is_week_off: shiftName === "Weekoff" ? true : false,
      },
    ];

    updateDailyShift(userShiftsDet?.user_id, shift_assignments);
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <Mui.Grid container>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{
              mt: 1,
              width: "100%",
              border: 1,
              borderRadius: 5,
              borderColor: themes.primary,
            }}
          >
            <Mui.Table>
              <Mui.TableHead>
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                    align="center"
                  >
                    Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                    align="center"
                  >
                    Shift & Time
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                    align="center"
                  >
                    WorkUnit
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {dailyShiftList?.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={4}>
                      <Mui.Alert
                        severity="info"
                        sx={{ width: "100%", margin: 2 }}
                      >
                        No data found
                      </Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  dailyShiftList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((shiftList, index) => (
                      <React.Fragment>
                        {shiftList?.shifts.map((shift, i) => (
                          <Mui.TableRow
                            key={index}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              if (checkDateWithMoment(shift?.date)) {
                                setUserShiftsDet(shiftList);
                                setCurShiftDetail(shift);
                                setShiftName(shift?.shift_name);
                                setShiftId(shift?.id);
                                setOpenDialog(true);
                              }
                            }}
                          >
                            <Mui.TableCell
                              sx={{ border: 1, borderColor: themes.primary }}
                            >
                              <Mui.Stack
                                direction="column"
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                {shiftList.user_name.length > 10 ? (
                                  <Mui.Tooltip title={shiftList.user_name}>
                                    <Mui.Typography
                                      noWrap
                                      sx={{ cursor: "pointer" }}
                                    >
                                      {shiftList.user_name.slice(0, 10)}...
                                    </Mui.Typography>
                                  </Mui.Tooltip>
                                ) : (
                                  <Mui.Typography>
                                    {shiftList.user_name}
                                  </Mui.Typography>
                                )}
                              </Mui.Stack>
                            </Mui.TableCell>

                            <Mui.TableCell
                              sx={{
                                fontSize: 13,
                                border: 1,
                                borderColor: themes.primary,
                              }}
                              style={{
                                color: checkDateWithMoment(shift?.date)
                                  ? themes.blackColor
                                  : "gray",
                              }}
                            >
                              <Mui.Stack
                                direction="column"
                                width={"100%"}
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                {shift?.shift_name === null ||
                                shift?.shift_name === "Weekoff" ? (
                                  ""
                                ) : shift?.shift_start_time &&
                                  shift?.shift_end_time ? (
                                  <>
                                    <Mui.Typography sx={{ fontSize: 12 }}>
                                      {moment(
                                        shift?.shift_start_time,
                                        "HH:mm:SS"
                                      ).format("h:mm a")}
                                      {" to "}{" "}
                                      {moment(
                                        shift?.shift_end_time,
                                        "HH:mm:SS"
                                      ).format("h:mm a")}
                                    </Mui.Typography>
                                  </>
                                ) : null}
                                <Mui.Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    backgroundColor: shift?.color_code,
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                    display: "inline-block",
                                  }}
                                >
                                  {shift?.shift_name
                                    ? shift?.shift_name
                                    : "Not Joined"}
                                </Mui.Typography>
                              </Mui.Stack>
                            </Mui.TableCell>
                            {/* <Mui.TableCell
                              sx={{
                                fontSize: 13,
                                border: 1,
                                borderColor: themes.primary,
                                textAlign: "center",
                              }}
                            >
                              {shift?.date ? shift?.date : "Not Joined"}
                            </Mui.TableCell> */}
                            <Mui.TableCell
                              sx={{ border: 1, borderColor: themes.primary }}
                              align="center"
                            >
                              {(shift?.additional_units?.total_count === 0 &&
                                shift?.default_units?.total_count === 0) ||
                              shift?.shift_name === "Weekoff" ? (
                                <Mui.Typography>N/A</Mui.Typography>
                              ) : (
                                <Mui.Stack>
                                  <Mui.Typography
                                    sx={{
                                      textAlign: "center",
                                      fontSize: {
                                        xs: 12,
                                        sm: 12,
                                        md: 15,
                                        lg: 15,
                                      },
                                    }}
                                  >
                                    {/* {shift?.default_units ? ( */}
                                    <Mui.Tooltip
                                      title={
                                        <>
                                          <Mui.Typography sx={{ fontSize: 15 }}>
                                            Default Work Unit
                                          </Mui.Typography>
                                          {shift?.default_units?.details?.map(
                                            (data) => (
                                              <Mui.Grid
                                                container
                                                sx={{
                                                  borderTop: `2px solid ${themes.sidebarDivider}`,
                                                  borderBottom: `2px solid ${themes.sidebarDivider}`,
                                                }}
                                              >
                                                <Mui.Grid xs={6}>
                                                  <Mui.Typography>
                                                    {data?.work_unit_name}
                                                  </Mui.Typography>
                                                </Mui.Grid>
                                                <Mui.Grid xs={6}>
                                                  <Mui.Typography>
                                                    {data?.number_of_units}
                                                  </Mui.Typography>
                                                </Mui.Grid>
                                              </Mui.Grid>
                                            )
                                          )}
                                        </>
                                      }
                                    >
                                      {shift?.default_units?.total_count
                                        ? shift?.default_units?.total_count
                                        : 0}
                                      {" + "}
                                    </Mui.Tooltip>
                                    {/* ) : null} */}
                                    {/* {shift?.additional_units
                                          ?.total_count ? ( */}
                                    <Mui.Tooltip
                                      title={
                                        <>
                                          <Mui.Typography sx={{ fontSize: 15 }}>
                                            Additional Work Unit
                                          </Mui.Typography>
                                          {shift?.additional_units?.details?.map(
                                            (data) => (
                                              <Mui.Grid
                                                container
                                                sx={{
                                                  borderTop: `2px solid ${themes.sidebarDivider}`,
                                                  borderBottom: `2px solid ${themes.sidebarDivider}`,
                                                }}
                                              >
                                                <Mui.Grid xs={6}>
                                                  <Mui.Typography>
                                                    {data?.work_unit_name}
                                                  </Mui.Typography>
                                                </Mui.Grid>
                                                <Mui.Grid xs={6}>
                                                  <Mui.Typography>
                                                    {data?.number_of_units}
                                                  </Mui.Typography>
                                                </Mui.Grid>
                                              </Mui.Grid>
                                            )
                                          )}
                                        </>
                                      }
                                    >
                                      {shift?.additional_units?.total_count
                                        ? shift?.additional_units?.total_count
                                        : 0}
                                    </Mui.Tooltip>
                                    {/* ) : null} */}
                                  </Mui.Typography>
                                </Mui.Stack>
                              )}
                            </Mui.TableCell>
                          </Mui.TableRow>
                        ))}
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {dailyShiftList?.length > 25 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={dailyShiftList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.Grid>
      )}

      {openDialog ? (
        <div>
          <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={openDialog}
          >
            <FuncDialogTitle
              id="customized-dialog-title"
              align="center"
              sx={{
                minWidth: {
                  xs: "90%",
                  sm: "80%",
                  md: "580px",
                },
                color: themes.headLine,
                fontWeight: 1000,
                fontSize: {
                  xs: "18px",
                  sm: "20px",
                  md: "23px",
                },
                background: themes.primary,
              }}
              onClose={() => {
                setOpenDialog(false);
              }}
              title={"Edit Shift Details"}
            >
              {"Edit Shift Details"}
            </FuncDialogTitle>
            <Mui.DialogContent dividers>
              <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
                <Mui.Typography>Employee Name</Mui.Typography>
                <Mui.TextField
                  type="text"
                  size="small"
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    fontSize: 14,
                    width: "100%",
                  }}
                  InputProps={{
                    inputProps: { maxLength: 512 },
                  }}
                  value={userShiftsDet?.user_name}
                  disabled={true}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
                <Mui.Typography>Date</Mui.Typography>
                <Mui.TextField
                  type="date"
                  size="small"
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    fontSize: 14,
                    width: "100%",
                  }}
                  InputProps={{
                    inputProps: { maxLength: 512 },
                  }}
                  value={moment(curShiftDetail?.date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )}
                  disabled={true}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
                <Mui.Typography>Shift Name</Mui.Typography>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  value={shiftName}
                  options={shiftData}
                  filterOptions={(options, state) => {
                    return options?.filter((option) =>
                      option?.label
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option?.label}</li>;
                  }}
                  ListboxProps={{
                    style: {
                      maxHeight: "150px",
                      overflow: "auto",
                    },
                  }}
                  sx={{ minWidth: "100%" }}
                  onChange={(event, value) => {
                    if (value) {
                      setShiftId(value.id);
                      setShiftName(value.label);
                    } else {
                      setShiftId("");
                      setShiftName("");
                    }
                  }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      value={shiftName}
                      sx={{
                        background: themes.whiteColor,
                      }}
                      placeholder="Select shift"
                      size="small"
                    />
                  )}
                />
              </Mui.Grid>

              <Mui.Grid container>
                <Mui.Grid xs={8}></Mui.Grid>
                <Mui.Grid
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <CustomButton
                    actionFuntion={() => {
                      setOpenDialog(false);
                    }}
                    actionName="Cancel"
                    typeName="button"
                  />
                  &nbsp;
                  <CustomButton
                    actionName="Update"
                    typeName="submit"
                    actionFuntion={updateIndividualShift}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.DialogContent>
          </BootstrapDialog>
        </div>
      ) : null}
    </React.Fragment>
  );
};
