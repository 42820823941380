import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import sessionHandling from "services/utils/notificationUtils";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { ComponentTypeDetails } from "pages/PayRoll/PayRollHeaders";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { rgxWithDot, onlyNumberRegex } from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { showToast } from "services/utils/Status";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
let multiApiCallRestriction = true;

export const AddSalaryComponents = ({
  openDialog,
  setOpenDialog,
  companyId,
  setReloadData,
}) => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [selectedComponentType, setSelectedComponentType] = useState("");
  const [componentName, setComponentName] = useState("");
  const [statutoryCheckBox, setStatutoryCheckBox] = useState(false);
  const [fixedAmount, setFixedAmount] = useState(false);
  const [fixedAmountValue, setFixedAmountValue] = useState(0);
  const [radioButtonOption, setRadioButtonOption] = useState("default");
  const [companyContributionCTC, setCompanyContributionCTC] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { result: members, setLoad: reCallApi } = useFetch(
    companyId !== 0 ? `${domain}earnings/?company=${companyId}` : null
  );
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isCheckingName, setIsCheckingName] = useState(false);
  const [employeeContributionPercentage, setEmployeeContributionPercentage] =
    useState(0);
  const [employerContributionPercentage, setEmployerContributionPercentage] =
    useState(0);
  useEffect(() => {
    if (openDialog) {
      reset();
      setSelectedComponentType("");
      setComponentName("");
      setStatutoryCheckBox(false);
      reCallApi(true);
      setEmployeeContributionPercentage(0);
      setEmployerContributionPercentage(0);
      setValue("employerContribution", 0);
      setValue("employeeContribution", 0);
    }
  }, [openDialog, reCallApi, reset, setValue]);

  const handleComponentType = (event, data) => {
    if (data) {
      setValue("componentType", data.label);
      setSelectedComponentType(data.label);
    }
  };

  const onSubmit = async (data) => {
    if (multiApiCallRestriction) {
      let payload = {};
      let apiKey = "";
      if (selectedComponentType === "Earnings") {
        apiKey = `${domain}earnings/`;
        payload = {
          name: componentName,
          company: companyId,
        };
      } else if (selectedComponentType === "Deduction") {
        let earningId = [];
        data?.earning?.forEach((element) => {
          earningId.push(element.id);
        });
        apiKey = `${domain}deductions/`;
        payload = {
          name: data.component_name,
          deduction_type:
            radioButtonOption === "statutory"
              ? "S"
              : radioButtonOption === "default"
              ? "D"
              : "F",
          employee_contribution_percentage:
            radioButtonOption === "statutory"
              ? parseFloat(employeeContributionPercentage).toFixed(2)
              : 0,
          company_contribution_percentage:
            radioButtonOption === "statutory"
              ? parseFloat(employerContributionPercentage).toFixed(2)
              : 0,
          company_contribution_part_of_ctc: companyContributionCTC,
          fixed_amount:
            radioButtonOption === "fixed_amount" ? fixedAmountValue : 0, // When deduction type is Fixed amopunt that is F then the fixed amount can't be null
          earnings: earningId,
          company: companyId,
        };
      } else {
        apiKey = `${domain}expenses/`;
        payload = {
          name: componentName,
          company: companyId,
        };
      }
      try {
        setButtonDisabled(true);
        multiApiCallRestriction = false;
        const response = await fetch(apiKey, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({ ...payload }),
        });
        const res = await response.json();
        if (response.ok) {
          setOpenDialog(false);
          showToast(
            "success",
            `New ${selectedComponentType} created successfully`
          );
          multiApiCallRestriction = true;
          setButtonDisabled(false);
          updateStateValue();
          setCompanyContributionCTC(false);
          setReloadData(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.fixed_amount);
        }
      } catch (error) {
        showToast("error", error.message);
        multiApiCallRestriction = true;
        setButtonDisabled(false);
      }
    }
  };

  const updateComponentName = (event) => {
    if (event.target.value.length <= 64) {
      setComponentName(event.target.value);
      setValue("component_name", event.target.value);
      clearErrors("component_name");
    }
  };

  const updateEmployeeContribution = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 6) {
      setValue("employeeContribution", event.target.value);
      setEmployeeContributionPercentage(event.target.value);
    } else {
      event.preventDefault();
    }
  };

  const updateEmployerContribution = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 6) {
      setValue("employerContribution", event.target.value);
      setEmployerContributionPercentage(event.target.value);
    } else {
      event.preventDefault();
    }
  };

  const updateStateValue = () => {
    setSelectedComponentType("");
    setComponentName("");
    setEmployeeContributionPercentage(0);
    setEmployerContributionPercentage(0);
    reset();
    setRadioButtonOption("default");
    setFixedAmount(false);
    setStatutoryCheckBox(false);
    setFixedAmountValue(0);
  };

  const handleChangeRadioButton = (event) => {
    setStatutoryCheckBox(event.target.value === "statutory" ? true : false);
    setFixedAmount(event.target.value === "fixed_amount" ? true : false);
    setRadioButtonOption(event.target.value);
  };

  return (
    <React.Fragment>
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
          updateStateValue();
          setCompanyContributionCTC(false);
          setRadioButtonOption("default");
        }}
        open={openDialog}
        title="Add Salary Component"
        minWidth={600}
        minHeight={400}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Component Type</Mui.Typography>
            <CustomAutoComplete
              dataList={ComponentTypeDetails}
              selectedValue={selectedComponentType}
              rules={{
                ...register("componentType", {
                  required: "Component Type is required",
                }),
              }}
              updateDataFunction={handleComponentType}
              placeHolder="Please select the component type"
              errorMessage={
                errors?.componentType?.message
                  ? errors?.componentType?.message
                  : ""
              }
              textBoxError={errors.componentType ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Component Name</Mui.Typography>
            <CustomTextBox
              selectedValue={componentName}
              rules={{
                ...register("component_name", {
                  required: "Component name is required",
                  minLength: {
                    value: 3,
                    message: "Minium length is 3 characters",
                  },
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                    uniqueNameCheck: async (value) => {
                      if (
                        errors?.component_name?.message ===
                        "Component name already exists"
                      ) {
                        return "Component name already exists";
                      }
                      if (isCheckingName) return true;
                      setIsCheckingName(true);
                      setButtonDisabled(true);
                      let apiEndPoint =
                        selectedComponentType === "Earnings"
                          ? `${domain}earning/unique`
                          : selectedComponentType === "Deduction"
                          ? `${domain}deduction/unique`
                          : `${domain}expense/unique`;
                      const response = await fetch(apiEndPoint, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `token ${token}`,
                        },
                        body: JSON.stringify({
                          company: companyId,
                          name: value.trim(),
                        }),
                      });
                      const res = await response.json();
                      setIsCheckingName(false);
                      setButtonDisabled(false);
                      if (res.exists) {
                        return "Component name already exists";
                      } else {
                        return true;
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateComponentName}
              placeHolder="Enter the component name"
              errorMessage={
                errors?.component_name?.message
                  ? errors?.component_name?.message
                  : ""
              }
              textBoxError={errors.component_name ? true : false}
            />
          </Mui.Grid>
          {selectedComponentType === "Deduction" ? (
            <>
              <Mui.Grid container>
                <Mui.Typography>Select one</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container>
                <Mui.RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleChangeRadioButton}
                  value={radioButtonOption}
                >
                  <Mui.FormControlLabel
                    value="statutory"
                    control={<Mui.Radio />}
                    label="Statutory components"
                  />
                  <Mui.FormControlLabel
                    value="fixed_amount"
                    control={<Mui.Radio />}
                    label="Fixed amount"
                  />
                  <Mui.FormControlLabel
                    value="default"
                    control={<Mui.Radio />}
                    label="Default"
                  />
                </Mui.RadioGroup>
                {statutoryCheckBox ? (
                  <>
                    <Mui.Grid container>
                      <Mui.Grid xs={6} sx={{ padding: 1 }}>
                        <Mui.Typography>
                          Default employee contribution %
                        </Mui.Typography>
                        <CustomTextBox
                          selectedValue={employeeContributionPercentage}
                          rules={{
                            ...register("employeeContribution", {
                              required: "Please enter employee contribution",
                              pattern: {
                                value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                              },
                              validate: {
                                checkAmount: (fieldValue) => {
                                  const value = parseFloat(fieldValue);
                                  if (
                                    isNaN(value) ||
                                    value < 0 ||
                                    value > 100
                                  ) {
                                    return "Employer contribution must be less then 100";
                                  } else {
                                    return true;
                                  }
                                },
                              },
                            }),
                          }}
                          updateDataFunction={updateEmployeeContribution}
                          placeHolder="Enter employee contribution in percentage"
                          errorMessage={
                            errors?.employeeContribution?.message
                              ? errors?.employeeContribution?.message
                              : ""
                          }
                          textBoxError={
                            errors.employeeContribution ? true : false
                          }
                        />
                      </Mui.Grid>
                      <Mui.Grid xs={6} sx={{ padding: 1 }}>
                        <Mui.Typography>
                          Default company contribution %
                        </Mui.Typography>
                        <CustomTextBox
                          selectedValue={employerContributionPercentage}
                          rules={{
                            ...register("employerContribution", {
                              required: "Please enter employer contribution",
                              pattern: {
                                value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                              },
                              validate: {
                                checkAmount: (fieldValue) => {
                                  const value = parseFloat(fieldValue);
                                  if (
                                    isNaN(value) ||
                                    value < 0 ||
                                    value > 100
                                  ) {
                                    return "Employer contribution must be less then 100";
                                  } else {
                                    return true;
                                  }
                                },
                              },
                            }),
                          }}
                          updateDataFunction={updateEmployerContribution}
                          placeHolder="Enter employer contribution in percentage"
                          errorMessage={
                            errors?.employerContribution?.message
                              ? errors?.employerContribution?.message
                              : ""
                          }
                          textBoxError={
                            errors.employerContribution ? true : false
                          }
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid container>
                      <Mui.Stack direction="row">
                        <Mui.Checkbox
                          checked={companyContributionCTC}
                          onChange={(event) =>
                            setCompanyContributionCTC(event.target.checked)
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <Mui.Typography sx={{ paddingTop: 1 }}>
                          Company contribution part of CTC
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.Grid>
                    <Mui.Grid container>
                      <Mui.Typography sx={{ paddingTop: 1 }}>
                        Choose all related earning components
                      </Mui.Typography>
                    </Mui.Grid>
                    <Controller
                      name="earning"
                      control={control}
                      defaultValue={selectedMembers}
                      rules={{
                        required: "Please select at least one earning ",
                      }}
                      render={({ field }) => (
                        <Mui.Autocomplete
                          {...field}
                          size="small"
                          fullWidth
                          multiline
                          multiple
                          limitTags={1}
                          disableCloseOnSelect
                          options={members}
                          getOptionLabel={(option) =>
                            option?.name ? option?.name : ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          ListboxProps={{ style: { maxHeight: "150px" } }}
                          onChange={(event, value) => {
                            if (value) {
                              setSelectedMembers(value.name);
                              field.onChange(value);
                            } else {
                              setSelectedMembers([]);
                              field.onChange([]);
                            }
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Mui.Chip
                                variant="outlined"
                                label={option?.name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderOption={(props, option, { selected }) => {
                            return (
                              <li {...props}>
                                <Mui.Checkbox
                                  icon={<CheckBoxOutlineBlank />}
                                  checkedIcon={<CheckBox />}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                &nbsp;{option.name}
                              </li>
                            );
                          }}
                          componentsProps={{
                            popper: { style: { width: "fit-content" } },
                          }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select earning"
                              error={errors.earning}
                              helperText={
                                errors.earning ? errors.earning.message : ""
                              }
                            />
                          )}
                        />
                      )}
                    ></Controller>
                  </>
                ) : fixedAmount ? (
                  <Mui.Grid container>
                    <Mui.Typography>Amount</Mui.Typography>
                    <Mui.TextField
                      value={fixedAmountValue}
                      onChange={(event) => {
                        if (onlyNumberRegex.test(event.target.value)) {
                          setValue("Amount", event.target.value);
                          setFixedAmountValue(event.target.value);
                        }
                      }}
                      size="small"
                      fullWidth
                    />
                  </Mui.Grid>
                ) : null}
              </Mui.Grid>
            </>
          ) : null}
          <Mui.Grid
            container
            sx={{
              paddingTop: 5,
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "relative",
              height:
                selectedComponentType === "" ||
                selectedComponentType === "Expense" ||
                (selectedComponentType === "Deduction" && !statutoryCheckBox)
                  ? "22vh"
                  : null,
            }}
          >
            <Mui.Grid>
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  updateStateValue();
                  setCompanyContributionCTC(false);
                  setRadioButtonOption("default");
                }}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionName="Add"
                typeName="submit"
                disableAction={buttonDisabled}
              />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
