export const leaveSuccess = {
  holidaySuccess: "Company holiday Created.",
  leaveRequest:
    "Leave request received! You'll get a notification when it's approved.",
  leavePermission: "Permission request submitted!",
  deletedLeaveRequest: "Leave Request has been Deleted",
  checkInOutTime: "check in/out time updated successfully",
  breakInOutTime: "break start/out time updated successfully",
};

export const projectSuccess = {
  editProject: "Project updated successfully.",
  cloneProject: "Project cloned successfully.",
  createTime: "Time added successfully.",
  updateTime: "Time updated successfully.",
  deleteTime: "Time removed successfully.",
  addClient: "Client added successfully.",
  projectCreation: "Project created successfully.",
  taskCreation: "Task created successfully.",
  moveSprint:
    "Task moved to another sprint successfully. Please try to complete this sprint.",
  sprintStart: "Sprint started successfully.",
  sprintCreation: "Sprint created successfully.",
  sprintUpdate: "Sprint updated successfully.",
  completeSprint: "Sprint completed successfully.",
  taskMaterial: "Task materials added successfully.",
  createLabel: "Label created successfully.",
};
