import React, { useCallback, useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { Close, AddCircleTwoTone, Info } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { tableRowSx } from "services/constants";
let rowId = 0;
export const EditDeduction = ({
  companyId,
  otherSalaryComponents,
  userDeductionDetails,
  setUserDeductionDetails,
  updateDeductionDetails,
  setUpdateDeductionDetails,
  earningDetailsData,
  emptyDeduction,
  earningDetailsUpdate,
  setEarningDetailsUpdate,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  // const [deductionData, setDeductionData] = useState([]);
  const [remainingDeductions, setRemainingComponents] = useState([]);
  const [overallDeductions, setOverAllDeductions] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  let { result: deductionDataDetails } = useFetch(
    `${domain}deductions/?company=${companyId}`
  );

  const getEarningDetailsAfterUpdateEarning = useCallback(
    (data) => {
      let matchEarning = [];
      data?.earnings_detail?.forEach((element) => {
        earningDetailsData?.forEach((earning) => {
          if (earning?.name === element?.name) {
            matchEarning = [...matchEarning, earning];
          }
        });
      });
      return matchEarning;
    },
    [earningDetailsData]
  );

  useEffect(() => {
    const updateDeductionDetails = () => {
      userDeductionDetails?.forEach((element) => {
        const earningData = getEarningDetailsAfterUpdateEarning(element);
        const calculateEmployeeConstributionAmount =
          calculateContributionAmount(
            earningData,
            element?.employee_contribution_percentage
          );
        const calculateCompanyContributionAmount = calculateContributionAmount(
          earningData,
          element?.company_contribution_percentage
        );
        element.employeeContributionAmount =
          calculateEmployeeConstributionAmount;
        element.companyContributionAmount = calculateCompanyContributionAmount;
      });
    };
    if (earningDetailsUpdate) {
      updateDeductionDetails();
      setEarningDetailsUpdate(false);
    }
  }, [
    earningDetailsUpdate,
    getEarningDetailsAfterUpdateEarning,
    setEarningDetailsUpdate,
    userDeductionDetails,
  ]);

  useEffect(() => {
    const updateDeductionDetails = () => {
      let filterData = deductionDataDetails.filter((data) => {
        return data.deduction_type !== "D";
      });
      filterData = filterData.map((data) => {
        return {
          ...data,
          deduction_name: data.name,
        };
      });
      let deduction = filterData;
      userDeductionDetails.forEach((earn) => {
        deduction = deduction.filter((data) => {
          return data.name !== earn.deduction_name;
        });
      });
      setRemainingComponents(deduction);
      setOverAllDeductions(filterData);
      setUpdateData(false);
    };
    if (deductionDataDetails.length !== 0 && updateData) {
      updateDeductionDetails();
    }
  });

  useEffect(() => {
    const updateDeductionAmountDetails = () => {
      userDeductionDetails?.forEach((element) => {
        const earningData = getEarningDetailsAfterUpdateEarning(element);
        const calculateEmployeeConstributionAmount =
          calculateContributionAmount(
            earningData,
            element?.employee_contribution_percentage
          );
        const calculateCompanyContributionAmount = calculateContributionAmount(
          earningData,
          element?.company_contribution_percentage
        );
        element.employeeContributionAmount =
          calculateEmployeeConstributionAmount;
        element.companyContributionAmount = calculateCompanyContributionAmount;
      });
    };
    if (updateDeductionDetails && userDeductionDetails !== 0) {
      updateDeductionAmountDetails();
      setUpdateDeductionDetails(false);
    }
  }, [
    getEarningDetailsAfterUpdateEarning,
    setUpdateDeductionDetails,
    updateDeductionDetails,
    userDeductionDetails,
  ]);

  const addNewRow = () => {
    const newRow = {
      rowId: rowId + 1,
      amount: "0",
      name: "",
      deduction_id: 0,
    };
    rowId = rowId + 1;
    setUserDeductionDetails([...userDeductionDetails, newRow]);
  };

  const removeSalaryComponent = (item) => {
    let remaingData = userDeductionDetails?.filter((data) => {
      return data?.deduction_id !== item?.deduction_id;
    });
    setUserDeductionDetails(remaingData);
    removeAddedDeduction(remaingData);
  };

  // const addDeductionData = (item) => {
  //   if (item?.name) {
  //     let removeDeductionDetials = [];
  //     addedDeduction?.forEach((element) => {
  //       if (element?.name === item?.name) {
  //         removeDeductionDetials = element;
  //       }
  //     });
  //     setDeductionData([removeDeductionDetials, ...deductionData]);
  //     let filterData = addedDeduction?.filter((data) => {
  //       return data?.name !== item?.name;
  //     });
  //     setAddedDeduction(filterData);
  //   }
  // };

  const handleSalaryComponent = (data, id) => {
    if (data) {
      let updatedData = userDeductionDetails?.map((element) => {
        if (element?.rowId === id) {
          const findEarningDetails = getEarningDetails(data);
          const calculateEmployeeConstributionAmount =
            calculateContributionAmount(
              findEarningDetails,
              data?.employee_contribution_percentage
            );
          const calculateCompanyContributionAmount =
            calculateContributionAmount(
              findEarningDetails,
              data?.company_contribution_percentage
            );
          return {
            ...element,
            ...data,
            earningDetail: findEarningDetails,
            employeeContributionAmount: calculateEmployeeConstributionAmount,
            companyContributionAmount: calculateCompanyContributionAmount,
            name: data.name,
            deduction_id: data.id,
          };
        } else {
          return element;
        }
      });
      setUserDeductionDetails(updatedData);
      removeAddedDeduction(updatedData);
    }
  };

  const removeAddedDeduction = (updatedData) => {
    let deduction = overallDeductions;
    updatedData.forEach((earn) => {
      deduction = deduction.filter((data) => {
        return data.name !== earn.name;
      });
    });
    setRemainingComponents(deduction);
  };

  // const removeAddedDeduction = (item) => {
  //   let removedData = deductionData?.filter((data) => {
  //     return data?.id !== item?.id;
  //   });
  //   setDeductionData(removedData);
  // };

  const getEarningDetails = (data) => {
    let matchEarning = [];
    data?.earnings_detail?.forEach((element) => {
      earningDetailsData?.forEach((earning) => {
        if (earning?.name === element?.name) {
          matchEarning = [...matchEarning, earning];
        }
      });
    });
    return matchEarning;
  };

  const calculateContributionAmount = (data, employeeContributions) => {
    let amount = 0;
    data.forEach((element) => {
      amount = amount + parseInt(element.amount);
    });
    return Math.round(
      (parseInt(amount) * parseFloat(employeeContributions)) / 100
    );
  };

  // useEffect(() => {
  //   const filterDeductionData = () => {
  //     const deductionDetailsData = deductionData?.map((obj) => ({
  //       ...obj,
  //       label: obj?.name,
  //     }));
  //     let deductionDetails = [];
  //     deductionDetailsData?.forEach((element) => {
  //       let findDeduction = userDeductionDetails?.filter((data) => {
  //         return data?.name === element?.label;
  //       });
  //       if (findDeduction?.length === 0) {
  //         deductionDetails = [...deductionDetails, element];
  //       }
  //     });
  //     if (deductionDetails?.length === 0) {
  //     } else {
  //       // setFilterDeduction(deductionDetails);
  //     }
  //   };

  //   if (userDeductionDetails?.length !== 0) {
  //     filterDeductionData();
  //   }
  // }, [deductionData, userDeductionDetails]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2 }}>
        <Mui.Typography sx={{ fontSize: 17 }}>Deductions </Mui.Typography>{" "}
        <Mui.Tooltip title="Additional allowances can be created in the salary components screen.">
          <Info />
        </Mui.Tooltip>
      </Mui.Grid>
      <Mui.TableContainer
        sx={{
          mt: 2,
          width: "100%",
          border: `1px solid ${themes.sidebarDivider}`,
        }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Component
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Depends on
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Employee contributions
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Company contributions
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Action
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {userDeductionDetails?.map((data, index) => (
              <Mui.TableRow key={data?.deduction_id} sx={tableRowSx}>
                <>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      defaultValue={data?.deduction_name}
                      options={remainingDeductions.map((obj) => ({
                        ...obj,
                        label: obj.name,
                      }))}
                      sx={{ width: "100%" }}
                      filterOptions={(options, state) => {
                        return options?.filter((option) =>
                          option?.label
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option?.label}</li>;
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "150px",
                        },
                      }}
                      onChange={(event, value) => {
                        if (value) {
                          handleSalaryComponent(value, data?.rowId);
                        }
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          sx={{ background: themes.whiteColor }}
                          placeholder="Please select the salary component"
                          size="small"
                          // value={data.name}
                        />
                      )}
                    />
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.Tooltip
                      title={data?.earnings_detail?.map((earn) => (
                        <span style={{ paddingLeft: 1 }}>{earn?.name}</span>
                      ))}
                    >
                      {data?.earnings_detail?.map((earn) => (
                        <span style={{ paddingLeft: 2 }}>{earn?.name}</span>
                      ))}
                    </Mui.Tooltip>
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    {data?.deduction_type === "S" ? (
                      <Mui.Stack direction="row">
                        <Mui.TextField
                          sx={{ width: 150 }}
                          size="small"
                          value={data?.employee_contribution_percentage}
                          disabled={true}
                        />
                        &nbsp;
                        <Mui.TextField
                          size="small"
                          value={data?.employeeContributionAmount}
                          disabled={true}
                          fullWidth
                        />
                      </Mui.Stack>
                    ) : data?.deduction_type === "F" ? (
                      <Mui.TextField
                        size="small"
                        value={data?.fixed_amount}
                        disabled={true}
                        fullWidth
                      />
                    ) : null}
                    {data.deduction_type !== "F" &&
                    emptyDeduction &&
                    data.employeeContributionAmount === 0 &&
                    data.companyContributionAmount === 0 ? (
                      <span style={{ color: themes.txtErrorColor }}>
                        {`Please add ${data?.earnings_detail?.map(
                          (earn) => earn?.name
                        )} relevent earning`}
                      </span>
                    ) : null}
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    {data?.deduction_type === "S" ? (
                      <Mui.Stack direction="row">
                        <Mui.TextField
                          sx={{ width: 150 }}
                          size="small"
                          value={data?.company_contribution_percentage}
                          disabled={true}
                        />
                        &nbsp;
                        <Mui.TextField
                          size="small"
                          value={data?.companyContributionAmount}
                          disabled={true}
                          fullWidth
                        />
                      </Mui.Stack>
                    ) : null}
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.IconButton>
                      <Close onClick={() => removeSalaryComponent(data)} />
                    </Mui.IconButton>
                  </Mui.TableCell>
                </>
              </Mui.TableRow>
            ))}
            <Mui.TableRow>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Mui.IconButton
                  size="medium"
                  onClick={() => addNewRow()}
                  disabled={remainingDeductions?.length === 0 ? true : false}
                >
                  <Mui.Tooltip title="Add Deduction">
                    <AddCircleTwoTone />
                  </Mui.Tooltip>
                </Mui.IconButton>
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      <Mui.Grid container sx={{ paddingTop: 2 }}></Mui.Grid>
    </React.Fragment>
  );
};
