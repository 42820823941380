import React, { useRef, useCallback, useState, useEffect } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { MoreVert } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { BackArrow } from "components/CustomComponents/BackArrow";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Cancel, Done } from "@material-ui/icons";
import sessionHandling from "services/utils/notificationUtils";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { tableRowSx } from "services/constants";
import { tableContainerSx ,tableHeaderSx} from "services/constants";

export const IndividualLeaveGroupDetails = ({
  setView,
  individualLeaveGroupData,
  leaveTypeData,
  usersData,
  edit,
  id,
  setFetchIndividualLeaveGroupData,
  setFetchLeaveGroupData,
  individualLeaveType,
  individualUser,
  leaveGroupData,
  setUpdate,
  page,
  setPage,
  page1,
  setPage1,
}) => {
  const {
    control: controlForm1,
    handleSubmit: handleSubmitForm1,
    reset: resetForm1,
    formState: { errors: errorsForm1 },
  } = useForm();

  const {
    control: controlForm2,
    handleSubmit: handleSubmitForm2,
    reset: resetForm2,
    formState: { errors: errorsForm2 },
  } = useForm();
  const dispatch = useDispatch();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [moveMenuAnchorEl, setMoveMenuAnchorEl] = useState(null);
  const [type, setType] = useState(null);
  const [leaveTypeId, setLeaveTypeId] = useState("");
  const [userId, setUserId] = useState("");
  const [noOfLeaves, setNoOfLeaves] = useState("");
  const [userCode, setUserCode] = useState("");
  const [email, setEmail] = useState("");
  const [filterLeaveType, setFilterLeaveType] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const tableContainerRef = useRef(null);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [leaveTypeIdToRemove, setLeaveTypeIdToRemove] = useState(null);
  const [cancelConfirmations, setCancelConfirmations] = useState(false);
  const [userIdToRemove, setUserIdToRemove] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const handleLeaveTypeMenuClose = () => {
    setAnchorEl1(null);
  };
  const handleUserMenuClose = () => {
    setAnchorEl2(null);
  };

  const handleLeaveTypeCancel = useCallback(() => {
    resetForm1();
    setLeaveTypeId("");
    setNoOfLeaves("");
    setType(null);
  }, [resetForm1]);

  const handleUserCancel = useCallback(() => {
    resetForm2();
    setUserId("");
    setUserCode("");
    setEmail("");
    setType(null);
  }, [resetForm2]);

  const moveUser = async (groupId) => {
    try {
      const response = await fetch(
        `${domain}move-employee-other-leave-group/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            user_id: userId,
            new_leave_group_id: groupId,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        setMoveMenuAnchorEl(null);
        setUpdate(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setFetchLeaveGroupData(true);
    }
  };

  const individualLeaveGroupApi = useCallback(
    async (body, type) => {
      setButtonLoader(true);
      try {
        const response = await fetch(`${domain}update/leave-group/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify(body),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", `${type}`);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setFetchIndividualLeaveGroupData(true);
        setFetchLeaveGroupData(true);
        handleLeaveTypeCancel();
        handleUserCancel();
        setButtonLoader(false);
      }
    },
    [
      domain,
      handleLeaveTypeCancel,
      handleUserCancel,
      setFetchIndividualLeaveGroupData,
      setFetchLeaveGroupData,
      token,
    ]
  );

  const onSubmit1 = (data) => {
    if (data) {
      individualLeaveGroupApi(
        {
          id: edit?.id,
          leave_type_ids: [leaveTypeId],
          action: "add",
        },
        "Leave type added successfully"
      );
    }
  };
  const onSubmit2 = (data) => {
    if (data) {
      individualLeaveGroupApi(
        {
          id: edit?.id,
          action: "add",
          user_ids: [userId],
        },
        "User added successfully"
      );
    }
  };
  useEffect(() => {
    const filterLeaveTypeData = () => {
      const leaveTypeDetailsData = leaveTypeData?.map((obj) => ({
        ...obj,
        label: obj?.leave_type,
      }));
      let leaveTypeDetails = [];
      leaveTypeDetailsData.forEach((element) => {
        let findLeaveType = individualLeaveGroupData?.leave_types?.filter(
          (data) => {
            return data.leave_type === element.label;
          }
        );
        if (findLeaveType?.length === 0) {
          leaveTypeDetails = [...leaveTypeDetails, element];
          setFilterLeaveType(leaveTypeDetails);
        }
      });
    };

    const filterUsers = () => {
      const userDetailsData = usersData.map((obj) => ({
        ...obj,
        label: obj.user_name,
      }));
      let userDetails = [];
      userDetailsData.forEach((element) => {
        let findLeaveType = individualLeaveGroupData?.users?.filter((data) => {
          return data.user_name === element.label;
        });
        if (findLeaveType?.length === 0) {
          userDetails = [...userDetails, element];
          setFilterUsers(userDetails);
        }
      });
    };

    if (individualLeaveGroupData?.length !== 0) {
      filterLeaveTypeData();
      filterUsers();
    }
  }, [
    individualLeaveGroupData?.leave_types,
    individualLeaveGroupData?.length,
    individualLeaveGroupData?.users,
    leaveTypeData,
    usersData,
  ]);

  const handleRemoveLeaveType = () => {
    setLeaveTypeIdToRemove(leaveTypeId);
    setCancelConfirmation(true);
  };
  const handleRemoveUser = () => {
    setUserIdToRemove(userId);
    setCancelConfirmations(true);
  };

  const handleCancelConfirmationClose = () => {
    setCancelConfirmation(false);
    setLeaveTypeIdToRemove(null);
  };

  const handleConfirmRemoveLeaveType = () => {
    if (leaveTypeIdToRemove) {
      individualLeaveGroupApi(
        {
          id: edit?.id,
          leave_type_ids: [leaveTypeIdToRemove],
          action: "remove",
        },
        "Leave type removed successfully"
      );
    }
  };

  const handleCancelConfirmation = () => {
    setCancelConfirmations(false);
    setUserIdToRemove(null);
  };

  const handleConfirmRemoveUser = () => {
    if (userIdToRemove) {
      individualLeaveGroupApi(
        {
          id: edit?.id,
          action: "remove",
          user_ids: [userIdToRemove],
        },
        "User removed successfully"
      );
    }
  };

  return (
    <React.Fragment>
      <Mui.TableContainer ref={tableContainerRef} sx={tableContainerSx}>
        <form onSubmit={handleSubmitForm1(onSubmit1)} noValidate>
          <BackArrow
            actionFunction={() => {
              setView("LeaveGroup");
              setType(null);
              dispatch(setGlobalSearchValue(""));
            }}
          />
          <Mui.Typography style={{ margin: 10, fontSize: 20 }}>
            <b>Leave Group Name:</b>{" "}
            <b style={{ color: themes.redColor }}>
              {individualLeaveGroupData?.name}
            </b>
          </Mui.Typography>
          <Mui.Table>
            <Mui.TableHead sx={tableHeaderSx}>
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Leave Type
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  No of Leaves
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Actions
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {individualLeaveType?.length === 0 ? (
                <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                  No data found
                </Mui.Alert>
              ) : (
                individualLeaveType
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <Mui.TableRow
                      key={item?.id}
                      sx={tableRowSx}
                      onDoubleClick={() => {
                        if (tableContainerRef.current) {
                          tableContainerRef.current.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }
                        setView("ViewLeaveGroup");
                      }}
                      maxWidth="xl"
                      align="left"
                    >
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.Typography>
                          {item?.leave_type ? item?.leave_type : "N/A"}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.Typography>
                          {item?.leave_per_period
                            ? item?.leave_per_period
                            : "N/A"}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.IconButton
                          onClick={(event) => {
                            setAnchorEl1(event.currentTarget);
                            setLeaveTypeId(item?.id);
                          }}
                        >
                          <MoreVert />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorEl1}
                          open={Boolean(anchorEl1)}
                          onClose={handleLeaveTypeMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.MenuItem
                            sx={{ width: "100%" }}
                            onClick={() => handleRemoveLeaveType()}
                          >
                            Remove
                          </Mui.MenuItem>
                        </Mui.Menu>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))
              )}
              {type === "AddLeaveType" ? (
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell>
                    <Controller
                      name="leave_type"
                      control={controlForm1}
                      rules={{
                        required: "Please select at least one leave type",
                      }}
                      render={({ field }) => (
                        <Mui.Autocomplete
                          disablePortal
                          size="small"
                          options={filterLeaveType}
                          getOptionLabel={(option) => option?.leave_type ?? ""}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          filterOptions={(options, state) => {
                            return options.filter((option) => {
                              return option?.leave_type
                                ?.toLowerCase()
                                .includes(state.inputValue?.toLowerCase());
                            });
                          }}
                          renderOption={(props, option) => {
                            return <li {...props}>{option?.leave_type}</li>;
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              overflow: "auto",
                            },
                          }}
                          onChange={async (event, value) => {
                            if (value) {
                              setLeaveTypeId(value?.id);
                              setNoOfLeaves(value?.leave_per_period);
                              field.onChange(value?.leave_type);
                            } else {
                              setLeaveTypeId("");
                              setNoOfLeaves("");
                              field.onChange("");
                            }
                          }}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{
                                background: themes.whiteColor,
                                padding: 0.5,
                              }}
                              placeholder="Select leave type"
                              size="small"
                              error={errorsForm1.leave_type}
                              helperText={
                                errorsForm1.leave_type
                                  ? errorsForm1.leave_type.message
                                  : ""
                              }
                            />
                          )}
                        />
                      )}
                    ></Controller>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    {noOfLeaves ? noOfLeaves : "N/A"}
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <React.Fragment>
                      <Mui.IconButton onClick={handleSubmitForm1(onSubmit1)}>
                        {buttonLoader ? (
                          <Mui.CircularProgress
                            sx={{
                              color: themes.primaryIndicator,
                            }}
                            size={24}
                          />
                        ) : (
                          <Done
                            sx={{
                              color: themes.greenColor,
                              strokeWidth: 2,
                            }}
                          />
                        )}
                      </Mui.IconButton>
                      <Mui.IconButton onClick={handleLeaveTypeCancel}>
                        <Cancel
                          sx={{
                            color: themes.redColor,
                            strokeWidth: 2,
                          }}
                        />
                      </Mui.IconButton>
                    </React.Fragment>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : null}
            </Mui.TableBody>
          </Mui.Table>
        </form>
        {individualLeaveGroupData?.leave_types?.length > 10 ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={individualLeaveGroupData?.leave_types?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
        <Mui.IconButton
          sx={{ stroke: "grey", strokeWidth: 1 }}
          onClick={() => {
            if (type === "AddUsers") {
              showToast(
                "error",
                "Please complete the editing firstConcurrent editing sessions are not permitted. Kindly finish or exit the existing session to proceed with a new edit."
              );
            } else {
              setType("AddLeaveType");
            }
          }}
        >
          <AddCircleOutlineIcon size="medium" />
        </Mui.IconButton>
        <Mui.Typography style={{ margin: 10, paddingTop: 30, fontSize: 20 }}>
          <b>Users</b>
        </Mui.Typography>
        <form onSubmit={handleSubmitForm2(onSubmit2)} noValidate>
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  User Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  User Code
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Email
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Actions
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {individualUser?.length === 0 ? (
                <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                  No data available
                </Mui.Alert>
              ) : (
                individualUser
                  ?.slice(
                    page1 * rowsPerPage1,
                    page1 * rowsPerPage1 + rowsPerPage1
                  )
                  .map((item, index) => (
                    <Mui.TableRow
                      key={item?.id}
                      sx={tableRowSx}
                      onDoubleClick={() => {
                        setView("ViewLeaveGroup");
                      }}
                      maxWidth="xl"
                      align="left"
                    >
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.Typography>
                          {item?.user_name ? item?.user_name : "N/A"}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.Typography>
                          {item?.user_code ? item?.user_code : "N/A"}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.Typography>
                          {item?.email ? item?.email : "N/A"}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.IconButton
                          onClick={(event) => {
                            setAnchorEl2(event.currentTarget);
                            setUserId(item?.id);
                          }}
                        >
                          <MoreVert />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorEl2}
                          open={Boolean(anchorEl2)}
                          onClose={handleUserMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.MenuItem
                            sx={{ width: "100%" }}
                            onClick={() => handleRemoveUser()}
                          >
                            Remove
                          </Mui.MenuItem>
                          <Mui.MenuItem
                            sx={{ width: "100%" }}
                            onClick={(event) => {
                              setMoveMenuAnchorEl(event.currentTarget);
                            }}
                          >
                            Move
                            <Mui.IconButton
                              onClick={(event) => {
                                setMoveMenuAnchorEl(event.currentTarget);
                              }}
                            >
                              <ChevronRightIcon />
                            </Mui.IconButton>
                          </Mui.MenuItem>
                        </Mui.Menu>
                        <Mui.Popover
                          anchorEl={moveMenuAnchorEl}
                          open={Boolean(moveMenuAnchorEl)}
                          onClose={() => {
                            setMoveMenuAnchorEl(null);
                          }}
                          elevation={1}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          {leaveGroupData.map((group) => (
                            <Mui.MenuItem
                              elevation={1}
                              key={group.id}
                              onClick={() => moveUser(group.id)}
                            >
                              {group.name}
                            </Mui.MenuItem>
                          ))}
                        </Mui.Popover>{" "}
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))
              )}
              {type === "AddUsers" ? (
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell>
                    <Controller
                      name="user_name"
                      control={controlForm2}
                      rules={{
                        required: "Please select at least one users",
                      }}
                      render={({ field }) => (
                        <Mui.Autocomplete
                          disablePortal
                          size="small"
                          options={filterUsers}
                          getOptionLabel={(option) => option?.user_name ?? ""}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          filterOptions={(options, state) => {
                            return options.filter((option) => {
                              return option?.user_name
                                ?.toLowerCase()
                                .includes(state.inputValue?.toLowerCase());
                            });
                          }}
                          renderOption={(props, option) => {
                            return <li {...props}>{option?.user_name}</li>;
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              overflow: "auto",
                            },
                          }}
                          onChange={async (event, value) => {
                            if (value) {
                              setUserId(value?.id);
                              setUserCode(value?.user_code);
                              setEmail(value?.email);
                              field.onChange(value?.user_name);
                            } else {
                              setUserId("");
                              setUserCode("");
                              setEmail("");
                              field.onChange("");
                            }
                          }}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{
                                background: themes.whiteColor,
                                padding: 0.5,
                              }}
                              placeholder="Select users"
                              size="small"
                              error={errorsForm2.user_name}
                              helperText={
                                errorsForm2.user_name
                                  ? errorsForm2.user_name.message
                                  : ""
                              }
                            />
                          )}
                        />
                      )}
                    ></Controller>
                  </Mui.TableCell>
                  <Mui.TableCell>{userCode ? userCode : "N/A"}</Mui.TableCell>
                  <Mui.TableCell>{email ? email : "N/A"}</Mui.TableCell>
                  <Mui.TableCell>
                    <React.Fragment>
                      <Mui.IconButton onClick={handleSubmitForm2(onSubmit2)}>
                        {buttonLoader ? (
                          <Mui.CircularProgress
                            sx={{
                              color: themes.primaryIndicator,
                            }}
                            size={24}
                          />
                        ) : (
                          <Done
                            sx={{
                              color: themes.greenColor,
                              strokeWidth: 2,
                            }}
                          />
                        )}
                      </Mui.IconButton>
                      <Mui.IconButton onClick={handleUserCancel}>
                        <Cancel
                          sx={{
                            color: themes.redColor,
                            strokeWidth: 2,
                          }}
                        />
                      </Mui.IconButton>
                    </React.Fragment>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : null}
            </Mui.TableBody>
          </Mui.Table>
        </form>
        {individualLeaveGroupData?.users?.length > 10 ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={individualLeaveGroupData?.users?.length}
            rowsPerPage={rowsPerPage1}
            page={page1}
            onPageChange={handleChangePage1}
            onRowsPerPageChange={handleChangeRowsPerPage1}
          />
        ) : null}
        <Mui.IconButton
          sx={{ stroke: "grey", strokeWidth: 1 }}
          onClick={() => {
            if (type === "AddLeaveType") {
              showToast(
                "error",
                "Please complete the editing firstConcurrent editing sessions are not permitted. Kindly finish or exit the existing session to proceed with a new edit."
              );
            } else {
              setType("AddUsers");
            }
          }}
        >
          <AddCircleOutlineIcon size="medium" />
        </Mui.IconButton>
      </Mui.TableContainer>

      <GlobalDialogBox
        open={cancelConfirmation}
        handleCloseDialog={handleCancelConfirmationClose}
        title="Remove Leave Type"
      >
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 21 }}>
            Are you sure you want to remove the{" "}
            <b style={{ color: themes.redColor }}>
              {
                individualLeaveGroupData?.leave_types?.find(
                  (item) => item.id === leaveTypeIdToRemove
                )?.leave_type
              }
            </b>{" "}
            from this leave group?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.DialogActions>
          <CustomButton
            actionFuntion={handleCancelConfirmationClose}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={handleConfirmRemoveLeaveType}
            actionName="Yes"
            disableAction={
              cancelConfirmation && buttonLoader ? buttonLoader : false
            }
            typeName="button"
          />
        </Mui.DialogActions>
      </GlobalDialogBox>

      <GlobalDialogBox
        open={cancelConfirmations}
        handleCloseDialog={handleCancelConfirmation}
        title="Remove User"
      >
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 21 }}>
            Are you sure you want to remove the user{" "}
            <b style={{ color: themes.redColor }}>
              {
                individualLeaveGroupData?.users?.find(
                  (item) => item.id === userIdToRemove
                )?.user_name
              }
            </b>{" "}
            from this leave group? <br></br>
            <span style={{ color: "red" }}>Note : </span>Removing a user from a
            leave group will remove any previously assigned leave types for the
            user.
          </Mui.Typography>
        </Mui.Grid>
        <Mui.DialogActions>
          <CustomButton
            actionFuntion={handleCancelConfirmation}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={handleConfirmRemoveUser}
            actionName="Yes"
            disableAction={buttonLoader}
            typeName="button"
          />
        </Mui.DialogActions>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
