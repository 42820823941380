import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import { themes } from "services/constants";
import dayjs from "dayjs";
import { CalendarMonth } from "@mui/icons-material";
import { Loader } from "components/Loader";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { format, parse } from "date-fns";
import "./ButtonGroup.css";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import sessionHandling from "services/utils/notificationUtils";
import moment from "moment";

export const ApplyLeave = ({ handleClose }) => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [selectedUserId, setSelectedUserId] = useState(userDetails.id);
  const { result: employeeNameOptions, loading } = useFetch(
    `${domain}list/employee?user_id=${userDetails.id}`
  );
  const { result: leaveTypeOptions, setLoad: reCallApi } = useFetch(
    `${domain}leavetypefilter/?user_id=${selectedUserId}`
  );
  const [selectedHalfDay, setSelectedHalfDay] = useState(null);
  const [checkboxChecked, setcheckboxChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [firstHalfChecked, setFirstHalfChecked] = useState(false);
  const [secondHalfChecked, setSecondHalfChecked] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [leaveTypeDetails, setLeaveTypeDetails] = useState("");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveStartDate, setLeaveStartDate] = useState("");
  const [leaveEndDate, setLeaveEndDate] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const { register, handleSubmit, reset, setValue, watch, trigger, formState } =
    useForm({ mode: "onBlur" });
  const { errors, isValid } = formState;

  const checkFromDate = watch("from_date");
  const checkToDate = watch("to_date");

  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  const validateEndDate = (value) => {
    const endDate = format(new Date(value), "yyyy-MM-dd");
    if (startDate && endDate < startDate) {
      return "End date cannot be before the start date";
    }
    return true;
  };

  const resetForm = () => {
    reset();
    setcheckboxChecked(false);
    setChecked(false);
    setFirstHalfChecked(false);
    setSecondHalfChecked(false);
    setSecondHalfChecked(false);
    const today = dayjs().format("YYYY-MM-DD");
    setStartDate(today);
    setEndDate(today);
    setValue("from_date", today);
    setValue("to_date", today);
  };

  const handleClearButtonClick = () => {
    resetForm();
  };

  const onSubmit = async (data) => {
    const currentUserDetails = employeeNameOptions?.filter((emp) => {
      return emp.user_name === data.user_name;
    });
    const currentLeaveType = leaveTypeOptions?.filter((leave) => {
      return leave.leave_type === data.leave_type;
    });
    setButtonLoader(true);
    try {
      const halfDay = firstHalfChecked || secondHalfChecked;
      const whichHalf = firstHalfChecked
        ? "1st"
        : secondHalfChecked
        ? "2nd"
        : null;
      const response = await fetch(`${domain}create/leave-request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          ...data,
          is_half_day: halfDay,
          half_day_period: whichHalf,
          user: currentUserDetails[0].user_id,
          status: "Pending",
          applied_date: new Date().toISOString().split("T")[0],
          createdby: userDetails.first_name,
          createdat: new Date().toISOString(),
          modifiedby: userDetails.first_name,
          modifiedat: new Date().toISOString(),
          leave_type_id: currentLeaveType[0].id,
          action: "Approved",
          approver_id: userDetails.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast(
          "success",
          `You created ${
            currentUserDetails[0].user_name
          } leave request on ${checkFromDate
            .split("-")
            .reverse()
            .join("-")} to ${checkToDate
            .split("-")
            .reverse()
            .join("-")} and it has been approved.`
        );
        reset();
        setEmployeeName("");
        setLeaveTypeDetails("");
        setcheckboxChecked(false);
        setChecked(false);
        setFirstHalfChecked(false);
        setSecondHalfChecked(false);
        if (
          new Date(leaveStartDate).getFullYear() === new Date().getFullYear() ||
          new Date(leaveEndDate).getFullYear() === new Date().getFullYear()
        ) {
          const today = dayjs().format("YYYY-MM-DD");
          setStartDate(today);
          setEndDate(today);
        } else {
          setStartDate(leaveStartDate);
          setEndDate(leaveEndDate);
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const today = dayjs().format("YYYY-MM-DD");
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setLeaveStartDate(sDate);
          setLeaveEndDate(eDate);
          if (
            new Date(
              res[0]?.start_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear() ||
            new Date(
              res[0]?.end_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear()
          ) {
            setStartDate(today);
            setEndDate(today);
          } else {
            setStartDate(sDate);
            setEndDate(eDate);
          }
          setValue("from_date", today);
          setValue("to_date", today);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, setValue, token]);

  const handleChangeLeavePeriod = (event, value) => {
    const today = dayjs().format("YYYY-MM-DD");
    if (value && value.filteredValue) {
      setFilteredLabel(value.label);
      setFilteredValue(value.filteredValue);
      setStartDate(formatDate(value.start_date));
      setEndDate(formatDate(value.end_date));
      setLeaveStartDate(formatDate(value.start_date));
      setLeaveEndDate(formatDate(value.end_date));
      if (
        new Date(
          value.start_date.split("-").reverse().join("-")
        ).getFullYear() === new Date().getFullYear() ||
        new Date(
          value.end_date.split("-").reverse().join("-")
        ).getFullYear() === new Date().getFullYear()
      ) {
        let startDateConvertions = moment(new Date()).format("DD-MM-YYYY");
        setStartDate(formatDate(startDateConvertions));
        setEndDate(formatDate(startDateConvertions));
        setValue("from_date", formatDate(startDateConvertions));
        setValue("to_date", formatDate(startDateConvertions));
      } else {
        setStartDate(formatDate(value.start_date));
        setEndDate(formatDate(value.end_date));
        setValue("from_date", formatDate(value.start_date));
        setValue("to_date", formatDate(value.end_date));
      }
    } else {
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate("");
      setLeaveEndDate("");
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate(today);
      setLeaveEndDate(today);
      setStartDate(today);
      setEndDate(today);
    }
  };

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setcheckboxChecked(checked);
    const today = dayjs().format("YYYY-MM-DD");
    const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD");

    if (checked) {
      setStartDate(tomorrow);
      setEndDate(tomorrow);
      setValue("from_date", tomorrow);
      setValue("to_date", tomorrow);
      setSecondHalfChecked(false);
      setFirstHalfChecked(false);
      setChecked(false);
    } else {
      setStartDate(today);
      setEndDate(today);
      setValue("from_date", today);
      setValue("to_date", today);
      setSecondHalfChecked(false);
      setFirstHalfChecked(false);
      setChecked(false);
    }
  };

  const handleCheckboxChanged = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      setEndDate(startDate);
      setcheckboxChecked(false);
    } else {
      setEndDate(endDate);
      setcheckboxChecked(false);
    }
  };

  const handleStartDateChange = (event) => {
    const date = event.target.value;
    setStartDate(date);
    setEndDate(date);
    setValue("from_date", date);
    setValue("to_date", date);
    trigger("from_date");
  };
  const handleEndDateChange = (event) => {
    const date = event.target.value;
    setEndDate(date);
    setValue("to_date", date);
    trigger("to_date");
  };

  const handleSetValue = () => {
    setValue("from_date", startDate);
    setValue("to_date", endDate);
  };

  const handleFirstHalfCheckboxChanged = (event) => {
    const checked = event.target.checked;
    setFirstHalfChecked(checked);
    if (checked) {
      setSecondHalfChecked(false);
      setSelectedHalfDay("1st-half");
      setcheckboxChecked(false);
    } else {
      setSelectedHalfDay(null);
    }
  };

  const handleSecondHalfCheckboxChanged = (event) => {
    const checked = event.target.checked;
    setSecondHalfChecked(checked);
    if (checked) {
      setFirstHalfChecked(false);
      setSelectedHalfDay("2nd-half");
      setcheckboxChecked(false);
    } else {
      setSelectedHalfDay(null);
    }
  };

  const updateUserName = (event, data) => {
    if (data) {
      setSelectedUserId(data?.user_id);
      setValue("user_name", data?.user_name);
      setEmployeeName(data?.user_name);
      setValue("leave_type", "");
      reCallApi(true);
    }
  };

  const updateLeaveTypeDetails = (event, data) => {
    if (data) {
      setLeaveTypeDetails(data?.label);
      setValue("leave_type", data?.label);
      setValue("leave_type_id", data?.id);
    }
  };

  return (
    <>
      {loading ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : (
        <React.Fragment>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Mui.Grid
              container
              sx={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "5%",
              }}
            >
              <Mui.Grid
                container
                sx={{
                  padding: 1,
                  backgroundColor: themes.primary,
                  borderRadius: "10px 10px 0 0",
                  border: `2px solid ${themes.sidebarDivider}`,
                  borderBottom: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Mui.Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 800,
                    font: "bold",
                    textAlign: "center",
                  }}
                >
                  Apply Leave
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                container
                sx={{
                  border: `2px solid ${themes.sidebarDivider}`,
                  padding: 2,
                  borderRadius: "0 0 10px 10px",
                }}
              >
                <Mui.Grid
                  container
                  spacing={2}
                  justifyContent={"space-between"}
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    value={
                      leaveDate?.find(
                        (option) => option?.filteredValue === filteredValue
                      ) || ""
                    }
                    options={leaveDate}
                    style={{
                      width: 305,
                    }}
                    getOptionLabel={(option) =>
                      option?.filteredValue ?? filteredValue ?? ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.filteredValue === value?.filteredValue
                    }
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.filteredValue
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.filteredValue}</li>;
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: 150,
                      },
                    }}
                    onChange={handleChangeLeavePeriod}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        sx={{ background: themes.whiteColor }}
                        placeholder="Select leave period"
                        label={filteredLabel ? filteredLabel : "Leave Period"}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <CalendarMonth
                                style={{
                                  margin: "0 8px",
                                  color: "#a6a6a6",
                                }}
                              />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                      Employee Name
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <CustomAutoComplete
                      dataList={employeeNameOptions?.map((obj) => ({
                        ...obj,
                        label: obj?.user_name,
                      }))}
                      rules={{
                        ...register("user_name", {
                          required: "Employee name is required",
                        }),
                      }}
                      selectedValue={employeeName}
                      updateDataFunction={updateUserName}
                      placeHolder="Select employee name"
                      errorMessage={
                        errors?.user_name?.message
                          ? errors?.user_name?.message
                          : ""
                      }
                      textBoxError={errors?.user_name ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                      Leave Type
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <CustomAutoComplete
                      dataList={leaveTypeOptions?.map((obj) => ({
                        ...obj,
                        label: obj?.leave_type,
                      }))}
                      rules={{
                        ...register("leave_type", {
                          required: "Leave type is required",
                        }),
                      }}
                      selectedValue={leaveTypeDetails}
                      updateDataFunction={updateLeaveTypeDetails}
                      placeHolder="Select leave type"
                      errorMessage={
                        errors?.leave_type?.message
                          ? errors?.leave_type?.message
                          : ""
                      }
                      textBoxError={errors?.leave_type ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}></Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.Grid container>
                      <Mui.Grid xs={6}>
                        <Mui.FormControlLabel
                          control={<Mui.Checkbox />}
                          label="Only For Tomorrow"
                          checked={checkboxChecked}
                          onChange={handleCheckboxChange}
                          onClick={handleSetValue}
                        />
                      </Mui.Grid>
                      <Mui.Grid xs={6}>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={checked}
                              onChange={handleCheckboxChanged}
                            />
                          }
                          label="Half Day"
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Grid>
                  {checked && (
                    <Mui.Grid container sx={{ paddingTop: 1 }}>
                      <Mui.Grid xs={4}></Mui.Grid>
                      <Mui.Grid xs={8}>
                        <Mui.Grid container>
                          <Mui.Grid xs={6}>
                            <Mui.FormControlLabel
                              control={
                                <Mui.Checkbox
                                  checked={firstHalfChecked}
                                  onChange={handleFirstHalfCheckboxChanged}
                                />
                              }
                              label="1st-half"
                            />
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            <Mui.FormControlLabel
                              control={
                                <Mui.Checkbox
                                  checked={secondHalfChecked}
                                  onChange={handleSecondHalfCheckboxChanged}
                                />
                              }
                              label="2nd-half"
                            />
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                  )}
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Grid xs={4}>
                    <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                      Start Date
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.TextField
                      size="small"
                      type="date"
                      value={startDate}
                      id="from_date"
                      inputProps={{
                        min: leaveStartDate,
                        max: leaveEndDate,
                      }}
                      {...register("from_date", {
                        required: "start date is required.",
                      })}
                      error={errors.from_date}
                      onChange={handleStartDateChange}
                      helperText={errors.from_date && errors.from_date.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={checkboxChecked}
                      fullWidth
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                      End Date
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.TextField
                      size="small"
                      type="date"
                      id="to_date"
                      value={endDate}
                      inputProps={{
                        min: leaveStartDate,
                        max: leaveEndDate,
                      }}
                      {...register("to_date", {
                        required: "End date is required.",
                        validate: {
                          dateNotBeforeStart: validateEndDate,
                        },
                      })}
                      onChange={handleEndDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={checked ? checked : false || checkboxChecked}
                      error={errors.to_date}
                      helperText={errors.to_date && errors.to_date.message}
                      fullWidth
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <br />
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                      Reason for Leave
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.TextField
                      fullWidth
                      id="description"
                      {...register("description", {
                        required: "Reason for leave is required",
                      })}
                      placeholder="Provide a reason for your leave"
                      multiline
                      minRows={2}
                      error={errors.description}
                      helperText={
                        errors.description && errors.description.message
                      }
                      onBlur={() => {
                        trigger("description");
                      }}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <br />
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid item xs={7}></Mui.Grid>
                  <Mui.Grid
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <CustomButton
                      actionFuntion={handleClearButtonClick}
                      actionName="Clear"
                      typeName="button"
                    />
                    &nbsp;&nbsp;
                    {buttonLoader ? (
                      <CustomButton
                        disableAction={buttonLoader}
                        type="submit"
                        actionName="Approve"
                      />
                    ) : (
                      <Mui.Button
                        sx={{
                          width: "100px",
                          height: "36px",
                          fontWeight: 600,
                          display: "block",
                          backgroundColor: themes.primaryButton,
                          "&:hover": {
                            backgroundColor: themes.primaryButton,
                          },
                          textTransform: "capitalize",
                          color: themes.headLine,
                        }}
                        type="submit"
                        variant="contained"
                      >
                        Approve
                      </Mui.Button>
                    )}
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </form>
        </React.Fragment>
      )}
    </>
  );
};
