import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { themes } from "services/constants";

export const BackArrow = ({ actionFunction }) => {
  return (
    <IconButton
      onClick={actionFunction}
      sx={{
        textTransform: "capitalize",
        color: themes.headLine,
      }}
      variant="contained"
      size="medium"
    >
      <ArrowCircleLeftIcon color={themes.headLine} fontSize="large" />
    </IconButton>
  );
};
