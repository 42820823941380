import React, { useState, useEffect } from "react";
import { ViewGroupMembers } from "../ViewGroupMembers";
import { EditAssignGroup1 } from "./EditAssignGroup1";
import { AssignUnitTable } from "./AssignUnitTable";
import { AddAssignGroup } from "./AddAssignGroup";

export const AssignUnit = ({
  assignGroupUnit,
  setAssignGroupUnit,
  setEditAssignUnit,
  setShowTopBar,
  departmentId,
  selectComponent,
  refreshCall,
  setRefreshCall,
}) => {
  const [viewAssignDetails, setViewAssignDetails] = useState({});
  const [renderComponentName, setRenderComponentName] = useState("ListView");
  useEffect(() => {
    if (assignGroupUnit) {
      setRenderComponentName("AddAssignGroup");
      setAssignGroupUnit(false);
    }
  }, [assignGroupUnit, setAssignGroupUnit]);

  const renderComponent = () => {
    switch (renderComponentName) {
      case "ListView":
        return (
          <AssignUnitTable
            setRenderComponentName={setRenderComponentName}
            setViewAssignDetails={setViewAssignDetails}
            setShowTopBar={setShowTopBar}
            viewAssignDetails={viewAssignDetails}
            departmentId={departmentId}
            selectComponent={selectComponent}
            refreshCall={refreshCall}
            setRefreshCall={setRefreshCall}
          />
        );
      case "EditAssignGroup":
        return (
          <EditAssignGroup1
            viewAssignDetails={viewAssignDetails}
            setRenderComponentName={setRenderComponentName}
            setShowTopBar={setShowTopBar}
          />
        );
      case "AddAssignGroup":
        return (
          <AddAssignGroup
            backToAssignScreens={setEditAssignUnit}
            setShowTopBar={setShowTopBar}
            setRenderComponentName={setRenderComponentName}
          />
        );
      case "ViewAssignGroup":
        return (
          <ViewGroupMembers
            viewAssignDetails={viewAssignDetails}
            setShowTopBar={setShowTopBar}
            setRenderComponentName={setRenderComponentName}
          />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {renderComponent()}
    </React.Fragment>
  );
};
