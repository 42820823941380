import React, { useEffect, useState, useRef } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ChangeDepartment } from "./ChangeDepartment";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { BackArrow } from "components/CustomComponents/BackArrow";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import sessionHandling from "services/utils/notificationUtils";
import { useDispatch } from "react-redux";
import {
  themes,
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
  tableCellFontSize,
  scrollTop,
} from "services/constants";
import { departmentMembersHeaderAction } from "pages/Administration/AdministrationHeaders";

export const ViewMembers = () => {
  const dispatch = useDispatch();
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId, departmentLeadId } = useSelector(
    (state) => state.userReducer
  );
  const location = useLocation();
  const [reloadApi, setReloadApi] = useState(true);
  const [departmentMembers, setDepartmentMembers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [changeDepartment, setChangeDepartment] = useState(false);
  const [page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(25);
  const history = useHistory();
  const [members, setMembers] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const tableContainerRef = useRef(null);
  const [getDepartmentDetails, setGetDepartmentDetails] = useState(false);

  useEffect(() => {
    scrollTop(tableContainerRef);
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  useEffect(() => {
    const getDepartment = async () => {
      try {
        const response = await fetch(
          `${domain}department-based-shiftcategory/?shift_category_id=${departmentMembers.shift_category_id}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const allDepartments = res.map((obj) => ({
            ...obj,
            label: obj.department_name,
          }));
          setDepartmentList(allDepartments);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (getDepartmentDetails) {
      getDepartment();
      setGetDepartmentDetails(false);
    }
  }, [
    departmentMembers.shift_category_id,
    domain,
    getDepartmentDetails,
    token,
  ]);

  useEffect(() => {
    const fetchDepartmentMembers = async () => {
      try {
        const response = await fetch(
          `${domain}get_department_members/${departmentLeadId}/${departmentId}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDepartmentMembers(res);
          setMasterDataForSearch(res.members_data);
          setMembers(res.members_data);
          setGetDepartmentDetails(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (reloadApi) {
      fetchDepartmentMembers();
      setReloadApi(false);
    }
  }, [departmentId, departmentLeadId, domain, reloadApi, token]);

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setMembers(FilterDockets());
    setLeavePage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, masterDataForSearch, page]);

  const departmentMembersCard = [
    { name: "Name", type: departmentMembers?.department_name },
    { name: "Lead", type: departmentMembers?.department_lead_name },
    { name: "Proxy", type: departmentMembers?.proxy_name },
    { name: "Shift Category", type: departmentMembers?.shift_category_name },
    { name: "Total Members", type: departmentMembers?.total_members },
  ];

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 3, paddingBottom: 1 }}>
        <Mui.Grid xs={12} sm={12} md={0.4} lg={0.4}>
          <BackArrow
            actionFunction={() => {
              if (location.pathname === "/admin/attendance/view/members") {
                dispatch(setGlobalSearchValue(""));
                history.push("/admin/teamzone/attendance");
              } else {
                dispatch(setGlobalSearchValue(""));
                history.push("/admin/departments");
              }
            }}
          />
        </Mui.Grid>
        {departmentMembersCard?.map((value) => (
          <Mui.Grid
            xs={12}
            sm={12}
            md={2.3}
            lg={2.3}
            sx={{
              textAlign: "center",
              borderRight: `2px solid ${themes.blackColor}`,
            }}
          >
            <Mui.Stack>
              <Mui.Typography
                sx={{
                  color: themes.primaryIndicator,
                  fontWeight: "bold",
                  fontSize: tableCellFontSize,
                }}
              >
                {value?.name}
              </Mui.Typography>
              <Mui.Typography
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  fontSize: tableCellFontSize,
                }}
              >
                {value?.type}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
        ))}
      </Mui.Grid>
      <Mui.TableContainer
        component={Mui.Paper}
        sx={tableContainerSx}
        ref={tableContainerRef}
      >
        <Mui.Table>
          <Mui.TableHead sx={tableHeaderSx}>
            <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
              {departmentMembersHeaderAction?.map?.((value) => (
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    fontSize: tableCellFontSize,
                  }}
                >
                  {value?.name}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {members
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((member) => (
                <React.Fragment>
                  <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        fontSize: tableCellFontSize,
                      }}
                    >
                      <Mui.Stack direction="row">
                        <Mui.Avatar
                          sx={{
                            width: 25,
                            height: 25,
                            fontSize: tableCellFontSize,
                          }}
                          alt={member?.department_member_name.charAt(0)}
                          src={member?.image_url}
                        />
                        <Mui.Typography
                          sx={{
                            paddingLeft: 1,
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {member?.department_member_name}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {member?.user_code ? member?.user_code : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {member?.designation ? member?.designation : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {member?.employment_type
                        ? member?.employment_type
                        : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {member?.phonenumber}
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {member?.system_access ? member?.system_access : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {member?.status}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      <Mui.IconButton
                        onClick={(event) => {
                          setEditUser(member);
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                      >
                        <Mui.MenuItem
                          onClick={() => {
                            setChangeDepartment(true);
                            setAnchorEl(null);
                          }}
                        >
                          Change Department
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                </React.Fragment>
              ))}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {members?.length > 25 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[25, 50, 75, 100]}
          count={members?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {changeDepartment ? (
        <ChangeDepartment
          changeDepartment={changeDepartment}
          setChangeDepartment={setChangeDepartment}
          editUser={editUser}
          departmentList={departmentList}
          departmentName={departmentMembers?.department_name}
          setAnchorEl={setAnchorEl}
          setReloadApi={setReloadApi}
        />
      ) : null}
    </React.Fragment>
  );
};
