import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { customAPI } from "services/utils/CustomAPI";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { leaveSuccess } from "services/constants/SuccessMessages";
import moment from "moment";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { Loader } from "components/Loader";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { leaveManagementError } from "services/constants/ErrorMessages";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { getContrastingTextColor } from "services/utils/colorChecker";
import { getColorWorkingHours } from "services/utils/Status";
import {
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
} from "services/constants";
export const AttendanceTimeLine = ({
  leavePage,
  setLeavePage,
  attendanceDetails,
  attendanceDataDetails,
  loading,
  reCallPayScheduleApi,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [leaverowsPerPage, setLeaveRowsPerPage] = useState(25);
  const [openDialog, setOpenDialog] = useState(false);
  const [handleCheckInError, setHandleCheckInError] = useState("");
  const [editCheckinCheckOutTime, setEditCheckInCheckOutDetails] = useState({});
  const [shiftStartTimeFormated, setShiftStartTimeFormated] = useState(null);
  const [shiftEndTimeFormated, setShiftEndTimeFormated] = useState(null);
  const [shiftStartDate, setShiftStartDate] = useState(new Date());
  const [shiftEndDate, setShiftEndDate] = useState(new Date());
  const [editUserName, setUserName] = useState({});
  const [personExpandableId, setPersonExpandableId] = useState(0);
  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };
  const getTwentyFourHourTime = (time) => {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes + ":00";
    }
  };

  const updateEditDetails = async () => {
    let validation = false;
    if (
      shiftStartTimeFormated &&
      shiftEndTimeFormated &&
      (dayjs(shiftEndDate).isSame(
        dayjs(editCheckinCheckOutTime?.date),
        "day"
      ) ||
        dayjs(shiftEndDate).isSame(
          dayjs(editCheckinCheckOutTime?.date).add(1, "day"),
          "day"
        ))
    ) {
      if (shiftStartDate === shiftEndDate) {
        const checkInData = getTwentyFourHourTime(
          moment(shiftStartTimeFormated["$d"]).format("hh:mm A")
        );
        const checkOutData = getTwentyFourHourTime(
          moment(shiftEndTimeFormated["$d"]).format("hh:mm A")
        );
        const checkTotalCheckOutTime =
          parseInt(checkOutData?.split(":")[0]) * 60 +
          parseInt(checkOutData?.split(":")[1]);
        const checkTotalCheckInTime =
          parseInt(checkInData?.split(":")[0]) * 60 +
          parseInt(checkInData?.split(":")[1]);
        if (checkTotalCheckInTime < checkTotalCheckOutTime) {
          validation = true;
        } else {
          validation = false;
          setHandleCheckInError(leaveManagementError.checkInError);
        }
      } else {
        validation = true;
      }
    } else {
      if (shiftEndTimeFormated) {
        if (shiftStartTimeFormated) {
          if (
            dayjs(shiftEndDate).isSame(
              dayjs(editCheckinCheckOutTime?.date),
              "day"
            ) ||
            dayjs(shiftEndDate).isSame(
              dayjs(editCheckinCheckOutTime?.date).add(1, "day"),
              "day"
            )
          ) {
            validation = true;
          } else {
            validation = false;
            setHandleCheckInError(
              `The check-out date can only be set to the selected date or the following day.`
            );
          }
        } else {
          validation = false;
          setHandleCheckInError("Invalid check-in format");
        }
      } else if (
        handleCheckInError === "Invalid check-out format" ||
        handleCheckInError === "Invalid check-out format"
      ) {
        validation = false;
        setHandleCheckInError("Invalid check-out format");
      } else {
        if (shiftEndTimeFormated?._d) {
          validation = true;
        } else {
          validation = false;
          setHandleCheckInError("Invalid check-out format");
        }
      }
    }
    if (validation) {
      if (
        editCheckinCheckOutTime.legend === "A" ||
        editCheckinCheckOutTime.legend === "H" ||
        editCheckinCheckOutTime.legend === "W"
      ) {
        setHandleCheckInError("");
        const payload = {
          check_in_time: shiftStartTimeFormated["$d"]
            ? getTwentyFourHourTime(
                moment(shiftStartTimeFormated["$d"]).format("hh:mm A")
              )
            : null,
          check_out_time: shiftEndTimeFormated["$d"]
            ? getTwentyFourHourTime(
                moment(shiftEndTimeFormated["$d"]).format("hh:mm A")
              )
            : null,
          date: editCheckinCheckOutTime.date,
          start_date: shiftStartDate,
          end_date: shiftEndDate,
          user: personExpandableId,
        };
        const response = await customAPI(
          `${domain}check-in-entries/`,
          "POST",
          payload
        );
        if (response.ok) {
          showToast("success", leaveSuccess.checkInOutTime);
          setHandleCheckInError("");
          reCallPayScheduleApi(true);
          setOpenDialog(false);
          setEditCheckInCheckOutDetails(null);
        }
      } else {
        setHandleCheckInError("");
        const payload = {
          id: editCheckinCheckOutTime?.check_in_id,
          check_out_time: shiftEndTimeFormated["$d"]
            ? getTwentyFourHourTime(
                moment(shiftEndTimeFormated["$d"]).format("hh:mm A")
              )
            : null,
          check_in_time: shiftStartTimeFormated["$d"]
            ? getTwentyFourHourTime(
                moment(shiftStartTimeFormated["$d"]).format("hh:mm A")
              )
            : null,
          start_date: shiftStartDate,
          end_date: shiftEndDate,
        };
        const response = await customAPI(
          `${domain}update_checkout_time/`,
          "PUT",
          payload
        );
        if (response.ok) {
          showToast("success", leaveSuccess.checkInOutTime);
          setHandleCheckInError("");
          setOpenDialog(false);
          setEditCheckInCheckOutDetails(null);
        }
      }
    }
  };

  const updateDateAndTimeDetails = (shiftDetails) => {
    const dateObjectCheckInTime = shiftDetails?.check_in_datetime
      ? new Date(shiftDetails?.check_in_datetime)
      : null;
    const dateObjectCheckOutTime = shiftDetails?.check_out_datetime
      ? new Date(shiftDetails?.check_out_datetime)
      : null;
    const formattedDate = dateObjectCheckInTime
      ? dateObjectCheckInTime.toISOString().split("T")[0]
      : shiftDetails?.date;
    const formattedDateCheckout = dateObjectCheckOutTime
      ? dateObjectCheckOutTime.toISOString().split("T")[0]
      : shiftDetails?.date;
    setShiftStartDate(formattedDate);
    setShiftEndDate(formattedDateCheckout);
    const defaultTime = shiftDetails?.check_in_time_formatted
      ? getTwentyFourHourTime(shiftDetails?.check_in_time_formatted)
      : null;
    const defaultCheckOutTime = shiftDetails?.check_out_time_formatted
      ? getTwentyFourHourTime(shiftDetails?.check_out_time_formatted)
      : null;
    const checkIntimeValue = defaultTime ? dayjs(defaultTime, "HH:mm") : null;
    const checkOuttimeValue = defaultCheckOutTime
      ? dayjs(defaultCheckOutTime, "HH:mm")
      : null;
    setShiftStartTimeFormated(checkIntimeValue);
    setShiftEndTimeFormated(checkOuttimeValue);
    setOpenDialog(true);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader info="Loading..." />
      ) : (
        <Mui.TableContainer sx={tableContainerSx}>
          <Mui.Table>
            <Mui.TableHead sx={tableHeaderSx}>
              <Mui.TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    height: "15px",
                    padding: "1px 1px",
                  },
                }}
              >
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    fontSize: 15,
                    textAlign: "left",
                  }}
                >
                  Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    fontSize: 15,
                    textAlign: "left",
                  }}
                >
                  Department
                </Mui.TableCell>
                {attendanceDetails?.headers?.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    <Mui.Stack>
                      <Mui.Typography textAlign="center" sx={{ fontSize: 10 }}>
                        {header.day_number}
                      </Mui.Typography>
                      <Mui.Typography textAlign="center" sx={{ fontSize: 10 }}>
                        {header.day}
                      </Mui.Typography>
                    </Mui.Stack>
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {attendanceDataDetails?.length === 0 ? (
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell
                    colSpan={attendanceDetails?.headers?.length + 6}
                    align="center"
                    sx={{ padding: 2 }}
                  >
                    <Mui.Alert severity="info">No data available</Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                attendanceDataDetails
                  ?.slice(
                    leavePage * leaverowsPerPage,
                    leavePage * leaverowsPerPage + leaverowsPerPage
                  )
                  .map((attendancedata) => (
                    <Mui.TableRow
                      key={attendancedata.user_id}
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "15px",
                          padding: "8px 1px",
                        },
                      }}
                    >
                      <Mui.TableCell className="column-cell">
                        <Mui.Tooltip title={attendancedata?.user}>
                          <Mui.Typography
                            noWrap
                            sx={{ cursor: "pointer", paddingLeft: 0.3 }}
                          >
                            {attendancedata?.user?.length > 15
                              ? attendancedata?.user.slice(0, 15) + "..."
                              : attendancedata?.user}
                          </Mui.Typography>
                        </Mui.Tooltip>
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        <Mui.Tooltip title={attendancedata?.user}>
                          <Mui.Typography
                            noWrap
                            sx={{ cursor: "pointer", paddingLeft: 0.3 }}
                          >
                            {attendancedata?.department?.length > 15
                              ? attendancedata?.department.slice(0, 15) + "..."
                              : attendancedata?.department}
                          </Mui.Typography>
                        </Mui.Tooltip>
                      </Mui.TableCell>
                      {attendancedata?.attendance?.map((dayDetails) => (
                        <Mui.TableCell
                          align="center"
                          onClick={() => {
                            if (
                              dayDetails.legend === "A" ||
                              dayDetails.legend === "W" ||
                              dayDetails.legend === "M" ||
                              dayDetails.legend === "H"
                            ) {
                              setPersonExpandableId(attendancedata?.user_id);
                              setEditCheckInCheckOutDetails(dayDetails);
                              updateDateAndTimeDetails(dayDetails);
                              setUserName(attendancedata?.user);
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              width: "25px",
                              height: "20px",
                              backgroundColor: dayDetails.colour_code,
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Mui.Typography
                              sx={{
                                fontSize: 15,
                                color: getContrastingTextColor(
                                  dayDetails?.colour_code
                                    ? dayDetails?.colour_code
                                    : "#FFFFF"
                                ),
                              }}
                            >
                              {dayDetails.legend}
                            </Mui.Typography>
                          </div>
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      <Mui.Grid
        container
        // sx={{
        //   display: "flex",
        //   flexDirection: "row",
        //   justifyContent: "end",
        //   alignItems: "flex-end",
        // }}
      >
        <Mui.Grid xs={8.5}>
          <Mui.Grid container sx={{ paddingTop: 1 }} spacing={1}>
            <Mui.Grid item xs={1}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("P"),
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Present</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={1.2}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("PR"),
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Permission</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={1}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("HL"),
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Half day</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={1}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("H"),
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Holiday</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={0.8}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("L"),
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Leave</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={0.9}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("A"),
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Absent</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={1}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("M"),
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Missing</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={1.1}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("W"),
                    justifyContent: "center",
                    cursor: "pointer",
                    border: "2px solid black",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Week off</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={1.3}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("UA"),
                    justifyContent: "center",
                    cursor: "pointer",
                    border: "2px solid black",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Unassigned</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={1.4}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("SP"),
                    justifyContent: "center",
                    cursor: "pointer",
                    border: "2px solid black",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Shift Planned</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={1.3}>
              <Mui.Stack direction="row">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "20px",
                    backgroundColor: getColorWorkingHours("NJ"),
                    justifyContent: "center",
                    cursor: "pointer",
                    border: "2px solid black",
                  }}
                />{" "}
                &nbsp;
                <Mui.Typography>Not Joined</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid xs={3.5}>
          {attendanceDataDetails?.length > 25 ? (
            <Mui.TablePagination
              className="custom-pagination"
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={attendanceDataDetails?.length}
              rowsPerPage={leaverowsPerPage}
              page={leavePage}
              onPageChange={handleChangeLeavePage}
              onRowsPerPageChange={handleChangeLeaveRowsPerPage}
            />
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
      {openDialog ? (
        <GlobalDialogBox
          handleCloseDialog={() => {
            setOpenDialog(false);
          }}
          open={openDialog}
          title="Edit Check In/Out Details"
          minWidth={750}
        >
          <Mui.Grid container>
            <Mui.Grid xs={4}>
              <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                Employee Name
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.Typography sx={{ fontSize: 20, paddingLeft: 2 }}>
                {editUserName}
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={4} sx={{ paddingTop: 2 }}>
              <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                Check In
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.Grid container>
                <Mui.Grid xs={6} sx={{ padding: 2 }}>
                  <Mui.TextField
                    size="small"
                    type="date"
                    value={shiftStartDate}
                    disabled={true}
                    fullWidth
                  />
                </Mui.Grid>
                <Mui.Grid xs={6} sx={{ padding: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <div sx={{ width: 4 }}>
                        <TimePicker
                          ampm={false}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          value={shiftStartTimeFormated || null}
                          slotProps={{
                            textField: {
                              size: "small",
                              width: 15,
                            },
                          }}
                          onChange={(newValue) => {
                            const timeWithDate = newValue
                              ? newValue.toDate()
                              : new Date();
                            let minTime = moment.utc(timeWithDate);
                            setShiftStartTimeFormated(
                              dayjs()
                                .set("hour", minTime._d.getHours())
                                .set("minute", minTime._d.getMinutes())
                                .set("second", minTime._d.getSeconds())
                            );
                          }}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                  <Mui.Typography
                    sx={{
                      color: themes.redColor,
                      fontSize: 12,
                    }}
                  >
                    {handleCheckInError ===
                    "Checkin time is greater then check out time"
                      ? "Checkin time is greater then check out time"
                      : null}
                    {handleCheckInError === "Invalid check-in format"
                      ? "Invalid check-in format"
                      : null}
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={4} sx={{ paddingTop: 2 }}>
              <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                Check Out
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.Grid container>
                <Mui.Grid xs={6} sx={{ padding: 2 }}>
                  <Mui.TextField
                    size="small"
                    type="date"
                    value={shiftEndDate}
                    onChange={(event) => {
                      if (
                        dayjs(event.target.value).isSame(
                          dayjs(editCheckinCheckOutTime?.date),
                          "day"
                        ) ||
                        dayjs(event.target.value).isSame(
                          dayjs(editCheckinCheckOutTime?.date).add(1, "day"),
                          "day"
                        )
                      ) {
                        setShiftEndDate(event.target.value);
                      }
                    }}
                    fullWidth
                  />
                  <Mui.Typography
                    sx={{
                      paddingTop: 1,
                      color: themes.redColor,
                      fontSize: 12,
                    }}
                  >
                    {handleCheckInError ===
                    "The check-out date can only be set to the selected date or the following day."
                      ? "The check-out date can only be set to the selected date or the following day."
                      : null}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={6} sx={{ padding: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <div sx={{ width: 4 }}>
                        <TimePicker
                          ampm={false}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          value={shiftEndTimeFormated || null}
                          slotProps={{
                            textField: {
                              size: "small",
                              width: 15,
                            },
                          }}
                          onChange={(newValue) => {
                            const timeWithDate = newValue
                              ? newValue.toDate()
                              : new Date();
                            let minTime = moment.utc(timeWithDate);
                            setShiftEndTimeFormated(
                              dayjs()
                                .set("hour", minTime._d.getHours())
                                .set("minute", minTime._d.getMinutes())
                                .set("second", minTime._d.getSeconds())
                            );
                          }}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                  <Mui.Typography
                    sx={{
                      color: themes.redColor,
                      fontSize: 12,
                    }}
                  >
                    {handleCheckInError === "Invalid check-out format"
                      ? "Invalid check-out format"
                      : null}
                    {handleCheckInError ===
                    "Check-in time to be earlier than check-out time."
                      ? "Check-in time to be earlier than check-out time."
                      : null}
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              paddingTop: 3,
            }}
          >
            <Mui.Grid xs={9}></Mui.Grid>
            <Mui.Grid
              xs={1.2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                }}
                actionName="Cancel"
                typeName="button"
              />
            </Mui.Grid>
            <Mui.Grid
              xs={1.2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  updateEditDetails();
                }}
                actionName="Update"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        </GlobalDialogBox>
      ) : null}
    </React.Fragment>
  );
};
