import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { themes } from "services/constants";
export const CustomAutoComplete = ({
  dataList,
  selectedValue,
  Icons,
  updateDataFunction,
  rules,
  placeHolder,
  label,
  errorMessage,
  textBoxError,
  disableAction,
}) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box"
      size="small"
      value={selectedValue}
      options={dataList}
      InputLabelProps={{ shrink: true }}
      sx={{ width: "100%" }}
      ListboxProps={{ style: { maxHeight: "150px" } }}
      filterOptions={(options, state) => {
        return options.filter((option) =>
          option?.label?.toLowerCase().includes(state.inputValue.toLowerCase())
        );
      }}
      renderOption={(props, option) => {
        return <li {...props}>{option.label}</li>;
      }}
      isOptionEqualToValue={(option, value) => option?.label === value?.label}
      // onInputChange={(event, value) => updateDataFunction(event, value)}
      onChange={(event, value) => updateDataFunction(event, value)}
      renderInput={(params) =>
        Icons ? (
          <TextField
            {...params}
            sx={{ background: themes.whiteColor, borderRadius: "10px" }}
            label={label}
            placeholder={placeHolder}
            size="small"
            value={selectedValue}
            rules={rules}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <Icons style={{ margin: "0 8px", color: "#a6a6a6" }} />
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            error={textBoxError}
            helperText={errorMessage}
            fullWidth
            disabled={disableAction}
          />
        ) : (
          <TextField
            {...params}
            sx={{
              background: themes.whiteColor,
              // "& .MuiOutlinedInput-root": {
              //   borderRadius: "16px", // Adjust the radius as needed
              // },
            }}
            size="small"
            InputLabelProps={{ shrink: true }}
            label={label}
            placeholder={placeHolder}
            value={selectedValue}
            validate
            error={textBoxError}
            helperText={errorMessage}
            disabled={disableAction}
            fullWidth
          />
        )
      }
    />
  );
};
