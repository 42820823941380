import React, { useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { Close, Info, AddCircleTwoTone } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { onlyNumberRegex } from "services/constants/patterns";
let rowId = 1;
export const EarningDetails = ({
  emptyEarnings,
  monthlySalary,
  otherSalaryComponents,
  salaryComponentDetails,
  setOtherSalaryComponents,
  existingUserDetails,
  setSalaryComponentDetails,
  setUpdateDeductionDetails,
  setSpecialAllowance,
  updateEarning,
  setupdateEarning,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [emptyRow, setEmptyRow] = useState(0);
  const [addedEarningData, setAddedEarningData] = useState([]);
  const [filterEarnings, setFilterEarnings] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [error, setError] = useState("");
  let { result: salaryComponent } = useFetch(
    `${domain}earnings/?company=${existingUserDetails.company_id}`
  );

  useEffect(() => {
    if (
      salaryComponent.length !== 0 &&
      otherSalaryComponents.length === 0 &&
      updateEarning
    ) {
      let componentWithAmount = salaryComponent?.map((element) => {
        if (element.name === "Special Allowance") {
          setSpecialAllowance(monthlySalary);
        }
        return {
          ...element,
          amount: element.name === "Special Allowance" ? monthlySalary : 0,
          earning_id: element.id,
        };
      });
      setSalaryComponentDetails(
        componentWithAmount.filter((data) => {
          return data.name !== "Special Allowance" && data.name !== "Basic";
        })
      );
      let basicComponent = componentWithAmount.filter((salary) => {
        return salary.name === "Basic" || salary.name === "Special Allowance";
      });
      setOtherSalaryComponents(basicComponent);
      setupdateEarning(false);
    } else if (
      salaryComponent.length !== 0 &&
      otherSalaryComponents.length !== 0 &&
      updateEarning
    ) {
      let salaryComponentData = salaryComponent;
      otherSalaryComponents.forEach((element) => {
        salaryComponentData = salaryComponentData.filter((data) => {
          return data.name !== element.name;
        });
      });
      setSalaryComponentDetails(salaryComponentData);
      setupdateEarning(false);
    }
  }, [
    monthlySalary,
    otherSalaryComponents,
    otherSalaryComponents.length,
    salaryComponent,
    setOtherSalaryComponents,
    setSalaryComponentDetails,
    setSpecialAllowance,
    setupdateEarning,
    updateEarning,
  ]);

  const handleSalaryComponent = (data, id) => {
    if (data) {
      let updatedData = otherSalaryComponents?.map((element) => {
        if (element.rowId === id) {
          return {
            ...element,
            ...data,
            earning_id: data.id,
          };
        } else {
          return element;
        }
      });
      setOtherSalaryComponents(updatedData);
      setAddedEarningData([...addedEarningData, data]);
      removeAddedEarnimgData(data);
      setIsAdd(false);
    }
  };

  const removeAddedEarnimgData = (removableEarning) => {
    let remainingData = salaryComponentDetails.filter((data) => {
      return data?.id !== removableEarning?.id;
    });
    setSalaryComponentDetails(remainingData);
    setIsAdd(false);
  };

  const updateAmount = (item, value) => {
    if (onlyNumberRegex.test(value) && item.name) {
      let updateSalaryComponent = [];
      if (value === "") {
        updateSalaryComponent = otherSalaryComponents?.map((element) => {
          if (element.id === item.id) {
            return {
              ...element,
              amount: value,
            };
          } else {
            return element;
          }
        });
        calculateSpecialAllowance(updateSalaryComponent);
      } else {
        updateSalaryComponent = otherSalaryComponents?.map((element) => {
          if (element.id === item.id) {
            return {
              ...element,
              amount: value,
            };
          } else {
            return element;
          }
        });
        calculateSpecialAllowance(updateSalaryComponent);
        setUpdateDeductionDetails(true);
      }
      setEmptyRow(emptyRow === item.rowId ? 0 : emptyRow);
    } else if (!item.name) {
      setEmptyRow(item.rowId);
    }
  };

  const calculateSpecialAllowance = (updateSalaryComponent) => {
    const otherThenSpecialAllowance = updateSalaryComponent.filter((data) => {
      return data.name !== "Special Allowance";
    });
    let TotalAmount = 0;
    otherThenSpecialAllowance.forEach((element) => {
      TotalAmount =
        parseInt(element.amount === "" ? 0 : element.amount) + TotalAmount;
    });
    let updatedSpecialAllowance = updateSalaryComponent?.map((element) => {
      if (element.name === "Special Allowance") {
        setSpecialAllowance(parseInt(monthlySalary) - TotalAmount);
        return {
          ...element,
          amount: parseInt(monthlySalary) - TotalAmount,
        };
      } else {
        return element;
      }
    });
    setOtherSalaryComponents(updatedSpecialAllowance);
  };

  const addNewRow = isAdd
    ? () => null
    : () => {
        if (!isAdd) {
          setError("");
          const hasZeroAmount = otherSalaryComponents.some(
            (item) => item.amount === "0" || item.amount === 0
          );

          if (hasZeroAmount) {
            setError("Please enter value");
            return;
          }
          let shouldSetIsAdd = false;
          for (const item of otherSalaryComponents) {
            if (item?.name === "") {
              shouldSetIsAdd = true;
              break;
            }
          }
          if (shouldSetIsAdd) {
            setIsAdd(true);
            return;
          }
          const newRow = {
            rowId: rowId + 1,
            amount: "0",
            name: "",
            earning_id: 0,
          };
          rowId = rowId + 1;
          setOtherSalaryComponents([...otherSalaryComponents, newRow]);
        }
      };

  const removeSalaryComponent = (item) => {
    let remaingData = otherSalaryComponents.filter((data) => {
      return data?.earning_id !== item?.earning_id;
    });
    setOtherSalaryComponents(remaingData);
    calculateSpecialAllowance(remaingData);
    addEarningData(item);
    setIsAdd(false);
  };

  const addEarningData = (item) => {
    if (item?.name) {
      let removeEarningDetials = [];
      addedEarningData.forEach((element) => {
        if (element.name === item.name) {
          removeEarningDetials = element;
        }
      });

      setSalaryComponentDetails([
        removeEarningDetials,
        ...salaryComponentDetails,
      ]);
      let filterData = addedEarningData.filter((data) => {
        return data.name !== item.name;
      });
      addEarningData(filterData);
    }
  };

  useEffect(() => {
    const filterSalaryData = () => {
      const salaryComponentDetailsData = salaryComponentDetails?.map((obj) => ({
        ...obj,
        label: obj?.name,
      }));
      let salaryDetails = [];
      salaryComponentDetailsData?.forEach((element) => {
        let findSalary = otherSalaryComponents?.filter((data) => {
          return data?.name === element?.label;
        });
        if (findSalary?.length === 0) {
          salaryDetails = [...salaryDetails, element];
        }
      });
      if (salaryDetails?.length === 0) {
        setIsAdd(true);
      } else {
        setIsAdd(false);
        setFilterEarnings(salaryDetails);
      }
    };

    if (otherSalaryComponents?.length !== 0) {
      filterSalaryData();
    }
  }, [otherSalaryComponents, salaryComponentDetails]);

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Typography sx={{ fontSize: 17 }}>Earnings </Mui.Typography>{" "}
        <Mui.Tooltip title="Additional allowances can be created in the salary components screen.">
          <Info />
        </Mui.Tooltip>
      </Mui.Grid>
      <Mui.TableContainer sx={{ mt: 2, width: "100%" }}>
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left">
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Component
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Amount
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Action
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {otherSalaryComponents?.map((data, index) => (
              <Mui.TableRow key={data.rowId}>
                <>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    {data?.name === "Basic" ||
                    data.name === "Special Allowance" ? (
                      <Mui.TextField size="small" value={data.name} fullWidth />
                    ) : (
                      <>
                        <Mui.Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          size="small"
                          value={data.name}
                          options={filterEarnings}
                          sx={{ width: "100%" }}
                          filterOptions={(options, state) => {
                            return options?.filter((option) =>
                              option.label
                                ?.toLowerCase()
                                ?.includes(state.inputValue.toLowerCase())
                            );
                          }}
                          renderOption={(props, option) => {
                            return (
                              option.label !== "Special Allowance" &&
                              option.label !== "Basic" && (
                                <li {...props}>{option.label}</li>
                              )
                            );
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                            },
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              handleSalaryComponent(value, data.rowId);
                            }
                          }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{ background: themes.whiteColor }}
                              placeholder="Please select the salary component"
                              size="small"
                              value={data.name}
                            />
                          )}
                        />
                      </>
                    )}
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.Stack direaction="row">
                      <Mui.TextField
                        size="small"
                        value={data.amount}
                        onChange={(event) => {
                          updateAmount(data, event.target.value);
                        }}
                        onBlur={() => {
                          addNewRow();
                        }}
                        disabled={
                          data.name === "Special Allowance" ? true : false
                        }
                        fullWidth
                      />
                      {data.amount < 0 && data.name === "Special Allowance" ? (
                        <span style={{ color: themes.txtErrorColor }}>
                          Special Allowance should be greater then 0
                        </span>
                      ) : null}
                      {((data.amount === "0" ||
                        data.amount === "" ||
                        data.amount === 0) &&
                        emptyEarnings) ||
                      (error === "Please enter value" &&
                        (data.amount === 0 || data.amount === "")) ? (
                        <span style={{ color: themes.txtErrorColor }}>
                          Please enter value
                        </span>
                      ) : null}
                    </Mui.Stack>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    {data?.name === "Basic" ||
                    data.name === "Special Allowance" ? null : (
                      <Mui.IconButton>
                        <Close onClick={() => removeSalaryComponent(data)} />
                      </Mui.IconButton>
                    )}
                  </Mui.TableCell>
                </>
              </Mui.TableRow>
            ))}
            <Mui.TableRow>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Mui.IconButton
                  size="small"
                  onClick={() => addNewRow()}
                  disabled={salaryComponentDetails?.length === 0 ? true : false}
                >
                  <Mui.Tooltip title="Add Earning">
                    <AddCircleTwoTone />
                  </Mui.Tooltip>
                </Mui.IconButton>
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
    </React.Fragment>
  );
};
