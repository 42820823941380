import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { tableRowSx } from "services/constants";
import { expenseHeader } from "../Headers";
const keys = [
  "status",
  "user_name",
  "date_incurred",
  "amount",
  "expense_type",
  "description",
  "reason",
];

export const History = () => {
  const { domain, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { result: expenseDetails, loading } = useFetch(
    `${domain}expense/?selected_user_id=${userDetails?.id}`
  );
  const [expenseData, setExpenseData] = useState(expenseDetails);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const filteredResults = expenseDetails?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setExpenseData(filteredResults);
    setPage(0);
  }, [globalSearchValue, expenseDetails]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ width: "100%", maxHeight: 724, mt: 2 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow sx={tableRowSx}>
                {expenseHeader?.map((value) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {value?.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {expenseData?.length === 0 ? (
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell colSpan={12}>
                    <Mui.Alert
                      severity="info"
                      sx={{ margin: 2, paddingTop: 2 }}
                    >
                      No data available
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                expenseData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((value) => (
                    <Mui.TableRow sx={tableRowSx}>
                      {expenseHeader.map((head) => (
                        <Mui.TableCell>
                          {value[head.key] ? value[head.key] : "N/A"}
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Grid>

      {expenseData?.length > 25 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={expenseData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
