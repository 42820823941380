import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { themes, defaultLead } from "services/constants";
import { showToast } from "services/utils/Status";
import { useForm } from "react-hook-form";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";

export const AddClient = ({
  screenName,
  editClientDetails,
  setAddClientScreen,
  updateData,
  setUpdateData,
  setFetchClients,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [yesCheck, setYesCheck] = useState(false);
  const [noCheck, setNoCheck] = useState(true);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (updateData) {
      setValue("clientname", editClientDetails.clientname);
      setValue("clientcode", editClientDetails.clientcode);
      setValue("gstno", editClientDetails.gstno);
      setValue("address", editClientDetails.address);
      setValue("email", editClientDetails.email);
      setValue("phoneno", editClientDetails.phonenumber);
      setUpdateData(false);
      if (
        editClientDetails.gstno !== "" &&
        editClientDetails.gstno !== defaultLead
      ) {
        setYesCheck(true);
        setNoCheck(false);
      }
    }
  }, [
    editClientDetails.address,
    editClientDetails.clientcode,
    editClientDetails.clientname,
    editClientDetails.clientnmae,
    editClientDetails.email,
    editClientDetails.gstno,
    editClientDetails.phonenumber,
    setUpdateData,
    setValue,
    updateData,
  ]);

  const onSubmit = async (data) => {
    const apiActionMethod = screenName === "Edit Client" ? "PUT" : "POST";
    const apiUrl =
      screenName === "Edit Client"
        ? `${domain}client/${editClientDetails?.id}/`
        : `${domain}client/`;

    try {
      const response = await fetch(apiUrl, {
        method: apiActionMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          clientname: data.clientname,
          shortname: "DRT4",
          clientid: data.clientcode,
          isactive: true,
          gstnumber: data.gstno,
          address: data.address,
          email: data.email,
          phonenumber: data.phoneno,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setFetchClients(true);
        reset();
        setAddClientScreen(false);
        showToast(
          "success",
          screenName === "Edit Client"
            ? "Client details updated successfully"
            : "Client added successfully"
        );
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const setYesCheckUpdated = () => {
    if (yesCheck) {
      setYesCheck(false);
      trigger("gstno");
    } else {
      setYesCheck(true);
      setNoCheck(false);
    }
  };

  const setNoCheckUpdated = () => {
    if (noCheck) {
      setNoCheck(false);
      trigger("gstno");
    } else {
      trigger("gstno");
      setNoCheck(true);
      setYesCheck(false);
      setValue("gstno", "");
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = /^[A-Za-z\s]$/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
  };

  const validateGST = (gstNo) => {
    const regex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return regex.test(gstNo);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid
          container
          sx={{ paddingLeft: 10, paddingRight: 10, paddingTop: 7 }}
        >
          <Mui.Grid container>
            <Mui.Grid
              xs={12}
              sx={{
                padding: 1,
                backgroundColor: themes.primary,
                borderRadius: "10px 10px 0 0",
                border: `2px solid ${themes.sidebarDivider}`,
                borderBottom: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
              >
                {screenName}
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              borderRadius: "0 0 10px 10px",
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
            }}
          >
            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Client Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  {...register("clientname", {
                    required: "Client name is required",
                    maxLength: {
                      value: 30,
                      message: "Maximum length is 30 characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Minimum length is 3 characters",
                    },
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        if (!/[A-Za-z]/.test(fieldValue)) {
                          return "Client name must contain at least one alphabet character.";
                        }
                        if (/^\d+$/.test(fieldValue)) {
                          return "Client name should not contain only digits.";
                        }
                        if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                          return "Client name should only contain alphabets, spaces, -,numbers and & symbol.";
                        }
                        return true;
                      },
                    },
                  })}
                  error={errors.clientname ? true : false}
                  helperText={errors.clientname?.message}
                  sx={{ background: themes.whiteColor }}
                  onKeyPress={handleKeyDown}
                  InputProps={{
                    inputProps: { maxLength: 30 },
                  }}
                  name="clientname"
                  size="small"
                  placeholder={projectPlaceholder.clientName}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Client Code
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  {...register("clientcode", {
                    required: "Client code is required",
                    minLength: {
                      value: 3,
                      message: "Minimum length is 3 digits",
                    },
                    validate: {
                      clientCodeAvailable: async (fieldValue) => {
                        if (screenName !== "Edit Client") {
                          try {
                            const response = await fetch(
                              `${domain}check_client_name/?client_id=${fieldValue}`,
                              {
                                method: "GET",
                                headers: {
                                  "content-type": "application/json",
                                  Authorization: `token ${token}`,
                                },
                              }
                            );
                            const res = await response.json();
                            if (response.ok) {
                              if (res?.exists) {
                                return "That client ID is already taken.choose a unique ID for your client.";
                              } else {
                                return true;
                              }
                            } else if (response.status === 409) {
                              sessionHandling();
                            } else {
                              throw new Error(res.error);
                            }
                          } catch (error) {
                            showToast("error", error.message);
                          }
                        }
                      },
                    },
                  })}
                  error={errors.clientcode ? true : false}
                  helperText={errors.clientcode?.message}
                  sx={{ background: themes.whiteColor }}
                  name="clientcode"
                  placeholder={projectPlaceholder.clientCode}
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    inputProps: { maxLength: 3 },
                  }}
                  size="small"
                  disabled={screenName === "Edit Client" ? true : false}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: 1 }}>
                <Mui.Stack direction="row">
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      padding: 1,
                      fontWeight: "bold",
                    }}
                  >
                    Is GST Registered ?{" "}
                  </Mui.Typography>
                  <Mui.Grid sx={{ marginTop: 0.5 }}>
                    <Mui.Checkbox
                      checked={yesCheck}
                      onChange={() => setYesCheckUpdated()}
                    />
                  </Mui.Grid>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      padding: 1,
                      fontWeight: "bold",
                    }}
                  >
                    Yes
                  </Mui.Typography>
                  <Mui.Grid sx={{ marginTop: 0.5 }}>
                    <Mui.Checkbox
                      checked={noCheck}
                      onChange={() => setNoCheckUpdated()}
                    />
                  </Mui.Grid>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      padding: 1,
                      fontWeight: "bold",
                    }}
                  >
                    No
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  multiline
                  rows={1}
                  maxRows={5}
                  {...register("gstno", {
                    required: yesCheck ? "Gst no is required" : false,
                    validate: {
                      gstnoAvailable: async (fieldValue) => {
                        if (yesCheck && fieldValue.length < 15) {
                          return "Minimum length is 15 characters";
                        }
                      },
                      gstNoValidation: async (fieldValue) => {
                        if (fieldValue !== "") {
                          const valid = validateGST(fieldValue);
                          return valid
                            ? true
                            : "Gst no in format 22AAAAA0000A1Z5";
                        }
                      },
                    },
                  })}
                  error={errors.gstno && yesCheck ? true : false}
                  helperText={
                    errors.gstno && yesCheck ? errors.gstno?.message : null
                  }
                  sx={{ background: themes.whiteColor }}
                  name="gstno"
                  placeholder={projectPlaceholder.gstNo}
                  disabled={yesCheck ? false : true}
                  InputProps={{
                    inputProps: { maxLength: 15 },
                  }}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Address
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  {...register("address", {
                    required: "Address is required",
                  })}
                  error={errors.address ? true : false}
                  helperText={errors.address?.message}
                  sx={{ background: themes.whiteColor }}
                  name="address"
                  placeholder={projectPlaceholder.clientAddress}
                  fullWidth
                  size="small"
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Email
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  id="email"
                  variant="outlined"
                  {...register("email", {
                    required: "Email id is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message || ""}
                  placeholder={projectPlaceholder.clientEmail}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                {" "}
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Phone Number
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  {...register("phoneno", {
                    required: "Phone no is required",
                    minLength: {
                      value: 10,
                      message: "Minimum length is 10 digits",
                    },
                    maxLength: {
                      value: 15,
                      message: "Maximum length is 15 digits",
                    },
                  })}
                  error={errors.phoneno ? true : false}
                  helperText={errors.phoneno?.message}
                  sx={{ background: themes.whiteColor }}
                  name="phoneno"
                  placeholder={projectPlaceholder.phoneNumber}
                  onKeyPress={handleKeyPress}
                  fullWidth
                  InputProps={{
                    inputProps: { maxLength: 15 },
                  }}
                  size="small"
                  onBlur={() => {
                    setValue(
                      "phoneno",
                      getValues("phoneno").replace(/[^\d-]/g, "")
                    );
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={screenName === "Add Client" ? 11.4 : 11.25}
              ></Mui.Grid>
              <Mui.Grid item xs={12} sm={6} md={6} lg={0.5}>
                <CustomButton
                  actionName={screenName === "Add Client" ? "Add" : "Update"}
                  typeName="submit"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
