import * as Mui from "@mui/material";
import { useDrag } from "react-dnd";
import { themes } from "services/constants";
import { getContrastingTextColor } from "services/utils/colorChecker";

const DraggableCard = ({ task }) => {
  const [, ref] = useDrag({
    type: "CARD",
    item: { task },
  });

  const showLabels = (labels, taskid) => {
    return labels.length > 0 ? (
      <div>
        <Mui.AvatarGroup
          max={4}
          sx={{
            "& .MuiAvatar-root": {
              width: 30,
              height: 20,
              fontSize: 10,
              padding: "1px 5px",
              margin: "0 1px",
              marginLeft: "0px",
              borderRadius: 2,
            },
          }}
        >
          {labels.map((lab) => (
            <Mui.Tooltip title={lab.label_name}>
              <Mui.Avatar
                key={taskid}
                sx={{
                  height: 20,
                  textTransform: "capitalize",
                  backgroundColor: lab.color_code
                    ? lab.color_code
                    : themes.primaryIndicator,
                  "&:hover": {
                    backgroundColor: lab.color_code
                      ? lab.color_code
                      : themes.primaryIndicator,
                  },
                  color: getContrastingTextColor(
                    lab.color_code ? lab.color_code : themes.primaryIndicator
                  ),
                }}
              >
                {String(lab.label_name).substring(0, 6)}
              </Mui.Avatar>
            </Mui.Tooltip>
          ))}
        </Mui.AvatarGroup>
      </div>
    ) : null;
  };

  return (
    <Mui.Card
      style={{ cursor: "grab" }}
      ref={ref}
      sx={{
        boxShadow: 5,
        borderRadius: 2,
        marginBottom: 2,
        cursor: "move",
        marginTop: 3,
      }}
    >
      <Mui.CardContent>
        <Mui.Tooltip title={task.taskname}>
          <Mui.Typography
            variant="body1"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {task.taskname}
          </Mui.Typography>
        </Mui.Tooltip>
        {task.labels.length > 0 ? showLabels(task.labels, task.id) : null}
        <br />
        <Mui.Divider />
        <Mui.Stack direction="row" sx={{ paddingTop: 1 }}>
          <Mui.Grid container>
            <Mui.Grid sm={12} md={12} lg={3}>
              <Mui.Tooltip title={task.current_assignee?.username}>
                <Mui.Avatar
                  sx={{ width: 25, height: 25 }}
                  alt={task.current_assignee?.username.charAt(0).toUpperCase()}
                  src={task.current_assignee?.image_url}
                />
              </Mui.Tooltip>
            </Mui.Grid>
            <Mui.Grid
              sm={12}
              md={12}
              lg={5}
              style={{
                wordWrap: "break-word",
                maxWidth: 100,
              }}
            >
              {task?.due_date ? task?.due_date : "N/A"}
            </Mui.Grid>
            <Mui.Grid
              sm={12}
              md={12}
              lg={4}
              style={{
                wordWrap: "break-word",
                maxWidth: 100,
              }}
            >
              {task.priority?.name}
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Stack>
      </Mui.CardContent>
    </Mui.Card>
  );
};

export default DraggableCard;
