import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { Loader } from "components/Loader";
import { tableRowSx } from "services/constants";
const overTimeKeyPair = [
  {
    key: "date",
    name: "Date",
  },
  {
    key: "user_name",
    name: "Employee name",
  },
  {
    key: "shift_name",
    name: "Shift Name",
  },
  {
    key: "check_in_time",
    name: "Check-in",
  },
  {
    key: "check_out_time",
    name: "Check-out",
  },
  {
    key: "approvedby",
    name: "Approver",
  },
  {
    key: "approvedat",
    name: "Approved At",
  },
];

export const History = ({ startDate, endDate }) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId } = useSelector((state) => state.userReducer);
  const [overTimeDetails, setOverTimeDetails] = useState([1]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [listViewPage, setListViewPage] = useState(0);
  const [loader, setLoader] = useState(true);
  const [approveOt, setApproveOt] = useState([]);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      setLoader(true);
      try {
        const response = await fetch(
          `${domain}week-off-approvals/?user_id=${userDetails.id}&start_date=${startDate}&end_date=${endDate}&status=Approved&department_id=${departmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const combinedData = res.map((item) => ({
            ...item,
            overtime_period: `${item.check_in_time || "N/A"} to ${
              item.check_out_time || "N/A"
            }`,
          }));
          setOverTimeDetails(combinedData);
          setApproveOt(combinedData);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
      setLoader(false);
    };
    fetchHistory();
  }, [departmentId, domain, endDate, startDate, token, userDetails.id]);

  useEffect(() => {
    const searchFields = [
      "user_name",
      "date",
      "shift",
      "check_in_time",
      "check_out_time",
      "approvedby",
      "createdat",
    ];
    const filteredResults = approveOt.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setOverTimeDetails(filteredResults);
    setListViewPage(0);
  }, [approveOt, globalSearchValue]);

  return (
    <React.Fragment>
      {loader ? (
        <Loader />
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ width: "100%", maxHeight: 724, mt: 2 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow sx={tableRowSx}>
                {overTimeKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {overTimeDetails.length === 0 ? (
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell colSpan={8}>
                    <Mui.Alert
                      severity="info"
                      sx={{ margin: 1, width: "100%" }}
                    >
                      No data available
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                overTimeDetails
                  ?.slice(
                    listViewPage * rowsPerPage,
                    listViewPage * rowsPerPage + rowsPerPage
                  )
                  .map((department) => (
                    <Mui.TableRow sx={tableRowSx}>
                      {overTimeKeyPair.map((header) => (
                        <Mui.TableCell sx={{ color: themes.blackColor }}>
                          {department[header.key]
                            ? department[header.key]
                            : "N/A"}
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
          {overTimeDetails?.length > 25 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={overTimeDetails?.length}
              rowsPerPage={rowsPerPage}
              page={listViewPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      )}
    </React.Fragment>
  );
};
