import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { useFetch } from "services/hooks/useFetch";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { customAPI } from "services/utils/CustomAPI";
import sessionHandling from "services/utils/notificationUtils";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { weekOffType } from "pages/TeamZone/TeamZoneHeaders";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

export const Apply = () => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    unregister,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      partDay: true,
      fullDay: false,
      selectType: "add to payroll",
      type_name: weekOffType[0]?.label || {},
    },
  });
  const { result: response } = useFetch(`${domain}weekoff-user/`);
  const users = response?.users || [];
  const [buttonLoader, setButtonLoader] = useState(false);
  const [weekOffDate, setWeekOffDate] = useState(null);
  const [validDates, setValidDates] = useState([]);
  const [apiCall, setApiCall] = useState(true);
  const [error, setError] = useState(false);
  const [shiftStartTimeFormated, setShiftStartTimeFormated] = useState(dayjs());
  const [shiftEndTimeFormated, setShiftEndTimeFormated] = useState(dayjs());
  const [shiftStartDate, setShiftStartDate] = useState();
  const [shiftEndDate, setShiftEndDate] = useState();
  const [checkinError, setCheckinError] = useState(false);
  const [sametimeError, setSameTimeError] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [endTimeError, setEndTimeError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [dateError, setDateError] = useState(false);
  const [previousDate, setPreviousDate] = useState(false);

  const typeName = watch("type_name");
  const partDayValue = watch("partDay");
  const fullDayValue = watch("fullDay");

  useEffect(() => {
    if (firstRender) {
      setValue("type_name", weekOffType[0]?.label);
      unregister(["partDay", "fullDay"]);
      setFirstRender(false);
    }
  }, [firstRender, setValue, unregister]);

  const selectTypeName = (event, value) => {
    if (value?.label === "Weekly") {
      setValue("type_name", value?.label || "");
      unregister(["partDay", "fullDay"]);
    } else {
      setValue("type_name", value?.label || "");
      setValue("partDay", true);
      setValue("fullDay", false);
    }
  };

  const updateUserName = (event, value) => {
    setValue("userName", value?.user_name);
    setValue("user_id", value?.user_id);
  };

  const handleDate = (value) => {
    setDate(value);
  };

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  const formatTime = (timestamp) => {
    if (timestamp !== null) {
      const date = new Date(timestamp);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return null;
    }
  };

  const formattedCheckIn = formatTime(shiftStartTimeFormated);
  const formattedCheckOut = formatTime(shiftEndTimeFormated);

  const onSubmit = async (data) => {
    if (
      shiftStartTimeFormated <= shiftEndTimeFormated &&
      startDate <= endDate
    ) {
      setSameTimeError(false);
      setCheckinError(false);
      setSameTimeError(false);
      const response = await customAPI(`${domain}weekoff-approval/`, "POST", {
        ...data,
        type_name:
          getValues("selectAddType") === "leave balance"
            ? "leave balance"
            : date,

        // start_date: shiftStartDate.format("DD-MM-YYYY"),
        // end_date: shiftEndDate.format("DD-MM-YYYY"),
        check_in: formattedCheckIn,
        check_out: formattedCheckOut,
        user_id: userDetails.id,
      });
      if (response.ok) {
        showToast("success", response.responseDetails.message);
        setButtonLoader(false);
        setShiftStartTimeFormated(null);
        setShiftEndTimeFormated(null);
        setEndTimeError(false);
        setEndDateError(false);
      }
    } else {
      if (shiftStartTimeFormated > shiftEndTimeFormated) {
        setEndTimeError(true);
      } else if (startDate > endDate) {
        setEndDateError(true);
      }
    }
    setButtonLoader(false);
  };

  const handleWeekOffDate = (date) => {
    if (date) {
      const isValid = validDates.some((validDate) =>
        validDate.isSame(date, "day")
      );
      if (isValid) {
        setWeekOffDate(date);
        setShiftStartDate(date);
        setShiftEndDate(date);
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const fetchWeekOffDetais = async () => {
      try {
        const response = await fetch(
          `${domain}weekoff-approval/?user_id=${userDetails.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setValidDates(res.Week_off_dates.map((date) => dayjs(date)));
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (userDetails.id && apiCall) {
      fetchWeekOffDetais();
      setApiCall(false);
    }
  }, [apiCall, domain, token, userDetails.id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Mui.Grid
        container
        xs={9}
        sx={{
          padding: 1,
          ml: "13%",
          mt: "4%",
          backgroundColor: themes.primary,
          borderRadius: "10px 10px 0 0",
          border: `2px solid ${themes.sidebarDivider}`,
          borderBottom: "none",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Mui.Typography sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}>
          Time Off Policy Change
        </Mui.Typography>
      </Mui.Grid>
      <Mui.Grid
        container
        xs={9}
        sx={{
          paddingTop: 1,
          ml: "13%",
          border: `2px solid ${themes.sidebarDivider}`,
          padding: 2,
          borderRadius: "0 0 10px 10px",
        }}
      >
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid xs={1.5}></Mui.Grid>
          <Mui.Grid xs={4}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 1,
              }}
            >
              Employee Name
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={5} sx={{ paddingTop: 1 }}>
            <CustomAutoComplete
              dataList={users?.map((obj) => ({
                ...obj,
                label: obj.user_name,
              }))}
              selectedValue={watch("userName")}
              rules={{
                ...register("userName", {
                  required: "User name is required",
                }),
              }}
              updateDataFunction={updateUserName}
              placeHolder="Select the name"
              errorMessage={
                errors?.userName?.message ? errors?.userName?.message : ""
              }
              textBoxError={errors.userName ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid xs={1.5}></Mui.Grid>
          <Mui.Grid xs={4}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingTop: 1,
                paddingBottom: 1,
                fontWeight: "bold",
                paddingLeft: 1,
              }}
            >
              Type
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={5}>
            <CustomAutoComplete
              dataList={weekOffType}
              selectedValue={watch("type_name")}
              rules={{
                ...register("type_name", {
                  required: "Type is required",
                }),
              }}
              updateDataFunction={selectTypeName}
              placeHolder="Select the type"
              errorMessage={
                errors?.type_name?.message ? errors?.type_name?.message : ""
              }
              textBoxError={errors?.type_name ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        {typeName !== "On Duty" && (
          <>
            <Mui.Grid container>
              <Mui.Grid xs={1.5}></Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    paddingTop: 1,
                    paddingBottom: 1,
                    fontWeight: "bold",
                    paddingLeft: 1,
                  }}
                >
                  WeekOff Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker"]}
                    style={{ width: "100%" }}
                  >
                    <DatePicker
                      size="small"
                      slotProps={{
                        textField: {
                          size: "small",
                          inputProps: { readOnly: true },
                        },
                      }}
                      value={weekOffDate}
                      format="DD/MM/YYYY"
                      onChange={handleWeekOffDate}
                      shouldDisableDate={(date) =>
                        !validDates.some((validDate) =>
                          dayjs(validDate, "DD/MM/YYYY").isSame(date, "day")
                        )
                      }
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          size="small"
                          fullWidth
                          error={Boolean(error)}
                          helperText={error}
                          sx={{ pointerEvents: "none" }}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {error ? (
                  <h6
                    style={{
                      paddingTop: 2,
                      color: themes.helperTextColor,
                      fontSize: 12,
                    }}
                  >
                    WeekOff Date is required
                  </h6>
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid xs={1.4}></Mui.Grid>
              <Mui.Grid
                container
                xs={9.1}
                sx={{ border: `2px solid ${themes.sidebarDivider}`, mt: 2 }}
              >
                <Mui.Grid container sx={{ paddingTop: 2 }}>
                  <Mui.Grid xs={5.5}>
                    <Mui.Typography
                      sx={{ fontSize: 18, fontWeight: "bold", paddingLeft: 1 }}
                    >
                      Check In
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid container xs={6}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography>{"10:00"}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: "bold",
                          paddingLeft: 1,
                        }}
                      >
                        Check Out
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.Typography>{"10:00"}</Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingTop: 2 }}>
                  <Mui.Grid xs={5.5}>
                    <Mui.Typography
                      sx={{ fontSize: 18, fontWeight: "bold", paddingLeft: 1 }}
                    >
                      Total Working Hours
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid container xs={6}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography>{"22:00 hours"}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: "bold",
                          paddingLeft: 1,
                        }}
                      >
                        Shift Name
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.Typography sx={{ paddingTop: 0.5 }}>
                        {"General Shift"}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ mt: 1 }}></Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={1.5}></Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{ fontSize: 18, fontWeight: "bold", paddingLeft: 1 }}
                >
                  Seeking for
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid xs={6}>
                <Controller
                  name="selectType"
                  control={control}
                  defaultValue="add to payroll"
                  render={({ field }) => (
                    <Mui.RadioGroup
                      {...field}
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      onChange={(e) => {
                        setValue("selectType", e.target.value);
                        unregister("selectAddType");
                      }}
                    >
                      <Mui.FormControlLabel
                        label="Add to payroll"
                        value="add to payroll"
                        control={<Mui.Radio />}
                      />
                      &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Mui.FormControlLabel
                        label="Compensatory leave"
                        value="compensatory leave"
                        control={<Mui.Radio />}
                      />
                    </Mui.RadioGroup>
                  )}
                />
              </Mui.Grid>
            </Mui.Grid>
            {watch("selectType") === "compensatory leave" && (
              <Mui.Grid container sx={{ paddingTop: 2 }}>
                <Mui.Grid xs={1.5}></Mui.Grid>
                <Mui.Grid
                  xs={4}
                  sx={{ fontSize: 18, fontWeight: "bold", paddingLeft: 1 }}
                >
                  Add to
                </Mui.Grid>
                <Mui.Grid xs={5}>
                  <Controller
                    name="selectAddType"
                    control={control}
                    defaultValue="leave balance"
                    render={({ field }) => (
                      <Mui.RadioGroup
                        {...field}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        onChange={(e) => {
                          setValue("selectAddType", e.target.value);
                        }}
                      >
                        <Mui.FormControlLabel
                          label="Leave balance"
                          value="leave balance"
                          control={<Mui.Radio />}
                        />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <Mui.FormControlLabel
                          value="leave_date"
                          control={<Mui.Radio />}
                          label={
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <div sx={{ width: 2 }}>
                                  <DatePicker
                                    size="small"
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                        sx: { width: 155 },
                                      },
                                    }}
                                    value={date}
                                    format="DD/MM/YYYY"
                                    onChange={handleDate}
                                    renderInput={(params) => (
                                      <Mui.TextField {...params} size="small" />
                                    )}
                                  />
                                </div>
                              </DemoContainer>
                            </LocalizationProvider>
                          }
                        />
                      </Mui.RadioGroup>
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
            )}
          </>
        )}
        {typeName === "On Duty" && (
          <>
            <Mui.Grid container>
              <Mui.Grid item xs={1.5}></Mui.Grid>
              <Mui.Grid item xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    paddingTop: 1,
                    paddingBottom: 1,
                    fontWeight: "bold",
                    paddingLeft: 1,
                  }}
                >
                  Duration
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container xs={5} direction="row" alignItems="center">
                <Mui.Grid xs={6}>
                  <Controller
                    name="partDay"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={watch("partDay")}
                            onChange={(e) => {
                              if (!watch("partDay")) {
                                setValue("partDay", true);
                                setValue("fullDay", false);
                                setStartDate(dayjs());
                                setEndDate(dayjs());
                                setEndTimeError(false);
                              }
                            }}
                          />
                        }
                        label="Part Day"
                      />
                    )}
                  />
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Controller
                    name="fullDay"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={watch("fullDay")}
                            onChange={(e) => {
                              if (!watch("fullDay")) {
                                setValue("partDay", false);
                                setValue("fullDay", true);
                                setShiftStartTimeFormated(dayjs());
                                setShiftEndTimeFormated(dayjs());
                                setEndDateError(false);
                              }
                            }}
                          />
                        }
                        label="Full Day"
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            {partDayValue && (
              <Mui.Grid container>
                <Mui.Grid item xs={1.5}></Mui.Grid>
                <Mui.Grid item xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingTop: 1,
                      paddingBottom: 1,
                      fontWeight: "bold",
                      paddingLeft: 1,
                    }}
                  >
                    Date
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      style={{ width: "100%" }}
                    >
                      <DatePicker
                        size="small"
                        slotProps={{ textField: { size: "small" } }}
                        value={date}
                        format="DD/MM/YYYY"
                        onChange={handleDate}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <Mui.TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  {dateError ? (
                    <Mui.Typography
                      variant="body2"
                      color="error"
                      sx={{ fontSize: 13, padding: 0.8, paddingRight: 0.5 }}
                    >
                      Date is required
                    </Mui.Typography>
                  ) : previousDate ? (
                    <Mui.Typography
                      variant="body2"
                      color="error"
                      sx={{ fontSize: 13, padding: 0.8, paddingRight: 0.5 }}
                    >
                      Date cannot be in the past.
                    </Mui.Typography>
                  ) : null}
                </Mui.Grid>
              </Mui.Grid>
            )}
            {partDayValue && (
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={1.5}></Mui.Grid>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingTop: 1,
                      paddingBottom: 1,
                      fontWeight: "bold",
                      paddingLeft: 1,
                    }}
                  >
                    Start Time
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid container xs={5}>
                  <Mui.Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <div sx={{ width: 4 }}>
                          <TimePicker
                            ampm={false}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            value={shiftStartTimeFormated || null}
                            slotProps={{
                              textField: {
                                size: "small",
                                width: 15,
                              },
                            }}
                            onChange={(newValue) => {
                              if (newValue) {
                                const timeWithDate = newValue
                                  ? newValue.toDate()
                                  : new Date();
                                let minTime = moment.utc(timeWithDate);
                                setShiftStartTimeFormated(
                                  dayjs()
                                    .set("hour", minTime._d.getHours())
                                    .set("minute", minTime._d.getMinutes())
                                    .set("second", minTime._d.getSeconds())
                                );
                              } else {
                                setShiftStartTimeFormated(null);
                              }
                            }}
                          />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Mui.Grid>
                  <Mui.Grid item xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 18,
                        color: themes.headLine,
                        paddingTop: 2,
                        paddingBottom: 1,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      End Time
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <div sx={{ width: 4 }}>
                          <TimePicker
                            ampm={false}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            value={shiftEndTimeFormated || null}
                            slotProps={{
                              textField: {
                                size: "small",
                                width: 15,
                              },
                            }}
                            onChange={(newValue) => {
                              if (newValue) {
                                const timeWithDate = newValue
                                  ? newValue.toDate()
                                  : new Date();
                                let minTime = moment.utc(timeWithDate);
                                setShiftEndTimeFormated(
                                  dayjs()
                                    .set("hour", minTime._d.getHours())
                                    .set("minute", minTime._d.getMinutes())
                                    .set("second", minTime._d.getSeconds())
                                );
                              } else {
                                setShiftEndTimeFormated(null);
                              }
                            }}
                          />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                    {endTimeError && (
                      <Mui.Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: 13, paddingLeft: 0.5 }}
                      >
                        End time cannot before start time
                      </Mui.Typography>
                    )}
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            )}

            {fullDayValue && (
              <>
                <Mui.Grid container sx={{ paddingTop: 0.5 }}>
                  <Mui.Grid item xs={1.5}></Mui.Grid>
                  <Mui.Grid item xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 18,
                        color: themes.headLine,
                        paddingTop: 1,
                        paddingBottom: 1,
                        fontWeight: "bold",
                        paddingLeft: 1,
                      }}
                    >
                      Start Date
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid container xs={5}>
                    <Mui.Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <div sx={{ width: 4 }}>
                            <DatePicker
                              size="small"
                              value={startDate}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  width: 15,
                                },
                              }}
                              format="DD/MM/YYYY"
                              onChange={handleStartDate}
                              renderInput={(params) => (
                                <Mui.TextField
                                  {...params}
                                  size="small"
                                  sx={{ width: "50px" }}
                                />
                              )}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </Mui.Grid>
                    <Mui.Grid item xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 18,
                          color: themes.headLine,
                          paddingTop: 2,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        End Date
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <div sx={{ width: 4 }}>
                            <DatePicker
                              size="small"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  width: 15,
                                },
                              }}
                              value={endDate}
                              format="DD/MM/YYYY"
                              onChange={handleEndDate}
                              renderInput={(params) => (
                                <Mui.TextField
                                  {...params}
                                  size="small"
                                  fullWidth
                                />
                              )}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                      {endDateError && (
                        <Mui.Typography
                          variant="body2"
                          color="error"
                          sx={{ fontSize: 13, paddingLeft: 0.5 }}
                        >
                          End date must after start date
                        </Mui.Typography>
                      )}
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </>
            )}
          </>
        )}

        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid xs={1.5}></Mui.Grid>
          <Mui.Grid xs={4}>
            <Mui.Typography
              sx={{ fontSize: 18, fontWeight: "bold", paddingLeft: 1 }}
            >
              Description
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={5}>
            <Mui.TextField
              fullWidth
              id="description"
              {...register("description", {
                required: "Reason for leave is required",
              })}
              placeholder="Provide a reason for your leave"
              multiline
              minRows={2}
              error={errors.description}
              helperText={errors.description && errors.description.message}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          xs={10.5}
          sx={{
            paddingTop: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Mui.Grid item>
            <CustomButton
              actionName="Apply"
              typeName="submit"
              disableAction={buttonLoader}
            />
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </form>
  );
};
