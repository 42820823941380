import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import {
  minimum3Characters,
  minimum10Digits,
} from "services/constants/ErrorMessages";
import {
  onlyNumberRegex,
  specialCharacterRestrict,
} from "services/constants/patterns";
import { Info } from "@mui/icons-material";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomComponents/CustomButton";
export const ContactDetailsForm = ({
  setPageNo,
  existingUserDetails,
  setExistingUserDetails,
  openDialogBox,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [parentName, setParentName] = useState(
    existingUserDetails?.parent_name
  );
  const [parentNumber, setParentNumber] = useState(
    existingUserDetails?.parent_mobile_number
      ? existingUserDetails?.parent_mobile_number
      : ""
  );
  const [contactName1, setContactName1] = useState(
    existingUserDetails?.emergency_contacts
      ? existingUserDetails?.emergency_contacts[0]?.contact_name
      : ""
  );
  const [relationType1, setRelationType1] = useState(
    existingUserDetails?.emergency_contacts
      ? existingUserDetails?.emergency_contacts[0]?.relation_type
      : ""
  );
  const [contactNumber1, setContactNumber1] = useState(
    existingUserDetails?.emergency_contacts[0]?.contact_number
      ? existingUserDetails?.emergency_contacts[0]?.contact_number
      : ""
  );
  const [contactName2, setContactName2] = useState(
    existingUserDetails?.emergency_contacts[1]?.contact_name
      ? existingUserDetails?.emergency_contacts[1]?.contact_name
      : ""
  );
  const [relationType2Details, setRelationType2Details] = useState(
    existingUserDetails?.emergency_contacts[1]?.relation_type
      ? existingUserDetails?.emergency_contacts[1]?.relation_type
      : ""
  );
  const [contactNumber2, setContactNumber2] = useState(
    existingUserDetails?.emergency_contacts[1]?.contact_number
      ? existingUserDetails?.emergency_contacts[1]?.contact_number
      : ""
  );
  const [saveAndExit, setSaveAndExit] = useState(false);

  useEffect(() => {
    setValue("parentName", existingUserDetails?.parent_name);
    setValue("mobileNumber", existingUserDetails?.parent_mobile_number);
    setValue(
      "emergencyContactName",
      existingUserDetails?.emergency_contacts[0]?.contact_name
        ? existingUserDetails?.emergency_contacts[0]?.contact_name
        : ""
    );
    setValue(
      "relationType",
      existingUserDetails?.emergency_contacts[0]?.relation_type
        ? existingUserDetails?.emergency_contacts[0]?.relation_type
        : ""
    );
    setValue(
      "emergencyContactNumber",
      existingUserDetails?.emergency_contacts[0]?.contact_number
        ? existingUserDetails?.emergency_contacts[0]?.contact_number
        : ""
    );
    setValue(
      "emergencyContactName2",
      existingUserDetails?.emergency_contacts[1]?.contact_name
        ? existingUserDetails?.emergency_contacts[1]?.contact_name
        : ""
    );
    setValue(
      "relationType2",
      existingUserDetails?.emergency_contacts[1]?.relation_type
        ? existingUserDetails?.emergency_contacts[1]?.relation_type
        : ""
    );
    setValue(
      "emergencyContactNumber2",
      existingUserDetails?.emergency_contacts[1]?.contact_number
        ? existingUserDetails?.emergency_contacts[1]?.contact_number
        : ""
    );
  }, [
    existingUserDetails?.emergency_contacts,
    existingUserDetails?.parent_mobile_number,
    existingUserDetails?.parent_name,
    setValue,
  ]);

  const onSubmit = async (data) => {
    const contactData = [
      {
        contact_name: data.emergencyContactName,
        relation_type: data.relationType,
        contact_number: data.emergencyContactNumber,
      },
      {
        contact_name: data.emergencyContactName2,
        relation_type: data.relationType2,
        contact_number: data.emergencyContactNumber2,
      },
    ];
    setExistingUserDetails({
      ...existingUserDetails,
      parent_name: data.parentName,
      parent_mobile_number: data.mobileNumber,
      emergency_contacts: contactData,
    });
    if (saveAndExit) {
      saveDataAndExists(contactData, data);
      setSaveAndExit(false);
    } else {
      setPageNo(4);
      reset();
    }
  };

  const saveDataAndExists = async (contactData, data) => {
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}employee-onboard`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_name: existingUserDetails.user_name,
          email: existingUserDetails.email_id,
          company_id: existingUserDetails.company_id,
          portal_access: existingUserDetails.portal_access,
          employee_id: existingUserDetails.employee_id,
          date_joined: existingUserDetails.date_of_joined
            ? existingUserDetails.date_of_joined?.split("-").reverse().join("-")
            : null,
          leave_group_id: existingUserDetails?.leave_group_id,
          department_id: existingUserDetails.department_id,
          designation_id: existingUserDetails.designation_id,
          employment_type_id: existingUserDetails.employment_type_id,
          parent_name: data.parentName,
          parent_mobile_number: data.mobileNumber,
          bankname: existingUserDetails.bank_name,
          account_holder_name: existingUserDetails.account_holder_name,
          accountno: existingUserDetails.account_number,
          ifsc: existingUserDetails.ifsc,
          branch_name: existingUserDetails.branch_name,
          pan: existingUserDetails.pan_number,
          aadhar: existingUserDetails.aadhar_number,
          address: existingUserDetails.address,
          pf: existingUserDetails.pf_number,
          uan: existingUserDetails.uan_number,
          esi: existingUserDetails.esi_number,
          dob: existingUserDetails.date_of_birth
            ? existingUserDetails.date_of_birth.split("-").reverse().join("-")
            : null,
          emergency_contacts: contactData,
          permanent_address: existingUserDetails?.permanent_address,
          transport_route_no: existingUserDetails?.transport_route_no,
          transport_type: existingUserDetails?.transport_type,
          block_name: existingUserDetails?.block_name,
          room_no: existingUserDetails?.room_no,
          phone_number: existingUserDetails.phone_number,
          salary_group: existingUserDetails.salary_group,
          earnings: existingUserDetails.earnings,
          deductions: existingUserDetails.deductions,
          salary_type: existingUserDetails.salary_type,
          amount: existingUserDetails.amount,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        openDialogBox();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const updateParentName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 32
    ) {
      setParentName(event.target.value);
      setValue("parentName", event.target.value);
    }
  };

  const updateParentNumber = (event) => {
    if (
      event.target.value.length <= 15 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setParentNumber(event.target.value);
      setValue("mobileNumber", event.target.value);
    }
  };

  const updateEmergencyContactName = (event) => {
    if (event.target.value.length <= 32) {
      setContactName1(event.target.value);
      setValue("emergencyContactName", event.target.value);
    }
  };

  const updateRelationType = (event) => {
    if (event.target.value.length <= 32) {
      setRelationType1(event.target.value);
      setValue("relationType", event.target.value);
    }
  };

  const updatedEmergencyContactNumber = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 15
    ) {
      setValue("emergencyContactNumber", event.target.value);
      setContactNumber1(event.target.value);
    }
  };

  const updateEmergencyContactName2 = (event) => {
    if (event.target.value.length <= 32) {
      setContactName2(event.target.value);
      setValue("emergencyContactName2", event.target.value);
    }
  };

  const updateRelationType2 = (event) => {
    if (event.target.value.length <= 32) {
      setRelationType2Details(event.target.value);
      setValue("relationType2", event.target.value);
    }
  };

  const updateEmergencyContactNumber2 = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 15
    ) {
      setValue("emergencyContactNumber2", event.target.value);
      setContactNumber2(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 10 }}>
        <Mui.Grid container fullWidth>
          <Mui.Grid xs={5.8} sx={{ padding: 1 }}>
            {" "}
            <Mui.Typography
              sx={{
                fontSize: 18,
                font: "bold",
                fontWeight: 700,
              }}
            >
              {existingUserDetails.user_name}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={0.4}></Mui.Grid>
          <Mui.Grid xs={5.8} sx={{ padding: 1 }}>
            {" "}
            <Mui.Typography
              sx={{
                fontSize: 18,
                font: "bold",
                fontWeight: 700,
              }}
            >
              {existingUserDetails.company_name}
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container fullWidth>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Spouse/Guardian/Parent Name
            </Mui.Typography>
            <CustomTextBox
              selectedValue={parentName}
              rules={{
                ...register("parentName", {
                  required: false,
                  validate: {
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateParentName}
              placeHolder="Enter parent name"
              errorMessage={
                errors?.parentName?.message ? errors?.parentName?.message : ""
              }
              textBoxError={errors.parentName ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid item xs={0.4}></Mui.Grid>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Spous/Guardian/Parent Number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={parentNumber}
              rules={{
                ...register("mobileNumber", {
                  required: false,
                  minLength: {
                    value: 10,
                    message: minimum10Digits,
                  },
                }),
              }}
              updateDataFunction={updateParentNumber}
              placeHolder="Enter Parent Number"
              errorMessage={
                errors?.mobileNumber?.message
                  ? errors?.mobileNumber?.message
                  : ""
              }
              textBoxError={errors.mobileNumber ? true : false}
            />
            {/* */}
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            Emergency Contact 1
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container fullWidth>
          <Mui.Grid item xs={4} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Contact name
            </Mui.Typography>
            <CustomTextBox
              selectedValue={contactName1}
              rules={{
                ...register("emergencyContactName", {
                  required: false,
                  validate: {
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateEmergencyContactName}
              placeHolder="Emergency contact name"
              errorMessage={
                errors?.emergencyContactName?.message
                  ? errors?.emergencyContactName?.message
                  : ""
              }
              textBoxError={errors.emergencyContactName ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid item xs={4} sx={{ padding: 1 }}>
            <Mui.Grid container>
              <Mui.Grid xs={12}>
                <Mui.Stack direction="row">
                  <Mui.Typography
                    sx={{
                      fontSize: 15,
                      paddingBottom: 0.5,
                      color: themes.colorShadow,
                    }}
                  >
                    Relation Type
                  </Mui.Typography>{" "}
                  &nbsp;&nbsp;
                  <Mui.Tooltip title="Ex:Brother,Mother,Father    ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
            </Mui.Grid>
            <CustomTextBox
              selectedValue={relationType1}
              rules={{
                ...register("relationType", {
                  required: false,
                  validate: {
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateRelationType}
              placeHolder="Enter RelationType"
              errorMessage={
                errors?.relationType?.message
                  ? errors?.relationType?.message
                  : ""
              }
              textBoxError={errors.relationType ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid item xs={4} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Contact number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={contactNumber1}
              rules={{
                ...register("emergencyContactNumber", {
                  required: false,
                  minLength: {
                    value: 10,
                    message: minimum10Digits,
                  },
                }),
              }}
              updateDataFunction={updatedEmergencyContactNumber}
              placeHolder="Emergency contact number"
              errorMessage={
                errors?.emergencyContactNumber?.message
                  ? errors?.emergencyContactNumber?.message
                  : ""
              }
              textBoxError={errors.emergencyContactNumber ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>

        <Mui.Grid container>
          <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            Emergency Contact 2
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container fullWidth>
          <Mui.Grid item xs={4} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Contact name
            </Mui.Typography>
            <CustomTextBox
              selectedValue={contactName2}
              rules={{
                ...register("emergencyContactName2", {
                  required: false,
                  validate: {
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateEmergencyContactName2}
              placeHolder="Emergency contact name"
              errorMessage={
                errors?.emergencyContactName2?.message
                  ? errors?.emergencyContactName2?.message
                  : ""
              }
              textBoxError={errors.emergencyContactName2 ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid item xs={4} sx={{ padding: 1 }}>
            <Mui.Grid container>
              <Mui.Grid xs={12}>
                <Mui.Stack direction="row">
                  <Mui.Typography
                    sx={{
                      fontSize: 15,
                      paddingBottom: 0.5,
                      color: themes.colorShadow,
                    }}
                  >
                    Relation Type
                  </Mui.Typography>{" "}
                  &nbsp;&nbsp;
                  <Mui.Tooltip title="Ex:Brother,Mother,Father    ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
            </Mui.Grid>
            <CustomTextBox
              selectedValue={relationType2Details}
              rules={{
                ...register("relationType2", {
                  required: false,
                  validate: {
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateRelationType2}
              placeHolder="Enter RelationType"
              errorMessage={
                errors?.relationType2?.message
                  ? errors?.relationType2?.message
                  : ""
              }
              textBoxError={errors.relationType2 ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid item xs={4} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Contact number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={contactNumber2}
              rules={{
                ...register("emergencyContactNumber2", {
                  required: false,
                  minLength: {
                    value: 10,
                    message: minimum10Digits,
                  },
                }),
              }}
              updateDataFunction={updateEmergencyContactNumber2}
              placeHolder="Emergency contact number"
              errorMessage={
                errors?.emergencyContactNumber2?.message
                  ? errors?.emergencyContactNumber2?.message
                  : ""
              }
              textBoxError={errors.emergencyContactNumber2 ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: {
              xs: 0,
              sm: 0,
              md: 10,
              lg: 10,
            },
          }}
        >
          <Mui.Grid
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "flex-start",
              paddingRight: 2,
            }}
          >
            <CustomButton
              actionFuntion={() => setPageNo(2)}
              actionName="Previous"
              typeName="button"
            />
          </Mui.Grid>
          <Mui.Grid
            xs={8}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "flex-end",
              paddingRight: 2,
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setSaveAndExit(true);
              }}
              actionName="Save & Exit"
              typeName="submit"
              disableAction={buttonDisabled}
            />
            &nbsp;&nbsp;
            <CustomButton
              actionName="Next"
              typeName="submit"
              actionFuntion={() => {
                setSaveAndExit(false);
              }}
            />
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
