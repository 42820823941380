import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { EditAndSidePeek } from "./EditAndSidePeek";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { MoreVert, Info } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { Loader } from "components/Loader";
import { GroupDetails } from "./GroupDetails";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { setGlobalSearchValue } from "services/Redux/userToken";
const keys = [
  "user_name",
  "employee_id",
  "paid_days",
  "gross_pay",
  "total_deduction",
  "net_pay",
  "per_day_salary",
];

const payRunKeyPair = [
  {
    name: "Employee Name",
  },
  {
    name: "Employee Id",
  },
  {
    name: "Paid Days",
  },
  {
    name: "Gross Pay",
  },
  {
    name: "Deductions",
  },
  // {
  //   name: "Net Salary",
  // },
  // {
  //   name: "Add. Deductions",
  // },
  {
    name: "Net Pay",
  },
  {
    name: "Action",
  },
];

export const ConstStructure = ({
  viewPayrunDetails,
  setPayrollCost,
  setEmployeeNetPay,
  setTotalDeduction,
  setTotalWorkingHours,
  companyId,
  reloadUserPayrollDetails,
  setReloadUserPayrollDetails,
  setDownloadReportDetails,
  downloadReportDetails,
  summaryName,
  companyName,
  status,
}) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const [buttonNotify, setButtonNotify] = useState("Employee List");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [listViewPage, setListViewPage] = useState(0);
  const [openSidePeek, setOpenSidePeek] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reason, setReason] = useState("");
  const [payrollGrpName, setPayrollGrpName] = useState("");
  const [payrollGroupId, setPayrollGroupId] = useState(0);
  const [changeGroupDialog, setChangeGroupDialog] = useState(false);
  const [changeGroupError, setChangeGroupError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [costStructureDetailsState, setCostStructureDetailsState] = useState(
    []
  );
  const [updateData, setUpdateData] = useState(true);
  const { result: payrollGroupData } = useFetch(
    companyId !== 0 ? `${domain}payroll-group/?company=${companyId}` : null
  );
  const {
    result: payrunData,
    setLoad: reloadApi,
    loading,
  } = useFetch(
    `${domain}payrun/retrieve/?pay_schedule_id=${viewPayrunDetails.id}`
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (reloadUserPayrollDetails) {
      reloadApi(true);
      setReloadUserPayrollDetails(false);
    }
  }, [reloadApi, reloadUserPayrollDetails, setReloadUserPayrollDetails]);

  useEffect(() => {
    if (payrunData) {
      setPayrollCost(payrunData?.summary?.payroll_cost);
      setEmployeeNetPay(payrunData?.summary?.employee_net_pay);
      setTotalDeduction(payrunData?.summary?.total_deduction);
      setTotalWorkingHours(payrunData?.working_days);
    }
  }, [
    payrunData,
    setEmployeeNetPay,
    setPayrollCost,
    setTotalDeduction,
    setTotalWorkingHours,
  ]);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}payrun/skip-salary/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          payroll_run_id: viewPayrunDetails.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        reloadApi(true);
        showToast("error", res.message);
        setOpenDialog(false);
        setReason("");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const updateReasonForSkipSalary = async (event) => {
    setReason(event.target.value);
  };

  useEffect(() => {
    if (
      updateData &&
      payrunData?.payroll_run_details?.length !== 0 &&
      payrunData
    ) {
      setCostStructureDetailsState(payrunData?.payroll_run_details);
      setUpdateData(false);
    }
  }, [payrunData, updateData]);

  useEffect(() => {
    if (payrunData?.length !== 0 || payrunData?.length === 0) {
      const filteredResults = payrunData?.payroll_run_details?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setCostStructureDetailsState(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payrunData]);

  useEffect(() => {
    const downloadReports = () => {
      if (costStructureDetailsState.length !== 0) {
        let dataPreparation = [];
        costStructureDetailsState?.forEach((element) => {
          let overAllData = {
            user_name: element.user_name,
            employee_id: element.employee_id,
            paid_days: element.paid_days,
            gross_pay: element.gross_pay,
            total_deduction: element.total_deduction,
            net_pay: element.net_pay,
          };
          dataPreparation = [...dataPreparation, overAllData];
        });
        const company_Name = ["Company Name"];
        const pay_ScheduleName = ["PaySchedule Name"];
        const paySchedulePeriod = ["PaySchedule Period"];
        const payRollCost = ["Payroll Cost"];
        const employeeNetPay = ["Employee's Net Pay"];
        const totalDeductions = ["Total Deductions"];
        const TableHead = ["Employee Name"];
        TableHead.push("Employee Id");
        TableHead.push("Paid Days");
        TableHead.push("Gross Pay");
        TableHead.push("Deductions");
        TableHead.push("Net Pay");
        company_Name.push(companyName);
        pay_ScheduleName.push(viewPayrunDetails.pay_schedule_name);
        paySchedulePeriod.push(
          viewPayrunDetails.start_date + "  to  " + viewPayrunDetails.end_date
        );
        payRollCost.push(payrunData?.summary?.payroll_cost);
        employeeNetPay.push(payrunData?.summary?.employee_net_pay);
        totalDeductions.push(payrunData?.summary?.total_deduction);

        const items = dataPreparation;
        const replacer = (key, value) => (value === null ? "" : value);
        const header = Object.keys(items[0]).filter(
          (field) => field !== "break_details"
        );
        let csv = items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        );

        csv.unshift(TableHead.join(","));
        csv.unshift(totalDeductions.join(","));
        csv.unshift(employeeNetPay.join(","));
        csv.unshift(payRollCost.join(","));
        csv.unshift(paySchedulePeriod.join(","));
        csv.unshift(pay_ScheduleName.join(","));
        csv.unshift(company_Name.join(","));

        csv = csv.join("\r\n");
        const ConvertData = csv;
        const element = document.createElement("a");
        const file = new Blob([ConvertData], {
          type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = "CostStructureReport.csv";
        document.body.appendChild(element);
        element.click();
      } else {
        showToast("error", "No data available");
      }
    };
    if (downloadReportDetails && summaryName === "Cost Structure") {
      downloadReports();
      setDownloadReportDetails(false);
    }
  }, [
    companyName,
    costStructureDetailsState,
    downloadReportDetails,
    payrunData?.summary?.employee_net_pay,
    payrunData?.summary?.payroll_cost,
    payrunData?.summary?.total_deduction,
    setDownloadReportDetails,
    summaryName,
    viewPayrunDetails.end_date,
    viewPayrunDetails.pay_schedule_name,
    viewPayrunDetails.start_date,
  ]);

  const handlePayrollGrpName = (event, data) => {
    if (data) {
      setPayrollGrpName(data?.label);
      setPayrollGroupId(data?.id);
    } else {
      setPayrollGrpName("");
      setPayrollGroupId(0);
    }
  };
  const changeGroupForUser = () => {
    if (payrollGrpName !== "") {
      console.log(payrollGroupId);
    } else {
      setChangeGroupError(true);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}></Mui.Grid>
      <Mui.ButtonGroup>
        <Mui.Button
          variant={buttonNotify === "Employee List" ? "contained" : "outlined"}
          onClick={() => {
            // reCallApi(true);
            setButtonNotify("Employee List");
            dispatch(setGlobalSearchValue(""));
          }}
          sx={{
            background:
              buttonNotify === "Employee List"
                ? themes.primaryButton
                : "outlined",
            textTransform: "capitalize",
            border: "none",
            "&:hover": {
              border: "none",
              background:
                buttonNotify === "Employee List"
                  ? themes.primaryButton
                  : "outlined",
            },
            color: themes.headLine,
          }}
        >
          Employee List
        </Mui.Button>
        <Mui.Button
          variant={buttonNotify === "Group" ? "contained" : "outlined"}
          onClick={() => {
            // reCallApi(true);
            setButtonNotify("Group");
            dispatch(setGlobalSearchValue(""));
          }}
          sx={{
            background:
              buttonNotify === "Group" ? themes.primaryButton : "outlined",
            textTransform: "capitalize",
            border: "none",
            "&:hover": {
              border: "none",
              background:
                buttonNotify === "Group" ? themes.primaryButton : "outlined",
            },
            color: themes.headLine,
          }}
        >
          Group
        </Mui.Button>
      </Mui.ButtonGroup>
      {buttonNotify === "Employee List" ? (
        loading ? (
          <Loader info="Loading..." />
        ) : (
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{ mt: 2, width: "100%", maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "3px 15px",
                    },
                  }}
                >
                  {payRunKeyPair.map((header) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {header.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {costStructureDetailsState?.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell
                      colSpan={payRunKeyPair.length}
                      align="center"
                    >
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  costStructureDetailsState
                    ?.slice(
                      listViewPage * rowsPerPage,
                      listViewPage * rowsPerPage + rowsPerPage
                    )
                    .map((user) => (
                      <Mui.TableRow
                        sx={{
                          cursor: "pointer",
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "3px 15px",
                          },
                        }}
                      >
                        <Mui.TableCell
                          onClick={() => {
                            setEditUserDetails(user);
                            setOpenSidePeek(true);
                          }}
                        >
                          <Mui.Stack direction="Row">
                            <Mui.Avatar
                              sx={{ width: 25, height: 25 }}
                              alt="Remy Sharp"
                              src={user.image_url}
                            />
                            <Mui.Typography
                              sx={{
                                paddingLeft: 1,
                              }}
                            >
                              {user.user_name}
                            </Mui.Typography>
                          </Mui.Stack>
                        </Mui.TableCell>
                        <Mui.TableCell
                          onClick={() => {
                            setEditUserDetails(user);
                            setOpenSidePeek(true);
                          }}
                        >
                          {user?.employee_id}
                        </Mui.TableCell>
                        <Mui.TableCell
                          onClick={() => {
                            setEditUserDetails(user);
                            setOpenSidePeek(true);
                          }}
                        >
                          {user?.paid_days}
                        </Mui.TableCell>
                        <Mui.TableCell
                          onClick={() => {
                            setEditUserDetails(user);
                            setOpenSidePeek(true);
                          }}
                        >
                          {user?.gross_pay}
                        </Mui.TableCell>
                        <Mui.TableCell
                          onClick={() => {
                            setEditUserDetails(user);
                            setOpenSidePeek(true);
                          }}
                        >
                          {user?.total_deduction}
                        </Mui.TableCell>
                        {/* <Mui.TableCell
                          onClick={() => {
                            setEditUserDetails(user);
                            setOpenSidePeek(true);
                          }}
                        >
                          N/A
                        </Mui.TableCell>
                        <Mui.TableCell
                          onClick={() => {
                            setEditUserDetails(user);
                            setOpenSidePeek(true);
                          }}
                        >
                          N/A
                        </Mui.TableCell> */}
                        <Mui.TableCell
                          onClick={() => {
                            setEditUserDetails(user);
                            setOpenSidePeek(true);
                          }}
                        >
                          {user?.net_pay}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              setEditUserDetails(user);
                            }}
                          >
                            <MoreVert />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {/* <Mui.MenuItem
                              sx={{ width: "100%" }}
                              onClick={() => {
                                setAnchorEl(null);
                                setOpenDialog(true);
                              }}
                            >
                              Skip from this payroll
                            </Mui.MenuItem> */}
                            <Mui.MenuItem
                              sx={{ width: "100%" }}
                              onClick={() => {
                                setAnchorEl(null);
                                setOpenSidePeek(true);
                              }}
                            >
                              View payment details
                            </Mui.MenuItem>
                            {/* <Mui.MenuItem
                              sx={{ width: "100%" }}
                              onClick={() => {
                                setAnchorEl(null);
                                setChangeGroupDialog(true);
                              }}
                            >
                              Change Group
                            </Mui.MenuItem> */}
                          </Mui.Menu>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        )
      ) : (
        <GroupDetails
          viewPayrunDetails={viewPayrunDetails}
          companyId={companyId}
          payrunData={payrunData}
          status={status}
        />
      )}
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {costStructureDetailsState?.length > 25 &&
          buttonNotify === "Employee List" && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={costStructureDetailsState?.length}
              rowsPerPage={rowsPerPage}
              page={listViewPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
      </Mui.Grid>
      {openSidePeek ? (
        <EditAndSidePeek
          openSidePeek={openSidePeek}
          setOpenSidePeek={setOpenSidePeek}
          editUserDetails={editUserDetails}
          companyId={companyId}
          reloadApi={reloadApi}
          viewPayrunDetails={viewPayrunDetails}
          payrunData={payrunData}
          status={status}
        />
      ) : null}
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
          setReason("");
        }}
        open={openDialog}
        title="Skip Employee"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <Mui.Grid container>
            <Mui.Grid
              container
              sx={{ padding: 2, backgroundColor: themes.formBackground }}
            >
              <Mui.Grid xs={2} sx={{ paddingTop: 1 }}>
                <Info sx={{ color: themes.redColor, fontSize: 40 }} />
              </Mui.Grid>
              <Mui.Grid xs={10}>
                <Mui.Typography>
                  Once you remove this employee from this payroll cycle, you
                  won’t be able to add them back or issue payment for this
                  period. Do you wish to proceed?
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid xs={5}>
                <Mui.Typography sx={{ paddingBottom: 0.5 }}>
                  Employee
                </Mui.Typography>
                <Mui.Grid container>
                  <Mui.Grid xs={3}>
                    <Mui.Avatar
                      sx={{ width: 40, height: 40 }}
                      alt="Remy Sharp"
                      src={editUserDetails.image_url}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={9}>
                    <Mui.Typography sx={{ color: themes.headLine }}>
                      {editUserDetails.user_name}
                    </Mui.Typography>
                    <Mui.Typography sx={{ color: themes.headLine }}>
                      {editUserDetails.employee_id}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={2}></Mui.Grid>
              <Mui.Grid xs={5}>
                <Mui.Typography sx={{ paddingBottom: 0.5 }}>
                  Payroll Period
                </Mui.Typography>
                <Mui.Typography>
                  {viewPayrunDetails.start_date +
                    "-" +
                    viewPayrunDetails.end_date}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Typography>Reason for skip</Mui.Typography>
              <CustomTextBox
                type="multiline"
                selectedValue={reason}
                rules={{
                  ...register("skip_reason", {
                    required: false,
                  }),
                }}
                updateDataFunction={updateReasonForSkipSalary}
                errorMessage={
                  errors?.skip_reason?.message
                    ? errors?.skip_reason?.message
                    : ""
                }
                textBoxError={errors.skip_reason ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  setReason("");
                }}
                actionName="No"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionName="Yes"
                typeName="submit"
                disableAction={buttonDisabled}
              />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
      {changeGroupDialog ? (
        <GlobalDialogBox
          handleCloseDialog={() => {
            setChangeGroupDialog(false);
            setChangeGroupError(false);
            setPayrollGrpName("");
            setPayrollGroupId(0);
          }}
          open={changeGroupDialog}
          title="Change Group"
        >
          <Mui.Grid container>
            <Mui.Grid container sx={{ padding: 2 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                <Mui.Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  Current Group
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8} sx={{ paddingTop: 1 }}>
                <Mui.Typography>{editUserDetails?.group_name}</Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 2 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                <Mui.Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  New Group
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8} sx={{ paddingTop: 1 }}>
                <CustomAutoComplete
                  dataList={payrollGroupData.map((obj) => ({
                    ...obj,
                    label: obj.name,
                  }))}
                  selectedValue={payrollGrpName}
                  updateDataFunction={handlePayrollGrpName}
                  placeHolder="Please select the payroll group"
                  errorMessage={
                    changeGroupError && payrollGrpName === ""
                      ? "Please select payroll group"
                      : ""
                  }
                  textBoxError={
                    changeGroupError && payrollGrpName === "" ? true : false
                  }
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setChangeGroupDialog(false);
                  setPayrollGrpName("");
                  setPayrollGroupId(0);
                }}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={() => {
                  changeGroupForUser();
                }}
                actionName="Save"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        </GlobalDialogBox>
      ) : null}
    </React.Fragment>
  );
};
