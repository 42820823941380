import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BasicInfo } from "./BasicInfo";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";

export const CreateEmployee = () => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [isFieldFilled, setIsFieldFilled] = useState(false);

  const handleFieldCheck = (filled) => {
    setIsFieldFilled(filled);
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const handlePopState = () => {
      if (isFieldFilled) {
        setOpenDialog(true);
        window.history.pushState(null, "", window.location.href);
      } else {
        history.push("/admin/hr/employee");
      }
    };
    window.addEventListener("popstate", handlePopState);
    window.history.pushState(null, "", window.location.href);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isFieldFilled, setOpenDialog, history]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 3, paddingRight: 3 }}>
        {" "}
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingRight: 3 }}>
        <Mui.Stack direction="row">
          <BackArrow
            actionFunction={() => {
              if (isFieldFilled) {
                setOpenDialog(true);
              } else {
                history.push("/admin/hr/employee");
              }
            }}
          />{" "}
          &nbsp;
          <Mui.Typography
            sx={{
              paddingTop: 1,
              fontSize: 23,
              fontWeight: "bold",
              color: themes.headLine,
              paddingLeft: 1,
            }}
          >
            Create New Employee
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          padding: 1,
          width: "99.5%",
          height: "100%",
          borderRadius: 5,
          border: `5px solid ${themes.sidebarDivider}`,
        }}
        component={Mui.Paper}
      >
        <Mui.Grid container></Mui.Grid>
        <BasicInfo onFieldCheck={handleFieldCheck} />
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Confirmation Required"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 20, font: "bold" }}>
          Are you sure you want to go back? Clicking 'Yes' will result in losing
          all entered data.
        </Mui.Typography>

        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionName="Yes"
              typeName="button"
              actionFuntion={() => history.push("/admin/hr/employee")}
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
