import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { themes } from "services/constants";
import { PayRunTable } from "./PayRunTable";
import { Loader } from "components/Loader";
import { PayHistory } from "./PayHistory";
import { ApprovalPayroll } from "./ApprovalPayroll";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { PaySchedule } from "./PaySchedule";

const keys = [
  "name",
  "start_date",
  "end_date",
  "run_date",
  "num_of_employees",
  "status",
  "total_net_pay",
];

export const RunPayRoll = () => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const { result: companyList } = useFetch(`${domain}get-active-company/`);
  const [companyName, setCompanyName] = useState("");
  const [buttonNotify, setButtonNotify] = useState("PaySchedule");
  const [companyId, setCompanyId] = useState(0);
  const [viewPayrunScreen, setViewPayRunScreen] = useState(false);
  const [payRunDetailsState, setPayRunDetailsState] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [listViewPage, setListViewPage] = useState(0);

  const {
    result: payrunDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0
      ? `${domain}detailed-pay-schedule/?company=${companyId}`
      : null
  );

  useEffect(() => {
    if (companyList.length !== 0) {
      const data = companyList.filter((company) => {
        return company.isprimary === true;
      });
      setCompanyId(data[0].id);
      setCompanyName(data[0].companyname);
    }
  }, [companyList]);

  const handleCompanyName = (event, data) => {
    if (data) {
      setCompanyName(data.companyname);
      setCompanyId(data.id);
      reCallApi(true);
    }
  };

  const reRenderComponent = () => {
    switch (buttonNotify) {
      case "Run Payroll":
        return (
          <PayRunTable
            listViewPage={listViewPage}
            setListViewPage={setListViewPage}
            payrunDetails={payRunDetailsState}
            companyId={companyId}
            companyName={companyName}
            setViewPayRunScreen={setViewPayRunScreen}
            viewPayrunScreen={viewPayrunScreen}
            reCallApi={reCallApi}
          />
        );
      case "Approval Payroll":
        return (
          <ApprovalPayroll
            companyName={companyName}
            companyId={companyId}
            setViewPayRunScreen={setViewPayRunScreen}
            viewPayrunScreen={viewPayrunScreen}
          />
        );
      case "Payroll History":
        return (
          <PayHistory
            setShowHistory={setButtonNotify}
            companyName={companyName}
            companyId={companyId}
            setViewPayRunScreen={setViewPayRunScreen}
            viewPayrunScreen={viewPayrunScreen}
          />
        );
      case "PaySchedule":
        return (
          <PaySchedule
            companyId={companyId}
            companyName={companyName}
            setViewPayRunScreen={setViewPayRunScreen}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (updateData && payrunDetails?.length !== 0 && payrunDetails) {
      setPayRunDetailsState(payrunDetails);
      setUpdateData(false);
    }
  }, [payrunDetails, updateData]);

  useEffect(() => {
    if (payrunDetails?.length !== 0 || payrunDetails?.length === 0) {
      const filteredResults = payrunDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setPayRunDetailsState(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payrunDetails]);

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      {viewPayrunScreen ? null : (
        <Mui.Grid
          sx={{
            paddingTop: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-center",
            alignItems: "flex-center",
          }}
        >
          <Mui.Grid container>
            <Mui.Grid xs={9} sx={{ paddingTop: 1.5 }}>
              <Mui.ButtonGroup>
                <Mui.Button
                  variant={
                    buttonNotify === "PaySchedule" ? "contained" : "outlined"
                  }
                  onClick={() => {
                    reCallApi(true);
                    setButtonNotify("PaySchedule");
                    dispatch(setGlobalSearchValue(""));
                  }}
                  sx={{
                    background:
                      buttonNotify === "PaySchedule"
                        ? themes.primaryButton
                        : "outlined",
                    textTransform: "capitalize",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      background:
                        buttonNotify === "PaySchedule"
                          ? themes.primaryButton
                          : "outlined",
                    },
                    color: themes.headLine,
                  }}
                >
                  Schedule
                </Mui.Button>
                <Mui.Button
                  variant={
                    buttonNotify === "Run Payroll" ? "contained" : "outlined"
                  }
                  onClick={() => {
                    reCallApi(true);
                    setButtonNotify("Run Payroll");
                    dispatch(setGlobalSearchValue(""));
                  }}
                  sx={{
                    background:
                      buttonNotify === "Run Payroll"
                        ? themes.primaryButton
                        : "outlined",
                    textTransform: "capitalize",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      background:
                        buttonNotify === "Run Payroll"
                          ? themes.primaryButton
                          : "outlined",
                    },
                    color: themes.headLine,
                  }}
                >
                  Run
                </Mui.Button>
                <Mui.Button
                  variant={
                    buttonNotify === "Approval Payroll"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setButtonNotify("Approval Payroll");
                    dispatch(setGlobalSearchValue(""));
                  }}
                  sx={{
                    background:
                      buttonNotify === "Approval Payroll"
                        ? themes.primaryButton
                        : "outlined",
                    textTransform: "capitalize",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      background:
                        buttonNotify === "Approval Payroll"
                          ? themes.primaryButton
                          : "outlined",
                    },
                    color: themes.headLine,
                  }}
                >
                  Approve
                </Mui.Button>
                <Mui.Button
                  variant={
                    buttonNotify === "Payroll History"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setButtonNotify("Payroll History");
                    dispatch(setGlobalSearchValue(""));
                  }}
                  sx={{
                    background:
                      buttonNotify === "Payroll History"
                        ? themes.primaryButton
                        : "outlined",
                    textTransform: "capitalize",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      background:
                        buttonNotify === "Payroll History"
                          ? themes.primaryButton
                          : "outlined",
                    },
                    color: themes.headLine,
                  }}
                >
                  History
                </Mui.Button>
              </Mui.ButtonGroup>
            </Mui.Grid>
            <Mui.Grid
              xs={3}
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              <Mui.Typography>Company Name</Mui.Typography>
              <CustomAutoComplete
                dataList={companyList.map((obj) => ({
                  ...obj,
                  label: obj.companyname,
                }))}
                selectedValue={companyName}
                updateDataFunction={handleCompanyName}
                placeHolder="Please select the company name"
                fullWith
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      )}
      <Mui.Grid container sx={{ paddingTop: 1 }}></Mui.Grid>
      {reRenderComponent()}
    </React.Fragment>
  );
};
