import React, { useState, useEffect, useCallback } from "react";
import * as Mui from "@mui/material";
import { showToast } from "services/utils/Status";
import { useSelector, useDispatch } from "react-redux";
import {
  setEditUserId,
  setEditUserDetailsApi,
  setProfileEditBack,
  setBackToUserGroup,
} from "services/Redux/userManagament";
import { setGlobalSearchValue } from "services/Redux/userToken";
import {
  Cancel,
  Done,
  AddCircleTwoTone,
  RemoveRedEye,
  DeleteSharp,
} from "@material-ui/icons";
import { masterUserData, defaultAllSelection } from "services/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { BackArrow } from "components/CustomComponents/BackArrow";
import sessionHandling from "services/utils/notificationUtils";
import { useFetch } from "services/hooks/useFetch";
import { customGetAPI } from "services/utils/CustomAPI";
import {
  groupProductHeader,
  groupUserHeader,
} from "pages/Administration/AdministrationHeaders";
import {
  themes,
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
  tableCellFontSize,
} from "services/constants";

export const ViewUserGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyName, viewUserGroupDetails } = useSelector(
    (state) => state.userReducer
  );
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [userGroups, setUserGroupsUsers] = useState([]);
  const [userProductDetails, setUserProductGroupDetails] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [getProductAccess, setGetProductAccess] = useState(true);
  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState(null);
  const [roleName, setRoleName] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedUserCode, setSelectedUserCode] = useState("");
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [errorHandling, setErrorHandling] = useState("");
  const [userGroupProducts, setUserGroupProducts] = useState([]);
  const [removeActionId, setRemoveActionId] = useState(null);
  const [removeActionLabel, setRemoveActionLabel] = useState("");
  const [addNewUserButton, setAddNewUserButton] = useState(false);
  const [addNewProductButton, setAddNewProductButton] = useState(false);
  const { result: users, setLoad: reLoadUsers } = useFetch(
    `${domain}get-all-users/?group_id=${viewUserGroupDetails.id}`
  );
  const [open, setOpen] = useState(false);
  const [masterUsers, setmasterUsers] = useState([]);
  const [masterProduct, setmasterProduct] = useState([]);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(25);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(25);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const getAllProductDetails = useCallback(
    async (allproducts) => {
      const res = await customGetAPI(`${domain}company/`);
      if (res.ok) {
        const productDetailsData = res.responseDetails.map((obj) => ({
          ...obj,
          label: obj.product,
        }));
        let productsDetails = [];
        productDetailsData.forEach((element) => {
          let findProduct = allproducts.filter((data) => {
            return data.product === element.label;
          });
          if (findProduct.length === 0) {
            productsDetails = [...productsDetails, element];
          }
        });
        setUserGroupProducts(productsDetails);
      }
    },
    [domain]
  );

  useEffect(() => {
    const getProductDetails = async () => {
      const res = await customGetAPI(
        `${domain}user-group-products-details/?group_id=${viewUserGroupDetails.id}`
      );
      if (res?.ok) {
        setUserProductGroupDetails(res?.responseDetails?.products);
        setmasterProduct(res?.responseDetails?.products);
        setUserGroupsUsers(res?.responseDetails?.users);
        setmasterUsers(res?.responseDetails?.users);
        getAllProductDetails(res?.responseDetails?.products);
      }
    };
    if (getProductAccess) {
      getProductDetails();
      setGetProductAccess(false);
    }
  }, [
    domain,
    getAllProductDetails,
    getProductAccess,
    token,
    viewUserGroupDetails.id,
  ]);

  const addNewProduct = () => {
    let product = {
      product_id: defaultAllSelection,
      product: "",
      roles: [],
    };
    let update = [...userProductDetails, product];
    setUserProductGroupDetails(update);
  };

  const removeProduct = async (id) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}user-group-products-details/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          group_id: viewUserGroupDetails.id,
          product_id: id,
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Product removed successfully");
        setGetProductAccess(true);
        setOpen(false);
        setRemoveActionId(null);
        setRemoveActionLabel("");
        setErrorHandling("");
        setProductName("");
        setProductId(null);
        setRoleName("");
        setRoleId(null);
        setSelectedUserEmail("");
        setSelectedUserCode("");
        setSelectedUserId(0);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };
  const onSubmit = async () => {
    if (productName !== "" && roleName !== "") {
      setButtonLoader(true);
      try {
        const response = await fetch(`${domain}user-group-products-details/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            group_id: viewUserGroupDetails.id,
            product_id: productId,
            role_id: roleId,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", "User group created successfully");
          setGetProductAccess(true);
          setProductId(0);
          setProductName("");
          setRoleId(0);
          setRoleName("");
          setErrorHandling("");
          setAddNewProductButton(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonLoader(false);
      }
    } else {
      setErrorHandling(
        productName === ""
          ? "Please select the product"
          : "Please select the Role"
      );
    }
  };

  const removeAddProduct = (id) => {
    const filterData = userProductDetails.filter((data) => {
      return data.product_id !== id;
    });
    setUserProductGroupDetails(filterData);
    setProductId(0);
    setProductName("");
    setRoleId(0);
    setRoleName("");
    setErrorHandling("");
    setAddNewProductButton(false);
  };

  const addNewUser = () => {
    let newUser = {
      user_id: defaultAllSelection,
      name_name: "",
      user_code: "",
      email: "",
    };
    let update = [...userGroups, newUser];
    setUserGroupsUsers(update);
  };

  const addNewGroupUser = async () => {
    if (selectedUserEmail !== "") {
      setButtonLoader(true);
      try {
        const response = await fetch(`${domain}assign-users-usergroup/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            group_id: viewUserGroupDetails.id,
            selected_members: [
              {
                user_id: selectedUserId,
              },
            ],
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", "User group created successfully");
          setGetProductAccess(true);
          setErrorHandling("");
          setSelectedUserEmail("");
          setSelectedUserCode("");
          setSelectedUserId(0);
          setAddNewUserButton(false);
          reLoadUsers(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonLoader(false);
      }
    } else {
      setErrorHandling("Please select user");
    }
  };

  const removeNewUserDetails = () => {
    const filterData = userGroups.filter((data) => {
      return data.user_id !== defaultAllSelection;
    });
    setUserGroupsUsers(filterData);
    setErrorHandling("");
    setSelectedUserId(0);
    setSelectedUserEmail("");
    setSelectedUserCode("");
    setAddNewUserButton(false);
  };

  const removeUser = async (userid) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}user-group-products-details/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          group_id: viewUserGroupDetails.id,
          user_id: userid,
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "User removed successfully");
        setGetProductAccess(true);
        setAllRoles([]);
        setOpen(false);
        setErrorHandling("");
        setRemoveActionId(null);
        setRemoveActionLabel("");
        reLoadUsers(true);
        setSelectedUserEmail("");
        setSelectedUserCode("");
        setSelectedUserId(0);
        setProductName("");
        setProductId(null);
        setRoleName("");
        setRoleId(null);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const closePopup = () => {
    setOpen(false);
  };

  useEffect(() => {
    const searchTerm = globalSearchValue.toLowerCase();
    const filteredResults = masterProduct.filter((item) => {
      const productMatches = item.product.toLowerCase().includes(searchTerm);
      const rolesMatch = item.roles.some((role) =>
        role.role_name.toLowerCase().includes(searchTerm)
      );
      return productMatches || rolesMatch;
    });
    setUserProductGroupDetails(filteredResults);
  }, [globalSearchValue, masterProduct]);

  useEffect(() => {
    const filteredResults = masterUsers.filter((item) =>
      masterUserData.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setUserGroupsUsers(filteredResults);
  }, [globalSearchValue, masterUsers]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <BackArrow
          actionFunction={() => {
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/view/company/groups");
          }}
        />
        <Mui.Typography sx={{ fontSize: 22, paddingLeft: 1, paddingTop: 1 }}>
          {companyName}
        </Mui.Typography>
      </Mui.Grid>
      <Mui.Grid container sx={{ justifyContent: "center" }}>
        <Mui.Typography
          sx={{
            padding: 1.2,
            fontSize: 23,
            border: "1px solid black",
            borderRadius: 2,
            fontWeight: "bold",
          }}
        >
          Group Name : {viewUserGroupDetails.name}
        </Mui.Typography>
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
          Group product access
        </Mui.Typography>
        {userProductDetails?.length !== 0 ? (
          <Mui.TableContainer component={Mui.Paper} sx={tableContainerSx}>
            <Mui.Table>
              <Mui.TableHead sx={tableHeaderSx}>
                <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                  {groupProductHeader?.map?.((value) => (
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {value?.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {userProductDetails?.map((data, index) =>
                  data.product_id !== defaultAllSelection ? (
                    <Mui.TableRow
                      key={data.id}
                      maxWidth="xl"
                      align="left"
                      sx={tableRowSx}
                    >
                      <>
                        <Mui.TableCell></Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {data?.product ? data?.product : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          <Mui.Grid container spacing={1}>
                            {data?.roles
                              ? data?.roles.map((data, index) => (
                                  <Mui.Typography
                                    sx={{
                                      paddingLeft: 1,
                                      fontSize: tableCellFontSize,
                                    }}
                                  >
                                    {data?.role_name}
                                  </Mui.Typography>
                                ))
                              : "N/A"}
                          </Mui.Grid>
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          <Mui.IconButton
                            onClick={() => {
                              setOpen(true);
                              setRemoveActionId(data?.product_id);
                              setRemoveActionLabel("RemoveProduct");
                              setAddNewUserButton(false);
                              setAddNewProductButton(false);
                            }}
                          >
                            <DeleteSharp
                              size="large"
                              sx={{
                                color: themes.redColor,
                                strokeWidth: 2,
                              }}
                            />
                          </Mui.IconButton>
                        </Mui.TableCell>
                      </>
                    </Mui.TableRow>
                  ) : (
                    <Mui.TableRow
                      key={data?.id}
                      maxWidth="xl"
                      align="left"
                      sx={tableRowSx}
                    >
                      <>
                        <Mui.TableCell></Mui.TableCell>
                        <Mui.TableCell align="left">
                          <Mui.Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            value={productName}
                            options={userGroupProducts}
                            sx={{ width: "100%" }}
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderOption={(props, option) => {
                              return <li {...props}>{option.label}</li>;
                            }}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                              },
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                setProductName(value.label);
                                setProductId(value.product_id);
                                setRoleName("");
                                setRoleId(null);
                                const productRoles = value.roles.map((obj) => ({
                                  ...obj,
                                  label: obj.role_name,
                                }));
                                setAllRoles(productRoles);
                                if (
                                  errorHandling === "Please select the product"
                                ) {
                                  setErrorHandling("");
                                }
                              } else {
                                setProductName("");
                                setProductId(null);
                                setRoleName("");
                                setRoleId(null);
                                setAllRoles([]);
                              }
                            }}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                sx={{ background: themes.whiteColor }}
                                placeholder="Select the product name"
                                size="small"
                                value={data.productName}
                                error={
                                  errorHandling === "Please select the product"
                                    ? true
                                    : false
                                }
                                helperText={
                                  errorHandling === "Please select the product"
                                    ? errorHandling
                                    : null
                                }
                              />
                            )}
                          />
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          <Mui.Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            value={roleName}
                            options={allRoles}
                            sx={{ width: "100%" }}
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderOption={(props, option) => {
                              return <li {...props}>{option.label}</li>;
                            }}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                              },
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                setRoleName(value.role_name);
                                setRoleId(value.role_id);
                                if (
                                  errorHandling === "Please select the Role"
                                ) {
                                  setErrorHandling("");
                                }
                              } else {
                                setRoleName("");
                                setRoleId(null);
                              }
                            }}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                sx={{ background: themes.whiteColor }}
                                placeholder="Select the Role"
                                size="small"
                                value={data.roleName}
                                error={
                                  errorHandling === "Please select the Role"
                                    ? true
                                    : false
                                }
                                helperText={
                                  errorHandling === "Please select the Role"
                                    ? errorHandling
                                    : null
                                }
                              />
                            )}
                          />
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          <React.Fragment>
                            <Mui.IconButton>
                              {buttonLoader ? (
                                <Mui.CircularProgress
                                  sx={{
                                    color: themes.primaryIndicator,
                                  }}
                                  size={24}
                                />
                              ) : (
                                <Done
                                  sx={{
                                    color: themes.primaryIndicator,
                                    strokeWidth: 2,
                                  }}
                                  onClick={() => onSubmit()}
                                />
                              )}
                            </Mui.IconButton>
                            <Mui.IconButton
                              onClick={() => removeAddProduct(data?.product_id)}
                            >
                              <Cancel
                                sx={{
                                  color: themes.redColor,
                                  strokeWidth: 2,
                                }}
                              />
                            </Mui.IconButton>
                          </React.Fragment>
                        </Mui.TableCell>
                      </>
                    </Mui.TableRow>
                  )
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        ) : (
          <Mui.Container maxWidth="xl" sx={{ marginTop: 2 }}>
            <Mui.Alert severity="info">No data available</Mui.Alert>
          </Mui.Container>
        )}
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {userProductDetails?.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={userProductDetails?.length}
              rowsPerPage={rowsPerPage1}
              page={page1}
              onPageChange={handleChangePage1}
              onRowsPerPageChange={handleChangeRowsPerPage1}
            />
          )}
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          paddingBottom: 2,
        }}
      >
        {addNewProductButton ||
        addNewUserButton ? null : userGroupProducts.length === 0 ? null : (
          <Mui.IconButton
            onClick={() => {
              setErrorHandling("");
              setAddNewProductButton(true);
              addNewProduct();
              setAddNewUserButton(false);
            }}
          >
            <AddCircleTwoTone size="large" sx={{ color: themes.blackColor }} />
          </Mui.IconButton>
        )}
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
          User list
        </Mui.Typography>
        {userGroups?.length !== 0 ? (
          <Mui.TableContainer component={Mui.Paper} sx={tableContainerSx}>
            <Mui.Table>
              <Mui.TableHead sx={tableHeaderSx}>
                <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                  {groupUserHeader?.map?.((value) => (
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {value?.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {userGroups?.map((data, index) => (
                  <>
                    {data.user_id !== defaultAllSelection ? (
                      <Mui.TableRow
                        key={data?.id}
                        maxWidth="xl"
                        align="left"
                        sx={tableRowSx}
                      >
                        <>
                          <Mui.TableCell></Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            sx={{
                              fontSize: tableCellFontSize,
                            }}
                          >
                            {data?.user_name ? data?.user_name : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            sx={{
                              fontSize: tableCellFontSize,
                            }}
                          >
                            {data?.user_code ? data?.user_code : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            sx={{
                              fontSize: tableCellFontSize,
                            }}
                          >
                            {data?.email ? data?.email : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            sx={{
                              fontSize: tableCellFontSize,
                            }}
                          >
                            <Mui.IconButton
                              onClick={() => {
                                dispatch(setEditUserId(data?.user_id));
                                dispatch(setEditUserDetailsApi(true));
                                dispatch(setProfileEditBack(true));
                                dispatch(setBackToUserGroup(true));
                                dispatch(setGlobalSearchValue(""));
                                history.push("/admin/edit/profile/");
                                setAddNewUserButton(false);
                                setAddNewProductButton(false);
                              }}
                            >
                              <RemoveRedEye
                                size="large"
                                sx={{
                                  color: themes.primaryIndicator,
                                  strokeWidth: 2,
                                }}
                              />
                            </Mui.IconButton>
                            <Mui.IconButton
                              onClick={() => {
                                setOpen(true);
                                setRemoveActionId(data?.user_id);
                                setRemoveActionLabel("RemoveUsers");
                                setAddNewUserButton(false);
                                setAddNewProductButton(false);
                              }}
                            >
                              <DeleteSharp
                                size="large"
                                sx={{
                                  color: themes.redColor,
                                  strokeWidth: 2,
                                }}
                              />
                            </Mui.IconButton>
                          </Mui.TableCell>
                        </>
                      </Mui.TableRow>
                    ) : (
                      <Mui.TableRow
                        key={data.id}
                        maxWidth="xl"
                        align="left"
                        sx={tableRowSx}
                      >
                        <>
                          <Mui.TableCell></Mui.TableCell>
                          <Mui.TableCell align="left">
                            <Mui.Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              options={users.map((obj) => ({
                                ...obj,
                                label: obj.user_name,
                              }))}
                              sx={{ width: "100%" }}
                              filterOptions={(options, state) => {
                                return options.filter((option) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                );
                              }}
                              renderOption={(props, option) => {
                                return <li {...props}>{option.label}</li>;
                              }}
                              ListboxProps={{
                                style: {
                                  maxHeight: "150px",
                                },
                              }}
                              onChange={(event, value) => {
                                if (value) {
                                  setSelectedUserId(value?.user_id);
                                  setSelectedUserEmail(value?.email);
                                  setSelectedUserCode(value?.user_code);
                                  if (errorHandling === "Please select user") {
                                    setErrorHandling("");
                                  }
                                }
                              }}
                              renderInput={(params) => (
                                <Mui.TextField
                                  {...params}
                                  sx={{ background: themes.whiteColor }}
                                  placeholder="Select the user"
                                  size="small"
                                  error={
                                    errorHandling === "Please select user"
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errorHandling === "Please select user"
                                      ? errorHandling
                                      : null
                                  }
                                />
                              )}
                            />
                          </Mui.TableCell>
                          <Mui.TableCell align="left">
                            <Mui.TextField
                              value={selectedUserCode}
                              size="small"
                              error={
                                errorHandling === "Please select user"
                                  ? true
                                  : false
                              }
                              helperText={
                                errorHandling === "Please select user"
                                  ? "Please select the user code"
                                  : null
                              }
                              disabled={true}
                            />
                          </Mui.TableCell>
                          <Mui.TableCell align="left">
                            <Mui.TextField
                              value={selectedUserEmail}
                              size="small"
                              disabled={true}
                              error={
                                errorHandling === "Please select user"
                                  ? true
                                  : false
                              }
                              helperText={
                                errorHandling === "Please select user"
                                  ? "Please enter email address"
                                  : null
                              }
                            />
                          </Mui.TableCell>
                          <Mui.TableCell align="left">
                            <React.Fragment>
                              <Mui.IconButton>
                                {buttonLoader ? (
                                  <Mui.CircularProgress
                                    sx={{
                                      color: themes.primaryIndicator,
                                    }}
                                    size={24}
                                  />
                                ) : (
                                  <Done
                                    sx={{
                                      color: themes.primaryIndicator,
                                      strokeWidth: 2,
                                    }}
                                    onClick={() => addNewGroupUser()}
                                  />
                                )}
                              </Mui.IconButton>
                              <Mui.IconButton
                                onClick={() => removeNewUserDetails()}
                              >
                                <Cancel
                                  sx={{
                                    color: themes.redColor,
                                    strokeWidth: 2,
                                  }}
                                />
                              </Mui.IconButton>
                            </React.Fragment>
                          </Mui.TableCell>
                        </>
                      </Mui.TableRow>
                    )}
                  </>
                ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        ) : (
          <Mui.Container maxWidth="xl" sx={{ paddingTop: 5 }}>
            <Mui.Alert severity="info">No data available</Mui.Alert>
          </Mui.Container>
        )}
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {userGroups?.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={userGroups?.length}
              rowsPerPage={rowsPerPage2}
              page={page2}
              onPageChange={handleChangePage2}
              onRowsPerPageChange={handleChangeRowsPerPage2}
            />
          )}
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: 1,
            paddingBottom: 2,
          }}
        >
          {addNewProductButton ||
          addNewUserButton ? null : userProductDetails?.length !== 0 ? (
            <Mui.IconButton
              onClick={() => {
                setAddNewUserButton(true);
                addNewUser();
                setAddNewProductButton(false);
              }}
            >
              <AddCircleTwoTone
                size="large"
                sx={{ color: themes.blackColor }}
              />
            </Mui.IconButton>
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
      <GlobalDialogBox
        open={open}
        handleCloseDialog={closePopup}
        title={
          removeActionLabel === "RemoveUsers"
            ? "Remove group user"
            : "Remove group product"
        }
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ fontSize: 22 }}>
            Do you want to remove the{" "}
            {removeActionLabel === "RemoveUsers" ? "user" : "product"}?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 2, justifyContent: "flex-end" }}>
          <CustomButton
            actionFuntion={() => closePopup()}
            actionName="No"
            typeName="submit"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => {
              if (removeActionLabel === "RemoveUsers") {
                removeUser(removeActionId);
              } else {
                removeProduct(removeActionId);
              }
            }}
            actionName="Yes"
            typeName="submit"
            disableAction={open && buttonLoader ? buttonLoader : false}
          />
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
