import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { PayRunTable } from "./PayRunTable";
import { ViewPayRun } from "./ViewPayRun";
import { Loader } from "components/Loader";
const keys = [
  "pay_schedule_name",
  "run_date",
  "start_date",
  "end_date",
  "status",
];
export const ApprovalPayroll = ({
  companyName,
  companyId,
  setViewPayRunScreen,
  viewPayrunScreen,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [viewPayrunDetails, setViewPayRunDetails] = useState([]);
  const [ApprovalDetailsState, setApprovalDetailsState] = useState([]);
  const [listViewPage, setListViewPage] = useState(0);
  const [updateData, setUpdateData] = useState(true);
  const {
    result: payrunDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0
      ? `${domain}payrun/approval-waiting/?company=${companyId}`
      : null
  );

  useEffect(() => {
    if (updateData && payrunDetails?.Approvals?.length !== 0 && payrunDetails) {
      setApprovalDetailsState(payrunDetails?.Approvals);
      setUpdateData(false);
    }
  }, [payrunDetails, updateData]);

  useEffect(() => {
    if (payrunDetails?.length !== 0 || payrunDetails?.length === 0) {
      const filteredResults = payrunDetails?.Approvals?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setApprovalDetailsState(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payrunDetails]);

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      {viewPayrunScreen ? (
        <ViewPayRun
          setViewPayRunScreen={setViewPayRunScreen}
          companyName={companyName}
          companyId={companyId}
          viewPayrunDetails={viewPayrunDetails}
          setViewPayRunDetails={setViewPayRunDetails}
          reCallApi={reCallApi}
        />
      ) : (
        <PayRunTable
          listViewPage={listViewPage}
          setListViewPage={setListViewPage}
          payrunDetails={ApprovalDetailsState}
          setViewPayRunScreen={setViewPayRunScreen}
          viewPayrunScreen={viewPayrunScreen}
          setViewPayRunDetails={setViewPayRunDetails}
        />
      )}
    </React.Fragment>
  );
};
