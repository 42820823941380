import React, { useState } from "react";
import * as Mui from "@mui/material";
import { EPFReport } from "./EPFReport";
import { themes } from "services/constants";
import { payrollReports } from "services/constants";
import { ESIReport } from "./ESIReport";
import { SalaryStatementReport } from "./SalaryStatementReport";
export const PayrollReports = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listViewPage, setListViewPage] = useState(0);
  const [reportName, setReportName] = useState("Summarized working hours");
  const [showReports, setShowReport] = useState(false);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  const renderComponent = () => {
    switch (reportName) {
      case "Salary Statement Report":
        return (
          <SalaryStatementReport
            setShowReport={setShowReport}
            reportName={reportName}
          />
        );
      case "ESI Report":
        return (
          <ESIReport setShowReport={setShowReport} reportName={reportName} />
        );
      case "EPF Report":
        return (
          <EPFReport setShowReport={setShowReport} reportName={reportName} />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {!showReports ? (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 8, width: "100%", maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Report Name
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {payrollReports
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((name) => (
                  <Mui.TableRow
                    onClick={() => {
                      setShowReport(true);
                      setReportName(name.label);
                    }}
                  >
                    <Mui.TableCell sx={{ color: themes.blackColor }}>
                      <Mui.Typography>{name.label}</Mui.Typography>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      ) : (
        renderComponent()
      )}
      {payrollReports.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          count={payrollReports.length}
          rowsPerPage={rowsPerPage}
          page={listViewPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
