import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
export const ReviseSalary = ({
  setOpenSidePeekRevise,
  openSidePeekRevise,
  editLoanDetails,
}) => {
  const [doAnyAct, setDoAnyAct] = useState(false);
  const [skipDate, setSkipDate] = useState(null);
  const [skipMonthCount, setSkipMonthCount] = useState(0);
  const [errorHandling, setErrorHandling] = useState("");
  const [showCalculatedValue, setShowCalculatedValue] = useState(false);
  // const [inCalculateSkip, setInCalculateSkip] = useState(false);
  // const [durationLoIncreased, setDurationLoIncreased] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Mui.Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSidePeekRevise(open);
    if (open === false) {
      if (doAnyAct) {
        setDoAnyAct(false);
      }
    }
  };

  const calculateLoan = () => {
    // if (
    //   skipMonthCount &&
    //   skipMonthCount !== "0" &&
    //   skipMonthCount !== "" &&
    //   skipDate
    // ) {
    setErrorHandling("");
    setShowCalculatedValue(true);
    // }
  };

  const updateLoanDetails = () => {};

  return (
    <React.Fragment>
      <Mui.Drawer
        anchor="right"
        open={openSidePeekRevise}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          sx: {
            paddingTop: 1,
            width: {
              xs: 300,
              sm: 360,
              md: 500,
              lg: 700,
            },
          },
        }}
      >
        <Mui.Grid
          container
          sx={{
            paddingLeft: 1,
            paddingBottom: 1,
          }}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.formBackground,
              textAlign: "center",
              borderRadius: 5,
            }}
          >
            <Mui.Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                textAlign: "center",
                width: "100%",
              }}
            >
              Revise Loan repayment
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={8}>
              <Mui.Typography
                sx={{
                  paddingTop: 1,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Revise From
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={4}>
              <Mui.TextField
                fullWidth
                size="small"
                type="date"
                id="from_date"
                inputProps={{
                  min: editLoanDetails?.repayment_start_date
                    ?.split("-")
                    .reverse()
                    .join("-"),
                  max: editLoanDetails?.repayment_end_date
                    ?.split("-")
                    .reverse()
                    .join("-"),
                }}
                value={skipDate}
                onChange={(event) => {
                  setSkipDate(event.target.value);
                }}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={8}>
              <Mui.Typography
                sx={{
                  paddingTop: 1,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Total balance on
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={4}>20000</Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={8}>
              <Mui.Typography
                sx={{
                  paddingTop: 1,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Current installment amount
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={4}>20000</Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={8}>
              {" "}
              <Mui.Typography
                sx={{
                  paddingTop: 1,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Number of installment remaining
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={4}>
              <Mui.Typography>5 Months</Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={8}>
              <Mui.Typography
                sx={{
                  paddingTop: 1,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Current interest rate
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={4}>12 %</Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={8}>
              {" "}
              <Mui.Typography
                sx={{
                  paddingTop: 1,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Number of installment proposed for remaining balance
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={4}>
              <Mui.Grid container>
                <Mui.Grid xs={8}>
                  <Mui.TextField
                    value={skipMonthCount}
                    size="small"
                    onChange={(event) => {
                      setSkipMonthCount(event.target.value);
                    }}
                    error={
                      errorHandling === "Please enter skip month count"
                        ? true
                        : false
                    }
                    helperText={
                      errorHandling === "Please enter skip month count"
                        ? errorHandling
                        : null
                    }
                  />
                </Mui.Grid>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ paddingLeft: 1, paddingTop: 1 }}>
                    Months
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={8}>
                <Mui.Typography
                  sx={{
                    paddingTop: 1,
                    fontWeight: "bold",
                    fontSize: 15,
                  }}
                >
                  Interest rate proposed for remaining balance
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.TextField
                  value={skipMonthCount}
                  size="small"
                  onChange={(event) => {
                    setSkipMonthCount(event.target.value);
                  }}
                  error={
                    errorHandling === "Please enter skip month count"
                      ? true
                      : false
                  }
                  helperText={
                    errorHandling === "Please enter skip month count"
                      ? errorHandling
                      : null
                  }
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={8}></Mui.Grid>
            <Mui.Grid xs={4}>
              <CustomButton
                actionFuntion={() => {
                  calculateLoan();
                }}
                actionName="ReCalculate"
                typeName="Button"
              />
            </Mui.Grid>
          </Mui.Grid>
          {showCalculatedValue ? (
            <>
              <Mui.Grid
                container
                component={Mui.Paper}
                elevation={2}
                sx={{ padding: 1 }}
              >
                <Mui.Grid container sx={{ padding: 0.5 }}>
                  <Mui.Grid xs={8}>
                    {" "}
                    <Mui.Typography
                      sx={{
                        paddingTop: 1,
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                    >
                      Revise installment amount
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={4}>2000</Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ padding: 0.5 }}>
                  <Mui.Grid xs={8}>
                    {" "}
                    <Mui.Typography
                      sx={{
                        paddingTop: 1,
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                    >
                      Remaining tenure
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={4}>5 months</Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ padding: 0.5 }}>
                  <Mui.Grid xs={8}>
                    {" "}
                    <Mui.Typography
                      sx={{
                        paddingTop: 1,
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                    >
                      Interest rate
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={4}>2%</Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  padding: 2,
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    setOpenSidePeekRevise(false);
                  }}
                  actionName="Cancel"
                  typeName="button"
                />{" "}
                &nbsp;
                <CustomButton
                  actionFuntion={() => {
                    updateLoanDetails();
                  }}
                  actionName="Save"
                  typeName="button"
                />
                &nbsp;
              </Mui.Grid>
            </>
          ) : null}
        </Mui.Grid>
      </Mui.Drawer>
    </React.Fragment>
  );
};
