import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import { incentiveMode } from "pages/PayRoll/PayRollHeaders";
import { customAPI } from "services/utils/CustomAPI";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
  specialCharacterRestrict,
  onlyNumberRegex,
  rgxWithDot,
} from "services/constants/patterns";
import { showToast } from "services/utils/Status";
const cycleDetails = [{ label: "Weekly" }, { label: "Monthly" }];
const incentiveTypeLabel = [{ label: "Attendance" }, { label: "OT" }];

export const EditIncentive = ({
  incentiveDetails,
  companyId,
  setEditIncentive,
  reCallApi,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [ceriteriaType, setCeriteriaType] = useState(
    incentiveDetails?.criteria_type ? incentiveDetails?.criteria_type : "days"
  );
  const {
    register,
    reset,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm();
  const [attendanceCriteriaCount, setAttendanceCriteriaCount] = useState(
    incentiveDetails?.criteria_type === "days" &&
      incentiveDetails?.incentive_type === "Attendance"
      ? incentiveDetails?.criteria_value
      : 0
  );
  const [incentiveNameDetails, setIncentiveNameDetails] = useState(
    incentiveDetails?.name ? incentiveDetails?.name : ""
  );
  const [cycleName, setCycleName] = useState(
    incentiveDetails?.cycle ? incentiveDetails?.cycle : ""
  );
  const [selectType, setSelectType] = useState(
    incentiveDetails?.select_type ? incentiveDetails?.select_type : "day"
  );
  const [incentiveModeState, setIncentiveModeState] = useState(
    incentiveDetails?.incentive_mode ? incentiveDetails?.incentive_mode : ""
  );
  const [incentiveType, setIncentiveType] = useState(
    incentiveDetails.incentive_type ? incentiveDetails.incentive_type : ""
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [criteriaOverTime, setCriteriaOverTime] = useState(
    incentiveDetails?.incentive_type === "OT"
      ? incentiveDetails?.criteria_value
      : 0
  );
  const [criteriaPercentage, setCriteriaPercentage] = useState(
    incentiveDetails?.criteria_type === "percentage" &&
      incentiveDetails?.incentive_type === "Attendance"
      ? incentiveDetails?.criteria_value
      : 0
  );
  const [payAmount, setPayAmount] = useState(
    incentiveDetails?.amount ? incentiveDetails?.amount : 0
  );
  const [date, setDate] = useState(
    incentiveDetails?.effective_from
      ? incentiveDetails?.effective_from.split("-").reverse().join("-")
      : ""
  );
  useEffect(() => {
    setValue(
      "effective_from",
      incentiveDetails?.effective_from
        ? incentiveDetails?.effective_from.split("-").reverse().join("-")
        : ""
    );
    setValue("amount", incentiveDetails?.amount ? incentiveDetails?.amount : 0);
    setValue(
      "incentive_mode",
      incentiveDetails?.incentive_mode ? incentiveDetails?.incentive_mode : ""
    );
    setValue(
      "criteria_value",
      incentiveDetails?.criteria_value ? incentiveDetails?.criteria_value : 0
    );

    setValue(
      "criteria_attendance_count",
      incentiveDetails?.criteria_type === "days" &&
        incentiveDetails?.incentive_type === "Attendance"
        ? incentiveDetails?.criteria_value
        : 0
    );
    setValue(
      "criteria_percentage",
      incentiveDetails?.criteria_type === "percentage" &&
        incentiveDetails?.incentive_type === "Attendance"
        ? incentiveDetails?.criteria_value
        : 0
    );
    setValue(
      "incentive_type",
      incentiveDetails.incentive_type ? incentiveDetails.incentive_type : ""
    );
    setValue("cycle", incentiveDetails?.cycle ? incentiveDetails?.cycle : "");
    setValue("name", incentiveDetails?.name ? incentiveDetails?.name : "");
  }, [incentiveDetails, setValue]);

  const onSubmit = async (data) => {
    if (data) {
      setButtonDisabled(true);
      let payload = {
        ...data,
        criteria_value:
          incentiveType === "OT"
            ? data.criteria_value
            : ceriteriaType === "days"
            ? data.criteria_attendance_count
            : parseFloat(data.criteria_percentage).toFixed(2),
        criteria_type: ceriteriaType,
        select_type: selectType,
      };
      const response = await customAPI(
        `${domain}incentives/${incentiveDetails.id}/`,
        "PUT",
        payload
      );
      if (response.ok) {
        showToast("success", "Incentive updated successfully");
        setEditIncentive(false);
        reCallApi(true);
        reset();
      } else {
        setButtonDisabled(false);
      }
    }
  };

  const updateIncentiveName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 64
    ) {
      setValue("name", event.target.value);
      setIncentiveNameDetails(event.target.value);
    }
  };

  const handleCycleName = (event, data) => {
    if (data) {
      setCycleName(data.label);
      setValue("cycle", data.label);
    }
  };

  const handleIncentiveType = (event, data) => {
    if (data) {
      setIncentiveType(data.label);
      setValue("incentive_type", data.label);
    }
  };

  const handleIncentiveMode = (event, data) => {
    if (data) {
      setValue("incentive_mode", data.label);
      setIncentiveModeState(data.label);
    }
  };
  const selectTypeChanges = (event) => {
    setSelectType(event.target.value);
  };

  const selectCriteriaType = (event) => {
    setCeriteriaType(event.target.value);
    setValue("criteria_percentage", 0);
    setValue("criteria_attendance_count", 0);
    setAttendanceCriteriaCount(0);
    setCriteriaPercentage(0);
  };

  const updateCriteriaOverTime = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length < 4
    ) {
      setCriteriaOverTime(event.target.value);
      setValue("criteria_value", event.target.value);
    }
  };

  const updateAttendanceCriteriaCount = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 5
    ) {
      setAttendanceCriteriaCount(event.target.value);
      setValue("criteria_attendance_count", event.target.value);
    }
  };

  const updateCriteriaPercentage = (event) => {
    if (
      rgxWithDot.test(event.target.value) &&
      parseInt(event.target.value) <= 100
    ) {
      setCriteriaPercentage(event.target.value);
      setValue("criteria_percentage", event.target.value);
    }
  };

  const updateAmount = (event) => {
    if (rgxWithDot.test(event.target.value)) {
      setPayAmount(event.target.value);
      setValue("amount", event.target.value);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
        <Mui.Grid
          container
          sx={{
            paddingTop: "5%",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 24,
                fontWeight: 800,
                font: "bold",
                textAlign: "center",
              }}
            >
              Edit Incentive
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              paddingTop: -1,
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
              borderRadius: "0 0 10px 10px",
            }}
          >
            <Mui.Grid container>
              <Mui.Grid container sx={{ padding: 2 }}>
                <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Name
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <CustomTextBox
                      selectedValue={incentiveNameDetails}
                      rules={{
                        ...register("name", {
                          required: "Incentive name is required",
                          validate: {
                            atleastOneCharacterWithSpaceReg: (value) => {
                              if (
                                atleastOneCharacterWithSpace.test(value) ||
                                value === ""
                              ) {
                                return true;
                              } else {
                                return "Atleast one alphabet required";
                              }
                            },
                          },
                        }),
                      }}
                      updateDataFunction={updateIncentiveName}
                      placeHolder="Enter Incentive Name"
                      errorMessage={
                        errors?.name?.message ? errors?.name?.message : ""
                      }
                      textBoxError={errors.name ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Cycle
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <CustomAutoComplete
                      dataList={cycleDetails}
                      selectedValue={cycleName}
                      rules={{
                        ...register("cycle", {
                          required: "Cycle is required",
                        }),
                      }}
                      updateDataFunction={handleCycleName}
                      placeHolder="Please select the incentive cycle"
                      errorMessage={
                        errors?.cycle?.message ? errors?.cycle?.message : ""
                      }
                      textBoxError={errors.cycle ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Incentive Type
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <CustomAutoComplete
                      dataList={incentiveTypeLabel}
                      selectedValue={incentiveType}
                      rules={{
                        ...register("incentive_type", {
                          required: "Incentive type is required",
                        }),
                      }}
                      updateDataFunction={handleIncentiveType}
                      placeHolder="Please select the incentive type"
                      errorMessage={
                        errors?.incentive_type?.message
                          ? errors?.incentive_type?.message
                          : ""
                      }
                      textBoxError={errors.incentive_type ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                {incentiveType === "" || incentiveType === "Attendance" ? (
                  <>
                    <Mui.Grid container sx={{ paddingTop: 1 }}>
                      <Mui.Grid xs={4}>
                        <Mui.Typography
                          sx={{
                            fontSize: 20,
                            color: themes.headLine,
                            paddingBottom: 0.5,
                            fontWeight: "bold",
                          }}
                        >
                          Criteria Type
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={8}>
                        <RadioGroup
                          row
                          onChange={selectCriteriaType}
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={ceriteriaType}
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="days"
                            control={<Radio />}
                            label="Day"
                          />
                          <FormControlLabel
                            value="percentage"
                            control={<Radio />}
                            label="Percentage"
                          />
                        </RadioGroup>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                      {ceriteriaType === "percentage" ? (
                        <>
                          <Mui.Grid xs={4}>
                            <Mui.Typography
                              sx={{
                                fontSize: 20,
                                color: themes.headLine,
                                paddingBottom: 0.5,
                                fontWeight: "bold",
                              }}
                            >
                              Criteria Percentage
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid xs={8}>
                            <CustomTextBox
                              selectedValue={criteriaPercentage}
                              rules={{
                                ...register("criteria_percentage", {
                                  required:
                                    ceriteriaType === "percentage" &&
                                    incentiveType === "Attendance"
                                      ? "Criteria value is required"
                                      : false,
                                }),
                              }}
                              updateDataFunction={updateCriteriaPercentage}
                              placeHolder="Enter criteria percentage"
                              errorMessage={
                                errors?.criteria_percentage?.message
                                  ? errors?.criteria_percentage?.message
                                  : ""
                              }
                              textBoxError={
                                errors.criteria_percentage ? true : false
                              }
                            />
                          </Mui.Grid>
                        </>
                      ) : (
                        <>
                          <Mui.Grid xs={4}>
                            <Mui.Typography
                              sx={{
                                fontSize: 20,
                                color: themes.headLine,
                                paddingBottom: 0.5,
                                fontWeight: "bold",
                              }}
                            >
                              Criteria Attendance
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid xs={8}>
                            <CustomTextBox
                              selectedValue={attendanceCriteriaCount}
                              rules={{
                                ...register("criteria_attendance_count", {
                                  required:
                                    ceriteriaType === "days" &&
                                    incentiveType === "Attendance"
                                      ? "Criteria value is required"
                                      : false,
                                }),
                              }}
                              updateDataFunction={updateAttendanceCriteriaCount}
                              placeHolder="Enter criteria percentage"
                              errorMessage={
                                errors?.criteria_attendance_count?.message
                                  ? errors?.criteria_attendance_count?.message
                                  : ""
                              }
                              textBoxError={
                                errors.criteria_attendance_count ? true : false
                              }
                            />
                          </Mui.Grid>
                        </>
                      )}
                    </Mui.Grid>
                  </>
                ) : (
                  <Mui.Grid container sx={{ paddingTop: 1 }}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 20,
                          color: themes.headLine,
                          paddingBottom: 0.5,
                          fontWeight: "bold",
                        }}
                      >
                        Criteria Overtime
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={8}>
                      <CustomTextBox
                        selectedValue={criteriaOverTime}
                        rules={{
                          ...register("criteria_value", {
                            required:
                              incentiveType === "OT"
                                ? "Criteria value is required"
                                : false,
                          }),
                        }}
                        updateDataFunction={updateCriteriaOverTime}
                        placeHolder="Enter criteria overtime count"
                        errorMessage={
                          errors?.criteria_value?.message
                            ? errors?.criteria_value?.message
                            : ""
                        }
                        textBoxError={errors.criteria_value ? true : false}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                )}
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Incentive Mode
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <CustomAutoComplete
                      dataList={incentiveMode}
                      selectedValue={incentiveModeState}
                      rules={{
                        ...register("incentive_mode", {
                          required: "Incentive mode is required",
                        }),
                      }}
                      updateDataFunction={handleIncentiveMode}
                      placeHolder="Please select the incentive mode"
                      errorMessage={
                        errors?.incentive_mode?.message
                          ? errors?.incentive_mode?.message
                          : ""
                      }
                      textBoxError={errors.incentive_mode ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Amount
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <CustomTextBox
                      selectedValue={payAmount}
                      rules={{
                        ...register("amount", {
                          required: "Amount is required",
                        }),
                      }}
                      updateDataFunction={updateAmount}
                      placeHolder="Enter amount"
                      errorMessage={
                        errors?.amount?.message ? errors?.amount?.message : ""
                      }
                      textBoxError={errors.amount ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Select Type
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      onChange={selectTypeChanges}
                      defaultValue={selectType}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="day"
                        control={<Radio />}
                        label="Day"
                      />
                      <FormControlLabel
                        value="week"
                        control={<Radio />}
                        label="Week"
                      />
                      <FormControlLabel
                        value="month"
                        control={<Radio />}
                        label="Month"
                      />
                      <FormControlLabel
                        value="unit"
                        control={<Radio />}
                        label="Unit"
                      />
                    </RadioGroup>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  fullWidth
                  sx={{ paddingTop: 1, paddingBottom: 2 }}
                >
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Effective From
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.TextField
                      id="from-date"
                      type="date"
                      size="small"
                      value={date}
                      {...register("effective_from", {
                        required: "Date is required.",
                      })}
                      onChange={(event) => {
                        setDate(event.target.value);
                      }}
                      error={Boolean(errors.fromDate)}
                      helperText={errors.fromDate?.message || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      disableOpenPicker
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <br />
                <Mui.Grid
                  container
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    padding: 0.5,
                  }}
                >
                  <CustomButton
                    actionFuntion={() => {
                      setEditIncentive(false);
                    }}
                    actionName="Cancel"
                    typeName="button"
                  />
                  &nbsp;&nbsp;
                  <CustomButton
                    actionName="Update"
                    typeName="submit"
                    disableAction={buttonDisabled}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
