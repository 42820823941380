import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { themes } from "services/constants";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";
import {
  Settings,
  Pix,
  CardMembership,
  AccountBalance,
  GroupWork,
  AddCard,
  Cyclone,
} from "@mui/icons-material";
export const SettingsMenu = ({
  setCurrentScreen,
  currentScreen,
  setAddNewSalaryTemplate,
  setEditTemplate,
  setEditSalary,
  setViewOption,
  setEditBankDetails,
  viewOption,
  eyeIconOption,
}) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid
        sx={{
          marginTop: 2,
          height:
            currentScreen === "Salary Template" && (viewOption || eyeIconOption)
              ? "100%"
              : "92.5vh",
          overflow: "hidden",
          borderRadius: 2,
          backgroundColor: themes.primary,
        }}
        component={Paper}
        elevation={3}
      >
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "General" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "General"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => setCurrentScreen("General")}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <Settings sx={{ fontSize: 20 }} /> General
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Salary Component" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Salary Component"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              setCurrentScreen("Salary Component");
              dispatch(setGlobalSearchValue(""));
              setAddNewSalaryTemplate(false);
              setEditTemplate(false);
              setEditSalary(false);
              setViewOption(false);
              setEditBankDetails(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <Pix sx={{ fontSize: 20 }} /> Salary Component
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Payroll Group" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Payroll Group"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              setCurrentScreen("Payroll Group");
              dispatch(setGlobalSearchValue(""));
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <CardMembership sx={{ fontSize: 20 }} /> Payroll Group
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Payment Bank" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Payment Bank"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              setCurrentScreen("Payment Bank");
              dispatch(setGlobalSearchValue(""));
              setAddNewSalaryTemplate(false);
              setEditTemplate(false);
              setEditSalary(false);
              setViewOption(false);
              setEditBankDetails(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <AccountBalance sx={{ fontSize: 20 }} /> Payment Bank
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Salary Template" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Salary Template"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              setCurrentScreen("Salary Template");
              dispatch(setGlobalSearchValue(""));
              setAddNewSalaryTemplate(false);
              setEditTemplate(false);
              setEditSalary(false);
              setViewOption(false);
              setEditBankDetails(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <GroupWork sx={{ fontSize: 20 }} /> Salary Template
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Incentive" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Incentive"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              setCurrentScreen("Incentive");
              dispatch(setGlobalSearchValue(""));
              setViewOption(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <AddCard sx={{ fontSize: 20 }} /> Incentive
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Payrun Cycle" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Payrun Cycle"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              setCurrentScreen("Payrun Cycle");
              dispatch(setGlobalSearchValue(""));
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <Cyclone sx={{ fontSize: 20 }} /> Payrun Cycle
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
