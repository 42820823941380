import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { payCycle } from "pages/PayRoll/PayRollHeaders";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { customAPI } from "services/utils/CustomAPI";
import { days_of_week } from "pages/PayRoll/PayRollHeaders";
import { showToast } from "services/utils/Status";

export const EditPayRunCycle = ({
  setDisplayScreen,
  reCallApi,
  domain,
  token,
  actionRowData,
  updateData,
  setUpdateData,
}) => {
  const {
    setValue,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [daysOfMonth, setDaysOfMonth] = useState("1");
  const [payCycleName, setPayCycleName] = useState("");
  const [payCycleType, setPayCycleType] = useState("");
  const [daysOfWeekName, setDaysOfWeekName] = useState("");
  const [paymentCalculation, setPaymentCalculation] = useState(
    actionRowData?.payment_basis
  );

  useEffect(() => {
    const updatePayRunDetails = () => {
      setPayCycleName(actionRowData?.name);
      setPayCycleType(actionRowData?.pay_run_cycle_type);
      setDaysOfWeekName(
        actionRowData?.day_of_week ? actionRowData?.day_of_week : "Monday"
      );
      setValue("payrun_cycle_name", actionRowData?.name);
      setValue(
        "days_of_week_name",
        actionRowData?.day_of_week ? actionRowData?.day_of_week : "Monday"
      );
      setValue(
        "date_of_month",
        actionRowData?.date_of_month ? actionRowData?.date_of_month : "1"
      );
      setDaysOfMonth(
        actionRowData?.date_of_month ? actionRowData?.date_of_month : "1"
      );
      setValue("pay_cycle", actionRowData?.pay_run_cycle_type);
      setUpdateData(false);
      setPaymentCalculation(actionRowData?.payment_basis);
    };
    if (updateData) {
      updatePayRunDetails();
    }
  });

  const onSubmit = async (data) => {
    let payload = {};
    if (data.pay_cycle === "Weekly" || data.pay_cycle === "Bi-Weekly") {
      payload = {
        pay_run_cycle_type: data.pay_cycle,
        name: data.payrun_cycle_name,
        day_of_week: daysOfWeekName,
        payment_basis: paymentCalculation,
      };
    } else {
      payload = {
        pay_run_cycle_type: data.pay_cycle,
        name: data.payrun_cycle_name,
        date_of_month: daysOfMonth,
        payment_basis: paymentCalculation,
      };
    }
    const response = await customAPI(
      `${domain}pay-run-cycles/${actionRowData?.id}/`,
      "PUT",
      payload
    );
    if (response.ok) {
      showToast("success", "Pay cycle updated successfully");
      setDisplayScreen("ListView");
      reCallApi(true);
      reset();
    }
  };

  const handlepaycycle = (event, data) => {
    if (data) {
      setValue("pay_cycle", data.label);
      setPayCycleType(data.label);
      updatePayrunCycleName(
        data.label,
        data.label === "Monthly" ? daysOfMonth : "Monday",
        paymentCalculation
      );
      setDaysOfWeekName("Monday");
      setValue("days_of_week_name", "Monday");
      setDaysOfMonth("1");
      setValue("date_of_month", "1");
    }
  };

  const handleDaysOfMonth = (event) => {
    if (
      /^(?:[1-9]|1[0-9]|2[0-8])$/.test(event.target.value) ||
      event.target.value === ""
    ) {
      setDaysOfMonth(event.target.value);
      setValue("date_of_month", event.target.value);
      updatePayrunCycleName(
        payCycleType,
        event.target.value,
        paymentCalculation
      );
    }
  };

  const handlePayrunCycleName = (event) => {
    if (event.target.value.length < 32) {
      setValue("payrun_cycle_name", event.target.value);
      setPayCycleName(event.target.value);
    }
  };

  const updatePayrunCycleName = (payCycleType, Date, pay) => {
    let appendValue = pay === "Shift" ? "S" : "H";
    if (payCycleType === "Monthly") {
      setPayCycleName(payCycleType + "-" + dayAdding(Date) + appendValue);
      setValue(
        "payrun_cycle_name",
        payCycleType + "-" + dayAdding(Date) + appendValue
      );
    } else if (payCycleType === "Weekly" || payCycleType === "Bi-Weekly") {
      setPayCycleName(payCycleType + "-" + Date + "-" + appendValue);
      setValue(
        "payrun_cycle_name",
        payCycleType + "-" + Date + "-" + appendValue
      );
    }
  };

  const dayAdding = (date) => {
    const suffixes = { 1: "st", 2: "nd", 3: "rd" };
    const suffix =
      date % 10 in suffixes && ![11, 12, 13].includes(date % 100)
        ? suffixes[date % 10]
        : "th";
    return `${date}${suffix}Day-`;
  };

  const handleDaysOfWeeKName = (event, data) => {
    if (data) {
      setDaysOfWeekName(data.label);
      setValue("days_of_week_name", data.label);
      updatePayrunCycleName(payCycleType, data.label, paymentCalculation);
    }
  };

  const selectTypeChanges = (event) => {
    setPaymentCalculation(event.target.value);
    // setTimeout(function () {
    updatePayrunCycleName(
      payCycleType,
      payCycleType === "Monthly" ? daysOfMonth : daysOfWeekName,
      event.target.value
    );
    // }, 500);
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingBottom: {
            xs: 10,
            sm: 20,
            md: 10,
            lg: 10,
            xl: 20,
          },
        }}
      >
        <Mui.Stack direction="row">
          <BackArrow actionFunction={() => setDisplayScreen("ListView")} />
          <Mui.Typography
            sx={{
              paddingLeft: 1,
              fontSize: 20,
              fontWeight: "bold",
              paddingTop: 1,
            }}
          >
            Edit Payrun Cycle
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid
          container
          sx={{
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 24,
                fontWeight: 800,
                font: "bold",
                textAlign: "center",
              }}
            >
              Edit payrun cycle
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              paddingTop: -1,
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
              borderRadius: "0 0 10px 10px",
            }}
          >
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Pay Cycle
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={payCycle}
                  rules={{
                    ...register("pay_cycle", {
                      required: "Pay cycle is required",
                    }),
                  }}
                  selectedValue={payCycleType}
                  updateDataFunction={handlepaycycle}
                  placeHolder="Select pay cycle"
                  errorMessage={
                    errors?.pay_cycle?.message ? errors?.pay_cycle?.message : ""
                  }
                  textBoxError={errors?.pay_cycle ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            {payCycleType !== "Monthly" ? (
              <Mui.Grid container sx={{ paddingTop: 2 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Start Day
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <CustomAutoComplete
                    dataList={days_of_week}
                    rules={{
                      ...register("days_of_week_name", {
                        required: "Days of week name is required",
                      }),
                    }}
                    selectedValue={daysOfWeekName}
                    updateDataFunction={handleDaysOfWeeKName}
                    placeHolder="Select pay cycle"
                    errorMessage={
                      errors?.days_of_week_name?.message
                        ? errors?.days_of_week_name?.message
                        : ""
                    }
                    textBoxError={errors?.days_of_week_name ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
            ) : (
              <Mui.Grid container sx={{ paddingTop: 2 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Start Day of Month
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={3}>
                  <CustomTextBox
                    selectedValue={daysOfMonth}
                    rules={{
                      ...register("date_of_month", {
                        required: "Day of month is required",
                      }),
                    }}
                    updateDataFunction={handleDaysOfMonth}
                    placeHolder="Enter day of month"
                    errorMessage={
                      errors?.date_of_month?.message
                        ? errors?.date_of_month?.message
                        : ""
                    }
                    textBoxError={errors.date_of_month ? true : false}
                  />
                </Mui.Grid>
                <Mui.Grid xs={5}>
                  {" "}
                  <Mui.Typography
                    sx={{
                      fontSize: 15,
                      fontWeight: "bold",
                      paddingTop: 1,
                      paddingLeft: 1,
                    }}
                  >
                    day of respective month
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            )}
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payment calculation based on
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <RadioGroup
                  row
                  onChange={selectTypeChanges}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={paymentCalculation}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    label="Shift"
                    value="Shift"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    label="Hours"
                    value="Hours"
                    control={<Radio />}
                  />
                </RadioGroup>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payrun Cycle Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={payCycleName}
                  rules={{
                    ...register("payrun_cycle_name", {
                      required: "Payrun cycle name is required",
                      minLength: {
                        value: 3,
                        message: "Minium length is 3 characters",
                      },
                      validate: {
                        atleastOneCharacterWithSpaceReg: (value) => {
                          if (
                            atleastOneCharacterWithSpace.test(value) ||
                            value === ""
                          ) {
                            return true;
                          } else {
                            return "Atleast one alphabet required";
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={handlePayrunCycleName}
                  placeHolder="Enter payrun cycle name"
                  errorMessage={
                    errors?.payrun_cycle_name?.message
                      ? errors?.payrun_cycle_name?.message
                      : ""
                  }
                  textBoxError={errors.payrun_cycle_name ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 3,
                  paddingBottom: 2,
                }}
              >
                <CustomButton
                  actionFuntion={() => setDisplayScreen("ListView")}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp; &nbsp;
                <CustomButton actionName="Update" typeName="submit" />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
