import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useFetch } from "services/hooks/useFetch";
import { Loader } from "components/Loader";
import { useSelector } from "react-redux";
import { overTimeKeyPair } from "pages/PayRoll/PayRollHeaders";
import {
  KeyboardArrowDown,
  ChevronRight,
} from "@material-ui/icons";


const keys = [
  "user_name",
  "employee_id",
  "paid_days",
  "gross_pay",
  "total_deduction",
  "net_pay",
  "per_day_salary",
];

export const OverTime = ({
  viewPayrunDetails,
  downloadReportDetails,
  companyName,
  summaryName,
  setDownloadReportDetails,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listViewPage, setListViewPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [overTimeDetailsState, setOverTimeDetailsState] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [showShiftCategaryDetails, setShowShiftCategaryDetails] = useState(0);
  const [expandData, setExpandData] = useState([]);

  const { result: overTimeData, loading } = useFetch(
    `${domain}overtime-report/?pay_schedule_id=${viewPayrunDetails.id}`
  );
  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  useEffect(() => {
    if (updateData && overTimeData?.users?.length !== 0 && overTimeData) {
      setOverTimeDetailsState(overTimeData?.users);
      setUpdateData(false);
    }
  }, [overTimeData, updateData]);

  useEffect(() => {
    if (overTimeData?.length !== 0 || overTimeData?.length === 0) {
      const filteredResults = overTimeData?.users?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setOverTimeDetailsState(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, overTimeData]);

  useEffect(() => {
    const downloadReports = () => {
      let dataPreparation = [];
      overTimeDetailsState?.forEach((element) => {
        let overAllData = {
          user_name: element.user_name,
          employee_id: element.employee_id,
          total_overtime_hours: element.total_overtime_hours,
          per_hour_salary: element.per_hour_salary,
          amount_earned: element.amount_earned,
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      const company_Name = ["Company Name"];
      const pay_ScheduleName = ["PaySchedule Name"];
      const paySchedulePeriod = ["PaySchedule Period"];
      const TableHead = ["Employee Name"];
      TableHead.push("Employee Id");
      TableHead.push("Overtime Hours");
      TableHead.push("Per Hour Rate");
      TableHead.push("Total Amount");
      company_Name.push(companyName);
      pay_ScheduleName.push(viewPayrunDetails.name);
      paySchedulePeriod.push(
        viewPayrunDetails.start_date + "  to  " + viewPayrunDetails.end_date
      );
      const items = dataPreparation;
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]).filter(
        (field) => field !== "break_details"
      );
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(paySchedulePeriod.join(","));
      csv.unshift(pay_ScheduleName.join(","));
      csv.unshift(company_Name.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "CostStructureReport.csv";
      document.body.appendChild(element);
      element.click();
    };
    if (downloadReportDetails && summaryName === "Over Time") {
      downloadReports();
      setDownloadReportDetails(false);
    }
  }, [companyName, downloadReportDetails, overTimeDetailsState, setDownloadReportDetails, summaryName, viewPayrunDetails.end_date, viewPayrunDetails.name, viewPayrunDetails.start_date]);

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      {overTimeDetailsState?.length === 0 ? (
        <Mui.Alert severity="info" sx={{ width: "100%", marginTop: 2 }}>
          No data available
        </Mui.Alert>
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 8, width: "100%", maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                {overTimeKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {overTimeDetailsState
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((payschedule) => (
                  <>
                    <Mui.TableRow
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        // setViewPayRunDetails(payschedule);
                        // setViewPayRunScreen(true);
                      }}
                    >
                      <Mui.TableCell>
                        {payschedule?.user_id === showShiftCategaryDetails ? (
                          <Mui.IconButton
                            onClick={() => setShowShiftCategaryDetails(0)}
                          >
                            <KeyboardArrowDown />
                          </Mui.IconButton>
                        ) : (
                          <Mui.IconButton
                            onClick={() => {
                              setExpandData(payschedule?.details);
                              setShowShiftCategaryDetails(payschedule?.user_id);
                            }}
                            style={{
                              padding: "0px",
                            }}
                          >
                            <ChevronRight />
                          </Mui.IconButton>
                        )}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        <Mui.Stack direction="Row">
                          <Mui.Avatar
                            sx={{ width: 25, height: 25 }}
                            alt="Remy Sharp"
                            src={payschedule.image_url}
                          />
                          <Mui.Typography
                            sx={{
                              paddingLeft: 1,
                            }}
                          >
                            {payschedule.user_name}
                          </Mui.Typography>
                        </Mui.Stack>
                      </Mui.TableCell>
                      <Mui.TableCell>{payschedule?.employee_id}</Mui.TableCell>
                      <Mui.TableCell>
                        {payschedule?.approved_overtime_hours}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {payschedule?.per_hour_salary}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {payschedule?.approved_amount_earned}
                      </Mui.TableCell>
                    </Mui.TableRow>
                    {showShiftCategaryDetails === payschedule?.user_id && (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={7}>
                          <Mui.TableContainer
                            sx={{
                              marginTop: 0,
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "100%px",
                            }}
                            className="w-96 border rounded"
                          >
                            {expandData?.length !== 0 ? (
                              <Mui.Table className="border">
                                <Mui.TableHead
                                  sx={{
                                    backgroundColor: themes.primary,
                                  }}
                                >
                                  <Mui.TableRow>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Date
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Check-In Time
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Check-Out time
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Shift Hours
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Over Time Hours
                                    </Mui.TableCell>

                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Approver Name
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Approved at
                                    </Mui.TableCell>
                                  </Mui.TableRow>
                                </Mui.TableHead>
                                <Mui.TableBody>
                                  {expandData.map((expandDetails) => (
                                    <Mui.TableRow>
                                      <Mui.TableCell>
                                        {expandDetails?.date}
                                      </Mui.TableCell>
                                      <Mui.TableCell>
                                        {expandDetails?.check_in}
                                      </Mui.TableCell>
                                      <Mui.TableCell>
                                        {expandDetails?.check_out}
                                      </Mui.TableCell>
                                      <Mui.TableCell>
                                        {expandDetails?.shift_time}
                                      </Mui.TableCell>
                                      <Mui.TableCell>
                                        {expandDetails?.overtime_hours}
                                      </Mui.TableCell>
                                      <Mui.TableCell>
                                        {expandDetails?.approver_name}
                                      </Mui.TableCell>
                                      <Mui.TableCell>
                                        {expandDetails?.approved_at}
                                      </Mui.TableCell>
                                    </Mui.TableRow>
                                  ))}
                                </Mui.TableBody>
                              </Mui.Table>
                            ) : (
                              <Mui.Stack
                                sx={{ padding: 0.5 }}
                                spacing={2}
                                fullWidth
                              >
                                <Mui.Alert severity="info">
                                  There is a no available shifts
                                </Mui.Alert>
                              </Mui.Stack>
                            )}
                          </Mui.TableContainer>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}
                  </>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {overTimeDetailsState?.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={overTimeDetailsState?.length}
            rowsPerPage={rowsPerPage}
            page={listViewPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>

      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
