import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { Delete, Edit } from "@mui/icons-material";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { AddShiftGroup } from "./AddShiftGroup";
import { EditShiftGroup } from "./EditShiftGroup";
import { ViewGroupMembers } from "./ViewGroupMembers";
import { Loader } from "components/Loader";
import { MoveShiftGroup } from "./MoveShiftGroup";
import { AddEmployee } from "./AddEmployee";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { ShiftGroupHeader } from "pages/Administration/AdministrationHeaders";
const keys = ["name", "shift_category_name", "departments", "group_user_count"];

export const ShiftGroup = ({
  companyId,
  addShiftGroup,
  moveShiftGroup,
  setAddShiftGroup,
  setMoveShiftGroup,
  setShowTopBar,
  setEditShiftGroup,
  editShiftGroup,
}) => {
  const { domain, globalSearchValue, token } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    result: shiftGroupData,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}shift-group/`);
  const [shiftGroupDetails, setShiftGroupDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [editGroup, setEditGroup] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showEmpShiftGrp, setShowEmpShiftGrp] = useState(false);
  const [departmentId, setDepartmentId] = useState([]);
  // const dispatch = useDispatch();
  // const [anchorEl, setAnchorEl] = useState(null);
  const [addEmployee, setAddEmployee] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (updateData && shiftGroupData?.length !== 0 && shiftGroupData) {
      setShiftGroupDetails(shiftGroupData);
      setUpdateData(false);
    }
  }, [shiftGroupData, updateData]);

  useEffect(() => {
    if (shiftGroupData?.length !== 0 || shiftGroupData?.length === 0) {
      const filteredResults = shiftGroupData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setShiftGroupDetails(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [globalSearchValue, page, shiftGroupData]);

  const deleteShiftGroup = async (account) => {
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}shift-group/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          is_deleted: true,
          shift_group_id: account.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Shift group deleted successfully");
        setEditShiftGroup(false);
        setOpenDialog(false);
        reCallApi(true);
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <React.Fragment>
      {addShiftGroup ? (
        <AddShiftGroup
          companyId={companyId}
          setAddShiftGroup={setAddShiftGroup}
          reCallApi={reCallApi}
          setShowTopBar={setShowTopBar}
        />
      ) : loading ? (
        <Loader />
      ) : editGroup ? (
        addEmployee ? (
          <AddEmployee
            setAddEmployee={setAddEmployee}
            editShiftGroup={editShiftGroup}
            reCallApi={reCallApi}
          />
        ) : (
          <EditShiftGroup
            reCallApi={reCallApi}
            setEditGroup={setEditGroup}
            setEditShiftGroup={setEditShiftGroup}
            shiftGroupDetails={shiftGroupDetails}
            setShowTopBar={setShowTopBar}
            editShiftGroup={editShiftGroup}
            departmentId={departmentId}
            setDepartmentId={setDepartmentId}
            setAddEmployee={setAddEmployee}
            addEmployee={addEmployee}
          />
        )
      ) : moveShiftGroup ? (
        <MoveShiftGroup
          editShiftGroup={editShiftGroup}
          setShowTopBar={setShowTopBar}
          setMoveShiftGroup={setMoveShiftGroup}
          reCallApi={reCallApi}
        />
      ) : showEmpShiftGrp ? (
        <ViewGroupMembers
          reCallApi={reCallApi}
          setEditGroup={setEditGroup}
          setEditShiftGroup={setEditShiftGroup}
          shiftGroupDetails={shiftGroupDetails}
          setShowTopBar={setShowTopBar}
          editShiftGroup={editShiftGroup}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          setShowEmpShiftGrp={setShowEmpShiftGrp}
        />
      ) : (
        <React.Fragment>
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "3px 32px",
                    },
                  }}
                >
                  {ShiftGroupHeader.map((head) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {head.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {shiftGroupDetails.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={8} align="center">
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  shiftGroupDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((account, index) => (
                      <Mui.TableRow
                        key={account.id}
                        sx={{
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "6px 35px",
                          },
                        }}
                      >
                        {ShiftGroupHeader.map((head, headIndex) => (
                          <Mui.TableCell key={`${account.id}-${headIndex}`}>
                            {head.key === "departments" ? (
                              account.departments
                                ?.map((dept) => dept.department)
                                .join(", ") || "N/A"
                            ) : head.key === "actions" ? (
                              <Mui.Stack direction="row">
                                <Mui.IconButton
                                  onClick={() => {
                                    let data = [];
                                    account.departments.forEach((element) => {
                                      data.push(element.id);
                                    });
                                    setDepartmentId(data);
                                    setEditGroup(true);
                                    setShowTopBar(false);
                                    setEditShiftGroup(account);
                                  }}
                                >
                                  <Edit
                                    sx={{
                                      strokeWidth: 2,
                                    }}
                                  />
                                </Mui.IconButton>

                                <Mui.IconButton
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setEditShiftGroup(account);
                                  }}
                                >
                                  <Delete
                                    sx={{
                                      strokeWidth: 2,
                                    }}
                                  />
                                </Mui.IconButton>
                              </Mui.Stack>
                            ) : (
                              account[head.key] || "N/A"
                            )}
                          </Mui.TableCell>
                        ))}
                      </Mui.TableRow>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {shiftGroupDetails?.length > 25 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={shiftGroupDetails?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}

      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete Shift Group"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 20, font: "bold" }}>
          Are you sure, you want to delete the &nbsp;
          <span style={{ color: themes.redColor }}>{editShiftGroup?.name}</span>
          ?
        </Mui.Typography>

        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => deleteShiftGroup(editShiftGroup)}
              actionName="Yes"
              typeName="button"
              disableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
