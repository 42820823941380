import { themes } from ".";
export const formKeyText = {
  fontSize: 20,
  fontWeight: "bold",
  color: themes.headLine,
};


export const tableContainerSx = {
  padding: 1,
  width: "100%",
  maxHeight: { xs: 150, sm: 300, md: 400, lg: 700, xl: 750 },
};

export const tableContainerSxWithCompany = {
  padding: 1,
  width: "100%",
  maxHeight: { xs: 150, sm: 300, md: 400, lg: 650, xl: 700 },
};

export const tableHeaderSx = {
  backgroundColor: themes.primary,
  position: "sticky",
  top: -1,
  zIndex: 10,
};

export const tableRowSx = {
  "& .MuiTableCell-root": {
    height: "10px",
    padding: "3px 15px",
  },
};

export const tableCellFontSize = { xs: 6, sm: 8, md: 10, lg: 12, xl: 15 };

export const scrollTop = (tableContainerRef) => {
  if (tableContainerRef.current) {
    tableContainerRef.current.scrollTop = 0;
  }
};