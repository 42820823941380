import React from "react";
import { Navbar, Container } from "reactstrap";

function AdminNavbar() {
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
        expand="md"
        id="navbar-main"
      >
        <Container></Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
