export const userHeader = [
  { name: "Name", key: "user_name" },
  { name: "User Code", key: "user_code" },
  { name: "Designation", key: "designation" },
  { name: "Employment Type", key: "employment_type" },
  { name: "Department", key: "department" },
  { name: "Department Lead", key: "department_lead_name" },
  { name: "Company Name", key: "company_name" },
  { name: "System Access", key: "system_access" },
  { name: "Status", key: "status" },
  { name: "Action", key: "" },
];

export const shiftCatagoryHeader = [
  { name: "" },
  { name: "Shift Category Name" },
  { name: "Number of Shifts" },
  { name: "Action" },
];

export const shiftHeader = [
  { name: "Shift Name" },
  { name: "Start Time" },
  { name: "Min Start Time" },
  { name: "Max Start Time" },
  { name: "End Time" },
  { name: "Min End Time" },
  { name: "Max End Time" },
];

export const designationHeader = [
  { name: "" },
  { name: "Designation" },
  { name: "Description" },
  { name: "Action" },
];

export const employmentTypeHeader = [
  { name: "" },
  { name: "Employment Type" },
  { name: "Description" },
  { name: "Actions" },
];

export const holidayHeader = [
  { name: "" },
  { name: "Date" },
  { name: "Holiday Name" },
  { name: "Action" },
];

export const departmentHeader = [
  { name: "" },
  { name: "Department Name" },
  { name: "Manager" },
  { name: "Proxy" },
  { name: "Department Members" },
  { name: "Action" },
];

export const departmentMembersHeader = [
  { name: "" },
  { name: "Name" },
  { name: "User code" },
  { name: "Designation" },
  { name: "Employment Type" },
  { name: "Phone Number" },
  { name: "System Access" },
  { name: "Status" },
];
export const departmentMembersHeaderAction = [
  { name: "" },
  { name: "Name" },
  { name: "User code" },
  { name: "Designation" },
  { name: "Employment Type" },
  { name: "Phone Number" },
  { name: "System Access" },
  { name: "Status" },
  { name: "Action" },
];

export const companyHeader = [
  { name: "" },
  { name: "Company Name" },
  { name: "Company Code" },
  { name: "Address" },
  { name: "Gst No" },
  { name: "Email" },
  { name: "Phone Number" },
  { name: "Status" },
  { name: "Type" },
  { name: "Action" },
];

export const userGroupHeader = [
  { name: "" },
  { name: "Group Name" },
  { name: "Users" },
  { name: "Action" },
];

export const groupProductHeader = [
  { name: "" },
  { name: "Products" },
  { name: "Role" },
  { name: "Action" },
];

export const groupUserHeader = [
  { name: "" },
  { name: "Name" },
  { name: "User Code" },
  { name: "Email" },
  { name: "Action" },
];

export const PayingUnitHeader = [
  { name: "Department", key: "department_name" },
  { name: "WorkUnit Name", key: "work_unit_name" },
  { name: "Total Number of WorkUnits", key: "total_number_of_work_unit" },
  { name: "Type of WorkUnit Payment", key: "unit_type" },
  { name: "Amount Per WorkUnit", key: "amount" },
  { name: "Effective From", key: "effective_from" },
  { name: "Actions" },
];

export const GroupUnitHeader = [
  { name: "Department", key: "department_name" },
  { name: "WorkUnit Name", key: "name" },
  { name: "Effective From", key: "effective_from" },
  { name: "Actions" },
];

export const AssignGroupUnitHeader = [
  { name: "WorkUnit Group Name", key: "name" },
  { name: "Employee Count", key: "employee_count" },
  { name: "Effective From", key: "start_date" },
  { name: "Effective To", key: "end_date" },
  { name: "Actions" },
];

export const IndividualMemberHeader = [
  { name: "Employee ID", key: "employee_id" },
  // { name: "Department", key: "department" },
  { name: "Employee Name", key: "user_name" },
];

export const IndividualSftGrpMemberHeader = [
  { name: "Employee Name", key: "username" },
  { name: "Department", key: "department_name" },
  { name: "Shift Category", key: "shift_category_name" },
  { name: "Manager", key: "manager_name" },
  { name: "Phone Number", key: "phone_number" },
  { name: "Action", key: "action" },
];

export const shiftTypes = [
  { id: 1, label: "Rotational Shift" },
  { id: 2, label: "Non Rotational Shift" },
  { id: 3, label: "Random Shift" },
];

export const ShiftGradeHeader = [
  { name: "Shift Grade Name", key: "shift_grade_name" },
  { name: "Shift Duration", key: "shift_duration" },
  { name: "Number of Week Off Days", key: "weekoff_days" },
  { name: "Factor Week Off Attendance", key: "factor_weekoff_days" },
  { name: "WorkUnit", key: "work_unit" },
  { name: "Number of Shift Category", key: "no_of_shift_category" },
  { name: "Number of Employees", key: "employee_count" },
  { name: "Actions" },
];

export const ShiftCategoryHeader = [
  { name: "Shift Category Name", key: "shift_category_name" },
  { name: "Shift Grade", key: "shift_grade_name" },
  { name: "Shift Type", key: "shift_type_name" },
  { name: "Week Off", key: "week_off_day" },
  { name: "Over Time", key: "over_time" },
  { name: "No of shifts", key: "number_of_shifts" },
  { name: "First Shift Start Time", key: "first_start_time" },
  { name: "Actions" },
];

export const shiftDetails = [
  { name: "Shift Name", key: "name" },
  { name: "Start Early", key: "min_start_time" },
  { name: "Start Time", key: "start_time" },
  { name: "Late Start", key: "max_start_time" },
  { name: "Break", key: "break_time" },
  { name: "Early End", key: "min_end_time" },
  { name: "End Time", key: "end_time" },
  { name: "End Late", key: "max_end_time" },
];

export const ShiftGroupHeader = [
  { name: "ShiftCategory", key: "shift_category_name" },
  { name: "Name", key: "name" },
  { name: "Department", key: "departments" },
  { name: "Employee Count", key: "group_user_count" },
  { name: "Actions", key: "actions" },
];

export const AdminShiftCategoryHeader = [
  { name: "Shift Category Name", key: "shift_category_name" },
  { name: "Rotaion Period", key: "shift_cycle" },
  { name: "No of Repetation", key: "no_of_cycle" },
  { name: "Start From", key: "start_date" },
  { name: "Repeat Untill", key: "end_date" },
  { name: "Actions", key: "actions" },
];

export const AddShiftCategoryHeader = [
  {
    name: "Shift Name",
    key: "shift_name",
  },
  {
    name: "Start Early",
    key: "min_start_time",
  },
  {
    name: "Start Time",
    key: "start_time",
  },
  {
    name: "Late Start",
    key: "min_end_time",
  },
  {
    name: "Break",
    key: "break_time",
  },
  {
    name: "Early End",
    key: "min_end_time",
  },
  {
    name: "End Time",
    key: "end_time",
  },
  {
    name: "End Late",
    key: "max_end_time",
  },
  {
    name: "Color",
    key: "",
  },
];

export const workUnitTypeList = [{ label: "Default" }, { label: "Fixed" }];
