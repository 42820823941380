import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import { customAPI } from "services/utils/CustomAPI";
import { Delete } from "@mui/icons-material";
import { defaultAllSelection } from "services/constants";
import { rgxWithDot } from "services/constants/patterns";
import { showToast } from "services/utils/Status";
import { BackArrow } from "components/CustomComponents/BackArrow";
let id = 0;
export const EditGroup = ({
  setEditGroupUnit,
  reCallApi,
  groupUnitDetails,
  setShowTopBar,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { result: department } = useFetch(
    `${domain}work-unint-based-department/`
  );
  const { result: workUnitList, setLoad: recallWorkUnitApi } = useFetch(
    `${domain}work_units/?department=${
      getValues("department") ? getValues("department") : defaultAllSelection
    }`
  );
  const [workUnitGroupName, setWorkUnitGroupName] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error, setError] = useState("");
  const [workUnit, setWorkUnit] = useState(0);
  const [departmentName, setDepartmentName] = useState("");
  const [workUnitName, setWorkUnitName] = useState("");
  const [addedUnitDetails, setAddedUnitDetails] = useState([
    ...groupUnitDetails.items,
  ]);
  const [workUnitUpdate, setWorkUnitUpdate] = useState(true);
  const [workUnitDetails, setWorkUnitDetails] = useState([]);

  let apiCall = true;

  useEffect(() => {
    if (apiCall && addedUnitDetails.length !== 0) {
      removeAddedWorkUnit(addedUnitDetails);
    }
  });

  useEffect(() => {
    if (workUnitUpdate && workUnitList?.length !== 0) {
      setWorkUnitDetails(workUnitList);
      setWorkUnitUpdate(false);
    } else {
      setWorkUnitDetails(workUnitList);
    }
  }, [workUnitUpdate, workUnitList]);

  useEffect(() => {
    setValue("name", groupUnitDetails?.name);
    setValue("department_name", groupUnitDetails?.department_name);

    setValue(
      "effective_from",
      groupUnitDetails?.effective_from?.split("-").reverse().join("-")
    );
    setValue("department", groupUnitDetails.department);
    setWorkUnitGroupName(groupUnitDetails?.name);
    setDepartmentName(groupUnitDetails?.department_name);
  }, [
    groupUnitDetails.department,
    groupUnitDetails?.department_name,
    groupUnitDetails?.effective_from,
    groupUnitDetails?.name,
    setValue,
  ]);

  const onSubmit = async (data) => {
    if (data) {
      let payload = { ...data, items: addedUnitDetails };
      setButtonDisabled(true);
      const response = await customAPI(
        `${domain}work_unit_groups/${groupUnitDetails.id}/`,
        "PUT",
        payload
      );
      if (response.ok) {
        showToast("success", "Work unit group updated successfully");
        setEditGroupUnit(false);
        setShowTopBar(true);
        reCallApi(true);
        reset();
      } else {
        setButtonDisabled(false);
      }
    }
  };

  const updateWorkUnitGroupName = (event) => {
    if (event.target.value?.length <= 64) {
      setValue("name", event.target.value);
      setWorkUnitGroupName(event.target.value);
    }
  };

  const numberOfWorkUnit = (event) => {
    if (
      rgxWithDot.test(event.target.value) &&
      event.target.value?.length <= 4
    ) {
      setWorkUnit(event.target.value);
      setValue("workunit", event.target.value);
    }
  };

  const handleDepartment = (event, data) => {
    if (data) {
      setValue("departmentName", data?.label);
      setValue("department", data?.id);
      setDepartmentName(data?.label);
      recallWorkUnitApi(true);
      setAddedUnitDetails([]);
      setTimeout(function () {
        setWorkUnitUpdate(true);
      }, 1000);
    } else {
      setValue("departmentName", "");
      setValue("department", null);
      setDepartmentName("");
      setAddedUnitDetails([]);
    }
  };

  const hadleWorkunit = (event, data) => {
    if (data) {
      setValue("work_unit_name", data?.label);
      setValue("id", data?.id);
      setWorkUnitName(data?.label);
    } else {
      setValue("work_unit_name", "");
      setValue("id", null);
      setWorkUnitName("");
    }
  };
  const validateStartDate = (value) => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (!value) {
      return "Date is required.";
    } else if (value < currentDate) {
      return "Date cannot be in the past.";
    }
  };

  const addNewRow = () => {
    setError("");
    if (
      getValues("work_unit_name") !== "" &&
      getValues("workunit") !== "" &&
      getValues("workunit") &&
      getValues("work_unit_name")
    ) {
      const newRow = {
        id: id + 1,
        work_unit_name: getValues("work_unit_name"),
        number_of_units: getValues("workunit"),
        work_unit: getValues("id"),
      };
      id = id + 1;
      setAddedUnitDetails([...addedUnitDetails, newRow]);
      removeAddedWorkUnit([...addedUnitDetails, newRow]);
      setValue("work_unit_name", "");
      setValue("workunit", "");
      setWorkUnitName("");
      setWorkUnit("");
    } else {
      setError("Enter value");
    }
  };

  const removeAddedWorkUnit = (addedData) => {
    let data = workUnitList;
    addedData.forEach((element) => {
      data = data.filter((details) => {
        return element.work_unit_name !== details.work_unit_name;
      });
    });
    setWorkUnitDetails(data);
  };

  const removeCurrentWorkUnit = (unitDetails) => {
    let remaining = addedUnitDetails.filter((data) => {
      return data.work_unit !== unitDetails.work_unit;
    });
    setAddedUnitDetails(remaining);
    removeAddedWorkUnit(remaining);
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setEditGroupUnit(false);
            setShowTopBar(true);
          }}
        />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
        >
          Work Unit / Edit WorkUnit Group
        </Mui.Typography>
      </Mui.Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: 20 }}
        noValidate
      >
        <Mui.Grid
          container
          sx={{ paddingLeft: 40, paddingRight: 40, paddingTop: 10 }}
        >
          {/* <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Mui.Grid item sx={{ padding: 1 }}>
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(true);
                }}
                actionName="Delete"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid> */}
          <Mui.Grid container component={Mui.Paper}>
            <Mui.Grid
              xs={12}
              sx={{
                padding: 1,
                backgroundColor: themes.primary,
                borderRadius: "10px 10px 0 0",
                border: `2px solid ${themes.sidebarDivider}`,
                borderBottom: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
              >
                Edit WorkUnit Group
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              padding: 3,
              borderRadius: "0 0 10px 10px",
              border: `2px solid ${themes.sidebarDivider}`,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Grid container sx={{ padding: 2 }}>
              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Department
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <CustomAutoComplete
                    dataList={department?.map((obj) => ({
                      ...obj,
                      label: obj.department,
                    }))}
                    selectedValue={departmentName}
                    rules={{
                      ...register("department_name", {
                        required: "Department name is required",
                      }),
                    }}
                    updateDataFunction={handleDepartment}
                    placeHolder="Select the department name"
                    errorMessage={
                      errors?.department_name?.message
                        ? errors?.department_name?.message
                        : ""
                    }
                    textBoxError={errors?.department_name ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    WorkUnit Group Name
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <CustomTextBox
                    selectedValue={workUnitGroupName}
                    rules={{
                      ...register("name", {
                        required: "Workunit group name is required",

                        validate: {
                          atleastOneCharacterWithSpaceReg: (value) => {
                            if (
                              atleastOneCharacterWithSpace.test(value) ||
                              value === ""
                            ) {
                              return true;
                            } else {
                              return "Atleast one alphabet required";
                            }
                          },
                          employeePhonenumberAvailable: async (fieldValue) => {
                            if (
                              fieldValue !== "" &&
                              groupUnitDetails.name !== fieldValue
                            ) {
                              const response = await fetch(
                                `${domain}unique/work-unit-group/?name=${fieldValue.trim()}`,
                                {
                                  method: "GET",
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `token ${token}`,
                                  },
                                }
                              );
                              const resp = await response.json();
                              if (resp.exists) {
                                return "Workunit group name already exists";
                              }
                            }
                          },
                        },
                      }),
                    }}
                    updateDataFunction={updateWorkUnitGroupName}
                    placeHolder="Enter workunit group name"
                    errorMessage={
                      errors?.name?.message ? errors?.name?.message : ""
                    }
                    textBoxError={errors.name ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid
                container
                fullWidth
                xs={12}
                sx={{ padding: 1 }}
                spacing={3}
              >
                <Mui.Grid item xs={4}></Mui.Grid>
                <Mui.Grid item xs={3.8}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    WorkUnit Name
                  </Mui.Typography>
                  <CustomAutoComplete
                    dataList={workUnitDetails?.map((obj) => ({
                      ...obj,
                      label: obj.name,
                    }))}
                    selectedValue={workUnitName}
                    rules={{
                      ...register("work_unit_name", {
                        required: false,
                        validate: {
                          validateWork: async (fieldValue) => {
                            if (fieldValue) {
                              return "Before submit please add the work unit name";
                            } else {
                              return true;
                            }
                          },
                        },
                      }),
                    }}
                    updateDataFunction={hadleWorkunit}
                    placeHolder="Select the workunit name"
                    errorMessage={
                      errors?.work_unit_name?.message
                        ? errors?.work_unit_name?.message
                        : ""
                    }
                    textBoxError={errors?.work_unit_name ? true : false}
                  />
                </Mui.Grid>

                <Mui.Grid item xs={3.5}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Number of WorkUnit
                  </Mui.Typography>
                  <>
                    <CustomTextBox
                      selectedValue={workUnit}
                      rules={{
                        ...register("workunit", {
                          required: false,
                          validate: {
                            validateWork: async (fieldValue) => {
                              if (fieldValue) {
                                return "Before submit please add the work unit";
                              } else {
                                return true;
                              }
                            },
                          },
                        }),
                      }}
                      updateDataFunction={numberOfWorkUnit}
                      placeHolder="Enter number of workunit"
                      errorMessage={
                        errors?.workunit?.message
                          ? errors?.workunit?.message
                          : ""
                      }
                      textBoxError={errors.workunit ? true : false}
                    />
                    {error === "Enter value" ? (
                      <Mui.Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: 13, paddingLeft: 0.5 }}
                      >
                        Enter value
                      </Mui.Typography>
                    ) : null}
                  </>
                </Mui.Grid>
                <Mui.Grid item xs={0.5} sx={{ marginTop: 4 }}>
                  <CustomButton
                    actionFuntion={() => addNewRow()}
                    actionName="Add"
                    typeName="button"
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid container xs={4}></Mui.Grid>
                <Mui.Grid
                  container
                  sx={{
                    border: `1px solid ${themes.sidebarDivider}`,
                  }}
                  xs={8}
                >
                  {addedUnitDetails?.map((unit, index) => (
                    <Mui.Grid
                      container
                      sx={{ border: `1px solid ${themes.sidebarDivider}` }}
                    >
                      <Mui.Grid xs={4}>
                        <Mui.Typography
                          sx={{ textAlign: "center", paddingTop: 1 }}
                        >
                          {unit.work_unit_name}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={6}>
                        <Mui.Typography
                          sx={{ textAlign: "center", paddingTop: 1 }}
                        >
                          {unit.number_of_units}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={2}>
                        <Mui.IconButton
                          onClick={() => removeCurrentWorkUnit(unit)}
                        >
                          <Delete />
                        </Mui.IconButton>
                      </Mui.Grid>
                    </Mui.Grid>
                  ))}
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Effective From
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.TextField
                    id="from-date"
                    type="date"
                    size="small"
                    {...register("effective_from", {
                      required: "Date is required.",
                      validate: validateStartDate,
                    })}
                    error={Boolean(errors.effective_from)}
                    helperText={
                      errors.effective_from?.message &&
                      errors.effective_from.message
                    }
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      },
                    }}
                    onBlur={() => trigger("effective_from")}
                    fullWidth
                    disableOpenPicker
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid
                container
                sx={{
                  paddingTop: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionName="Update"
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
