import React, { useCallback, useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SalaryDetails } from "./SalaryDetails";
import {
  setEditEmployee,
  setEditEmployeeID,
  setEmployeeScreenAction,
} from "services/Redux/TeamZone";
import { PageSideBar } from "../PageSideBar";
import { themes } from "services/constants";
import { BasicDetailsForm } from "./BasicDetailsForm";
import { PersonalDetailsForm } from "./PersonalDetailsForm";
import { ContactDetailsForm } from "./ContactDetailsForm";
import { BankDetailsForm } from "./BankDetailsForm";
import { useDispatch } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/CustomComponents/BackArrow";

export const EmployeeOnBoard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { editEmployeeId, editEmployee, employeeScreenAction } = useSelector(
    (state) => state.teamZoneReducer
  );
  const [pageNo, setPageNo] = useState(1);
  const [existingUserDetails, setExistingUserDetails] = useState([]);
  const [updateBasicDetailsData, setUpdateBasicDetailsData] = useState(false);
  const [employeeIdExisting, setEmployeeId] = useState("");
  const [selectedUserMailId, setSelectedUserMailId] = useState("");
  const [selectedUserPhoneNumber, setSelectedUserPhoneNumber] = useState("");
  const [selectedUSerPFNumber, setSelectedUserPFNumber] = useState("");

  const getSelectedUserDetails = useCallback(
    async (id) => {
      try {
        const response = await fetch(
          `${domain}employee/details?user_id=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setExistingUserDetails(res);
          setSelectedUserMailId(res.email_id);
          setSelectedUserPhoneNumber(res.phone_number);
          setSelectedUserPFNumber(res.pf_number);
          setEmployeeId(res.employee_id);
          setUpdateBasicDetailsData(true);
        } else if (response.status === 409) {
          setExistingUserDetails([]);
          sessionHandling();
        } else {
          setExistingUserDetails([]);
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  useEffect(() => {
    if (editEmployee) {
      getSelectedUserDetails(editEmployeeId);
      localStorage.setItem("editEmployeeIdNumber", null);
      localStorage.setItem("editEmployeeDetails", false);
      dispatch(setEditEmployeeID(null));
      dispatch(setEditEmployee(false));
    }
  }, [dispatch, editEmployee, editEmployeeId, getSelectedUserDetails]);

  //ToDo:I need this to updated
  // const createEmployee = async () => {
  //   try {
  //     const response = await fetch(`${domain}employee-onboard`, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `token ${token}`,
  //       },
  //       body: JSON.stringify({
  //         user_id: existingUserDetails.user_id,
  //         user_name: existingUserDetails.user_name,
  //         email: existingUserDetails.email_id,
  //         company_id: existingUserDetails.company_id,
  //         portal_access: existingUserDetails.portal_access,
  //         employee_id: existingUserDetails.employee_id,
  //         date_joined: existingUserDetails.date_of_joined
  //           ?.split("-")
  //           .reverse()
  //           .join("-"),
  //         leave_group_id: existingUserDetails?.leave_group_id,
  //         department_id: existingUserDetails.department_id,
  //         designation_id: existingUserDetails.designation_id,
  //         employment_type_id: existingUserDetails.employment_type_id,
  //         parent_name: existingUserDetails.parent_name,
  //         parent_mobile_number: existingUserDetails.parent_mobile_number,
  //         bankname: existingUserDetails.bank_name,
  //         account_holder_name: existingUserDetails.account_holder_name,
  //         accountno: existingUserDetails.account_number,
  //         ifsc: existingUserDetails.ifsc,
  //         branch_name: existingUserDetails.branch_name,
  //         pan: existingUserDetails.pan_number,
  //         aadhar: existingUserDetails.aadhar_number,
  //         address: existingUserDetails.address,
  //         pf: existingUserDetails.pf_number,
  //         uan: existingUserDetails.uan_number,
  //         esi: existingUserDetails.esi_number,
  //         dob: existingUserDetails.dob?.split("-").reverse().join("-"),
  //         emergency_contacts: existingUserDetails.emergency_contacts,
  //       }),
  //     });
  //     if (!response.ok) {
  //       const errorRes = await response.json();
  //       throw new Error(errorRes.error);
  //     } else {
  //       getSelectedUserDetails(existingUserDetails.user_id);
  //       setOpenDialog(true);
  //     }
  //   } catch (error) {
  //     showToast("error", error.message);
  //   }
  // };

  const openDialogBox = () => {
    // setOpenDialog(true);
    if (employeeScreenAction) {
      const message =
        employeeScreenAction === "Existing User"
          ? "Employee registered successfully"
          : "Employee updated successfully";

      showToast("success", message);
    }
    history.push("/admin/teamzone/employee");
    dispatch(setEmployeeScreenAction(""));
  };

  function visualComponents() {
    switch (pageNo) {
      case 1:
        return (
          <BasicDetailsForm
            setPageNo={setPageNo}
            existingUserDetails={existingUserDetails}
            getSelectedUserDetails={getSelectedUserDetails}
            updateBasicDetailsData={updateBasicDetailsData}
            setUpdateBasicDetailsData={setUpdateBasicDetailsData}
            setExistingUserDetails={setExistingUserDetails}
            openDialogBox={openDialogBox}
            selectedUserMailId={selectedUserMailId}
            selectedUserPhoneNumber={selectedUserPhoneNumber}
            employeeIdExisting={employeeIdExisting}
          />
        );
      case 2:
        return (
          <PersonalDetailsForm
            setPageNo={setPageNo}
            existingUserDetails={existingUserDetails}
            selectedUSerPFNumber={selectedUSerPFNumber}
            setUpdateBasicDetailsData={setUpdateBasicDetailsData}
            setExistingUserDetails={setExistingUserDetails}
            openDialogBox={openDialogBox}
          />
        );
      case 3:
        return (
          <ContactDetailsForm
            setPageNo={setPageNo}
            existingUserDetails={existingUserDetails}
            getSelectedUserDetails={getSelectedUserDetails}
            setExistingUserDetails={setExistingUserDetails}
            openDialogBox={openDialogBox}
          />
        );
      case 4:
        return (
          <BankDetailsForm
            existingUserDetails={existingUserDetails}
            setExistingUserDetails={setExistingUserDetails}
            setPageNo={setPageNo}
            openDialogBox={openDialogBox}
          />
        );
      case 5:
        return (
          <SalaryDetails
            setPageNo={setPageNo}
            existingUserDetails={existingUserDetails}
            setExistingUserDetails={setExistingUserDetails}
            openDialogBox={openDialogBox}
          />
        );
      default:
        break;
    }
  }

  // const addButtonAction = () => {
  //   history.push("/admin/teamzone/employee");
  //   dispatch(setEmployeeScreenAction(""));
  // };

  return (
    <React.Fragment
      sx={{
        position: "absolute",
      }}
    >
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <BackArrow
          actionFunction={() => history.push("/admin/teamzone/employee")}
        />
        <Mui.Typography
          sx={{
            fontSize: 22,
            paddingLeft: 1,
            fontWeight: "bold",
            paddingTop: 1,
          }}
        >
          Employee Onboarding / {employeeScreenAction}
        </Mui.Typography>
      </Mui.Grid>
      <Mui.Grid container sx={{ overflowY: "-moz-hidden-unscrollable" }}>
        <Mui.Grid
          item
          xs={5}
          sm={4}
          md={4}
          lg={3}
          sx={{
            borderRight: `3px solid ${themes.colorShadow}`,
            paddingTop: 8,
          }}
        >
          <PageSideBar pageNo={pageNo} />
        </Mui.Grid>
        <Mui.Grid item xs={7} sm={8} md={8} lg={9} sx={{ paddingLeft: 2 }}>
          {visualComponents()}
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
