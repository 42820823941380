import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  Edit,
  Delete,
} from "@mui/icons-material";
import { Loader } from "components/Loader";
import { GroupUnitHeader } from "pages/Administration/AdministrationHeaders";
import { AddGroupUnit } from "./AddGroupUnit";
import { EditGroup } from "./EditGroup";
import { showToast } from "services/utils/Status";
const keys = ["department_name", "name", "effective_from"];

export const GroupUnit = ({
  groupUnit,
  companyId,
  workUnitGroup,
  reCallApi,
  loading,
  setShowTopBar,
  setGroupUnit,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { globalSearchValue } = useSelector((state) => state.tokenReducer);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [incentiveDetailsStateData, setIncentiveStateData] = useState([]);
  const [expendRowId, setExpendRowId] = useState(0);
  const [updateData, setUpdateData] = useState(true);
  const [groupUnitDetails, setGroupUnitDetails] = useState({});
  const [editGroupUnit, setEditGroupUnit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (updateData && workUnitGroup.length !== 0) {
      setIncentiveStateData(workUnitGroup);
      setUpdateData(false);
    }
  }, [updateData, workUnitGroup]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (updateData && workUnitGroup?.length !== 0 && workUnitGroup) {
      setIncentiveStateData(workUnitGroup);
      setUpdateData(false);
    }
  }, [workUnitGroup, updateData]);

  useEffect(() => {
    if (workUnitGroup?.length !== 0 || workUnitGroup?.length === 0) {
      const filteredResults = workUnitGroup?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setIncentiveStateData(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [globalSearchValue, page, workUnitGroup]);

  const deleteWorkUnitGroup = async (id) => {
    try {
      const response = await fetch(`${domain}work_unit_groups/${id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Work unit group deleted successfully");
        setEditGroupUnit(false);
        reCallApi(true);
        setOpenDialog(false);
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      {groupUnit ? (
        <AddGroupUnit
          companyId={companyId}
          setGroupUnit={setGroupUnit}
          reCallApi={reCallApi}
          setShowTopBar={setShowTopBar}
        />
      ) : loading ? (
        <Loader />
      ) : editGroupUnit ? (
        <EditGroup
          setEditGroupUnit={setEditGroupUnit}
          reCallApi={reCallApi}
          groupUnitDetails={groupUnitDetails}
          setShowTopBar={setShowTopBar}
        />
      ) : (
        <React.Fragment>
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "6px 15px",
                    },
                  }}
                >
                  <Mui.TableCell></Mui.TableCell>
                  {GroupUnitHeader.map((head) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {head.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {incentiveDetailsStateData?.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={8}>
                      <Mui.Alert
                        severity="info"
                        sx={{ width: "100%", margin: 2 }}
                      >
                        No data found
                      </Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  incentiveDetailsStateData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((account, index) => (
                      <React.Fragment>
                        <Mui.TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              height: "10px",
                              padding: "6px 15px",
                            },
                          }}
                        >
                          <Mui.TableCell>
                            {expendRowId === account.id ? (
                              <Mui.IconButton onClick={() => setExpendRowId(0)}>
                                <KeyboardArrowDown />
                              </Mui.IconButton>
                            ) : (
                              <Mui.IconButton
                                onClick={() => setExpendRowId(account.id)}
                              >
                                <KeyboardArrowRight />
                              </Mui.IconButton>
                            )}
                          </Mui.TableCell>
                          {GroupUnitHeader.map((head) =>
                            head.name === "Actions" ? (
                              <Mui.TableCell>
                                <Mui.IconButton
                                  onClick={() => {
                                    setShowTopBar(false);
                                    setGroupUnitDetails(account);
                                    setEditGroupUnit(true);
                                  }}
                                >
                                  <Edit />
                                </Mui.IconButton>
                                <Mui.IconButton
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setGroupUnitDetails(account);
                                  }}
                                >
                                  <Delete />
                                </Mui.IconButton>
                                {/* <Mui.IconButton
                                  onClick={() => {
                                    setShowTopBar(false);
                                    setGroupUnitDetails(account);
                                    setEditGroupUnit(true);
                                  }}
                                >
                                  <RemoveRedEyeIcon />
                                </Mui.IconButton> */}
                              </Mui.TableCell>
                            ) : (
                              <Mui.TableCell>
                                {account[head.key] ? account[head.key] : "N/A"}
                              </Mui.TableCell>
                            )
                          )}
                        </Mui.TableRow>
                        {expendRowId === account.id && (
                          <Mui.TableRow>
                            <Mui.TableCell colSpan={12}>
                              <Mui.TableContainer
                                sx={{
                                  marginTop: 0,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "100%px",
                                }}
                                className="w-96 border rounded"
                              >
                                <Mui.Table>
                                  <Mui.TableHead
                                    sx={{
                                      backgroundColor: themes.primary,
                                    }}
                                  >
                                    <Mui.TableRow>
                                      <Mui.TableCell
                                        sx={{
                                          color: themes.blackColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Work Unit Name
                                      </Mui.TableCell>

                                      <Mui.TableCell
                                        sx={{
                                          color: themes.blackColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Number of Work Unit
                                      </Mui.TableCell>
                                    </Mui.TableRow>
                                  </Mui.TableHead>
                                  <Mui.TableBody>
                                    {account?.items?.map((detail) => (
                                      <Mui.TableRow>
                                        <Mui.TableCell>
                                          {detail.work_unit_name}
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {detail.number_of_units}
                                        </Mui.TableCell>
                                      </Mui.TableRow>
                                    ))}
                                  </Mui.TableBody>
                                </Mui.Table>
                              </Mui.TableContainer>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        )}
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {incentiveDetailsStateData?.length > 25 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={incentiveDetailsStateData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete WorkUnit Group"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 21.5 }}>
          Are you sure,you want to delete the{" "}
          <span style={{ color: themes.redColor }}>
            {groupUnitDetails.name}
          </span>
          ?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => deleteWorkUnitGroup(groupUnitDetails.id)}
              actionName="Yes"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
