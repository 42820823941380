import React from "react";

import { Grid, Paper, Typography } from "@mui/material";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import {
  PersonOff,
  EditLocationAlt,
  EventNote,
  ViewModule,
} from "@mui/icons-material";

export const SettingsMenu = ({ setCurrentScreen, currentScreen }) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid
        conatiner
        spacing={2}
        sx={{
          marginTop: 2,
          height: "92.5vh",
          overflow: "hidden",
          // borderRadius: 2,
          backgroundColor: themes.primary,
        }}
        component={Paper}
        elevation={3}
      >
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Leave Period" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color:
                currentScreen === "Leave Period"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Leave Period"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("settingScreens", "Leave Period");
              setCurrentScreen("Leave Period");
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <EventNote sx={{ fontSize: 20 }} /> Leave Period
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Leave Type" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color:
                currentScreen === "Leave Type"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Leave Type"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("settingScreens", "Leave Type");
              setCurrentScreen("Leave Type");
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <ViewModule sx={{ fontSize: 20 }} /> Leave Type
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Leave Group" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color:
                currentScreen === "Leave Group"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Leave Group"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("settingScreens", "Leave Group");
              setCurrentScreen("Leave Group");
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <PersonOff sx={{ fontSize: 20 }} /> Leave Group
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Site Settings" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color:
                currentScreen === "Site Settings"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Site Settings"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("settingScreens", "Site Settings");
              setCurrentScreen("Site Settings");
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <EditLocationAlt sx={{ fontSize: 20 }} /> Site Settings
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
