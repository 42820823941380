import React from "react";
import MyTaskList from "./Mytasklist";
import { TaskContextProvider } from "../Tasks/TaskContext";

export const MyTasks = () => {
  return (
    <React.Fragment>
      <TaskContextProvider>
        <MyTaskList />
      </TaskContextProvider>
    </React.Fragment>
  );
};
