import React, { useState } from "react";
import { SettingsMenu } from "./SettingsMenu";
import { Grid, Paper } from "@mui/material";
import { Designation } from "./Screens/Designation";
import { EmploymentType } from "./Screens/EmploymentType";
import { Unit } from "./Screens/Unit";
import { useLocation } from "react-router-dom";
export const Master = () => {
  const [currentScreen, setCurrentScreen] = useState("Designation");
  const location = useLocation();
  
  return (
    <React.Fragment>
      <Grid container>
        <Grid
          xs={4}
          sm={3}
          md={2.5}
          lg={2.5}
          xl={1.5}
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          <SettingsMenu
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
          />
        </Grid>
        <Grid xs={8} sm={9} md={9.5} lg={9.5} xl={10.5} sx={{ padding: 1 }}>
          <Paper
            sx={{
              boxShadow: 3,
              height: "93vh",
            }}
          >
            {location.pathname === "/admin/master/designation" ? (
              <Designation />
            ) : null}
            {location.pathname === "/admin/master/employment/type" ? (
              <EmploymentType />
            ) : null}
            {location.pathname === "/admin/master/unit" ? <Unit /> : null}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
