import React, { useState } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { viewTableData } from "pages/MyZone/MyZoneApply/Headers";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import dayjs from "dayjs";
const today = dayjs().format("YYYY-MM-DD");
export const ViewLoan = ({
  viewLoanDetails,
  setViewLoan,
  selectComponent,
  setDepartmentChange,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [disbursementDate, setDisbursementDate] = useState(today);
  const [disbursementReason, setDisbursementReason] = useState("");
  const [paymentDate, setPaymentDate] = useState(today);
  const [errorHandling, setErrorHandling] = useState(false);
  const [reasonForRejectTxt, setReasonForRejectTxt] = useState("");
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const handleCloseRejectDialogBox = () => {
    setOpenRejectDialog(false);
    setReasonForRejectTxt("");
  };
  const disbursementTheLoan = async () => {
    const paymentDateWithFormat = new Date(paymentDate);
    const disbursementDateWithFormat = new Date(disbursementDate);
    let payDateLessDisuburseDate = false;
    if (paymentDateWithFormat < disbursementDateWithFormat) {
      payDateLessDisuburseDate = true;
    }
    if (
      disbursementDate &&
      disbursementReason.length > 3 &&
      paymentDate &&
      !payDateLessDisuburseDate
    ) {
      try {
        setErrorHandling(false);
        const response = await fetch(`${domain}disbursement/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            id: viewLoanDetails?.id,
            disbursement_date: disbursementDate,
            repayment_date: paymentDate,
            disbursement_reason: disbursementReason,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", "Loan disbursemented successfully");
          setViewLoan(false);
          setDepartmentChange(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      setErrorHandling(true);
    }
  };

  const rejectCurrentLoan = async () => {
    try {
      const response = await fetch(`${domain}payroll/reject-loan/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          id: viewLoanDetails.id,
          reject_reason: reasonForRejectTxt,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Loan rejected");
        setViewLoan(false);
        setDepartmentChange(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <Mui.Stack direction="row">
          <BackArrow
            actionFunction={() => {
              setViewLoan(false);
            }}
          />
          &nbsp;&nbsp;
          <Mui.Typography
            sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 0.5 }}
          >
            {selectComponent === "history" ? "History / " : "Active Loans / "}
            Loan Details
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.Grid
          container
          sx={{
            paddingLeft: 2,
            border: `2px solid ${themes.sidebarDivider}`,
            padding: 0.5,
          }}
        >
          <Mui.Grid xs={12} sm={6} md={1} lg={1} xl={1}>
            <Mui.Avatar
              sx={{ width: 70, height: 70 }}
              alt="Remy Sharp"
              src={viewLoanDetails?.image_url}
            />
          </Mui.Grid>
          <Mui.Grid xs={12} sm={6} md={2} lg={2} xl={2}>
            <Mui.Typography
              sx={{
                paddingTop: 3,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                fontSize: 20,
                color: themes.shadowText,
              }}
            >
              {viewLoanDetails?.user_name}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={6} md={2} lg={2} xl={2}>
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{
                  paddingTop: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  fontSize: 20,
                  color: themes.shadowText,
                }}
              >
                Employee Id :
              </Mui.Typography>
              <Mui.Typography
                sx={{
                  paddingTop: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  fontSize: 20,
                  color: themes.headLine,
                  paddingLeft: 1,
                }}
              >
                {viewLoanDetails?.employee_id}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={6} md={2} lg={2} xl={2}>
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{
                  paddingTop: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  fontSize: 20,
                  color: themes.shadowText,
                }}
              >
                {selectComponent !== "Approve"
                  ? "Loan Id :"
                  : "Application Id :"}
              </Mui.Typography>
              <Mui.Typography
                sx={{
                  paddingTop: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  fontSize: 20,
                  color: themes.headLine,
                  paddingLeft: 1,
                }}
              >
                {selectComponent !== "Approve"
                  ? viewLoanDetails?.loan_id
                    ? viewLoanDetails?.loan_id
                    : "N/A"
                  : viewLoanDetails?.application_id
                  ? viewLoanDetails?.application_id
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={6} md={5} lg={5} xl={5}>
            <Mui.Grid
              container
              sx={{
                paddingTop: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 2,
              }}
            >
              <Mui.Box
                sx={{
                  width: 120,
                  height: 15,
                  backgroundColor: themes.primaryIndicator,
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: themes.headLine,
                  p: 2,
                  fontSize: 13,
                }}
              >
                {viewLoanDetails?.status ? viewLoanDetails?.status : "N/A"}
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      {/* Loan details */}
      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.Grid
          container
          sx={{ padding: 2, backgroundColor: themes.sidebarDivider }}
        >
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Disbursement date
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.disbursement_date
                  ? viewLoanDetails?.disbursement_date
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Principal amount{" "}
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.principal_amount
                  ? viewLoanDetails?.principal_amount
                  : "0"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Interest
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.interest_rate
                  ? viewLoanDetails?.interest_rate
                  : "0"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Interest Paid
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.total_interest_paid
                  ? viewLoanDetails?.total_interest_paid
                  : "0"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Principal Paid
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.total_principal_paid
                  ? viewLoanDetails?.total_principal_paid
                  : "0"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Principal Balance
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.remaining_principal_balance
                  ? viewLoanDetails?.remaining_principal_balance
                  : "0"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Tenure
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.tenure_months}&nbsp;&nbsp;
                {viewLoanDetails?.tenure_type}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                End date
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.repayment_end_date
                  ? viewLoanDetails?.repayment_end_date
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      {selectComponent !== "Approve" && selectComponent !== "Disburse" ? (
        viewLoanDetails.status !== "Rejected" ? (
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.TableContainer
              sx={{
                marginTop: 2,
                marginLeft: "auto",
                marginRight: "auto",
                overflow: "auto",
                paddingBottom: 2,
              }}
            >
              <Mui.Table>
                <Mui.TableHead
                  sx={{
                    backgroundColor: themes.sidebarDivider,
                  }}
                >
                  <Mui.TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "15px",
                        padding: "6px 35px",
                      },
                    }}
                  >
                    {viewTableData.map((head) => (
                      <Mui.TableCell>
                        <Mui.Typography fontWeight="bold">
                          {head.name}
                        </Mui.Typography>
                      </Mui.TableCell>
                    ))}
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {viewLoanDetails?.monthly_payments?.map((item) => (
                    <Mui.TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "15px",
                          padding: "6px 35px",
                        },
                      }}
                    >
                      {viewTableData?.map((head) => (
                        <Mui.TableCell>
                          {item[head.key] || item[head.key] === 0
                            ? item[head.key]
                            : "N/A"}
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                  ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
          </Mui.Grid>
        ) : (
          <>
            <Mui.Typography sx={{ paddingLeft: 2, fontWeight: "bold" }}>
              Reject Reason
            </Mui.Typography>
            <Mui.Grid container sx={{ padding: 2 }}>
              <Mui.Box
                sx={{
                  paddingLeft: 10,
                  width: "100%",
                  backgroundColor: themes.whiteColor,
                  borderRadius: "6px",
                  color: themes.headLine,
                  p: 2,
                  fontSize: 18,
                  border: `2px solid ${themes.sidebarDivider}`,
                }}
              >
                {viewLoanDetails?.reject_reason
                  ? viewLoanDetails?.reject_reason
                  : "N/A"}
              </Mui.Box>
            </Mui.Grid>
          </>
        )
      ) : (
        <Mui.Grid container sx={{ padding: 1 }}>
          <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
            Approve Details
          </Mui.Typography>
          <Mui.Grid
            container
            sx={{ border: `2px solid ${themes.sidebarDivider}` }}
          >
            <Mui.Grid container>
              <Mui.Grid xs={4}>
                <Mui.Grid container sx={{ padding: 2 }}>
                  <Mui.Grid xs={3} sx={{ paddingTop: 1 }}>
                    <Mui.Typography
                      sx={{ fontWeight: "bold", color: themes.shadowText }}
                    >
                      Approve Name
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={9}>
                    <Mui.Box
                      sx={{
                        width: 250,
                        height: 15,
                        backgroundColor: themes.whiteColor,
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: themes.headLine,
                        p: 2,
                        fontSize: 18,
                        border: `2px solid ${themes.sidebarDivider}`,
                      }}
                    >
                      {viewLoanDetails?.approver_name
                        ? viewLoanDetails?.approver_name
                        : "N/A"}
                    </Mui.Box>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid xs={3} sx={{ paddingTop: 1 }}>
                    <Mui.Typography
                      sx={{ fontWeight: "bold", color: themes.shadowText }}
                    >
                      Designation
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={9}>
                    <Mui.Box
                      sx={{
                        width: 250,
                        height: 15,
                        backgroundColor: themes.whiteColor,
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: themes.headLine,
                        p: 2,
                        fontSize: 18,
                        border: `2px solid ${themes.sidebarDivider}`,
                      }}
                    >
                      {viewLoanDetails?.designation
                        ? viewLoanDetails?.designation
                        : "N/A"}
                    </Mui.Box>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid xs={3} sx={{ paddingTop: 1 }}>
                    <Mui.Typography
                      sx={{ fontWeight: "bold", color: themes.shadowText }}
                    >
                      Approve Date
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={9}>
                    <Mui.Box
                      sx={{
                        width: 250,
                        height: 15,
                        backgroundColor: themes.whiteColor,
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: themes.headLine,
                        p: 2,
                        fontSize: 18,
                        border: `2px solid ${themes.sidebarDivider}`,
                      }}
                    >
                      {viewLoanDetails?.approved_date
                        ? viewLoanDetails?.approved_date
                        : "N/A"}
                    </Mui.Box>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Stack>
              <Mui.Typography
                sx={{
                  paddingLeft: 2,
                  fontWeight: "bold",
                  color: themes.shadowText,
                }}
              >
                Reason for approve
              </Mui.Typography>
            </Mui.Stack>
            <Mui.Grid container sx={{ padding: 2 }}>
              <Mui.Box
                sx={{
                  paddingLeft: 10,
                  width: "100%",
                  backgroundColor: themes.whiteColor,
                  borderRadius: "6px",
                  color: themes.headLine,
                  p: 2,
                  fontSize: 18,
                  border: `2px solid ${themes.sidebarDivider}`,
                }}
              >
                {viewLoanDetails?.approve_reason
                  ? viewLoanDetails?.approve_reason
                  : "N/A"}
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>
          {/* Payment Details */}

          <Mui.Typography
            sx={{ fontWeight: "bold", fontSize: 20, paddingTop: 2 }}
          >
            Payment Details
          </Mui.Typography>
          <Mui.Grid
            container
            component={Mui.Paper}
            sx={{ border: `2px solid ${themes.sidebarDivider}` }}
          >
            <Mui.Grid container>
              <Mui.Grid xs={5}>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                    <Mui.Typography
                      sx={{ fontWeight: "bold", color: themes.shadowText }}
                    >
                      Disbursement Date
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.TextField
                      type="date"
                      size="small"
                      value={disbursementDate}
                      onChange={(event) =>
                        setDisbursementDate(event.target.value)
                      }
                      error={errorHandling && !disbursementDate ? true : false}
                      helperText={
                        errorHandling && !disbursementDate
                          ? "Disbursement date is required"
                          : false
                      }
                      fullWidth
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={5}>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid xs={3} sx={{ paddingTop: 1 }}>
                    <Mui.Typography
                      sx={{ fontWeight: "bold", color: themes.shadowText }}
                    >
                      Re-Payment Date
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={9}>
                    <Mui.TextField
                      type="date"
                      size="small"
                      value={paymentDate}
                      onChange={(event) => setPaymentDate(event.target.value)}
                      error={
                        errorHandling &&
                        (!paymentDate ||
                          new Date(paymentDate) < new Date(disbursementDate))
                          ? true
                          : false
                      }
                      helperText={
                        errorHandling
                          ? !paymentDate
                            ? "Repayment date is required"
                            : new Date(paymentDate) < new Date(disbursementDate)
                            ? "Payment Date is greater then disbursement date"
                            : false
                          : false
                      }
                      fullWidth
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={2}></Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{ fontWeight: "bold", color: themes.shadowText }}
              >
                Disbursement Reason
              </Mui.Typography>
              <Mui.TextField
                multiline
                minRows={3}
                sx={{
                  marginTop: 1,
                  width: "95%",
                  marginLeft: 0,
                  marginRight: "auto",
                }}
                value={disbursementReason}
                onChange={(event) => {
                  setDisbursementReason(event.target.value);
                }}
                error={
                  errorHandling && disbursementReason.length < 3 ? true : false
                }
                helperText={
                  errorHandling && disbursementReason.length < 3
                    ? "Disbursement Reason is atleast 3 characters"
                    : false
                }
                placeholder="please enter reason of disbursement"
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              padding: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Mui.Grid item sx={{ padding: 1 }}>
              <CustomButton
                actionFuntion={() => {
                  setOpenRejectDialog(true);
                }}
                actionName="Reject"
                typeName="button"
              />
            </Mui.Grid>
            <Mui.Grid item sx={{ padding: 1 }}>
              <CustomButton
                actionFuntion={disbursementTheLoan}
                actionName="Disburse"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      )}
      <GlobalDialogBox
        handleCloseDialog={handleCloseRejectDialogBox}
        open={openRejectDialog}
        title="Reject Loan"
      >
        <Mui.Grid container sx={{ padding: 1 }}>
          <Mui.TextField
            sx={{
              marginTop: 1,
              width: "100%",
            }}
            label="Provide a reason for reject loan"
            multiline
            rows={3}
            value={reasonForRejectTxt}
            onChange={(event) => setReasonForRejectTxt(event.target.value)}
          />
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => {
                handleCloseRejectDialogBox();
              }}
              actionName="Cancel"
              typeName="button"
            />
          </Mui.Grid>
          &nbsp;&nbsp;
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => {
                rejectCurrentLoan();
              }}
              actionName="Reject"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
