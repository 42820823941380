import React, { useState } from "react";
import * as Mui from "@mui/material";
import { Box } from "@material-ui/core";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import moment from "moment";

export const Weekly = ({ loading, weeklyShiftList }) => {
  const [page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  const checkDateWithMoment = (inputDate) => {
    const givenDate = moment(inputDate, "DD-MM-YYYY");
    const currentDate = moment();
    if (givenDate.isSameOrAfter(currentDate, "day")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <Mui.Grid container sx={{ paddingRight: 1 }}>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{
              mt: 2,
              width: "100%",
              border: 1,
              borderRadius: 5,
              borderColor: themes.primary,
            }}
          >
            <Mui.Table>
              <Mui.TableHead>
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                    align="center"
                  >
                    Date
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                    align="center"
                  >
                    Shift & Time
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                    align="center"
                  >
                    Work Unit
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {weeklyShiftList?.length === 0 ? (
                  <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                    No data found
                  </Mui.Alert>
                ) : (
                  weeklyShiftList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((shiftList, index) => (
                      <React.Fragment>
                        {shiftList?.shifts.map((shift, i) => (
                          <Mui.TableRow
                            key={index}
                            sx={{
                              backgroundColor:
                                shift?.date ===
                                new Date()
                                  .toISOString()
                                  .split("T")[0]
                                  .split("-")
                                  .reverse()
                                  .join("-")
                                  ? themes.primary
                                  : "inherit",
                            }}
                          >
                            {/* hover={true} */}
                            <Mui.TableCell
                              sx={{ border: 1, borderColor: themes.primary }}
                            >
                              <Mui.Stack
                                direction="column"
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                <Mui.Typography
                                  sx={{
                                    color: checkDateWithMoment(shift?.date)
                                      ? themes.blackColor
                                      : "gray",
                                    fontWeight: "bold",
                                    fontSize: 24,
                                  }}
                                >
                                  {moment(shift?.date, "DD-MM-YYYY").format(
                                    "DD"
                                  )}
                                </Mui.Typography>
                                <Mui.Typography
                                  sx={{
                                    color: checkDateWithMoment(shift?.date)
                                      ? themes.blackColor
                                      : "gray",
                                    fontWeight: "bold",
                                    fontSize: 12,
                                  }}
                                >
                                  {moment(shift?.date, "DD-MM-YYYY").format(
                                    "ddd, MMM"
                                  )}
                                </Mui.Typography>
                              </Mui.Stack>
                            </Mui.TableCell>

                            <Mui.TableCell
                              sx={{
                                fontSize: 13,
                                border: 1,
                                borderColor: themes.primary,
                              }}
                              style={{
                                color: checkDateWithMoment(shift?.date)
                                  ? themes.blackColor
                                  : "gray",
                              }}
                            >
                              <Mui.Stack
                                direction="column"
                                width={"100%"}
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                {shift?.shift_name === null ||
                                shift?.shift_name === "Weekoff" ? (
                                  ""
                                ) : shift?.shift_start_time &&
                                  shift?.shift_end_time ? (
                                  <>
                                    <Mui.Typography sx={{ fontSize: 12 }}>
                                      {moment(
                                        shift?.shift_start_time,
                                        "HH:mm:SS"
                                      ).format("h:mm a")}
                                      {" to "}{" "}
                                      {moment(
                                        shift?.shift_end_time,
                                        "HH:mm:SS"
                                      ).format("h:mm a")}
                                    </Mui.Typography>
                                  </>
                                ) : null}
                                <Mui.Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    backgroundColor: shift?.color_code,
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                    display: "inline-block",
                                  }}
                                >
                                  {shift?.shift_name
                                    ? shift?.shift_name
                                    : "Not Joined"}
                                </Mui.Typography>
                              </Mui.Stack>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{ border: 1, borderColor: themes.primary }}
                              align="center"
                            >
                              {/* <Mui.Typography
                                sx={{ fontSize: 12, fontWeight: "bold" }}
                                style={{
                                  color: checkDateWithMoment(shift?.date)
                                    ? themes.blackColor
                                    : "gray",
                                }}
                              >
                                {shift?.unit ? shift?.unit : 0}
                              </Mui.Typography> */}
                              {(shift?.additional_units?.total_count === 0 &&
                                shift?.default_units?.total_count === 0) ||
                              shift?.shift_name === "Weekoff" ? (
                                <Mui.Typography>N/A</Mui.Typography>
                              ) : (
                                <Mui.Stack>
                                  <Mui.Typography
                                    sx={{
                                      textAlign: "center",
                                      fontSize: {
                                        xs: 12,
                                        sm: 12,
                                        md: 15,
                                        lg: 15,
                                      },
                                    }}
                                  >
                                    {/* {shift?.default_units ? ( */}
                                    <Mui.Tooltip
                                      title={
                                        <>
                                          <Mui.Typography sx={{ fontSize: 15 }}>
                                            Default Work Unit
                                          </Mui.Typography>
                                          {shift?.default_units?.details?.map(
                                            (data) => (
                                              <Mui.Grid
                                                container
                                                sx={{
                                                  borderTop: `2px solid ${themes.sidebarDivider}`,
                                                  borderBottom: `2px solid ${themes.sidebarDivider}`,
                                                }}
                                              >
                                                <Mui.Grid xs={6}>
                                                  <Mui.Typography>
                                                    {data?.work_unit_name}
                                                  </Mui.Typography>
                                                </Mui.Grid>
                                                <Mui.Grid xs={6}>
                                                  <Mui.Typography>
                                                    {data?.number_of_units}
                                                  </Mui.Typography>
                                                </Mui.Grid>
                                              </Mui.Grid>
                                            )
                                          )}
                                        </>
                                      }
                                    >
                                      {shift?.default_units?.total_count
                                        ? shift?.default_units?.total_count
                                        : 0}
                                      {" + "}
                                    </Mui.Tooltip>
                                    {/* ) : null} */}
                                    {/* {shift?.additional_units
                                          ?.total_count ? ( */}
                                    <Mui.Tooltip
                                      title={
                                        <>
                                          <Mui.Typography sx={{ fontSize: 15 }}>
                                            Additional Work Unit
                                          </Mui.Typography>
                                          {shift?.additional_units?.details?.map(
                                            (data) => (
                                              <Mui.Grid
                                                container
                                                sx={{
                                                  borderTop: `2px solid ${themes.sidebarDivider}`,
                                                  borderBottom: `2px solid ${themes.sidebarDivider}`,
                                                }}
                                              >
                                                <Mui.Grid xs={6}>
                                                  <Mui.Typography>
                                                    {data?.work_unit_name}
                                                  </Mui.Typography>
                                                </Mui.Grid>
                                                <Mui.Grid xs={6}>
                                                  <Mui.Typography>
                                                    {data?.number_of_units}
                                                  </Mui.Typography>
                                                </Mui.Grid>
                                              </Mui.Grid>
                                            )
                                          )}
                                        </>
                                      }
                                    >
                                      {shift?.additional_units?.total_count
                                        ? shift?.additional_units?.total_count
                                        : 0}
                                    </Mui.Tooltip>
                                    {/* ) : null} */}
                                  </Mui.Typography>
                                </Mui.Stack>
                              )}
                            </Mui.TableCell>
                          </Mui.TableRow>
                        ))}
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {weeklyShiftList.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={weeklyShiftList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.Grid>
      )}
    </React.Fragment>
  );
};
