import * as React from "react";
import { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useForm, Controller } from "react-hook-form";
// import { CalendarMonth } from "@mui/icons-material";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { format, parse } from "date-fns";
import { leaveSuccess } from "services/constants/SuccessMessages";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import sessionHandling from "services/utils/notificationUtils";
import moment from "moment";

export const Permission = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState,
    trigger,
  } = useForm({
    mode: "onBlur",
    start_time: null,
    end_time: null,
  });
  const { errors } = formState;
  const { token, domain, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [startTimeSelected, setStartTimeSelected] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [leaveStartDate, setLeaveStartDate] = useState("");
  const [leaveEndDate, setLeaveEndDate] = useState("");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const onSubmit = async (data) => {
    const formatTime = (time) => {
      return `${String(time.$H).padStart(2, "0")}:${String(time.$m).padStart(
        2,
        "0"
      )}:${String(time.$s).padStart(2, "0")}`;
    };

    const startTime = data.time_range[0]
      ? formatTime(data.time_range[0])
      : null;
    const endTime = data.time_range[1] ? formatTime(data.time_range[1]) : null;
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}create_permission/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          date: data.from_date,
          description: data.description,
          user_id: userDetails.id,
          applied_date: new Date().toISOString().split("T")[0],
          start_time: startTime,
          end_time: endTime,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", leaveSuccess.leavePermission);
        setButtonLoader(false);
        if (
          new Date(leaveStartDate).getFullYear() === new Date().getFullYear() ||
          new Date(leaveEndDate).getFullYear() === new Date().getFullYear()
        ) {
          const today = dayjs().format("YYYY-MM-DD");
          setStartDate(today);
        } else {
          setStartDate(leaveStartDate);
        }
        reset();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setButtonLoader(false);
    }
  };

  const validateTimeRange = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return "Both start and end times are required.";
    }

    const start = dayjs(startTime);
    const end = dayjs(endTime);

    if (start.isAfter(end)) {
      return "End time cannot be before start time.";
    }

    const durationHours = end.diff(start, "hour", true);
    if (durationHours > 2) {
      return "Duration cannot be more than 2 hours.";
    }

    return true;
  };

  const validateStartDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
  };

  const resetForm = () => {
    reset();
    const today = dayjs().format("YYYY-MM-DD");
    setStartDate(today);
    setValue("from_date", today);
    setValue("to_date", today);
  };
  const handleClearButtonClick = () => {
    resetForm();
  };

  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const today = dayjs().format("YYYY-MM-DD");
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setLeaveStartDate(sDate);
          setLeaveEndDate(eDate);
          if (
            new Date(
              res[0]?.start_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear() ||
            new Date(
              res[0]?.end_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear()
          ) {
            setStartDate(today);
          } else {
            setStartDate(sDate);
          }
          setValue("from_date", today);
          setValue("to_date", today);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, setValue, token]);

  const updateLeavePeriod = (event, value) => {
    const today = dayjs().format("YYYY-MM-DD");
    if (value && value.filteredValue) {
      setFilteredLabel(value.label);
      setFilteredValue(value.filteredValue);
      setStartDate(formatDate(value.start_date));
      setLeaveStartDate(formatDate(value.start_date));
      setLeaveEndDate(formatDate(value.end_date));
      if (
        new Date(value.start_date).getFullYear() === new Date().getFullYear() ||
        new Date(
          value.end_date.split("-").reverse().join("-")
        ).getFullYear() === new Date().getFullYear()
      ) {
        let startDateConvertions = moment(new Date()).format("DD-MM-YYYY");
        setStartDate(formatDate(startDateConvertions));
        setValue("from_date", formatDate(startDateConvertions));
        setValue("to_date", formatDate(startDateConvertions));
      } else {
        setStartDate(formatDate(value.start_date));

        setValue("from_date", formatDate(value.start_date));
        setValue("to_date", formatDate(value.end_date));
      }
    } else {
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate("");
      setLeaveEndDate("");
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate(today);
      setLeaveEndDate(today);
      setStartDate(today);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid sx={{ paddingLeft: 10, paddingRight: 10, mt: 8 }}>
          <Mui.Grid container>
            <Mui.Grid
              xs={12}
              sx={{
                padding: 1,
                backgroundColor: themes.primary,
                borderRadius: "10px 10px 0 0",
                border: `2px solid ${themes.sidebarDivider}`,
                borderBottom: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
              >
                Apply Permission
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            sx={{
              borderRadius: "0 0 10px 10px",
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
            }}
          >
            <Mui.Grid container justifyContent={"flex-end"}>
              <Mui.Grid xs={3} sx={{ padding: 1 }}>
                <CustomAutoComplete
                  dataList={leaveDate}
                  selectedValue={filteredValue}
                  updateDataFunction={updateLeavePeriod}
                  placeHolder="Select leave period"
                  label={filteredLabel ? filteredLabel : "Leave Period"}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  fullWidth
                  size="small"
                  type="date"
                  id="from_date"
                  value={startDate}
                  inputProps={{
                    min: leaveStartDate,
                    max: leaveEndDate,
                  }}
                  {...register("from_date", {
                    required: "Date is required.",
                    validate: validateStartDate,
                  })}
                  error={errors.from_date}
                  helperText={errors.from_date && errors.from_date.message}
                  onChange={(event) => {
                    setStartDate(event.target.value);
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Start Time
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="time_range"
                    rules={{
                      validate: (value) => {
                        if (!value || value[0] === null || value[1] === null) {
                          return "Both start and end times are required.";
                        }
                        const startTime = dayjs(value[0]);
                        const endTime = dayjs(value[1]);
                        return validateTimeRange(startTime, endTime);
                      },
                    }}
                    control={control}
                    defaultValue={[null, null]}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <TimePicker
                          value={value[0]}
                          fullWidth
                          onChange={(newValue) => {
                            onChange([newValue, value[1]]);
                            setStartTimeSelected(true);
                          }}
                          error={error}
                          helperText={error ? error.message : null}
                          sx={{
                            width: "100%",
                            ".MuiInputBase-root": {
                              height: "39px",
                              fontSize: "16px",
                            },
                            ".MuiFormHelperText-root": {
                              marginTop: "2px",
                            },
                          }}
                          TextFieldComponent={(params) => (
                            <Mui.TextField
                              {...params}
                              fullWidth
                              size="small"
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                          onBlur={() => {
                            trigger("time_range");
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                        {error && (
                          <Mui.Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: 13, paddingLeft: 1.5 }}
                          >
                            {error.message}
                          </Mui.Typography>
                        )}
                      </>
                    )}
                  />
                </LocalizationProvider>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  End Time
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="time_range"
                    rules={{
                      validate: (value) => {
                        if (!value || value[0] === null || value[1] === null) {
                          return "Both start and end times are required.";
                        }
                        const startTime = dayjs(value[0]);
                        const endTime = dayjs(value[1]);
                        return validateTimeRange(startTime, endTime);
                      },
                    }}
                    control={control}
                    defaultValue={[null, null]}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <TimePicker
                          value={value[1]}
                          onChange={(newValue) =>
                            onChange([value[0], newValue])
                          }
                          error={error}
                          helperText={error ? error.message : null}
                          sx={{
                            width: "100%",
                            ".MuiInputBase-root": {
                              height: "39px",
                              fontSize: "16px",
                            },
                            ".MuiFormHelperText-root": {
                              marginTop: "2px",
                            },
                          }}
                          TextFieldComponent={(params) => (
                            <Mui.TextField
                              {...params}
                              fullWidth
                              sx={{ width: "100%" }}
                            />
                          )}
                          onBlur={() => {
                            trigger("time_range");
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disabled={!startTimeSelected}
                        />
                        {error && (
                          <Mui.Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: 13, paddingLeft: 1.5 }}
                          >
                            {error.message}
                          </Mui.Typography>
                        )}
                      </>
                    )}
                  />
                </LocalizationProvider>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Reason for Permission
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  fullWidth
                  id="description"
                  {...register("description", {
                    required: "Reason for permission is required",
                  })}
                  placeholder="Provide a reason for your permission"
                  multiline
                  rows={2}
                  error={errors.description}
                  helperText={errors.description && errors.description.message}
                  onBlur={() => {
                    trigger("description");
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container spacing={3} sx={{ paddingTop: 1 }}>
              <Mui.Grid item xs={8}></Mui.Grid>
              <Mui.Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={handleClearButtonClick}
                  actionName="Clear"
                  typeName="button"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionName="Apply"
                  typeName="submit"
                  disableAction={buttonLoader}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </>
  );
};
