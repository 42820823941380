import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { MoreVert } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CreateShift } from "./CreateShift";
import { EditShiftAssign } from "./EditShiftAssign";
import { Loader } from "components/Loader";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { Delete, Edit } from "@mui/icons-material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { AdminShiftCategoryHeader } from "pages/Administration/AdministrationHeaders";
const keys = [
  "shift_category_name",
  "shift_cycle",
  "no_of_cycle",
  "start_date",
  "end_date",
];

export const ShiftAssign = ({
  companyId,
  addShiftCategory,
  setAddShiftCategory,
  setShowTopBar,
  setEditShiftCategory,
  editShiftCategory,
}) => {
  const { domain, globalSearchValue, token } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    result: shiftCategoryData,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}shift-assignment/`);
  const [shiftCategoryDetails, setShiftCategoryDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selSftAsgnData, setSelSftAsgnData] = useState("");
  const [showEditShiftAsgn, setShowEditShiftAsgn] = useState(false);
  const [shftAsgnPro, setShftAsgnPro] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (updateData && shiftCategoryData?.length !== 0 && shiftCategoryData) {
      setShiftCategoryDetails(shiftCategoryData);
      setUpdateData(false);
    }
  }, [shiftCategoryData, updateData]);

  useEffect(() => {
    if (shiftCategoryData?.length !== 0 || shiftCategoryData?.length === 0) {
      const filteredResults = shiftCategoryData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setShiftCategoryDetails(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [globalSearchValue, page, shiftCategoryData]);

  const deleteShiftGroup = async (account) => {
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}shift-group/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          is_deleted: true,
          shift_group_id: account.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Shift group deleted successfully");
        setEditShiftCategory(false);
        setOpenDialog(false);
        reCallApi(true);
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {addShiftCategory ? (
        <CreateShift
          companyId={companyId}
          setAddShiftCategory={setAddShiftCategory}
          reCallApi={reCallApi}
          setShowTopBar={setShowTopBar}
        />
      ) : loading ? (
        <Loader />
      ) : showEditShiftAsgn ? (
        <EditShiftAssign
          reCallApi={reCallApi}
          updateShowAddShiftAsgn={setShowEditShiftAsgn}
          shftAsgnPro={shftAsgnPro}
          selSftAsgnData={selSftAsgnData}
          setShowTopBar={setShowTopBar}
        />
      ) : (
        <React.Fragment>
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "3px 15px",
                    },
                  }}
                >
                  {AdminShiftCategoryHeader.map((head) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {head.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {shiftCategoryDetails.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={8} align="center">
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  shiftCategoryDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((account, index) => (
                      <Mui.TableRow
                        key={account.id}
                        sx={{
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "6px 16px",
                          },
                        }}
                      >
                        {AdminShiftCategoryHeader.map((head, headIndex) => (
                          <Mui.TableCell key={`${account.id}-${headIndex}`}>
                            {head.key === "actions" ? (
                              // <Mui.Stack direction="row">
                              //   <Mui.IconButton
                              //     onClick={(e) => {
                              //       setAnchorEl(e.currentTarget);
                              //       setSelSftAsgnData(account);
                              //     }}
                              //     // disabled={
                              //     //   account?.status === "Completed"
                              //     //     ? true
                              //     //     : false
                              //     // }
                              //   >
                              //     <MoreVert />
                              //     <Mui.Menu
                              //       anchorEl={anchorEl}
                              //       open={Boolean(anchorEl)}
                              //       onClose={handleMenuClose}
                              //       elevation={1}
                              //       transformOrigin={{
                              //         horizontal: "right",
                              //         vertical: "top",
                              //       }}
                              //       anchorOrigin={{
                              //         horizontal: "right",
                              //         vertical: "bottom",
                              //       }}
                              //     >
                              //       <Mui.MenuItem
                              //         onClick={() => {
                              //           setShftAsgnPro("View");
                              //           setShowEditShiftAsgn(true);
                              //           setShowTopBar(false);
                              //           handleMenuClose();
                              //         }}
                              //       >
                              //         View
                              //       </Mui.MenuItem>
                              //     </Mui.Menu>
                              //   </Mui.IconButton>
                              // </Mui.Stack>
                              <Mui.Stack direction="row">
                                <Mui.IconButton
                                  onClick={() => {
                                    setSelSftAsgnData(account);
                                    setShowEditShiftAsgn(true);
                                    setShowTopBar(false);
                                  }}
                                >
                                  <RemoveRedEyeIcon sx={{ fontSize: "10" }} />
                                </Mui.IconButton>
                              </Mui.Stack>
                            ) : (
                              account[head.key] || "N/A"
                            )}
                          </Mui.TableCell>
                        ))}
                      </Mui.TableRow>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {shiftCategoryDetails?.length > 25 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={shiftCategoryDetails?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}

      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete Shift Group"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 20, font: "bold" }}>
          Are you sure, you want to delete the &nbsp;
          <span style={{ color: themes.redColor }}>
            {editShiftCategory?.name}
          </span>
          ?
        </Mui.Typography>

        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => deleteShiftGroup(editShiftCategory)}
              actionName="Yes"
              typeName="button"
              disableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
