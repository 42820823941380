import React from "react";
import { themes } from "services/constants";
import { LoadingButton } from "@mui/lab";

export const CustomButton = ({
  actionFuntion,
  actionName,
  typeName,
  disableAction,
}) => {
  return (
    <LoadingButton
      onClick={actionFuntion}
      sx={{
        background: themes.primaryButton,
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: themes.primaryButton,
        },
        color: themes.headLine,
        fontWeight:'bold'
      }}
      type={typeName}
      variant="contained"
      size="medium"
      loading={disableAction}
      // loadingPosition="end"
      disabled={disableAction}
    >
      {actionName}
    </LoadingButton>
  );
};
