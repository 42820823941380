import React, { useState, useContext } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { MoreVert } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { ProjectDashboardContext } from "./DashboardContext";
import { CreateSprint } from "./CreateSprint";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { CustomButton } from "components/CustomComponents/CustomButton";

export const ProDashboard = () => {
  const history = useHistory();
  const { projectData, dataLoading } = useContext(ProjectDashboardContext);
  const editProject = useSelector((state) => state.leaveReducer.editproject);
  const [open, setOpen] = useState(false);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [optName, setOptName] = useState("Add");
  const [sprintDetails, setSprintDetails] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const navigateSprintDashboard = () => {
    localStorage.setItem("sprDashboard", true);
    localStorage.setItem("sprDashData", JSON.stringify(sprintDetails));
    history.push("/admin/project/sprint/dashboard");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2 }}>
        <Mui.Grid xs={11}>
          <Mui.Stack direction="row">
            <BackArrow
              actionFunction={() => {
                localStorage.setItem("proDashboard", false);
                localStorage.removeItem("projectData");
                history.goBack();
              }}
            />
            <Mui.Typography
              sx={{
                color: themes.outTap,
                fontSize: 28,
                fontWeight: "bold",
                paddingLeft: 3,
                paddingTop: 0.5,
              }}
            >
              Project Dashboard
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid xs={0.7} sx={{ paddingTop: 0.5 }}>
          <CustomButton
            actionName="Add Sprint"
            typeName="button"
            actionFuntion={() => {
              setOptName("Add");
              setSprintDetails(null);
              setOpen(true);
            }}
            disabled={
              editProject?.status?.status !== "Completed" &&
              editProject?.status?.status !== "Inactive"
                ? false
                : true
            }
          />
        </Mui.Grid>
      </Mui.Grid>
      <CreateSprint
        open={open}
        setOpen={setOpen}
        editProject={editProject}
        option={optName}
        sprintDetails={sprintDetails}
      />
      {projectData.length > 0 ? (
        <>
          <Mui.Stack sx={{ paddingLeft: 6, paddingRight: 6, paddingTop: 2 }}>
            <Mui.Card sx={{ borderRadius: 5 }} elevation={5}>
              <Mui.Grid container sx={{ padding: 3.5 }}>
                <Mui.Grid sm={12} md={4} lg={4}>
                  <Mui.Stack direction="row">
                    <Mui.Grid container>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Name
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={1}>
                        <Mui.Typography>:</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={5.5}>
                        <Mui.Tooltip title={projectData[0]?.projectname}>
                          <Mui.Typography
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {projectData[0]?.projectname
                              ? projectData[0]?.projectname
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Tooltip>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid sm={12} md={4} lg={4}>
                  <Mui.Stack direction="row">
                    <Mui.Grid container>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Code
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={1}>
                        <Mui.Typography>:</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {projectData[0]?.project_code
                            ? projectData[0]?.project_code
                            : "N/A"}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid sm={12} md={4} lg={4}>
                  <Mui.Stack direction="row">
                    <Mui.Grid container>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Type
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={1}>
                        <Mui.Typography>:</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {projectData[0]?.projectType
                            ? projectData[0]?.projectType
                            : "N/A"}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Stack>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Divider />
              <Mui.Grid container sx={{ padding: 3.5 }}>
                <Mui.Grid sm={12} md={4} lg={4}>
                  <Mui.Stack direction="row">
                    <Mui.Grid container>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Start Date
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={1}>
                        <Mui.Typography>:</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {projectData[0]?.date ? projectData[0]?.date : "N/A"}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid sm={12} md={4} lg={4}>
                  <Mui.Stack direction="row">
                    <Mui.Grid container>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Total Task
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={1}>
                        <Mui.Typography>:</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {projectData[0]?.total_tasks
                            ? projectData[0]?.total_tasks
                            : "N/A"}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid sm={12} md={4} lg={4}>
                  <Mui.Stack direction="row">
                    <Mui.Grid container>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Completed Task
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={1}>
                        <Mui.Typography>:</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {projectData[0]?.completed_tasks
                            ? projectData[0]?.completed_tasks
                            : "N/A"}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Stack>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Card>
          </Mui.Stack>
          <br />
          <Mui.Grid xs={12}>
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{
                  fontSize: 28,
                  fontWeight: "bold",
                  paddingLeft: 10,
                  paddingTop: 0.5,
                }}
              >
                Sprint Details
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          {projectData[0]?.sprints?.length > 0 ? (
            <Mui.TableContainer
              sx={{
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 2,
                width: "100%",
              }}
            >
              <Mui.Table>
                <Mui.TableHead
                  sx={{
                    backgroundColor: themes.primary,
                  }}
                >
                  <Mui.TableRow maxWidth="xl" align="left">
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.outTap,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Sprint Name
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.outTap,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Goal
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.outTap,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Start Date
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.outTap,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      End Date
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.outTap,
                        fontWeight: "bold",
                      }}
                    >
                      Total Task
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.outTap,
                        fontWeight: "bold",
                      }}
                    >
                      Completed Task
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.outTap,
                        fontWeight: "bold",
                      }}
                    >
                      Hours Spend
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.outTap,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Status
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.outTap,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Action
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {projectData[0]?.sprints
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((spr, index) => (
                      <Mui.TableRow key={spr.id} hover={true}>
                        <Mui.Tooltip title={spr?.name}>
                          <Mui.TableCell
                            sx={{
                              fontSize: 14,
                              maxWidth: 100,
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {spr?.name ? spr?.name : "N/A"}
                          </Mui.TableCell>
                        </Mui.Tooltip>
                        <Mui.Tooltip title={spr?.description}>
                          <Mui.TableCell
                            sx={{
                              fontSize: 14,
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            {spr?.description ? spr?.description : "N/A"}
                          </Mui.TableCell>
                        </Mui.Tooltip>
                        <Mui.TableCell
                          sx={{
                            fontSize: 14,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          {spr?.start_date ? spr?.start_date : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: 14,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          {spr?.end_date ? spr?.end_date : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: 14,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          {spr?.total_tasks ? spr?.total_tasks : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: 14,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          {spr?.completed_tasks ? spr?.completed_tasks : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: 14,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          {spr?.total_time_spent
                            ? spr?.total_time_spent
                            : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: 14,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          {spr?.status ? spr?.status : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: 14,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          <Mui.IconButton
                            onClick={(event) => {
                              setSprintDetails(spr);
                              setActionAnchorEl(event.currentTarget);
                            }}
                          >
                            <MoreVert
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={actionAnchorEl}
                            open={Boolean(actionAnchorEl)}
                            onClose={() => setActionAnchorEl(null)}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.Stack>
                              <Mui.MenuItem
                                key={"View"}
                                onClick={() => {
                                  navigateSprintDashboard();
                                  setActionAnchorEl(null);
                                }}
                              >
                                View Sprint
                              </Mui.MenuItem>
                            </Mui.Stack>
                          </Mui.Menu>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ))}
                </Mui.TableBody>
              </Mui.Table>

              {projectData[0]?.sprints?.length > 25 && (
                <Mui.TablePagination
                  className="custom-pagination"
                  component="div"
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  count={projectData[0]?.sprints?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Mui.TableContainer>
          ) : (
            <div style={{ paddingTop: 8 }}>
              <Mui.Alert severity="info">No data available</Mui.Alert>
            </div>
          )}
        </>
      ) : dataLoading ? (
        <Loader info="Loading..." />
      ) : (
        <div style={{ paddingTop: 8 }}>
          <Mui.Alert severity="info">No data available</Mui.Alert>
        </div>
      )}
    </React.Fragment>
  );
};
