import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import {
  WatchLater,
  Payments,
  CreditScore,
  PersonRemove,
  AccountBalanceWallet,
  AccessibilityNew,
  Timelapse,
} from "@mui/icons-material";

export const SettingsMenu = ({
  setCurrentScreen,
  currentScreen,
  loanScreen,
  setLoanScreen,
}) => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Grid
        sx={{
          marginTop: 2,
          height: currentScreen === "Loans" && loanScreen ? "100%" : "92.5vh",
          overflow: "hidden",
          borderRadius: 2,
          backgroundColor: themes.primary,
        }}
        component={Paper}
        elevation={3}
      >
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Leaves" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Leaves" ? themes.whiteColor : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Leaves");
              setCurrentScreen("Leaves");
              dispatch(setGlobalSearchValue(""));
              setLoanScreen(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <PersonRemove sx={{ fontSize: 20 }} /> Leaves
            </Typography>
          </Paper>
        </Grid>

        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Permissions" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Permissions"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              dispatch(setDepartmentId(99999));
              dispatch(setDepartmentName("All"));
              localStorage.setItem("approveSettingScreens", "Permissions");
              setCurrentScreen("Permissions");
              dispatch(setGlobalSearchValue(""));
              setLoanScreen(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <Timelapse sx={{ fontSize: 20 }} /> Permissions
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Over time" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Over time"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Over time");
              setCurrentScreen("Over time");
              setLoanScreen(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <WatchLater sx={{ fontSize: 20 }} /> Over Time
            </Typography>
          </Paper>
        </Grid>

        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Advance Salary" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Advance Salary"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Advance Salary");
              setCurrentScreen("Advance Salary");
              dispatch(setGlobalSearchValue(""));
              setLoanScreen(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <Payments sx={{ fontSize: 20 }} /> Advance Salary
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Loans" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Loans" ? themes.whiteColor : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Loans");
              setCurrentScreen("Loans");
              dispatch(setGlobalSearchValue(""));
              setLoanScreen(true);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <CreditScore sx={{ fontSize: 20 }} /> Loans
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Expense" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Expense"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Expense");
              setCurrentScreen("Expense");
              dispatch(setGlobalSearchValue(""));
              setLoanScreen(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <AccountBalanceWallet sx={{ fontSize: 20 }} /> Expense
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Week Off" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Week Off"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Week Off");
              setCurrentScreen("Week Off");
              dispatch(setGlobalSearchValue(""));
              setLoanScreen(false);
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <AccessibilityNew sx={{ fontSize: 20 }} /> Time Off Policy
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
