import React from "react";
import { ProDashboard } from "./Dashboard";
import { ProjectDashboardContextProvider } from "./DashboardContext";

export const ProjectDashbard = () => {
  return (
    <React.Fragment>
      <ProjectDashboardContextProvider>
        <ProDashboard />
      </ProjectDashboardContextProvider>
    </React.Fragment>
  );
};
