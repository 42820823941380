import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { customAPI } from "services/utils/CustomAPI";
import { useFetch } from "services/hooks/useFetch";
import { monthNames } from "pages/PayRoll/PayRollHeaders";
import { onlyNumberRegex } from "services/constants/patterns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const days_of_week = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const NewPaySchedule = ({
  companyId,
  companyName,
  setCreateNewPaySchedule,
  reCallApi,
  reCallApiWithApproval,
  setViewPayRunScreen,
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [payrunName, setPayrunName] = useState("");
  const [payrunCycleName, setPayrunCycleName] = useState("");
  const { domain } = useSelector((state) => state.tokenReducer);
  const [payrunCreatedCount, setPayrunCreatedCount] = useState("");
  const [updatePayrunCycle, setUpdatePayrunCycle] = useState(true);
  const [payrunCycleDetails, setPayrunCycleDetails] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [dayCount, setDayCount] = useState(0);
  const [payCycle, setPayCycle] = useState("");
  const [payCycleDateDay, setPayCycleDateDay] = useState("");
  const [pickerDateError, setPickerDateError] = useState(false);
  const { result: payrunCycleData, loading: payrunCycleLoading } = useFetch(
    `${domain}pay-run-cycles/`
  );

  useEffect(() => {
    const addnewLabelKeyPayrunCycle = () => {
      let data = payrunCycleData.map((pay) => {
        return {
          ...pay,
          label: pay.name,
        };
      });
      setPayrunCycleDetails(data);
      setUpdatePayrunCycle(false);
    };
    if (updatePayrunCycle && !payrunCycleLoading) {
      addnewLabelKeyPayrunCycle();
    }
  });

  const handlePayRunName = (event) => {
    if (event.target.value.length <= 32) {
      setValue("name", event.target.value);
      setPayrunName(event.target.value);
    }
  };

  const handlePayrunStartDate = (date) => {
    setValue("start_date", date);
    if (date) {
      let dateValue = moment(date).format("YYYY-MM-DD");
      let payrunNameDetails = payrunCycleName
        ? payrunCycleName +
          "-" +
          dateValue?.split("-")[2] +
          "th-" +
          getMonthName(dateValue?.split("-")[1]).slice(0, 3) +
          dateValue?.split("-")[0]
        : dateValue?.split("-")[2] +
          "th-" +
          getMonthName(dateValue?.split("-")[1]).slice(0, 3) +
          dateValue?.split("-")[0];
      setValue("name", payrunNameDetails);
      setPayrunName(payrunNameDetails);
    }
  };

  const onSubmitFormHandle = async (data) => {
    setButtonDisabled(true);
    setPickerDateError(false);
    const options = { weekday: "long" }; // Set the options to get the full day name
    const dayName = new Intl.DateTimeFormat("en-US", options).format(
      data.start_date
    );
    let checkDate =
      payCycle === "Monthly"
        ? true
        : dayName === payCycleDateDay
        ? true
        : false;
    if (checkDate) {
      let payload = {
        ...data,
        company: companyId,
        start_date: moment(data.start_date).format("YYYY-MM-DD"),
      };
      const response = await customAPI(
        `${domain}payschedule/`,
        "POST",
        payload
      );
      if (response.ok) {
        showToast("success", "Payschedule created successfully");
        setCreateNewPaySchedule(false);
        reCallApi(true);
        reCallApiWithApproval(true);
        setViewPayRunScreen(false);
      }
      setButtonDisabled(false);
    } else {
      setButtonDisabled(false);
      setPickerDateError(true);
    }
  };

  const getMonthName = (date) => {
    return monthNames[+date - 1];
  };

  const handleCycleName = (event, data) => {
    if (data) {
      setPayrunCycleName(data?.label);
      setValue("payrun_cycle", data?.label);
      setValue("pay_run_cycle", data.id);
      let dateValue = moment(getValues("start_date")).format("YYYY-MM-DD");
      setPayCycleDateDay(
        data?.pay_run_cycle_type === "Monthly"
          ? data?.date_of_month
          : data?.day_of_week
      );
      setPayCycle(data?.pay_run_cycle_type);
      let payrunNameDetails = dateValue
        ? data?.label +
          "-" +
          dateValue?.split("-")[2] +
          "th-" +
          getMonthName(dateValue?.split("-")[1]).slice(0, 3) +
          dateValue?.split("-")[0]
        : data?.label;
      setValue("name", payrunNameDetails);
      setPayrunName(payrunNameDetails);
      if (data?.pay_run_cycle_type !== "Monthly") {
        setDayCount(days_of_week.indexOf(data?.day_of_week));
      }
    }
  };

  const updatePayrunCreatedCount = (event) => {
    if (
      event.target.value.length <= 2 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setPayrunCreatedCount(event.target.value);
      setValue("num_of_cycles", event.target.value);
    }
  };

  const disableNonTuesdays = (date) => {
    return payCycle === "Monthly" ? null : date.getDay() !== dayCount;
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingTop: 1.5,
          paddingBottom: {
            xs: 5,
            sm: 5,
            md: 10,
            lg: 10,
            xl: 10,
          },
        }}
      >
        <BackArrow
          actionFunction={() => {
            setCreateNewPaySchedule(false);
            setViewPayRunScreen(false);
          }}
        />
        &nbsp;
        <Mui.Typography
          sx={{
            fontSize: 25,
            fontWeight: "bold",
            color: themes.blackColor,
            paddingTop: 1,
          }}
        >
          Add Payrun Schedule
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmitFormHandle)}>
        <Mui.Grid
          container
          sx={{
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 24,
                fontWeight: 800,
                font: "bold",
                textAlign: "center",
              }}
            >
              Create Payrun Schedule
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              paddingTop: -1,
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
              borderRadius: "0 0 10px 10px",
            }}
          >
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payrun Cycle
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={payrunCycleDetails}
                  rules={{
                    ...register("payrun_cycle", {
                      required: "Payrun cycle is required",
                    }),
                  }}
                  selectedValue={payrunCycleName}
                  updateDataFunction={handleCycleName}
                  placeHolder="Select pay cycle"
                  errorMessage={
                    errors?.payrun_cycle?.message
                      ? errors?.payrun_cycle?.message
                      : ""
                  }
                  textBoxError={errors?.payrun_cycle ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            {payCycle !== "" ? (
              <Mui.Grid container sx={{ paddingTop: 2 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Pay Cycle
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.Grid container>
                    <Mui.Grid xs={4}>{payCycle}</Mui.Grid>
                    <Mui.Grid xs={4}>
                      {payCycle === "Monthly" ? "Start Date" : "Start Day"}
                    </Mui.Grid>
                    <Mui.Grid xs={4}>{payCycleDateDay}</Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            ) : null}
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payrun Starts On
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    slotProps={{ textField: { size: "small" } }}
                    format="dd/MM/yyyy"
                    onChange={(date) => handlePayrunStartDate(date)}
                    shouldDisableDate={disableNonTuesdays}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        sx={{ width: "100%" }}
                        fullWidth
                        {...register("start_date", {
                          required: "Payrun start date is required",
                          validate: {
                            minLengthNoSpaces: (value) => {
                              const startDate = new Date(value);
                              const minDate = new Date("2020-01-01");
                              if (startDate < minDate) {
                                return "Start date must be 01/01/2020 or later";
                              }
                            },
                          },
                        })}
                        helperText={
                          errors?.start_date?.message
                            ? errors?.start_date?.message
                            : ""
                        }
                        error={errors.start_date ? true : false}
                      />
                    )}
                  />
                </LocalizationProvider>
                {pickerDateError ? (
                  <h5 style={{ color: themes.helperTextColor }}>
                    Invalid date format
                  </h5>
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  No of payruns to be created
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={payrunCreatedCount}
                  rules={{
                    ...register("num_of_cycles", {
                      required: "Number of cycles is required",
                    }),
                  }}
                  updateDataFunction={updatePayrunCreatedCount}
                  placeHolder="Enter payrun created count"
                  errorMessage={
                    errors?.num_of_cycles?.message
                      ? errors?.num_of_cycles?.message
                      : ""
                  }
                  textBoxError={errors.num_of_cycles ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payrun Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={payrunName}
                  rules={{
                    ...register("name", {
                      required: "payrunname is required",
                      maxLength: {
                        value: 32,
                        message: "Maximum 32 characters are required",
                      },
                      validate: {
                        atleastOneCharacterWithSpaceReg: (value) => {
                          if (
                            atleastOneCharacterWithSpace.test(value) ||
                            value === ""
                          ) {
                            return true;
                          } else {
                            return "Atleast one alphabet required";
                          }
                        },
                        minLengthNoSpaces: (value) => {
                          if (value?.trim().length < 3 && value !== "") {
                            return minimum3Characters;
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={handlePayRunName}
                  placeHolder="Enter the payrun name"
                  errorMessage={
                    errors?.name?.message ? errors?.name?.message : ""
                  }
                  textBoxError={errors.name ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 3,
                  paddingBottom: 2,
                }}
              >
                <CustomButton
                  actionFuntion={() => setCreateNewPaySchedule("ListView")}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp; &nbsp;
                <CustomButton
                  actionName="Create"
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
