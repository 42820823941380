import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { BackArrow } from "components/CustomComponents/BackArrow";
export const ApproveLoan = ({
  viewLoanDetails,
  setShowApprove,
  setUserChange,
  setDepartmentChange,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [reasonForApproveTxt, setReasonForApproveTxt] = useState("");
  const [reasonForRejectTxt, setReasonForRejectTxt] = useState("");

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
    setReasonForApproveTxt("");
  };

  const handleCloseRejectDialogBox = () => {
    setOpenRejectDialog(false);
    setReasonForRejectTxt("");
  };

  const approveCurrentLoan = async () => {
    try {
      const response = await fetch(`${domain}loans/${viewLoanDetails.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          status: "Approved",
          approve_reason: reasonForApproveTxt,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "Loan Approved");
        handleCloseDialogBox();
        setShowApprove(false);
        setUserChange(true);
        setDepartmentChange(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const rejectCurrentLoan = async () => {
    try {
      const response = await fetch(`${domain}loans/${viewLoanDetails.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          status: "Rejected",
          reject_reason: reasonForRejectTxt,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Loan rejected");
        handleCloseRejectDialogBox();
        setShowApprove(false);
        setUserChange(true);
        setDepartmentChange(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <Mui.Grid container>
      <Mui.Grid container>
        <Mui.Stack direction="row">
          <BackArrow
            actionFunction={() => {
              setShowApprove(false);
            }}
          />
          &nbsp;&nbsp;
          <Mui.Typography
            sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 0.5 }}
          >
            Approve Loan
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          paddingLeft: { sx: 0, sm: 10, md: 20, lg: 30 },
          paddingRight: { sx: 0, sm: 10, md: 20, lg: 30 },
        }}
      >
        <Mui.Grid
          container
          sx={{ border: `2px solid ${themes.sidebarDivider}`, borderRadius: 2 }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `2px solid ${themes.sidebarDivider}`,
              padding: 1,
              backgroundColor: themes.sidebarDivider,
            }}
          >
            <Mui.Typography
              sx={{ paddingLetf: 2, fontSize: 20, fontWeight: "bold" }}
            >
              Loan Details
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Department
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.tenure_months}
                  size="small"
                  disabled={true}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Employee Id & Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Grid container>
                  <Mui.Grid xs={4}>
                    <Mui.TextField
                      value="32875434"
                      size="small"
                      disabled={true}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={8} sx={{ paddingLeft: 1 }}>
                    <Mui.TextField
                      value={viewLoanDetails?.user_name}
                      size="small"
                      disabled={true}
                      fullWidth
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.loan_type}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Amount
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.principal_amount}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Interest Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.interest_rate}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Interest
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.interest_rate}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Tenure(Month)
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.tenure_months}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Applied Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  type="date"
                  value={viewLoanDetails?.applied_date
                    .split("-")
                    .reverse()
                    .join("-")}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Reason for loan
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  sx={{
                    marginTop: 1,
                    width: "100%",
                  }}
                  placeHolder="Enter reason for loan"
                  multiline
                  rows={3}
                  value={viewLoanDetails?.description}
                  disabled={true}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 2,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionFuntion={() => {
                  setOpenRejectDialog(true);
                }}
                actionName="Reject"
                typeName="button"
              />
            </Mui.Grid>
            <Mui.Grid item>
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(true);
                }}
                actionName="Approve"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Approve Loan"
      >
        <Mui.Grid container sx={{ padding: 1 }}>
          <Mui.TextField
            sx={{
              marginTop: 1,
              width: "100%",
            }}
            label="Provide a reason for approve loan"
            multiline
            rows={3}
            value={reasonForApproveTxt}
            onChange={(event) => setReasonForApproveTxt(event.target.value)}
          />
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => {
                handleCloseDialogBox();
              }}
              actionName="No"
              typeName="button"
            />
          </Mui.Grid>
          &nbsp;&nbsp;
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => {
                approveCurrentLoan();
              }}
              actionName="Yes"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      <GlobalDialogBox
        handleCloseDialog={handleCloseRejectDialogBox}
        open={openRejectDialog}
        title="Reject Loan"
      >
        <Mui.Grid container sx={{ padding: 1 }}>
          <Mui.TextField
            sx={{
              marginTop: 1,
              width: "100%",
            }}
            label="Provide a reason for reject loan"
            multiline
            rows={3}
            value={reasonForRejectTxt}
            onChange={(event) => setReasonForRejectTxt(event.target.value)}
          />
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => {
                handleCloseRejectDialogBox();
              }}
              actionName="No"
              typeName="button"
            />
          </Mui.Grid>
          &nbsp;&nbsp;
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => {
                rejectCurrentLoan();
              }}
              actionName="Yes"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </Mui.Grid>
  );
};
