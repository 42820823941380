import React, { useEffect, useState } from "react";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import * as Icons from "@mui/icons-material";
import * as Mui from "@mui/material";
import Person4Icon from "@mui/icons-material/Person4";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { customAPI } from "services/utils/CustomAPI";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";
import { Close } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";

export const EditDepartment = ({
  setOpenEditScreen,
  editDepartmentDetails,
  setDepartmentApi,
}) => {
  const [selectedShift, setSelectedShift] = useState([]);
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [departmentLeadName, setDepartmentLeadName] = useState(
    editDepartmentDetails.department_lead_name
  );
  const [departmentProxyName, setDepartmentProxyName] = useState(
    editDepartmentDetails.proxy_name
  );
  const [departmentName, setDepartmentName] = useState(
    editDepartmentDetails.department_name
  );
  const [workUnit, setWorkUnit] = useState(editDepartmentDetails?.work_unit);
  const { result: shiftCategoryList } = useFetch(`${domain}shift_category/`);
  const { result: workUnitBasedList } = useFetch(
    `${domain}workunit-based-shiftcategory/`
  );
  const { result: departmentLeadList } = useFetch(
    `${domain}departmentleadfilter/`
  );
  const [editValue, setEditValue] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const transformedWorkUnitBasedList = workUnitBasedList?.map((item) => ({
    id: item.shift_category_id,
    name: item.shift_category_name,
  }));

  const {
    setValue,
    handleSubmit,
    reset,
    register,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (editValue) {
      setValue("department", editDepartmentDetails.department_name);
      setValue("shiftCategory", editDepartmentDetails.shift_category_name);
      setValue("departmentLead", editDepartmentDetails.department_lead_name);
      setValue("proxyName", editDepartmentDetails.proxy_name);
      setValue("departmentLeadId", editDepartmentDetails.department_lead_id);
      setValue("departmentProxy", editDepartmentDetails.proxy_id);
      setValue("shiftCategoryId", editDepartmentDetails.shift_category_id);
      setEditValue(false);
    }
  }, [
    editDepartmentDetails.department_lead_id,
    editDepartmentDetails.department_lead_name,
    editDepartmentDetails.department_name,
    editDepartmentDetails.proxy_id,
    editDepartmentDetails.proxy_name,
    editDepartmentDetails.shift_categories,
    editDepartmentDetails.shift_category_id,
    editDepartmentDetails.shift_category_name,
    editValue,
    selectedShift,
    setValue,
    shiftCategoryList,
    transformedWorkUnitBasedList,
    workUnit,
  ]);

  useEffect(() => {
    let shiftCateId = [];
    editDepartmentDetails?.shift_categories?.forEach((element) => {
      shiftCateId.push(element.id);
    });
    setSelectedShift(shiftCateId);
    setValue("shiftCategory", shiftCateId);
  }, [editDepartmentDetails?.shift_categories, setValue]);

  const onSubmit = async (data) => {
    setButtonLoader(true);
    if (data) {
      clearErrors("shiftCategory");
      if (selectedShift.length === 0) {
        setError("shiftCategory", {
          type: "manual",
          message: "Shift category is required",
        });
        setButtonLoader(false);
        return;
      }
      const response = await customAPI(`${domain}edit_department/`, "PUT", {
        department_lead_id: data.departmentLeadId,
        shift_category_ids: selectedShift,
        department_name: data.department.trim(),
        department_id: editDepartmentDetails.department_id,
        proxy_id: data.departmentProxy,
        approved_by: userDetails.username,
        work_unit: workUnit,
      });
      if (response.ok) {
        reset();
        setOpenEditScreen(false);
        setDepartmentApi(true);
        showToast("success", response?.responseDetails?.message);
      } else {
        setButtonLoader(false);
      }
    }
  };

  const checkSpecialChar = (e) => {
    if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const hadleWorkUnit = (event) => {
    setWorkUnit(event.target.checked);
    setSelectedShift([]);
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <BackArrow actionFunction={() => setOpenEditScreen(false)} />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
        >
          Departments / Edit Department
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid xs={12} sm={12} md={3} lg={2.5}></Mui.Grid>
          <Mui.Grid xs={12} sm={12} md={6} lg={7.5} sx={{ paddingTop: 10 }}>
            <Mui.Grid container component={Mui.Paper}>
              <Mui.Grid
                xs={12}
                sx={{
                  padding: 1,
                  backgroundColor: themes.primary,
                  borderRadius: "10px 10px 0 0",
                  border: `2px solid ${themes.sidebarDivider}`,
                  borderBottom: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Mui.Typography
                  sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
                >
                  Edit Department
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Stack
              sx={{
                paddingTop: 1,
                padding: 5,
                borderRadius: "0 0 10px 10px",
                border: `2px solid ${themes.sidebarDivider}`,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Department name
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.TextField
                    size="small"
                    placeholder="Enter department name"
                    sx={{
                      borderWidth: "10px solid black",
                      paddingBottom: 2,
                    }}
                    {...register("department", {
                      required: "Department is required",
                      maxLength: {
                        value: 30,
                        message: "Maximum length is 30 characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3 characters",
                      },
                      validate: {
                        noDigitsOrSpecial: (fieldValue) => {
                          if (!/[A-Za-z]/.test(fieldValue)) {
                            return "Department Name must contain at least one alphabet character.";
                          }
                          if (/^\d+$/.test(fieldValue)) {
                            return "Department Name should not contain only digits.";
                          }
                          if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                            return "DepartmentClient Name should only contain alphabets, spaces, -,numbers and & symbol.";
                          }
                          return true;
                        },
                        checkUnique: async (fieldValue) => {
                          if (
                            fieldValue !== "" &&
                            fieldValue !==
                              editDepartmentDetails.department_name &&
                            !buttonLoader
                          ) {
                            setButtonLoader(true);
                            try {
                              const response = await fetch(
                                `${domain}check_department_name/?department_name=${fieldValue.trim()}`,
                                {
                                  method: "GET",
                                  headers: {
                                    "content-type": "application/json",
                                    Authorization: `token ${token}`,
                                  },
                                }
                              );
                              const res = await response.json();
                              if (response.ok) {
                                if (res.exists) {
                                  return "The department name has been already taken.Use a unique department name to proceed further";
                                } else {
                                  return true;
                                }
                              } else if (response.status === 409) {
                                sessionHandling();
                              } else {
                                throw new Error(res.error);
                              }
                            } catch (error) {
                              showToast("error", error.message);
                            } finally {
                              setButtonLoader(false);
                            }
                          }
                        },
                      },
                    })}
                    onChange={(event) => {
                      setDepartmentName(event.target.value);
                      setValue("department", event.target.value);
                    }}
                    value={departmentName}
                    error={Boolean(errors.department)}
                    helperText={errors.department?.message || ""}
                    onKeyPress={checkSpecialChar}
                    InputProps={{
                      startAdornment: (
                        <Mui.InputAdornment>
                          <Mui.InputAdornment>
                            <Icons.AddBusiness
                              style={{
                                color: "#a6a6a6",
                              }}
                            />
                            &nbsp;
                          </Mui.InputAdornment>
                        </Mui.InputAdornment>
                      ),
                    }}
                    fullWidth
                    disabled={
                      editDepartmentDetails?.department_name === "General"
                        ? true
                        : false
                    }
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                    Is workunit applicable ?
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Switch
                    checked={workUnit}
                    onChange={hadleWorkUnit}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                    Shift Category
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  {" "}
                  <Mui.Autocomplete
                    multiple
                    id="checkboxes-tags-demo-edit"
                    size="small"
                    options={
                      workUnit
                        ? transformedWorkUnitBasedList
                        : shiftCategoryList
                    }
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={
                      (workUnit
                        ? transformedWorkUnitBasedList
                        : shiftCategoryList
                      )?.filter((member) =>
                        selectedShift?.includes(member.id)
                      ) || []
                    }
                    onChange={(event, value) => {
                      setSelectedShift(value.map((item) => item.id));
                      if (value.length > 0) {
                        clearErrors("shiftCategory");
                      }
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Mui.Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        multiline
                        rows={2}
                        maxRows={5}
                        error={Boolean(errors.shiftCategory)}
                        helperText={errors.shiftCategory?.message || ""}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <AcUnitIcon
                                style={{
                                  margin: "0 8px",
                                  color: "#a6a6a6",
                                }}
                              />
                              {params.InputProps.startAdornment} &nbsp;{" "}
                              <div>
                                {selectedShift.length === 0 ? (
                                  <span style={{ color: "#b0b0b0" }}>
                                    Select shift category
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                    Department Lead
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    value={departmentLeadName}
                    options={departmentLeadList.map((obj) => ({
                      ...obj,
                      label: obj.user.auth_user.username,
                    }))}
                    sx={{ width: "100%" }}
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>{option.user.auth_user.username}</li>
                      );
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setDepartmentLeadName(value.user.auth_user.username);
                        setValue("departmentLeadId", value.user.id);
                      } else {
                        setValue("departmentLeadId", null);
                        setDepartmentLeadName("");
                      }
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        value={departmentLeadName}
                        {...register("departmentLead", {
                          required: "Department lead is required",
                        })}
                        sx={{
                          background: themes.whiteColor,
                          maxHeight: 120,
                          paddingBottom: 2,
                        }}
                        placeholder="Select department lead"
                        size="small"
                        error={Boolean(errors.departmentLead)}
                        helperText={errors.departmentLead?.message || ""}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <Person4Icon
                                style={{
                                  margin: "0 8px",
                                  color: "#a6a6a6",
                                }}
                              />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                    Proxy
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    value={departmentProxyName}
                    options={departmentLeadList.map((obj) => ({
                      ...obj,
                      label: obj.user.auth_user.username,
                    }))}
                    sx={{ width: "100%", paddingBottom: 2 }}
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        departmentLeadName !==
                          option.user.auth_user.username && (
                          <li {...props}>{option.user.auth_user.username}</li>
                        )
                      );
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setDepartmentProxyName(value.user.auth_user.username);
                        setValue("departmentProxy", value.user.id);
                      } else {
                        setValue("departmentProxy", null);
                        setDepartmentProxyName("");
                      }
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        value={departmentProxyName}
                        {...register("proxyName", {
                          required: "Proxy name is required",
                        })}
                        sx={{
                          background: themes.whiteColor,
                          maxHeight: 120,
                        }}
                        placeholder="Select proxy name"
                        size="small"
                        error={Boolean(errors.proxyName)}
                        helperText={errors.proxyName?.message || ""}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <Person4Icon
                                style={{
                                  margin: "0 8px",
                                  color: "#a6a6a6",
                                }}
                              />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid
                container
                sx={{ justifyContent: "right", paddingBottom: 2 }}
              >
                <CustomButton
                  key={buttonLoader}
                  actionName="Update"
                  typeName="submit"
                  disableAction={buttonLoader}
                />
              </Mui.Grid>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={12} md={3} lg={3}></Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};

// const InputWrapper = Mui.styled("div")(
//   ({ theme, autocompleteVisible }) => `
//   width: 100%;
//    border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
//    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
//    border-radius: 4px;
//    padding: 1px;
//    display: flex;
//    flex-wrap: wrap;

//    &:hover {
//      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
//    }

//    ${
//      autocompleteVisible
//        ? `
//        &.focused {
//          border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
//          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
//        }
//      `
//        : ""
//    }

//    & input {
//      background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
//      color: ${
//        theme.palette.mode === "dark"
//          ? "rgba(255,255,255,0.65)"
//          : "rgba(0,0,0,.85)"
//      };

//      flex-grow: 1;
//      border: 0;
//      margin: 0;
//      outline: 0;
//    }
// `
// );

// const Listbox = Mui.styled("ul")(
//   ({ theme, autocompleteVisible }) => `
//    width: 1000px;
//    margin: 2px 0 0;
//    padding: 0;
//    position: absolute;
//    list-style: none;
//    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
//    overflow: auto;
//    max-height: 150px;
//    border-radius: 4px;
//    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//    z-index: 1;

//    ${
//      autocompleteVisible
//        ? `
//      background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
//    `
//        : ""
//    }

//    & li {
//      padding: 5px 12px;
//      display: flex;

//      & span {
//        flex-grow: 1;
//      }

//      & svg {
//        color: transparent;
//      }
//    }

//    & li[aria-selected='true'] {
//      background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
//      font-weight: 600;

//      & svg {
//        color: #1890ff;
//      }
//    }

//    & li.${Mui.autocompleteClasses.focused} {
//      background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
//      cursor: pointer;

//      & svg {
//        color: currentColor;
//      }
//    }
// `
// );

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <Close onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

// const StyledTag = Mui.styled(Tag)(
//   ({ theme }) => `
//   display: flex;
//   align-items: center;
//   height: 34px;
//   margin: 2px;
//   line-height: 22px;
//   border-radius: 25px;
//   background-color: ${
//     theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
//   };
//   border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
//   border-radius: 2px;
//   box-sizing: content-box;
//   padding: 0 4px 0 10px;
//   outline: 0;
//   overflow: hidden;
//   border-radius: 25px;

//   &:focus {
//     border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
//     background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
//   }

//   & span {
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }

//   & svg {
//     font-size: 12px;
//     cursor: pointer;
//     padding: 4px;
//   }
// `
// );
