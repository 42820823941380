import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { customAPI } from "services/utils/CustomAPI";
import { showToast } from "services/utils/Status";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import {
  specialCharacterRestrict,
  onlyNumberRegex,
  ifscCodePatterns,
} from "services/constants/patterns";

export const EditBank = ({
  companyId,
  setEditBankDetails,
  bankDetails,
  reCallApi,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [bankNameDetails, setBankNameDetails] = useState(bankDetails.bank_name);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [accountNumber, setAccountNumber] = useState(
    bankDetails.bank_account_number
  );
  const [confirmAccountNumber, setConfirmAccountNumber] = useState(
    bankDetails.bank_account_number
  );
  const [branchNameDetails, setBranchNameDetails] = useState(
    bankDetails.bank_branch
  );
  const [ifscCodeDetails, setIFSCcodeDetails] = useState(
    bankDetails.bank_ifsc_code
  );
  const [nickName, setNickName] = useState(bankDetails.nick_name);

  useEffect(() => {
    setValue("bankName", bankDetails.bank_name);
    setValue("nick_name", bankDetails.nick_name);
    setValue("bankAccountNumber", bankDetails.bank_account_number);
    setValue("confirm_account_number", bankDetails.bank_account_number);
    setValue("branchName", bankDetails.bank_branch);
    setValue("ifscCode", bankDetails.bank_ifsc_code);
  }, [
    bankDetails.bank_account_number,
    bankDetails.bank_branch,
    bankDetails.bank_ifsc_code,
    bankDetails.bank_name,
    bankDetails.nick_name,
    setValue,
  ]);

  const onSubmit = async (data) => {
    setButtonDisabled(true);
    const response = await customAPI(
      `${domain}source-bank/${bankDetails.id}`,
      "PUT",
      {
        bank_account_number: data.bankAccountNumber,
        bank_name: data.bankName,
        bank_branch: data.branchName,
        bank_ifsc_code: data.ifscCode,
        company: companyId,
        nick_name: data.nick_name,
      }
    );
    if (response.ok) {
      setEditBankDetails(false);
      reset();
      reCallApi(true);
      setButtonDisabled(false);
      showToast("success", "Bank details updated successfully");
    } else {
      setButtonDisabled(false);
    }
  };

  const updateBankName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 64
    ) {
      setValue("bankName", event.target.value);
      setBankNameDetails(event.target.value);
    }
  };

  const updateNickName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 64
    ) {
      setValue("nick_name", event.target.value);
      setNickName(event.target.value);
    }
  };

  const updateBankAccountNumber = (event) => {
    if (
      event.target.value.length <= 18 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("bankAccountNumber", event.target.value);
      setAccountNumber(event.target.value);
    }
  };

  const updateConfirmAccountNumber = (event) => {
    if (
      event.target.value.length <= 18 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("confirm_account_number", event.target.value);
      setConfirmAccountNumber(event.target.value);
    }
  };

  const updateBranchName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 32
    ) {
      setValue("branchName", event.target.value);
      setBranchNameDetails(event.target.value);
    }
  };

  const updateIFSCcode = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 11
    ) {
      setValue("ifscCode", event.target.value);
      setIFSCcodeDetails(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingLeft: 10, paddingRight: 10 }}
      >
        <Mui.Grid
          container
          sx={{
            paddingTop: "10%",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 24,
                fontWeight: 800,
                font: "bold",
                textAlign: "center",
              }}
            >
              Edit Payment Bank
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              paddingTop: -1,
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
              borderRadius: "0 0 10px 10px",
            }}
          >
            <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: themes.headLine,
                    paddingBottom: 0.5,
                  }}
                >
                  Bank Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={bankNameDetails}
                  rules={{
                    ...register("bankName", {
                      required: "Bank name is required",
                      validate: {
                        atleastOneCharacterWithSpaceReg: (value) => {
                          if (
                            atleastOneCharacterWithSpace.test(value) ||
                            value === ""
                          ) {
                            return true;
                          } else {
                            return "Atleast one alphabet required";
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateBankName}
                  placeHolder="Enter Bank Name"
                  errorMessage={
                    errors?.bankName?.message ? errors?.bankName?.message : ""
                  }
                  textBoxError={errors.bankName ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: themes.headLine,
                    paddingBottom: 0.5,
                  }}
                >
                  Nick Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={nickName}
                  rules={{
                    ...register("nick_name", {
                      required: "Nick name is required",
                      validate: {
                        atleastOneCharacterWithSpaceReg: (value) => {
                          if (
                            atleastOneCharacterWithSpace.test(value) ||
                            value === ""
                          ) {
                            return true;
                          } else {
                            return "Atleast one alphabet required";
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateNickName}
                  placeHolder="Enter Nick Name"
                  errorMessage={
                    errors?.nick_name?.message ? errors?.nick_name?.message : ""
                  }
                  textBoxError={errors.nick_name ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: themes.headLine,
                    paddingBottom: 0.5,
                  }}
                >
                  Account Number
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={accountNumber}
                  rules={{
                    ...register("bankAccountNumber", {
                      required: "Bank account number is required",
                      minLength: {
                        value: 8,
                        message: "Minimum 8 characters required",
                      },
                    }),
                  }}
                  updateDataFunction={updateBankAccountNumber}
                  placeHolder="Enter Bank Account Number"
                  errorMessage={
                    errors?.bankAccountNumber?.message
                      ? errors?.bankAccountNumber?.message
                      : ""
                  }
                  textBoxError={errors.bankAccountNumber ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: themes.headLine,
                    paddingBottom: 0.5,
                  }}
                >
                  Confirm Account Number
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={confirmAccountNumber}
                  rules={{
                    ...register("confirm_account_number", {
                      required: "Confirm account number is required",
                      validate: {
                        minLengthNoSpaces: (value) => {
                          if (value !== getValues("bankAccountNumber")) {
                            return "Account number and confirm account number should be same";
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateConfirmAccountNumber}
                  placeHolder="Enter Confirm Account Number"
                  errorMessage={
                    errors?.confirm_account_number?.message
                      ? errors?.confirm_account_number?.message
                      : ""
                  }
                  textBoxError={errors.confirm_account_number ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: themes.headLine,
                    paddingBottom: 0.5,
                  }}
                >
                  Branch
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={branchNameDetails}
                  rules={{
                    ...register("branchName", {
                      required: "Branch name is required",
                      validate: {
                        atleastOneCharacterWithSpaceReg: (value) => {
                          if (
                            atleastOneCharacterWithSpace.test(value) ||
                            value === ""
                          ) {
                            return true;
                          } else {
                            return "Atleast one alphabet required";
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateBranchName}
                  placeHolder="Enter Branch Name"
                  errorMessage={
                    errors?.branchName?.message
                      ? errors?.branchName?.message
                      : ""
                  }
                  textBoxError={errors.branchName ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: themes.headLine,
                    paddingBottom: 0.5,
                  }}
                >
                  IFSC code
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={ifscCodeDetails}
                  rules={{
                    ...register("ifscCode", {
                      required: "IFSC code is required",
                      validate: {
                        minLengthNoSpaces: (value) => {
                          if (value?.trim().length < 11 && value !== "") {
                            return "Minimum 11 characters with no spaces";
                          } else {
                            return true;
                          }
                        },
                        checkPatterns: (value) => {
                          if (!ifscCodePatterns.test(value)) {
                            return "Please enter a valid format: ABCD0123456";
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateIFSCcode}
                  placeHolder="Enter IFSC Code"
                  errorMessage={
                    errors?.ifscCode?.message ? errors?.ifscCode?.message : ""
                  }
                  textBoxError={errors.ifscCode ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              sx={{
                paddingTop: "2%",
                paddingBottom: 2,
              }}
            >
              <Mui.Grid
                xs={12}
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "flex-end",
                  paddingRight: 2,
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    setEditBankDetails(false);
                  }}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionName="Update"
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
