import React, { useEffect, useState, useCallback } from "react";
import * as Mui from "@mui/material";
import { Info } from "@mui/icons-material";
import { themes } from "services/constants";
import moment from "moment";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";

export const RandomShift = ({
  shift_grade_id,
  shift_category_name,
  setShiftStartTime,
  shiftStartTime,
  setShiftEndTime,
  shiftEndTime,
  randomShiftError,
  shiftStartTimeFormated,
  shiftEndTimeFormated,
  setShiftStartTimeFormated,
  setShiftEndTimeFormated,
  shiftCategoryName,
  setShiftCategoryName,
  shiftGrade,
  screenName,
  firstNameUpdate,
  updatename,
  setFirstNameUpdate,
  setUpdateName,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const handleStartTime = (newValue) => {
    setShiftStartTime(newValue.format("HH:mm"));
    const timeWithDate = newValue ? newValue.toDate() : new Date();
    let minTime = moment.utc(timeWithDate);
    setShiftStartTimeFormated(
      dayjs()
        .set("hour", minTime._d.getHours())
        .set("minute", minTime._d.getMinutes())
        .set("second", minTime._d.getSeconds())
    );
    if (screenName === "Add") {
      setFirstNameUpdate(true);
    } else {
      setUpdateName(true);
    }
  };
  const [errorMessage, setErrorMessage] = useState("");
  const handleEndTime = (newValue) => {
    setShiftEndTime(newValue.format("HH:mm"));
    const timeWithDate = newValue ? newValue.toDate() : new Date();
    let minTime = moment.utc(timeWithDate);
    setShiftEndTimeFormated(
      dayjs()
        .set("hour", minTime._d.getHours())
        .set("minute", minTime._d.getMinutes())
        .set("second", minTime._d.getSeconds())
    );
  };

  const getShiftDetails = useCallback(
    async (time) => {
      if (time && time !== "Invalid Date") {
        try {
          const response = await fetch(
            `${domain}shift-over-time-details/?shift_category_name=${shift_category_name}&shift_grade_id=${shift_grade_id}&start_time=${time}&break_time=00&no_of_shifts=1&start_start_early=00&start_end_delay=00&end_end_early=0&end_end_delay=00&start_delay=00&end_early=00`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `token ${token}`,
              },
            }
          );
          const res = await response.json();
          if (response.ok) {
            res?.shifts.forEach((element) => {
              setShiftEndTime(element.end_time);
              setShiftEndTimeFormated(
                dayjs()
                  .set("hour", element.end_time.split(":")[0])
                  .set("minute", element.end_time.split(":")[1])
                  .set("second", "00")
              );
            });
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    },
    [
      domain,
      setShiftEndTime,
      setShiftEndTimeFormated,
      shift_category_name,
      shift_grade_id,
      token,
    ]
  );

  const shiftCategoryNameCheck = (event) => {
    const value = event.target.value;
    updateShiftCategoryName(value);
  };

  const updateShiftCategoryName = useCallback(
    async (value) => {
      setShiftCategoryName(value);
      if (value.length < 3) {
        setErrorMessage("Minimum length is 3 characters");
        return;
      } else if (!/[a-zA-Z]/.test(value)) {
        setErrorMessage("At least one alphabet is required");
        return;
      } else if (value.length >= 3) {
        if (
          screenName === "Add" ||
          (screenName === "Edit" && shift_category_name !== value)
        )
          try {
            const response = await fetch(
              `${domain}check_shift_category_name/?shift_category_name=${value}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `token ${token}`,
                },
              }
            );
            const resp = await response.json();
            if (resp.exists) {
              setErrorMessage("Shift category name already exists");
            } else {
              getShiftDetails(shiftStartTime);
              setErrorMessage(null);
            }
          } catch (error) {
            showToast("error", error.message);
          }
      }
    },
    [
      domain,
      getShiftDetails,
      screenName,
      setShiftCategoryName,
      shiftStartTime,
      shift_category_name,
      token,
    ]
  );

  useEffect(() => {
    if (firstNameUpdate && screenName === "Add") {
      const newShiftName = `${shiftGrade}-${shiftStartTime}`;
      setShiftCategoryName(newShiftName);
      updateShiftCategoryName(newShiftName);
      setFirstNameUpdate(false);
    }
  }, [
    firstNameUpdate,
    screenName,
    setFirstNameUpdate,
    setShiftCategoryName,
    shiftGrade,
    shiftStartTime,
    updateShiftCategoryName,
  ]);

  useEffect(() => {
    if (updatename && screenName === "Edit") {
      const newShiftName = `${shiftGrade}-${shiftStartTime}`;
      setShiftCategoryName(newShiftName);
      updateShiftCategoryName(newShiftName);
      setUpdateName(false);
    }
  }, [
    screenName,
    setShiftCategoryName,
    setUpdateName,
    shiftGrade,
    shiftStartTime,
    updateShiftCategoryName,
    updatename,
  ]);

  return (
    <Mui.Grid container>
      <Mui.Grid
        container
        component={Mui.Paper}
        sx={{ padding: 4 }}
        elevation={2}
      >
        <Mui.Grid container>
          <Mui.Grid xs={4}>
            <Mui.Typography
              sx={{
                paddingTop: 1,
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              Shift Start Time&nbsp;
              <Mui.Tooltip title="This is the Start Time field. Specify the exact time the shift begins, such as 08:00 AM, to define the official start of the workday.">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid xs={7}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <div sx={{ width: 4 }}>
                  <TimePicker
                    ampm={false}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={shiftStartTimeFormated || null}
                    slotProps={{
                      textField: {
                        size: "small",
                        width: 15,
                      },
                    }}
                    onChange={(newValue) => {
                      handleStartTime(newValue);
                      const time = newValue.format("HH:mm");
                      getShiftDetails(time);
                    }}
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>
            {(!shiftStartTime || shiftStartTime === "00:00") &&
            randomShiftError ? (
              <h5 style={{ color: themes.helperTextColor }}>
                Shift start time is required
              </h5>
            ) : null}
            {/* {randomShiftError} */}
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid xs={4}>
            <Mui.Typography
              sx={{
                paddingTop: 1,
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              Shift End Time&nbsp;
              <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <div sx={{ width: 4 }}>
                  <TimePicker
                    ampm={false}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={shiftEndTimeFormated || null}
                    slotProps={{
                      textField: {
                        size: "small",
                        width: 15,
                      },
                    }}
                    onChange={(newValue) => {
                      handleEndTime(newValue);
                    }}
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>
            {(!shiftEndTime || shiftEndTime === "00:00") && randomShiftError ? (
              <h5 style={{ color: themes.helperTextColor }}>
                Shift end time is required
              </h5>
            ) : null}
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ borderRadius: 3, paddingTop: 1 }}>
          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              Shift Category Name &nbsp;
              <Mui.Tooltip title="This is the Shift Category Name field. Enter a unique name for the shift category, such as Day Shift or Night Shift, to organize and categorize different shifts.">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid item xs={6}>
            <CustomTextBox
              selectedValue={shiftCategoryName}
              updateDataFunction={shiftCategoryNameCheck}
              placeHolder="Enter shift category name"
            />
            {errorMessage && (
              <h5 style={{ color: themes.helperTextColor }}>{errorMessage}</h5>
            )}
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Grid>
  );
};
