import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Icons from "@mui/icons-material";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useFetch } from "services/hooks/useFetch";
import { customGetAPI, customAPI } from "services/utils/CustomAPI";
let multiApiCallRestriction = true;

export const AddDepartment = ({ setOpen, setDepartmentApi }) => {
  const { domain, userDetails } = useSelector((state) => state.tokenReducer);
  const [departmentLeadName, setDepartmentLeadName] = useState("");
  const [departmentProxyName, setDepartmentProxyName] = useState("");
  const [departmentProxyList, setDepartmentProxyList] = useState([]);
  const [workUnit, setWorkUnit] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [selectedShift, setSelectedShift] = useState([]);
  const { result: departmentLeadList } = useFetch(
    `${domain}departmentleadfilter/`
  );
  const { result: shiftCategoryList } = useFetch(`${domain}shift_category/`);
  const { result: workUnitBasedList } = useFetch(
    `${domain}workunit-based-shiftcategory/`
  );

  const {
    setValue,
    handleSubmit,
    reset,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const transformedWorkUnitBasedList = workUnitBasedList?.map((item) => ({
    id: item.shift_category_id,
    name: item.shift_category_name,
  }));

  const onSubmit = async (data) => {
    setButtonLoader(true);
    if (multiApiCallRestriction) {
      multiApiCallRestriction = false;
      clearErrors("shiftCategory");
      if (selectedShift.length === 0) {
        setError("shiftCategory", {
          type: "manual",
          message: "Shift category is required",
        });
        setButtonLoader(false);
        return;
      }
      const response = await customAPI(`${domain}create_department/`, "POST", {
        department_lead_id: data.departmentLeadId,
        shift_category_ids: selectedShift,
        // shift_category: shiftCategoryName,
        departmentname: data.department.trim(),
        proxy_id: data.departmentProxy,
        approved_by: userDetails.username,
        // selectedMembers: null,
        work_unit: workUnit,
      });
      if (response.ok) {
        reset();
        setDepartmentApi(true);
        showToast("success", "Department created successfully");
        setOpen(false);
        setButtonLoader(false);
        multiApiCallRestriction = true;
      } else {
        setButtonLoader(false);
        multiApiCallRestriction = true;
      }
    }
  };
  const checkSpecialChar = (e) => {
    if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const getDepartmentProxyList = (leadName) => {
    if (leadName !== "") {
      setDepartmentProxyList(departmentLeadList);
    } else {
      setDepartmentProxyList([]);
    }
  };

  const hadleWorkUnit = (event) => {
    setWorkUnit(event.target.checked);
    setSelectedShift([]);
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <BackArrow actionFunction={() => setOpen(false)} />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
        >
          Departments / Add Department
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid xs={12} sm={12} md={3} lg={2.5}></Mui.Grid>

          <Mui.Grid xs={12} sm={12} md={6} lg={7.5} sx={{ paddingTop: 10 }}>
            <Mui.Grid container component={Mui.Paper}>
              <Mui.Grid
                xs={12}
                sx={{
                  padding: 1,
                  backgroundColor: themes.primary,
                  borderRadius: "10px 10px 0 0",
                  border: `2px solid ${themes.sidebarDivider}`,
                  borderBottom: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Mui.Typography
                  sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
                >
                  Add Department
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Stack
              sx={{
                paddingTop: 1,
                padding: 5,
                borderRadius: "0 0 10px 10px",
                border: `2px solid ${themes.sidebarDivider}`,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Mui.Grid container>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Department name
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.TextField
                    size="small"
                    placeholder="Enter department name"
                    sx={{
                      borderWidth: "10px solid black",
                      paddingBottom: 2,
                    }}
                    {...register("department", {
                      required: "Department is required",
                      maxLength: {
                        value: 30,
                        message: "Maximum length is 30 characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3 characters",
                      },
                      validate: {
                        noDigitsOrSpecial: (fieldValue) => {
                          if (!/[A-Za-z]/.test(fieldValue)) {
                            return "Department Name must contain at least one alphabet character.";
                          }
                          if (/^\d+$/.test(fieldValue)) {
                            return "Department Name should not contain only digits.";
                          }
                          if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                            return "DepartmentClient Name should only contain alphabets, spaces, -,numbers and & symbol.";
                          }
                          return true;
                        },
                        checkUnique: async (fieldValue) => {
                          if (fieldValue !== "" && !buttonLoader) {
                            const res = await customGetAPI(
                              `${domain}check_department_name/?department_name=${fieldValue.trim()}`
                            );
                            if (res.ok) {
                              if (res?.responseDetails?.exists) {
                                return "The department name has been already taken.Use a unique department name to proceed further";
                              } else {
                                return true;
                              }
                            }
                          }
                        },
                      },
                    })}
                    error={Boolean(errors.department)}
                    helperText={errors.department?.message || ""}
                    onKeyPress={checkSpecialChar}
                    InputProps={{
                      startAdornment: (
                        <Mui.InputAdornment>
                          <Icons.AddBusiness
                            style={{
                              color: "#a6a6a6",
                            }}
                          />
                          &nbsp;
                        </Mui.InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Is workunit applicable ?
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.Switch
                    // checked={checked}
                    onChange={hadleWorkUnit}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Shift Category
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    size="small"
                    options={
                      workUnit
                        ? transformedWorkUnitBasedList
                        : shiftCategoryList
                    }
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={
                      (workUnit
                        ? transformedWorkUnitBasedList
                        : shiftCategoryList
                      )?.filter((member) =>
                        selectedShift.includes(member.id)
                      ) || []
                    }
                    onChange={(event, value) => {
                      setSelectedShift(value.map((item) => item.id));
                      if (value.length > 0) {
                        clearErrors("shiftCategory");
                      }
                    }}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Mui.Checkbox
                            icon={
                              <Icons.CheckBoxOutlineBlank fontSize="small" />
                            }
                            checkedIcon={<Icons.CheckBox fontSize="small" />}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        multiline
                        rows={3}
                        maxRows={5}
                        error={Boolean(errors.shiftCategory)}
                        helperText={errors.shiftCategory?.message || ""}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <Icons.AcUnit
                                style={{
                                  margin: "0 8px",
                                  color: "#a6a6a6",
                                }}
                              />
                              {params.InputProps.startAdornment} &nbsp;{" "}
                              <div>
                                {selectedShift.length === 0 ? (
                                  <span style={{ color: "#b0b0b0" }}>
                                    Select shift category
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Department Lead
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    value={departmentLeadName}
                    options={departmentLeadList.map((obj) => ({
                      ...obj,
                      label: obj.user.auth_user.username,
                    }))}
                    sx={{ width: "100%" }}
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.label}</li>;
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setDepartmentLeadName(value.user.auth_user.username);
                        setValue("departmentLeadId", value.user.id);
                        getDepartmentProxyList(value.user.auth_user.username);
                      } else {
                        setValue("departmentLeadId", null);
                        setDepartmentLeadName("");
                        getDepartmentProxyList("");
                      }
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        size="small"
                        {...params}
                        value={departmentLeadName}
                        {...register("departmentLead", {
                          required: "Department lead is required",
                        })}
                        sx={{
                          background: themes.whiteColor,
                          // maxHeight: 120,
                          paddingBottom: 2,
                        }}
                        placeholder="Select department lead"
                        error={Boolean(errors.departmentLead)}
                        helperText={errors.departmentLead?.message || ""}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <Icons.Person4
                                style={{
                                  margin: "0 8px",
                                  color: "#a6a6a6",
                                }}
                              />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                    Proxy
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    value={departmentProxyName}
                    options={departmentProxyList.map((obj) => ({
                      ...obj,
                      label: obj.user.auth_user.username,
                    }))}
                    sx={{ width: "100%", paddingBottom: 2 }}
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.label}</li>;
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setDepartmentProxyName(value.user.auth_user.username);
                        setValue("departmentProxy", value.user.id);
                      } else {
                        setValue("departmentProxy", null);
                        setDepartmentProxyName("");
                      }
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        value={departmentProxyName}
                        {...register("proxyName", {
                          required: "Proxy name is required",
                        })}
                        sx={{
                          background: themes.whiteColor,
                        }}
                        placeholder="Select proxy name"
                        size="small"
                        error={Boolean(errors.proxyName)}
                        helperText={errors.proxyName?.message || ""}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <Icons.Person4
                                style={{
                                  margin: "0 8px",
                                  color: "#a6a6a6",
                                }}
                              />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid
                container
                sx={{ justifyContent: "right", paddingBottom: 2 }}
              >
                <CustomButton
                  key={buttonLoader}
                  actionName="Create"
                  typeName="submit"
                  disableAction={buttonLoader}
                />
              </Mui.Grid>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={12} md={3} lg={2}></Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
