import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { showToast } from "services/utils/Status";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { Apartment } from "@mui/icons-material";
import { setGlobalSearchValue } from "services/Redux/userToken";
import {
  setEditUserId,
  setEditUserDetailsApi,
  setProfileEditBack,
} from "services/Redux/userManagament";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
  themes,
  employeeListKeys,
  defaultAllSelection,
} from "services/constants";
import { customGetAPI } from "services/utils/CustomAPI";
import {
  setAdminCompanyName,
  setAdminCompanyId,
} from "services/Redux/userToken";
import sessionHandling from "services/utils/notificationUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ListView } from "./ListView";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { DialogWithoutCloseOption } from "components/GlobalDialogBox/DialogWithoutCloseOption";
export const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    domain,
    globalSearchValue,
    adminCompanyId,
    token,
    userDetails,
    adminCompanyName,
  } = useSelector((state) => state.tokenReducer);
  const [fetchUserDetails, setFetchUserDetails] = useState(true);
  const [masterDataUserDetails, setMasterDataUserDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editUser, setEditUserDetails] = useState(null);
  const [dropDown, setDropDown] = useState([]);
  const [filterChanges, setFilterChanges] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openRevokeDialog, setOpenRevokeDialog] = useState(false);
  const [openDeactivatePopup, setOpenDeactivatePopup] = useState(false);
  const [actionOption, setActionOption] = useState("");
  const dataViewFormat = "ListView";
  const [buttonLoader, setButtonLoader] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const getCompany = async () => {
      const res = await customGetAPI(`${domain}get-active-company/`);
      if (res.ok) {
        if (res.length === 0) {
          setOpenDialogBox(true);
        }
        const allCompanies = {
          id: defaultAllSelection,
          label: "All Companies",
          companyname: "All Companies",
        };
        setCompanyDetails([allCompanies, ...res?.responseDetails]);
      }
    };
    getCompany();
  }, [domain, token]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      setIsLoading(true);
      const res = await customGetAPI(
        `${domain}new_user_details/?company_id=${adminCompanyId}`
      );
      if (res.ok) {
        setMasterDataUserDetails(res?.responseDetails);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    if (fetchUserDetails) {
      fetchAllUsers();
      setFetchUserDetails(false);
    }
  }, [adminCompanyId, domain, fetchUserDetails, setAllUsers, token]);

  useEffect(() => {
    const filteredResults = masterDataUserDetails.filter((item) =>
      employeeListKeys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setFilterChanges(true);
    setPage(globalSearchValue ? 0 : page);
    setAllUsers(filteredResults);
  }, [dataViewFormat, globalSearchValue, masterDataUserDetails, page]);

  const updateMenuItem = (status, role, access) => {
    switch (status) {
      case "Invited":
        setDropDown([{ dropMenu: "Resend" }]);
        break;
      case "Active":
        if (userDetails.role === "Admin") {
          setDropDown([
            { dropMenu: "View Profile" },
            { dropMenu: "Inactive" },
            { dropMenu: "Make TeamLead" },
            {
              dropMenu:
                access === "Yes"
                  ? "Revoke System Access"
                  : "Invite System Access",
            },
          ]);
        } else if (role === "User") {
          setDropDown([
            { dropMenu: "View Profile" },
            { dropMenu: "Inactive" },
            { dropMenu: "Make TeamLead" },
          ]);
        } else if (role === "Team Lead") {
          setDropDown([
            { dropMenu: "View Profile" },
            { dropMenu: "Inactive" },
            { dropMenu: "Make TeamLead" },
            { dropMenu: "Make Admin" },
          ]);
        }
        break;
      case "Inactive":
        setDropDown([{ dropMenu: "View Profile" }, { dropMenu: "Active" }]);
        break;
      case "Expired":
        setDropDown([{ dropMenu: "Resend" }]);
        break;
      default:
        break;
    }
  };

  const UpdateUserDetails = async (option) => {
    switch (option) {
      case "Active":
      case "Inactive":
        setActionOption(option);
        setOpenDeactivatePopup(true);

        break;
      case "Resend":
        try {
          const response = await fetch(
            `${domain}send-registration-email/?role=User&email=${editUser.email}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const res = await response.json();
          if (response.ok) {
            const field = "status";
            const updatedUsers = allUsers.map((element) => {
              if (element.id === editUser.id) {
                return { ...element, [field]: "Invited" };
              }
              return element;
            });
            setAllUsers(updatedUsers);
            setAnchorEl(null);
            showToast("success", "Email resend successfully");
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          setAnchorEl(null);
          showToast("error", error.message);
        }
        break;
      case "View Profile":
        dispatch(setEditUserId(editUser.id));
        dispatch(setEditUserDetailsApi(true));
        dispatch(setProfileEditBack(true));
        setAnchorEl(null);
        dispatch(setGlobalSearchValue(""));
        history.push("/admin/edit/profile/");
        break;
      case "Make TeamLead":
        setAnchorEl(null);
        try {
          const response = await fetch(`${domain}make_team_lead/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              user_id: editUser.id,
              user_name: editUser.user_name,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setAnchorEl(null);
            const field = "role";
            setAllUsers([]);
            const updatedUsers = allUsers.map((element) => {
              if (element.id === editUser.id) {
                return { ...element, [field]: "Team Lead" };
              }
              return element;
            });
            setAllUsers(updatedUsers);
            showToast("success", "He is now as a Team lead");
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        } finally {
          setAnchorEl(null);
        }
        break;
      case "Invite System Access":
        setAnchorEl(null);
        setOpenEmail(true);
        setAnchorEl(null);
        break;
      case "Revoke System Access":
        setAnchorEl(null);
        setOpenRevokeDialog(true);
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const inviteSystemAccess = async (userId, emailId) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}invite_system_access/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_id: userId,
          email: emailId,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.success);
        setFetchUserDetails(true);
        setOpenEmail(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  //Api Integration for Check Existing User

  const checkExistingUser = async (userId) => {
    const res = await customGetAPI(
      `${domain}invoke/system-access/existing-user/?user_id=${userId}`
    );
    if (res.ok) {
      return res?.responseDetails?.email;
    }
  };

  //Api Integration for Revoke System Access

  const revokeSystemAccess = async (userId, emailId) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}revoke_system_access/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_id: userId,
          email: emailId,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        const field = "system_access";
        setAllUsers([]);
        const updatedUsers = allUsers.map((element) => {
          if (element.id === editUser.id) {
            return { ...element, [field]: "No" };
          }
          return element;
        });
        setAllUsers(updatedUsers);
        showToast("success", res.success);
        setOpenRevokeDialog(false);
        setFetchUserDetails(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialogBox(false);
  };

  const handleDeactivateDialogBox = () => {
    setOpenDeactivatePopup(false);
  };

  const deactivateUser = async () => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}deactivate/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_id: editUser.id,
          status: actionOption,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        if (response.status === 201) {
          setAnchorEl(null);
          if (actionOption === "Active") {
            showToast("success", res.message);
          } else if (actionOption === "Inactive") {
            showToast("error", res.message);
          }
          const field = "status";
          setAllUsers([]);
          const updatedUsers = allUsers.map((element) => {
            if (element.id === editUser.id) {
              return { ...element, [field]: actionOption };
            }
            return element;
          });
          setAllUsers(updatedUsers);
        } else {
          setAnchorEl(null);
          throw new Error(
            `Cannot inactivate team lead when there were employees assigned under him. Either remove or move all the employess under him to proceed further.`
          );
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      handleDeactivateDialogBox();
      setButtonLoader(false);
    }
  };

  //ToDo: Create user in the administration hidden with sadheesh sir instructions
  // const navigateToCreateUser = () => {
  //   dispatch(setGlobalSearchValue(""));
  //   history.push("/admin/create/user/");
  // };

  const handleCompanyName = (event, data) => {
    if (data) {
      dispatch(setAdminCompanyName(data.label));
      dispatch(setAdminCompanyId(data.id));
      localStorage.setItem("adminCompanyId", data.id);
      localStorage.setItem("adminCompanyname", data.label);
      setFetchUserDetails(true);
    } else {
      dispatch(setAdminCompanyName(""));
    }
  };

  return (
    <React.Fragment>
      {companyDetails.length > 1 ? (
        <React.Fragment>
          <Mui.Grid
            container
            sx={{ paddingTop: { xs: 7, sm: 4, md: 4, lg: 2 } }}
          >
            <Mui.Grid xs={12} sm={3} md={3} lg={3}>
              <CustomAutoComplete
                dataList={companyDetails.map((obj) => ({
                  ...obj,
                  label: obj.companyname,
                }))}
                selectedValue={adminCompanyName}
                updateDataFunction={handleCompanyName}
                placeHolder="Please select the company name"
                Icons={Apartment}
              />
            </Mui.Grid>
          </Mui.Grid>
          {dataViewFormat === "ListView" ? (
            <ListView
              openRevokeDialog={openRevokeDialog}
              setOpenRevokeDialog={setOpenRevokeDialog}
              openEmail={openEmail}
              setOpenEmail={setOpenEmail}
              checkExistingUser={checkExistingUser}
              inviteSystemAccess={inviteSystemAccess}
              revokeSystemAccess={revokeSystemAccess}
              allUsers={allUsers}
              isLoading={isLoading}
              updateMenuItem={updateMenuItem}
              dropDown={dropDown}
              editUser={editUser}
              setEditUserDetails={setEditUserDetails}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              filterChanges={filterChanges}
              setFilterChanges={setFilterChanges}
              UpdateUserDetails={UpdateUserDetails}
              buttonLoader={buttonLoader}
              setPage={setPage}
              page={page}
            />
          ) : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <DialogWithoutCloseOption
            handleCloseDialog={handleCloseDialogBox}
            open={openDialogBox}
            title="Welcome to Organflow"
          >
            <Mui.Typography
              fontSize={25}
              sx={{ paddingLeft: 3, fontWeight: "bold" }}
            >
              Please create the company
            </Mui.Typography>
            <Mui.Grid container>
              <Mui.Grid xs={9}></Mui.Grid>
              <Mui.Grid xs={3}>
                <Mui.Button
                  onClick={() => history.push("/admin/companies")}
                  sx={{
                    background: themes.primaryIndicator,
                    color: themes.whiteColor,
                    float: "right",
                    width: 150,
                    "&:hover": {
                      backgroundColor: themes.primaryIndicator,
                    },
                  }}
                >
                  Next
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </DialogWithoutCloseOption>
        </React.Fragment>
      )}
      <GlobalDialogBox
        open={openDeactivatePopup}
        handleCloseDialog={handleDeactivateDialogBox}
        title={
          actionOption === "Inactive"
            ? "Inactivate the user"
            : "Activate the user"
        }
      >
        <Mui.Typography sx={{ fontSize: 22 }}>
          Do you want to{" "}
          {actionOption === "Inactive" ? "inactivate" : "activate"} the user?
        </Mui.Typography>
        <Mui.Grid
          container
          spacing={2}
          sx={{ justifyContent: "flex-end", paddingTop: 1 }}
        >
          <Mui.Grid item>
            <CustomButton
              actionFuntion={handleDeactivateDialogBox}
              actionName="No"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid item>
            <CustomButton
              actionFuntion={deactivateUser}
              actionName="Yes"
              typeName="submit"
              disableAction={
                openDeactivatePopup && buttonLoader ? buttonLoader : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
