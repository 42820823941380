import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useDispatch } from "react-redux";
import { Edit, Delete } from "@mui/icons-material";
import { showToast } from "services/utils/Status";
import { Loader } from "components/Loader";
import { PayingUnitHeader } from "pages/Administration/AdministrationHeaders";
import { AddWorkUnit } from "./AddWorkUnit";
import { EditWorkUnit } from "./EditWorkUnit";
import { GlobalDialogBox } from "components/GlobalDialogBox";

const keys = [
  "work_unit_name",
  "department_name",
  "total_number_of_work_unit",
  "unit_type",
  "amount",
  "default_unit",
  "effective_from",
  "department_name",
];

export const PayingUnit = ({
  companyId,
  payUnit,
  setPayUnit,
  editWorkUnitPay,
  setEditWorkUnitPay,
  setShowTopBar,
  departmentId,
  selectComponent,
  refreshCall,
  setRefreshCall,
}) => {
  const { domain, globalSearchValue, token } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deleteWorkUnitPayDetails, setDeleteWorkUnitPayDetails] = useState({});
  const [workUnitDataDetails, setWorkUnitDataDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const {
    result: workUnitData,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}work_unit_pay/?department=${departmentId}`);

  useEffect(() => {
    if (selectComponent === "Pay" && refreshCall) {
      reCallApi(true);
      setRefreshCall(false);
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (updateData && workUnitData?.length !== 0 && workUnitData) {
      setWorkUnitDataDetails(workUnitData);
      setUpdateData(false);
    }
  }, [workUnitData, updateData]);

  useEffect(() => {
    if (workUnitData?.length !== 0 || workUnitData?.length === 0) {
      const filteredResults = workUnitData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setWorkUnitDataDetails(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [globalSearchValue, page, workUnitData]);

  const deleteWorkUnit = async (id) => {
    try {
      const response = await fetch(`${domain}work_unit_pay/${id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Work unit pay deleted successfully");
        setEditWorkUnitPay(false);
        reCallApi(true);
        setOpenDialog(false);
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      {payUnit ? (
        <AddWorkUnit
          companyId={companyId}
          setPayUnit={setPayUnit}
          reCallApi={reCallApi}
          setShowTopBar={setShowTopBar}
        />
      ) : editWorkUnitPay ? (
        <EditWorkUnit
          reCallApi={reCallApi}
          deleteWorkUnitPayDetails={deleteWorkUnitPayDetails}
          setEditWorkUnitPay={setEditWorkUnitPay}
          workUnitDataDetails={workUnitDataDetails}
          setShowTopBar={setShowTopBar}
        />
      ) : (
        <React.Fragment>
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "3px 15px",
                    },
                  }}
                >
                  {PayingUnitHeader.map((head) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {head.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {workUnitDataDetails.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={12} align="center">
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  workUnitDataDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((account, index) => (
                      <React.Fragment>
                        <Mui.TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              height: "10px",
                              padding: "6px 15px",
                            },
                          }}
                        >
                          {PayingUnitHeader.map((head) =>
                            head.name === "Actions" ? (
                              <Mui.TableCell>
                                <Mui.IconButton
                                  onClick={() => {
                                    setShowTopBar(false);
                                    setDeleteWorkUnitPayDetails(account);
                                    setEditWorkUnitPay(true);
                                    dispatch(setGlobalSearchValue(""));
                                  }}
                                >
                                  <Edit />
                                </Mui.IconButton>
                                <Mui.IconButton
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setDeleteWorkUnitPayDetails(account);
                                  }}
                                >
                                  <Delete />
                                </Mui.IconButton>
                              </Mui.TableCell>
                            ) : (
                              <Mui.TableCell>
                                {account[head.key] ? account[head.key] : "N/A"}
                              </Mui.TableCell>
                            )
                          )}
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {workUnitDataDetails?.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={workUnitDataDetails?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete WorkUnit Pay"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 21.5 }}>
          Are you sure,you want to delete the{" "}
          <span style={{ color: themes.redColor }}>
            {deleteWorkUnitPayDetails.work_unit_name}
          </span>
          ?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => deleteWorkUnit(deleteWorkUnitPayDetails.id)}
              actionName="Yes"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
