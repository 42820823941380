import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useSelector } from "react-redux";
import { customAPI } from "services/utils/CustomAPI";
import { showToast } from "services/utils/Status";
import moment from "moment";

export const EditPaySchedule = ({
  companyId,
  companyName,
  setEditPaySchedule,
  editPayScheduleDetails,
  reCallApi,
  reCallApiWithApproval,
  setViewPayRunScreen,
  updatePayrunCycle,
  setUpdatePayrunCycle,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain } = useSelector((state) => state.tokenReducer);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { result: payrunCycleData, loading: payrunCycleLoading } = useFetch(
    `${domain}pay-run-cycles/`
  );
  const [payCycleDateDay, setPayCycleDateDay] = useState("");
  const [payCycle, setPayCycle] = useState("");

  const [payrunCycleName, setPayrunCycleName] = useState(
    editPayScheduleDetails?.pay_run_cycle_name
  );
  const [payrunName, setPayrunName] = useState(editPayScheduleDetails.name);

  useEffect(() => {
    const addnewLabelKeyPayrunCycle = () => {
      payrunCycleData.forEach((element) => {
        if (element?.id === editPayScheduleDetails?.pay_run_cycle) {
          setPayCycle(element?.pay_run_cycle_type);
          setPayCycleDateDay(element?.day_of_week);
        }
      });
      setUpdatePayrunCycle(false);
    };
    if (updatePayrunCycle && !payrunCycleLoading) {
      addnewLabelKeyPayrunCycle();
    }
  });

  useEffect(() => {
    setValue("name", editPayScheduleDetails.name);
    setValue(
      "start_date",
      new Date(
        editPayScheduleDetails.start_date?.split("-").reverse().join("-")
      )
    );

    setValue("payrun_cycle", editPayScheduleDetails?.pay_run_cycle_name);
    setValue("pay_run_cycle", editPayScheduleDetails?.pay_run_cycle);
    setPayrunCycleName(editPayScheduleDetails?.pay_run_cycle_name);
  }, [editPayScheduleDetails, setValue]);

  const handlePayRunName = (event) => {
    if (event.target.value.length <= 32) {
      setValue("name", event.target.value);
      setPayrunName(event.target.value);
    }
  };

  const onSubmitFormHandle = async (data) => {
    setButtonDisabled(true);
    const options = { weekday: "long" }; // Set the options to get the full day name
    const dayName = new Intl.DateTimeFormat("en-US", options).format(
      data.start_date
    );
    let checkDate =
      payCycle === "Monthly"
        ? true
        : dayName === payCycleDateDay
        ? true
        : false;
    let payload = {
      ...data,
      company: companyId,
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      run_date: editPayScheduleDetails?.run_date
        ?.split("-")
        .reverse()
        .join("-"),
      end_date: editPayScheduleDetails?.end_date
        ?.split("-")
        .reverse()
        .join("-"),
    };
    if (checkDate) {
      const response = await customAPI(
        `${domain}payschedule/${editPayScheduleDetails?.id}/`,
        "PUT",
        payload
      );
      if (response.ok) {
        showToast("success", "Payschedule updated successfully");
        reCallApi(true);
        setEditPaySchedule(false);
        setViewPayRunScreen(false);
      }
      setButtonDisabled(false);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingTop: 1.5,
          paddingBottom: {
            xs: 5,
            sm: 5,
            md: 10,
            lg: 10,
            xl: 10,
          },
        }}
      >
        <BackArrow
          actionFunction={() => {
            setEditPaySchedule(false);
            setViewPayRunScreen(false);
            setUpdatePayrunCycle(true);
          }}
        />
        &nbsp;
        <Mui.Typography
          sx={{
            fontSize: 25,
            fontWeight: "bold",
            color: themes.blackColor,
            paddingTop: 1,
          }}
        >
          Edit Payrun Schedule
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmitFormHandle)}>
        <Mui.Grid
          container
          sx={{
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 24,
                fontWeight: 800,
                font: "bold",
                textAlign: "center",
              }}
            >
              Edit Payrun Schedule
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              paddingTop: -1,
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
              borderRadius: "0 0 10px 10px",
            }}
          >
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payrun Cycle
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>{editPayScheduleDetails?.end_date}</Mui.Grid>
            </Mui.Grid>
            {payrunCycleName !== "" ? (
              <Mui.Grid container sx={{ paddingTop: 2 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Pay Cycle
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.Grid container>
                    <Mui.Grid xs={4}>{payCycle}</Mui.Grid>
                    <Mui.Grid xs={4}>
                      {payCycle === "Monthly" ? "Start Date" : "Start Day"}
                    </Mui.Grid>
                    <Mui.Grid xs={4}>{payCycleDateDay}</Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            ) : null}
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payrun Starts On
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>{editPayScheduleDetails?.start_date}</Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2, paddingBottom: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payrun Enddate
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>{editPayScheduleDetails?.end_date}</Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1, paddingBottom: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payrun Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={payrunName}
                  rules={{
                    ...register("name", {
                      required: "payrunname is required",
                      maxLength: {
                        value: 32,
                        message: "Maximum 32 characters are required",
                      },
                      validate: {
                        atleastOneCharacterWithSpaceReg: (value) => {
                          if (
                            atleastOneCharacterWithSpace.test(value) ||
                            value === ""
                          ) {
                            return true;
                          } else {
                            return "Atleast one alphabet required";
                          }
                        },
                        minLengthNoSpaces: (value) => {
                          if (value?.trim().length < 3 && value !== "") {
                            return minimum3Characters;
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={handlePayRunName}
                  placeHolder="Enter the payrun name"
                  errorMessage={
                    errors?.name?.message ? errors?.name?.message : ""
                  }
                  textBoxError={errors.name ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => setEditPaySchedule(false)}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;
              <CustomButton
                actionName="Update"
                typeName="submit"
                disableAction={buttonDisabled}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
