import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { tableRowSx } from "services/constants";

const keys = [
  "status",
  "user_name",
  "amount",
  "date_incurred",
  "expense_type",
  "description",
  "reason",
];

export const History = ({
  departmentChange,
  setDepartmentChange,
  userChange,
  setUserChange,
  expenseScreen,
  departmentId,
  userId,
}) => {
  const { domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [expandedRow, setExpandedRow] = useState(null);
  const {
    result: advanceDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    `${domain}expense/?user_id=${userDetails.id}&approved_reject_status=Approved&department_id=${departmentId}&selected_user_id=${userId}`
  );
  const [ExpenseHistory, setExpenseHistory] = useState(advanceDetails);

  useEffect(() => {
    if ((departmentChange || userChange) && expenseScreen === "History") {
      reCallApi(true);
      setDepartmentChange(false);
      setUserChange(false);
    }
  }, [
    departmentChange,
    expenseScreen,
    reCallApi,
    setDepartmentChange,
    setUserChange,
    userChange,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    if (advanceDetails?.length !== 0 || advanceDetails?.length === 0) {
      const filteredResults = advanceDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setExpenseHistory(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, advanceDetails]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        component={Mui.Paper}
        sx={{ width: "100%", maxHeight: 724, mt: 2 }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
              "& .MuiTableCell-root": {
                height: "15px",
                padding: "6px 35px",
              },
            }}
          >
            <Mui.TableRow sx={tableRowSx}>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Date</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Employee Name</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Amount</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Expense Type</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Status</Mui.Typography>
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {ExpenseHistory.length === 0 && !loading ? (
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell colSpan={12}>
                  <Mui.Alert sx={{ paddingTop: 1 }} severity="info">
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              ExpenseHistory.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((item) => (
                <>
                  <Mui.TableRow sx={tableRowSx}>
                    <Mui.TableCell>
                      {expandedRow === item.id ? (
                        <Mui.IconButton
                          onClick={() => {
                            setExpandedRow(null);
                          }}
                        >
                          <KeyboardArrowDownIcon
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                        </Mui.IconButton>
                      ) : (
                        <Mui.IconButton
                          onClick={() => {
                            handleRowClick(item.id);
                          }}
                        >
                          <KeyboardArrowRightIcon
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                        </Mui.IconButton>
                      )}
                    </Mui.TableCell>
                    <Mui.TableCell>{item?.date_incurred}</Mui.TableCell>
                    <Mui.TableCell>{item?.user_name}</Mui.TableCell>
                    <Mui.TableCell>{item?.amount}</Mui.TableCell>
                    <Mui.TableCell>{item?.expense_type}</Mui.TableCell>
                    <Mui.TableCell>{item?.status}</Mui.TableCell>
                  </Mui.TableRow>
                  {expandedRow === item.id && (
                    <Mui.TableRow sx={tableRowSx}>
                      <Mui.TableCell colSpan={9}>
                        <Mui.TableContainer
                          sx={{
                            marginTop: 0.5,
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%px",
                          }}
                          className="w-96 border rounded"
                        >
                          <Mui.Table className="border">
                            <Mui.Typography
                              variant="body1"
                              gutterBottom
                              sx={{ marginLeft: 1, marginTop: 0.5 }}
                            >
                              <strong>Description :</strong>{" "}
                              <Mui.Typography
                                fontSize={15}
                                color="textSecondary"
                                sx={{ marginLeft: 13 }}
                              >
                                {item.description}
                              </Mui.Typography>
                            </Mui.Typography>
                          </Mui.Table>
                        </Mui.TableContainer>
                        <Mui.TableContainer
                          sx={{
                            marginTop: 0.5,
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%px",
                          }}
                          className="w-96 border rounded"
                        >
                          <Mui.Table className="border">
                            <Mui.Typography
                              variant="body1"
                              gutterBottom
                              sx={{ marginLeft: 1, marginTop: 0.5 }}
                            >
                              <strong>Reason for Rejection :</strong>{" "}
                              <Mui.Typography
                                fontSize={15}
                                color="textSecondary"
                                sx={{ marginLeft: 13 }}
                              >
                                {item.reason}
                              </Mui.Typography>
                            </Mui.Typography>
                          </Mui.Table>
                        </Mui.TableContainer>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  )}
                </>
              ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {ExpenseHistory.length > 25 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={ExpenseHistory.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
