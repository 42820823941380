import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import {
  WatchLater,
  Payments,
  CreditScore,
  PersonRemove,
  AccountBalanceWallet,
  AccessibilityNew,
  Timelapse,
} from "@mui/icons-material";
export const SettingsMenu = ({
  setCurrentScreen,
  currentScreen,
  setReloadData,
  countDetails,
  expandScreen,
  expandLeavePermission,
  setExpandLeavePermission,
}) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid
        sx={{
          marginTop: 2,
          height:
            (currentScreen === "Loans" ||
              currentScreen === "Leaves" ||
              currentScreen === "Permissions") &&
            (expandScreen || expandLeavePermission)
              ? "100%"
              : "92.5vh",
          overflow: "hidden",
          backgroundColor: themes.primary,
          borderRadius: 2,
        }}
        component={Paper}
        elevation={3}
      >
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Leaves" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Leaves" ? themes.whiteColor : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Leaves");
              setCurrentScreen("Leaves");
              setReloadData(true);
              dispatch(setGlobalSearchValue(""));
            }}
          >
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Mui.Grid item>
                {" "}
                <Typography sx={{ padding: 1.5, fontSize: 17 }}>
                  <PersonRemove sx={{ fontSize: 20 }} /> Leaves
                </Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                sx={{
                  paddingTop: 1.4,
                }}
              >
                {countDetails?.leave_count !== 0 && (
                  <div
                    style={{
                      marginLeft: "auto",
                      width: 34,
                      height: 34,
                      borderRadius: "50%",
                      backgroundColor: themes.blackColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    <span
                      style={{
                        color: themes.lightGreen,
                      }}
                    >
                      {countDetails?.leave_count}
                    </span>
                  </div>
                )}
              </Mui.Grid>
            </Mui.Grid>
          </Paper>
        </Grid>

        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Permissions" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Permissions"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              dispatch(setDepartmentId(99999));
              dispatch(setDepartmentName("All"));
              localStorage.setItem("approveSettingScreens", "Permissions");
              setCurrentScreen("Permissions");
              setReloadData(true);
              dispatch(setGlobalSearchValue(""));
            }}
          >
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Mui.Grid item>
                <Typography sx={{ padding: 1.5, fontSize: 17 }}>
                  <Timelapse sx={{ fontSize: 20 }} /> Permissions{" "}
                </Typography>
              </Mui.Grid>
              <Mui.Grid item sx={{ paddingTop: 1.4 }}>
                {countDetails?.permission_count !== 0 && (
                  <div
                    style={{
                      marginLeft: "auto",
                      width: 34,
                      height: 34,
                      borderRadius: "50%",
                      backgroundColor: themes.blackColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    <span
                      style={{
                        color: themes.lightGreen,
                      }}
                    >
                      {countDetails?.permission_count}
                    </span>
                  </div>
                )}
              </Mui.Grid>
            </Mui.Grid>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Over time" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Over time"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Over time");
              setCurrentScreen("Over time");
              dispatch(setGlobalSearchValue(""));
              setReloadData(true);
              setExpandLeavePermission(false);
            }}
          >
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Mui.Grid item>
                <Typography sx={{ padding: 1.5, fontSize: 17 }}>
                  <WatchLater sx={{ fontSize: 20 }} /> Over Time{" "}
                </Typography>
              </Mui.Grid>
              <Mui.Grid item sx={{ paddingTop: 1.4 }}>
                {countDetails?.over_time !== 0 && (
                  <div
                    style={{
                      marginLeft: "auto",
                      width: 34,
                      height: 34,
                      borderRadius: "50%",
                      backgroundColor: themes.blackColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    <span
                      style={{
                        color: themes.lightGreen,
                      }}
                    >
                      {countDetails?.over_time}
                    </span>
                  </div>
                )}
              </Mui.Grid>
            </Mui.Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={currentScreen === "Advance Salary" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Advance Salary"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Advance Salary");
              setCurrentScreen("Advance Salary");
              dispatch(setGlobalSearchValue(""));
              setReloadData(true);
              setExpandLeavePermission(false);
            }}
          >
            <Mui.Grid container>
              <Mui.Grid item>
                <Typography sx={{ padding: 1.5, fontSize: 17 }}>
                  <Payments sx={{ fontSize: 20 }} /> Advance Salary
                </Typography>
              </Mui.Grid>
              <Mui.Grid item sx={{ paddingTop: 1.3 }}>
                {countDetails?.advance_salary !== 0 && (
                  <div
                    style={{
                      marginLeft: "auto",
                      width: 34,
                      height: 34,
                      borderRadius: "50%",
                      backgroundColor: themes.blackColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    <span
                      style={{
                        color: themes.lightGreen,
                      }}
                    >
                      {countDetails?.advance_salary}
                    </span>
                  </div>
                )}
              </Mui.Grid>
            </Mui.Grid>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Loans" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Loans" ? themes.whiteColor : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Loans");
              setCurrentScreen("Loans");
              dispatch(setGlobalSearchValue(""));
              setReloadData(true);
              setExpandLeavePermission(false);
            }}
          >
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Mui.Grid item>
                <Typography sx={{ padding: 1.5, fontSize: 17 }}>
                  <CreditScore sx={{ fontSize: 20 }} /> Loans
                </Typography>
              </Mui.Grid>
              <Mui.Grid item sx={{ paddingTop: 1.3 }}>
                {countDetails?.loans !== 0 && (
                  <div
                    style={{
                      marginLeft: "auto",
                      width: 34,
                      height: 34,
                      borderRadius: "50%",
                      backgroundColor: themes.blackColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    <span
                      style={{
                        color: themes.lightGreen,
                      }}
                    >
                      {countDetails?.loans}
                    </span>
                  </div>
                )}
              </Mui.Grid>
            </Mui.Grid>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "Expense" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Expense"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Expense");
              setCurrentScreen("Expense");
              dispatch(setGlobalSearchValue(""));
              setReloadData(true);
              setExpandLeavePermission(false);
            }}
          >
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Mui.Grid item>
                <Typography sx={{ padding: 1.5, fontSize: 17 }}>
                  <AccountBalanceWallet sx={{ fontSize: 20 }} /> Expense
                </Typography>
              </Mui.Grid>
              <Mui.Grid item sx={{ paddingTop: 1.3 }}>
                {countDetails?.expense !== 0 && (
                  <div
                    style={{
                      marginLeft: "auto",
                      width: 34,
                      height: 34,
                      borderRadius: "50%",
                      backgroundColor: themes.blackColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    <span
                      style={{
                        color: themes.lightGreen,
                      }}
                    >
                      {countDetails?.expense}
                    </span>
                  </div>
                )}
              </Mui.Grid>
            </Mui.Grid>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={currentScreen === "WeekOff" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "WeekOff"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "WeekOff");
              setCurrentScreen("WeekOff");
              dispatch(setGlobalSearchValue(""));
              setReloadData(true);
              setExpandLeavePermission(false);
            }}
          >
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Mui.Grid item>
                <Typography sx={{ padding: 1.5, fontSize: 17 }}>
                  <AccessibilityNew sx={{ fontSize: 20 }} /> Time Off Policy
                </Typography>
              </Mui.Grid>
              <Mui.Grid item sx={{ paddingTop: 1.3 }}>
                {countDetails?.week_off !== 0 && countDetails?.week_off && (
                  <div
                    style={{
                      marginLeft: "auto",
                      width: 34,
                      height: 34,
                      borderRadius: "50%",
                      backgroundColor: themes.blackColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    <span
                      style={{
                        color: themes.lightGreen,
                      }}
                    >
                      {countDetails?.week_off}
                    </span>
                  </div>
                )}
              </Mui.Grid>
            </Mui.Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
