import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { AddShiftGrade } from "./AddShiftGrade";
import { EditShiftGrade } from "./EditShiftGrade";
import { Loader } from "components/Loader";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { ShiftGradeHeader } from "pages/Administration/AdministrationHeaders";

const keys = [
  "shift_grade_id",
  "shift_grade_name",
  "shift_duration",
  "weekoff_days",
  "factor_weekoff_days",
  "work_unit",
  "no_of_shift_category",
  "employee_count",
];

export const ShiftGrade = ({
  companyId,
  payUnit,
  setPayUnit,
  editWorkUnitPay,
  setEditWorkUnitPay,
  setShowTopBar,
}) => {
  const { domain, globalSearchValue, token } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [shiftGradeDetails, setWorkUnitDataDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [EditGrade, setEditGrade] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();

  const {
    result: shiftGradeData,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}shift-grade/`);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (updateData && shiftGradeData?.length !== 0 && shiftGradeData) {
      setWorkUnitDataDetails(shiftGradeData);
      setUpdateData(false);
    }
  }, [shiftGradeData, updateData]);

  useEffect(() => {
    if (shiftGradeData?.length !== 0 || shiftGradeData?.length === 0) {
      const filteredResults = shiftGradeData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setWorkUnitDataDetails(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [globalSearchValue, page, shiftGradeData]);

  const deleteShiftGrade = async (editWorkUnitPay) => {
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}shift-grade/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
          shift_grade_id: editWorkUnitPay.shift_grade_id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Shift grade deleted successfully");
        setEditWorkUnitPay(false);
        setOpenDialog(false);
        reCallApi(true);
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <React.Fragment>
      {payUnit ? (
        <AddShiftGrade
          companyId={companyId}
          setPayUnit={setPayUnit}
          reCallApi={reCallApi}
          setShowTopBar={setShowTopBar}
        />
      ) : loading ? (
        <Loader />
      ) : EditGrade ? (
        <EditShiftGrade
          reCallApi={reCallApi}
          editWorkUnitPay={editWorkUnitPay}
          shiftGradeDetails={shiftGradeDetails}
          setEditGrade={setEditGrade}
          setShowTopBar={setShowTopBar}
        />
      ) : (
        <React.Fragment>
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "3px 15px",
                    },
                  }}
                >
                  {ShiftGradeHeader.map((head) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {head.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {shiftGradeDetails.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={8} align="center">
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  shiftGradeDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((account, index) => (
                      <React.Fragment>
                        <Mui.TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              height: "10px",
                              padding: "6px 35px",
                            },
                          }}
                        >
                          {ShiftGradeHeader.map((head) =>
                            head.name === "Actions" ? (
                              <Mui.TableCell>
                                <Mui.Stack direction="row">
                                  <Mui.IconButton
                                    onClick={() => {
                                      setEditWorkUnitPay(account);
                                      setEditGrade(true);
                                      setShowTopBar(false);
                                      dispatch(setGlobalSearchValue(""));
                                    }}
                                  >
                                    <Edit sx={{ fontSize: "10" }} />
                                  </Mui.IconButton>
                                  <Mui.IconButton
                                    onClick={() => {
                                      setEditWorkUnitPay(account);
                                      setOpenDialog(true);
                                    }}
                                    disabled={
                                      account.no_of_shift_category !== 0 &&
                                      account.employee_count !== 0
                                    }
                                  >
                                    <Delete />
                                  </Mui.IconButton>
                                </Mui.Stack>
                              </Mui.TableCell>
                            ) : (
                              <Mui.TableCell>{account[head.key]}</Mui.TableCell>
                            )
                          )}
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {shiftGradeDetails?.length > 25 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={shiftGradeDetails?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}

      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete Shift Grade"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 20, font: "bold" }}>
          Are you sure, you want to delete the &nbsp;
          <span style={{ color: themes.redColor }}>
            {editWorkUnitPay?.shift_grade_name}
          </span>
          ?
        </Mui.Typography>

        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => deleteShiftGrade(editWorkUnitPay)}
              actionName="Yes"
              typeName="button"
              disableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
