import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  CalendarMonth,
} from "@mui/icons-material";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { showToast } from "services/utils/Status";
import TablePagination from "@mui/material/TablePagination";
import { essApplyLeave } from "services/constants";
import { PermissionsDetails } from "./permissionDetails";
import { leaveSuccess } from "services/constants/SuccessMessages";
import { leaveManagamentPlaceholder } from "services/constants/PlaceHolder";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import Carousel from "react-grid-carousel";
import { getContrastingTextColor } from "services/utils/colorChecker";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { Loader } from "components/Loader";
import { tableRowSx } from "services/constants";

export const PastLeaveDetails = ({
  selectedUser,
  setReUpdatePastLeaveDetails,
  reUpdatePastLeaveDetails,
  taxYear,
  taxYearStartDate,
  taxYearEndDate,
  setSelectedUser,
  shiftId,
  handleClose,
}) => {
  const { token, domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const [taxYearStartDateUser, setTaxYearStartDate] =
    useState(taxYearStartDate);
  const { result: leaveYears } = useFetch(`${domain}leave-years/`);
  const [expandedRow, setExpandedRow] = useState(null);
  const [taxYearEndDateUser, setTaxYearEndDate] = useState(taxYearEndDate);
  const [taxYearUser, setTaxYearUser] = useState(taxYear);
  const [getDataFromApi, setGetDataFromApi] = useState(false);
  const [leavePage, setLeavePage] = useState(0);
  const [leaverowsPerPage, setLeaveRowsPerPage] = useState(25);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [pastLeaveDataSearchResults, setPastLeaveDataSearchResults] = useState(
    []
  );
  const [totalPermission, setTotalPermission] = useState(0);
  const [loadPermissionApi, setLoadPersmissionApi] = useState(false);
  const [tagIndicator, setTagIndicator] = useState("Leaves");
  const [cancelConfirmation, setCancelConfirmation] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [leaveYear, setLeaveYear] = useState(null);
  const [cancelLeaveData, setCancelLeaveData] = useState({});
  const [leaveTypeDetail, setLeaveTypeDetail] = useState([]);
  const [leaveTypeId, setLeaveTypeId] = useState(shiftId);
  const [permissionId, setPermissionId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (leaveYears.length !== 0) {
      setLeaveYear(leaveYears[0]?.name);
      setTaxYearStartDate(
        leaveYears[0]?.start_date.split("-").reverse().join("-")
      );
      setTaxYearEndDate(leaveYears[0]?.end_date.split("-").reverse().join("-"));
      setLeaveYear(
        leaveYears[0]?.start_date + " To " + leaveYears[0]?.end_date
      );
      setGetDataFromApi(true);
      setLoadPersmissionApi(true);
    }
  }, [leaveYears]);

  useEffect(() => {
    const updateLeaveDetails = async () => {
      const sortedLeaveRequests = selectedUser?.leave_requests?.sort(
        (a, b) => new Date(b.from_date) - new Date(a.from_date)
      );
      setPastLeaveDataSearchResults(sortedLeaveRequests);
      setMasterDataForSearch(sortedLeaveRequests);
    };
    if (reUpdatePastLeaveDetails) {
      updateLeaveDetails();
      setReUpdatePastLeaveDetails(false);
    }
  }, [
    reUpdatePastLeaveDetails,
    selectedUser?.leave_requests,
    setReUpdatePastLeaveDetails,
  ]);

  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Completed":
        return "primary";
      case "Approved":
        return "success";
      case "Pending":
        return "warning";
      case "Rejected":
        return "error";
      case "cancel":
        return "error";
      default:
        return "default";
    }
  };

  const handleDeleteConfirmation = (leaveId) => {
    setDeleteConfirmation(leaveId);
  };

  const handleCancelConfirmation = (leaveId) => {
    setCancelConfirmation(leaveId);
  };

  const handleDeleteCancel = (event) => {
    setDeleteConfirmation(null);
  };

  const handleCancelCancel = (event) => {
    setCancelConfirmation(null);
  };

  useEffect(() => {
    const filteredResults = masterDataForSearch?.filter((item) =>
      essApplyLeave.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setPastLeaveDataSearchResults(filteredResults);
    setLeavePage(0);
  }, [globalSearchValue, masterDataForSearch]);

  const handleDeleteLeave = async (leaveId) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}leave_request_delete/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
          id: leaveId,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", leaveSuccess.deletedLeaveRequest);
        setGetDataFromApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
    setDeleteConfirmation(null);
  };

  const handleCancelLeave = async (leaveId) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}create_cancel_leave_request/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          original_leave_request_id: leaveId,
          user_id: selectedUser?.user_id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        handleCancelCancel();
        setGetDataFromApi(true);
        showToast(
          "success",
          `Your leave request for ${cancelLeaveData.from_date} to ${cancelLeaveData.to_date} has been cancelled successfully`
        );
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await fetch(
          `${domain}leavesummary/v1/?user_id=${selectedUser?.user_id}&start_date=${taxYearStartDateUser}&end_date=${taxYearEndDateUser}&leave_type_id=${leaveTypeId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setPastLeaveDataSearchResults(res?.leave_count?.leave_requests);
          setMasterDataForSearch(res?.leave_count?.leave_requests);
          setSelectedUser(res?.leave_count);
          setLeaveTypeDetail(res?.available_leave_types);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (getDataFromApi && tagIndicator === "Leaves") {
      setLoading(true);
      getUserDetails();
      setGetDataFromApi(false);
    }
  }, [
    domain,
    getDataFromApi,
    selectedUser?.user_id,
    setSelectedUser,
    leaveTypeId,
    taxYearEndDateUser,
    taxYearStartDateUser,
    token,
    tagIndicator,
  ]);

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  return (
    <React.Fragment>
      <Mui.Box
        sx={{
          marginTop: 0,
          boxShadow: 3,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
          marginLeft: 1,
          marginRight: 1,
        }}
      >
        <Mui.Grid container sx={{ paddingTop: 4, paddingBottom: 2 }}>
          <Mui.Grid xs={1} md={1} lg={1} sm={1} sx={{ marginTop: -2 }}>
            <BackArrow
              actionFunction={() => {
                dispatch(setGlobalSearchValue(""));
                handleClose();
              }}
            />
          </Mui.Grid>
          <Mui.Grid xs={3} md={3} lg={1} sm={3}>
            <Mui.Avatar
              sx={{
                marginTop: -2,
                width: 50,
                height: 50,
                marginLeft: 5,
                backgroundColor: "#ff4d88",
              }}
              alt={selectedUser?.user_name.charAt(0).toUpperCase()}
              src={selectedUser?.image_url}
            />
          </Mui.Grid>
          <Mui.Grid xs={3} md={3} lg={2} sm={3}>
            <Mui.Tooltip title={selectedUser?.user_name}>
              <Mui.Typography
                sx={{
                  fontWeight: "bold",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedUser?.user_name}
              </Mui.Typography>
            </Mui.Tooltip>
            <Mui.Tooltip title={selectedUser?.role}>
              <Mui.Typography
                sx={{
                  fontWeight: "bold",
                  color: "#B2B2B5",
                  marginLeft: 5,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedUser?.role}
              </Mui.Typography>
            </Mui.Tooltip>
          </Mui.Grid>
          <Mui.Grid lg={5}></Mui.Grid>
          <Mui.Grid xs={4.5} md={4.5} lg={2.5} sm={4.5}>
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={leaveYear}
              options={leaveYears.map((obj) => ({
                ...obj,
                label: obj.name,
                yearDate: obj.start_date + " To " + obj.end_date,
              }))}
              sx={{ width: "100%", marginTop: -2 }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.label}</li>;
              }}
              ListboxProps={{
                style: {
                  maxHeight: 150,
                },
              }}
              onChange={(event, value) => {
                if (value) {
                  setTaxYearUser(value.label);
                  setTaxYearStartDate(
                    value.start_date.split("-").reverse().join("-")
                  );
                  setTaxYearEndDate(
                    value.end_date.split("-").reverse().join("-")
                  );
                  setGetDataFromApi(true);
                  setLoadPersmissionApi(true);
                  setLeaveYear(value.yearDate);
                }
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  label={taxYearUser}
                  sx={{ background: themes.whiteColor }}
                  placeholder={leaveManagamentPlaceholder.taxyearLeave}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <CalendarMonth
                          style={{ margin: "0 8px", color: "#a6a6a6" }}
                        />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Box>

      <Mui.Grid sx={{ padding: 2 }}></Mui.Grid>

      <Carousel
        cols={4}
        rows={1}
        gap={20}
        responsiveLayout={[
          {
            breakpoint: 800,
            cols: 3,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 1000,
          },
        ]}
        autoplay={30000}
        loop={true}
      >
        <Carousel.Item>
          <Mui.Card
            sx={{
              backgroundColor:
                parseInt(leaveTypeId) === 99999
                  ? themes.primaryIndicator
                  : themes.lightGray,
              justifyContent: "center",
              alignContent: "center",
              height: parseInt(leaveTypeId) === 99999 ? "10vh" : "8vh",
              marginTop: parseInt(leaveTypeId) === 99999 ? 0 : 2,
              cursor: "pointer",
              borderRadius: 2,
              marginBottom: 1,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "hidden",
            }}
            onClick={() => {
              setPermissionId(null);
              setLeaveTypeId(99999);
              setTagIndicator("Leaves");
              setGetDataFromApi(true);
            }}
          >
            <Mui.Typography
              sx={{
                color: getContrastingTextColor(
                  parseInt(leaveTypeId) === 99999
                    ? themes.primaryIndicator
                    : themes.lightGray
                ),
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                fontWeight: 700,
                fontSize: 18,
              }}
            >
              All Leaves
            </Mui.Typography>
            <Mui.Typography
              sx={{
                color: getContrastingTextColor(
                  parseInt(leaveTypeId) === 99999
                    ? themes.primaryIndicator
                    : themes.lightGray
                ),
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                fontWeight: 500,
                fontSize: 16,
              }}
            ></Mui.Typography>
          </Mui.Card>
        </Carousel.Item>
        <Carousel.Item>
          <Mui.Card
            sx={{
              backgroundColor:
                permissionId === 99999
                  ? themes.primaryIndicator
                  : themes.lightGray,
              justifyContent: "center",
              alignContent: "center",
              height: permissionId === 99999 ? "10vh" : "8vh",
              marginTop: permissionId === 99999 ? 0 : 2,
              cursor: "pointer",
              borderRadius: 2,
              marginBottom: 1,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "hidden",
            }}
            onClick={() => {
              setPermissionId(99999);
              setLeaveTypeId("");
              dispatch(setGlobalSearchValue(""));
              setTagIndicator("Permissions");
              setLoadPersmissionApi(true);
            }}
          >
            <Mui.Typography
              sx={{
                color: getContrastingTextColor(themes.lightGray),
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                fontWeight: 700,
                fontSize: 18,
              }}
            >
              Permissions
            </Mui.Typography>
            <Mui.Typography
              sx={{
                color: getContrastingTextColor(themes.lightGray),
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                fontWeight: 500,
                fontSize: 16,
              }}
            ></Mui.Typography>
          </Mui.Card>
        </Carousel.Item>
        {leaveTypeDetail?.map((ltdet) => (
          <Carousel.Item>
            <Mui.Card
              sx={{
                backgroundColor:
                  leaveTypeId === ltdet?.leave_type_id
                    ? themes.primaryIndicator
                    : themes.lightGray,
                justifyContent: "center",
                alignContent: "center",
                height: leaveTypeId === ltdet?.leave_type_id ? "10vh" : "8vh",
                marginTop: leaveTypeId === ltdet?.leave_type_id ? 0 : 2,
                cursor: "pointer",
                borderRadius: 2,
                marginBottom: 1,
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflow: "hidden",
              }}
              onClick={() => {
                setLeaveTypeId(ltdet?.leave_type_id);
                setPermissionId(null);
                setTagIndicator("Leaves");
                setGetDataFromApi(true);
              }}
            >
              <Mui.Typography
                sx={{
                  color: getContrastingTextColor(
                    leaveTypeId === ltdet?.leave_type_id
                      ? themes.primaryIndicator
                      : themes.lightGray
                  ),
                  justifyContent: "center",
                  alignContent: "center",
                  display: "flex",
                  fontWeight: 700,
                  fontSize: 18,
                }}
              >
                {ltdet?.leave_type}
              </Mui.Typography>
              <Mui.Typography
                sx={{
                  color: getContrastingTextColor(
                    leaveTypeId === ltdet?.leave_type_id
                      ? themes.primaryIndicator
                      : themes.lightGray
                  ),
                  justifyContent: "center",
                  alignContent: "center",
                  display: "flex",
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                {ltdet?.taken_leave}
              </Mui.Typography>
            </Mui.Card>
          </Carousel.Item>
        ))}
      </Carousel>

      <Mui.Box
        sx={{
          marginTop: 2,
          boxShadow: 3,
          display: "flex",
          flexDirection: "row",
          ml: 20,
          mr: 20,
          p: 2,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? themes.primary : themes.primary,
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
      >
        {tagIndicator === "Leaves" ? (
          <>
            <Mui.Grid
              xs={12}
              sm={12}
              md={3}
              lg={3}
              sx={{ textAlign: "center", borderRight: "1px solid black" }}
            >
              <Mui.Typography>
                <span
                  style={{
                    color: themes.blackColor,
                    fontSize: 18,
                  }}
                >
                  Total
                </span>
                <span
                  style={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;{selectedUser?.total_leave || 0} days
                </span>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sm={12}
              md={3}
              lg={3}
              sx={{ textAlign: "center", borderRight: "1px solid black" }}
            >
              <Mui.Typography>
                <span
                  style={{
                    color: themes.blackColor,
                    fontSize: 18,
                  }}
                >
                  Planned{" "}
                </span>{" "}
                <span
                  style={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;{selectedUser?.planned_leave || 0}{" "}
                  days{" "}
                </span>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sm={12}
              md={3}
              lg={3}
              sx={{ textAlign: "center", borderRight: "1px solid black" }}
            >
              <Mui.Typography>
                <span
                  style={{
                    color: themes.blackColor,
                    fontSize: 18,
                  }}
                >
                  Availed{" "}
                </span>{" "}
                <span
                  style={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;{selectedUser?.completed_leave || 0}{" "}
                  days{" "}
                </span>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sm={12}
              md={3}
              lg={3}
              sx={{ textAlign: "center" }}
            >
              <Mui.Typography>
                <span
                  style={{
                    color: themes.blackColor,
                    fontSize: 18,
                  }}
                >
                  Balance{" "}
                </span>{" "}
                <span
                  style={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;{selectedUser?.available_leave || 0}{" "}
                  days
                </span>
              </Mui.Typography>
            </Mui.Grid>
          </>
        ) : (
          <>
            <Mui.Grid xs={12} sx={{ textAlign: "center" }}>
              <Mui.Typography>
                <span
                  style={{
                    color: themes.blackColor,
                    fontSize: 18,
                  }}
                >
                  <span
                    style={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    {totalPermission} &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  Permissions Taken
                </span>
              </Mui.Typography>
            </Mui.Grid>
          </>
        )}
      </Mui.Box>

      {loading ? (
        <div>
          <Loader info="Loading..." />
        </div>
      ) : null}

      {tagIndicator === "Leaves" && !loading ? (
        <Mui.Grid container sx={{ paddingRight: 1 }}>
          <Mui.TableContainer
            sx={{
              height: "100%",
              overflowY: "auto",
              marginTop: 2,
              marginLeft: "auto",
              marginRight: "auto",
              minWidth: "90%",
              maxWidth: "100%",
            }}
            className="w-96 border  rounded "
          >
            <Mui.Table className="border" sx={{ borderRadius: "20px" }}>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell className="column-cell"></Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>
                      Leave Type
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>
                      {" "}
                      Start Date
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>
                      {" "}
                      End Date
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>
                      {" "}
                      Business days{" "}
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>Status</Mui.Typography>
                  </Mui.TableCell>
                  {userDetails.id === parseInt(selectedUser?.user_id) ? (
                    <Mui.TableCell className="column-cell">
                      <Mui.Typography fontWeight="bold">Action</Mui.Typography>
                    </Mui.TableCell>
                  ) : null}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {pastLeaveDataSearchResults
                  ?.slice(
                    leavePage * leaverowsPerPage,
                    leavePage * leaverowsPerPage + leaverowsPerPage
                  )
                  .map((leave) => (
                    <>
                      <Mui.TableRow key={leave.id} sx={tableRowSx}>
                        <Mui.TableCell className="column-cell">
                          {expandedRow === leave.id ? (
                            <Mui.IconButton
                              onClick={() => {
                                setExpandedRow(null);
                              }}
                            >
                              <KeyboardArrowDown
                                style={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                }}
                              />
                            </Mui.IconButton>
                          ) : (
                            <Mui.IconButton
                              onClick={() => handleRowClick(leave.id)}
                            >
                              <KeyboardArrowRight
                                style={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                }}
                              />
                            </Mui.IconButton>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          {leave.leave_type}
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          {leave.from_date}
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          {leave.to_date}
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          <Mui.Typography variant="body1" gutterBottom>
                            {leave.business_days}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          {leave.status}
                        </Mui.TableCell>
                        {userDetails.id === parseInt(selectedUser?.user_id) ? (
                          <Mui.TableCell className="column-cell">
                            <Mui.Button
                              sx={{ borderRadius: 3 }}
                              variant="contained"
                              color={getStatusColor("cancel")}
                              className="cancel-button"
                              onClick={() => {
                                if (leave.action === "cancel") {
                                  setCancelLeaveData(leave);
                                  handleCancelConfirmation(leave.id);
                                } else {
                                  handleDeleteConfirmation(leave.id);
                                }
                              }}
                              disabled={leave.action === null ? true : false}
                            >
                              {leave.action === null ? "Cancel" : leave.action}
                            </Mui.Button>
                          </Mui.TableCell>
                        ) : null}
                      </Mui.TableRow>
                      {expandedRow === leave.id && (
                        <Mui.TableRow sx={tableRowSx}>
                          <Mui.TableCell colSpan={12}>
                            <Mui.TableContainer
                              sx={{
                                marginTop: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              <Mui.Table className="border">
                                <Mui.Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginLeft: 1, marginTop: 1 }}
                                >
                                  <strong>Description :</strong>{" "}
                                  <Mui.Typography
                                    fontSize={15}
                                    color="textSecondary"
                                    sx={{ marginLeft: 13 }}
                                  >
                                    {leave.description}
                                  </Mui.Typography>
                                </Mui.Typography>
                              </Mui.Table>
                            </Mui.TableContainer>
                            {leave.status === "Rejected" ? (
                              <Mui.TableContainer
                                sx={{
                                  marginTop: 0.5,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "100%px",
                                }}
                                className="w-96 border rounded"
                              >
                                <Mui.Table className="border">
                                  <Mui.Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{ marginLeft: 1, marginTop: 1 }}
                                  >
                                    <strong>Reason for Rejection :</strong>{" "}
                                    <Mui.Typography
                                      fontSize={15}
                                      color="textSecondary"
                                      sx={{ marginLeft: 13 }}
                                    >
                                      {leave.reason}
                                    </Mui.Typography>
                                  </Mui.Typography>
                                </Mui.Table>
                              </Mui.TableContainer>
                            ) : null}
                          </Mui.TableCell>
                        </Mui.TableRow>
                      )}
                    </>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
            {pastLeaveDataSearchResults?.length > 0 ? null : (
              <div style={{ paddingTop: 8 }}>
                <Mui.Alert severity="info">
                  There is no data available
                </Mui.Alert>
              </div>
            )}
            {pastLeaveDataSearchResults?.length > 25 ? (
              tagIndicator === "Leaves" ? (
                <TablePagination
                  sx={{ marginRight: 7 }}
                  className="custom-pagination"
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  component="div"
                  count={pastLeaveDataSearchResults?.length}
                  rowsPerPage={leaverowsPerPage}
                  page={leavePage}
                  onPageChange={handleChangeLeavePage}
                  onRowsPerPageChange={handleChangeLeaveRowsPerPage}
                />
              ) : null
            ) : null}
          </Mui.TableContainer>
        </Mui.Grid>
      ) : (
        <PermissionsDetails
          selectedUser={selectedUser}
          loadPermissionApi={loadPermissionApi}
          setLoadPersmissionApi={setLoadPersmissionApi}
          setTotalPermission={setTotalPermission}
          taxYearStartDateUser={taxYearStartDateUser}
          taxYearEndDateUser={taxYearEndDateUser}
        />
      )}
      <GlobalDialogBox
        open={Boolean(deleteConfirmation)}
        handleCloseDialog={handleDeleteCancel}
        title=" Delete Confirmation"
      >
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 21 }}>
            {" "}
            Are you sure you want to delete this leave?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.DialogActions>
          <CustomButton
            actionFuntion={handleDeleteCancel}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => {
              handleDeleteLeave(deleteConfirmation);
            }}
            disableAction={
              deleteConfirmation && buttonLoader ? buttonLoader : false
            }
            actionName="Yes"
            typeName="button"
          />
        </Mui.DialogActions>
      </GlobalDialogBox>
      <GlobalDialogBox
        open={Boolean(cancelConfirmation)}
        handleCloseDialog={handleCancelCancel}
        title="Cancel confirmation"
      >
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 21 }}>
            {" "}
            Are you sure you want to cancel this leave?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.DialogActions>
          <CustomButton
            actionFuntion={handleCancelCancel}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => handleCancelLeave(cancelConfirmation)}
            disableAction={
              cancelConfirmation && buttonLoader ? buttonLoader : false
            }
            actionName="Yes"
            typeName="button"
          />
        </Mui.DialogActions>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
