import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { tableRowSx } from "services/constants";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { DateRangePicker } from "./DateRangePicker";
import moment from "moment";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "components/Loader";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";

export const Approved = ({ startDate, endDate }) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [loading, setLoading] = useState(false);
  const [expandedRow, setExpandedRow] = React.useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredData, setFilteredData] = useState([]);
  const [rejectOt, setRejectOt] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [showDetails, setShowDetails] = useState(true);

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    const getWorkingHours = async () => {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
      const mostRecentMonday = new Date(
        currentDate.setDate(currentDate.getDate() + diffToMonday)
      );
      const currentDateDetails = new Date();
      const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
      const currentWeekSunday = new Date(
        currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
      );
      let searchStartDate = moment(new Date(mostRecentMonday))
        .format("DD/MM/YYYY")
        .split("/")
        .reverse()
        .join("-");
      let searchEndDate = moment(new Date(currentWeekSunday))
        .format("DD/MM/YYYY")
        .split("/")
        .reverse()
        .join("-");
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}over-time-approve/?selected_user_id=${userDetails.id}&status=Rejected&start_date=${searchStartDate}&end_date=${searchEndDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setFilteredData(res);
          setRejectOt(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
        setLoading(false);
        setShowDetails(false);
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (showDetails) {
      getWorkingHours();
    }
  }, [domain, setShowDetails, showDetails, token, userDetails.id]);

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
    const mostRecentMonday = new Date(
      currentDate.setDate(currentDate.getDate() + diffToMonday)
    );
    const currentDateDetails = new Date();
    const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
    const currentWeekSunday = new Date(
      currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
    );
    setSelectedDateRange(
      `${moment(new Date(mostRecentMonday.toISOString().split("T")[0])).format(
        "DD/MM/YYYY"
      )} - ${moment(
        new Date(currentWeekSunday.toISOString().split("T")[0])
      ).format("DD/MM/YYYY")}`
    );
  }, []);

  const handleStartDate = async (startDate, endDate) => {
    setSelectedDateRange(`${startDate} - ${endDate}`);
    let searchStartDate = startDate.split("/").reverse().join("-");
    let searchEndDate = endDate.split("/").reverse().join("-");
    setLoading(true);
    try {
      const response = await fetch(
        `${domain}over-time-hours/?selected_user_id=${userDetails.id}&status=Rejected&start_date=${searchStartDate}&end_date=${searchEndDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setFilteredData(res);
        setRejectOt(res);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
      setLoading(false);
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const searchFields = [
      "username",
      "date",
      "shift_name",
      "overtime_start",
      "overtime_end",
      "overtime",
      "approvedby",
      "createdat",
    ];
    const filteredResults = rejectOt.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setFilteredData(filteredResults);
    setPage(0);
  }, [globalSearchValue, rejectOt]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid xs={9.3}></Mui.Grid>
      <Mui.Grid xs={2.4} sx={{ paddingTop: 1, marginTop: -7 }}>
        <DateRangePicker
          handleStartDate={handleStartDate}
          selectedDateRange={selectedDateRange}
        />
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingRight: 2, paddingLeft: 2 }}>
        <Mui.TableContainer sx={{ width: "100%", maxHeight: 724, mt: 2 }}>
          <Mui.Table className="border">
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell></Mui.TableCell>
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">
                    Employee name
                  </Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">Date</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">Shift Name</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">
                    Working Hours
                  </Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">Shift Time</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">Break</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">Over Time</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">Rejected by</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">Rejected At</Mui.Typography>
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>

            <Mui.TableBody>
              {filteredData?.length === 0 ? (
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell colSpan={12}>
                    <Mui.Alert
                      severity="info"
                      sx={{ margin: 2, width: "100%" }}
                    >
                      No data available
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <>
                      <Mui.TableRow sx={tableRowSx}>
                        <Mui.TableCell>
                          {expandedRow === item.check_in_id ? (
                            <Mui.IconButton
                              onClick={() => {
                                setExpandedRow(null);
                              }}
                            >
                              <KeyboardArrowRight
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              />
                            </Mui.IconButton>
                          ) : (
                            <Mui.IconButton
                              onClick={() => handleRowClick(item.check_in_id)}
                            >
                              <KeyboardArrowDown
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              />
                            </Mui.IconButton>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell key={item.id}>
                          {item.username ? item.username : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell>{item?.date}</Mui.TableCell>
                        <Mui.TableCell>
                          {" "}
                          {item.shift_name ? item.shift_name : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell>{item.work_hours}</Mui.TableCell>
                        <Mui.TableCell>{item.total_shift_time}</Mui.TableCell>
                        <Mui.TableCell>{item.total_break_time}</Mui.TableCell>
                        <Mui.TableCell>{item.overtime}</Mui.TableCell>
                        <Mui.TableCell>
                          {item.approvedby ? item.approvedby : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          {item.createdat ? item.createdat : "N/A"}
                        </Mui.TableCell>
                      </Mui.TableRow>
                      {expandedRow === item.check_in_id && (
                        <Mui.TableRow>
                          <Mui.TableCell colSpan={9}>
                            <Mui.TableContainer
                              sx={{
                                marginTop: 0.5,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              <Mui.Table className="border">
                                <Mui.Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginLeft: 1, marginTop: 0.5 }}
                                >
                                  <strong>Reason for Rejection :</strong>{" "}
                                  <Mui.Typography
                                    fontSize={15}
                                    color="textSecondary"
                                    sx={{ marginLeft: 13 }}
                                  >
                                    {item.reason}
                                  </Mui.Typography>
                                </Mui.Typography>
                              </Mui.Table>
                            </Mui.TableContainer>
                            {item.title === "Leave" ? null : (
                              <Mui.TableContainer
                                sx={{
                                  marginTop: 0.5,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "100%px",
                                }}
                                className="w-96 border rounded"
                              >
                                <Mui.Table className="border">
                                  <Mui.Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{ marginLeft: 1, marginTop: 0.5 }}
                                  >
                                    <strong>
                                      Time Period : {item.overtime_start} to{" "}
                                      {item.overtime_end}
                                    </strong>
                                  </Mui.Typography>
                                </Mui.Table>
                              </Mui.TableContainer>
                            )}
                          </Mui.TableCell>
                        </Mui.TableRow>
                      )}
                    </>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
        {filteredData.length > 25 && (
          <Mui.TablePagination
            className="custom-pagination"
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
