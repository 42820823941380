import React, { useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { Close, Info, AddCircleTwoTone } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { onlyNumberRegex } from "services/constants/patterns";
import { tableRowSx } from "services/constants";

export const EarningDetails = ({
  earningDetailsData,
  setEarningDetailsData,
  setUpdateDeductionDetails,
  companyId,
  emptyEarning,
  monthlySalary,
  setEmptyEarning,
  setSpecialAllowance,
  setEarningDetailsUpdate,
  userDeductionDetails,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [emptyRow, setEmptyRow] = useState(0);
  const [updateEarning, setUpdateEarning] = useState(true);
  const [error, setError] = useState("");
  const { result: salaryComponent } = useFetch(
    companyId !== 0 ? `${domain}earnings/?company=${companyId}` : null
  );
  const [remainingEarning, setRemainingEarning] = useState([]);

  // useEffect(() => {
  //   const updateRemainingEarnings = () => {
  //     setRemainingEarning(salaryComponent);
  //     setUpdateEarning(false);
  //   };
  //   if (
  //     updateEarning &&
  //     remainingEarning.length === 0 &&
  //     salaryComponent.length !== 0
  //   ) {
  //     updateRemainingEarnings();
  //   }
  // });

  useEffect(() => {
    if (
      salaryComponent.length !== 0 &&
      remainingEarning.length === 0 &&
      updateEarning
    ) {
      let componentWithAmount = salaryComponent?.map((element) => {
        if (element.name === "Special Allowance") {
          setSpecialAllowance(monthlySalary);
        }
        return {
          ...element,
          amount: element.name === "Special Allowance" ? monthlySalary : 0,
          earning_id: element.id,
          rowId: element.id,
        };
      });
      let remain = componentWithAmount.filter((data) => {
        return data.name !== "Special Allowance" && data.name !== "Basic";
      });
      setEarningDetailsData(remain);
      setRemainingEarning(remain);
      let basicComponent = componentWithAmount.filter((salary) => {
        return salary.name === "Basic" || salary.name === "Special Allowance";
      });
      setEarningDetailsData(basicComponent);
      setUpdateEarning(false);
    } else if (
      salaryComponent.length !== 0 &&
      earningDetailsData.length !== 0 &&
      updateEarning
    ) {
      let salaryComponentData = salaryComponent;
      earningDetailsData.forEach((element) => {
        salaryComponentData = salaryComponentData.filter((data) => {
          return data.name !== element.name;
        });
      });
      setEarningDetailsData(salaryComponentData);
      setUpdateEarning(false);
    }
  }, [
    earningDetailsData,
    monthlySalary,
    remainingEarning.length,
    salaryComponent,
    setEarningDetailsData,
    setSpecialAllowance,
    updateEarning,
  ]);

  const handleSalaryComponent = (data, id) => {
    if (data) {
      let updatedData = earningDetailsData?.map((element) => {
        if (element?.rowId === id) {
          return {
            ...element,
            ...data,
            earning_id: data?.id,
            name: data?.name,
          };
        } else {
          return element;
        }
      });
      setEarningDetailsData(updatedData);
      removeSelectedEarning(updatedData);
    }
  };

  const updateAmount = (item, value) => {
    if (onlyNumberRegex.test(value) && item?.name) {
      let updateSalaryComponent = [];
      if (value === "") {
        updateSalaryComponent = earningDetailsData?.map((element) => {
          if (element?.rowId === item?.rowId) {
            return {
              ...element,
              amount: value,
            };
          } else {
            return element;
          }
        });
        // setEarningDetailsData(updateSalaryComponent);
        calculateSpecialAllowance(updateSalaryComponent);
        setUpdateDeductionDetails(true);
        if (userDeductionDetails.length > 0) {
          setEarningDetailsUpdate(true);
        }
      } else {
        updateSalaryComponent = earningDetailsData?.map((element) => {
          if (element?.rowId === item?.rowId) {
            return {
              ...element,
              amount: value,
              earning_id: element?.earning_id,
            };
          } else {
            return element;
          }
        });
        // setEarningDetailsData(updateSalaryComponent);
        calculateSpecialAllowance(updateSalaryComponent);
        setUpdateDeductionDetails(true);
        if (userDeductionDetails.length > 0) {
          setEarningDetailsUpdate(true);
        }
      }
      setEmptyRow(emptyRow === item?.earning_id ? 0 : emptyRow);
    } else if (!item?.name) {
      setEmptyRow(item?.earning_id);
    }
  };

  const calculateSpecialAllowance = (updateSalaryComponent) => {
    const otherThenSpecialAllowance = updateSalaryComponent.filter((data) => {
      return data.name !== "Special Allowance";
    });
    let TotalAmount = 0;
    otherThenSpecialAllowance.forEach((element) => {
      TotalAmount =
        parseInt(element.amount === "" ? 0 : element.amount) + TotalAmount;
    });
    let updatedSpecialAllowance = updateSalaryComponent?.map((element) => {
      if (element.name === "Special Allowance") {
        setSpecialAllowance(parseInt(monthlySalary) - TotalAmount);
        return {
          ...element,
          amount: parseInt(monthlySalary) - TotalAmount,
        };
      } else {
        return element;
      }
    });
    setEarningDetailsData(updatedSpecialAllowance);
  };

  const removeSelectedEarning = (updatedData) => {
    let overAllEarnings = salaryComponent;
    updatedData.forEach((earn) => {
      overAllEarnings = overAllEarnings.filter((data) => {
        return data.name !== earn.name;
      });
    });
    setRemainingEarning(overAllEarnings);
  };

  const addNewRow = () => {
    setError("");
    setEmptyEarning(false);
    const hasZeroAmount = earningDetailsData.some(
      (item) => item.amount === "0" || item.amount === 0
    );

    if (hasZeroAmount) {
      setError("Please enter value");
      return;
    }
    const newRow = {
      rowId: earningDetailsData?.length + 1,
      amount: "0",
      name: "",
      earning_id: 0,
    };
    setEarningDetailsData([...earningDetailsData, newRow]);
  };

  const removeSalaryComponent = (item) => {
    let remaingData = earningDetailsData?.filter((data) => {
      return data?.rowId !== item?.rowId;
    });
    setEarningDetailsData(remaingData);
    removeSelectedEarning(remaingData);
    calculateSpecialAllowance(remaingData);
    if (userDeductionDetails.length > 0) {
      setEarningDetailsUpdate(true);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Typography
          sx={{ fontSize: 20, fontWeight: "bold", color: themes.headLine }}
        >
          Earnings &nbsp;
          <Mui.Tooltip title="Additional allowances can be created in the salary components screen.">
            <Info />
          </Mui.Tooltip>
        </Mui.Typography>{" "}
      </Mui.Grid>
      <Mui.TableContainer
        sx={{
          mt: 2,
          width: "100%",
          border: `2px solid ${themes.sidebarDivider}`,
        }}
      >
        <Mui.Table sx={{ border: `3px solid ${themes.sidebarDivider}` }}>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Component
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Amount
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Action
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {earningDetailsData?.map((data, index) => (
              <Mui.TableRow key={data?.earning_id} sx={tableRowSx}>
                <>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    {data?.name === "Basic" ||
                    data.name === "Special Allowance" ? (
                      <Mui.TextField size="small" value={data.name} fullWidth />
                    ) : (
                      <>
                        <Mui.Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          size="small"
                          value={data?.name}
                          options={remainingEarning?.map((obj) => ({
                            ...obj,
                            label: obj?.name,
                          }))}
                          renderOption={(props, option) => {
                            return (
                              option.label !== "Special Allowance" &&
                              option.label !== "Basic" && (
                                <li {...props}>{option.label}</li>
                              )
                            );
                          }}
                          filterOptions={(options, state) => {
                            return options?.filter((option) =>
                              option.label
                                ?.toLowerCase()
                                ?.includes(state.inputValue.toLowerCase())
                            );
                          }}
                          sx={{ width: "100%" }}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                            },
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              handleSalaryComponent(value, data?.rowId);
                            }
                          }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{ background: themes.whiteColor }}
                              placeholder="Select the salary component"
                              size="small"
                              value={data?.name}
                            />
                          )}
                        />
                      </>
                    )}
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.Stack direaction="row">
                      <Mui.TextField
                        size="small"
                        value={data?.amount}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (/^\d{0,8}$/.test(inputValue)) {
                            updateAmount(data, inputValue);
                          }
                        }}
                        disabled={
                          data.name === "Special Allowance" ? true : false
                        }
                        fullWidth
                      />
                      {data.amount < 0 && data.name === "Special Allowance" ? (
                        <span style={{ color: themes.txtErrorColor }}>
                          Special Allowance should be greater then 0
                        </span>
                      ) : null}
                      {((data.amount === "0" ||
                        data.amount === "" ||
                        data.amount === 0) &&
                        emptyEarning) ||
                      (error === "Please enter value" &&
                        (data.amount === 0 ||
                          data.amount === "" ||
                          data.amount === "0")) ? (
                        <span style={{ color: themes.txtErrorColor }}>
                          Please enter value
                        </span>
                      ) : null}
                    </Mui.Stack>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    {data?.name === "Basic" ? null : (
                      <Mui.IconButton>
                        <Close onClick={() => removeSalaryComponent(data)} />
                      </Mui.IconButton>
                    )}
                  </Mui.TableCell>
                </>
              </Mui.TableRow>
            ))}
            <Mui.TableRow>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Mui.IconButton
                  size="small"
                  onClick={() => {
                    addNewRow();
                  }}
                  disabled={remainingEarning?.length === 0 ? true : false}
                >
                  <Mui.Tooltip title="Add Earning">
                    <AddCircleTwoTone size="large" />
                  </Mui.Tooltip>
                </Mui.IconButton>
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
    </React.Fragment>
  );
};
