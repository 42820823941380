import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { summaryDetails } from "pages/PayRoll/PayRollHeaders";
import { getStatusColor } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { ConstStructure } from "../CostStructure";
import { OverTime } from "../OverTime";
import { SalaryRevision } from "../SalaryRevision";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { AttendanceTimeLine } from "../AttendanceTimeLine";
import { PayrunLog } from "./PayrunLog";
import { useDispatch } from "react-redux";
import { amountNumberFormat } from "services/utils/numberFormat";
import { DownloadForOffline } from "@mui/icons-material";
export const ViewPayRun = ({
  setViewPayRunScreen,
  companyId,
  companyName,
  viewPayrunDetails,
  setViewPayRunDetails,
  reCallApi,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [downloadReportDetails, setDownloadReportDetails] = useState(false);
  const [summaryName, setSummaryName] = useState("Cost Structure");
  const [payrollCost, setPayrollCost] = useState(0);
  const [employeeNetPay, setEmployeeNetPay] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [rejectReason, setRejectReason] = useState("");
  const [openSidePeek, setOpenSidePeek] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [rejectReasonError, setRejectReasonError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [reloadUserPayrollDetails, setReloadUserPayrollDetails] =
    useState(false);
  const dispatch = useDispatch();
  const handleSummaryDetails = (event, data) => {
    setSummaryName(data?.label);
  };

  const renderComponent = () => {
    switch (summaryName) {
      case "Cost Structure":
        return (
          <ConstStructure
            viewPayrunDetails={viewPayrunDetails}
            setPayrollCost={setPayrollCost}
            setEmployeeNetPay={setEmployeeNetPay}
            setTotalDeduction={setTotalDeduction}
            setTotalWorkingHours={setTotalWorkingHours}
            companyId={companyId}
            companyName={companyName}
            reloadUserPayrollDetails={reloadUserPayrollDetails}
            setReloadUserPayrollDetails={setReloadUserPayrollDetails}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
            summaryName={summaryName}
          />
        );
      case "Attendance Timeline":
        return (
          <AttendanceTimeLine
            viewPayrunDetails={viewPayrunDetails}
            companyName={companyName}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
            summaryName={summaryName}
          />
        );
      case "Over Time":
        return (
          <OverTime
            viewPayrunDetails={viewPayrunDetails}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
          />
        );
      case "Salary Revision":
        return (
          <SalaryRevision
            viewPayrunDetails={viewPayrunDetails}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
          />
        );
      default:
        break;
    }
  };

  const approveButtonConfigurations = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}payrun/status-update/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          pay_schedule_id: viewPayrunDetails.id,
          status: "Approved",
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        setViewPayRunScreen(false);
        setButtonDisabled(false);
        reCallApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setButtonDisabled(false);
    }
  };

  const rejectButtonConfigurations = async () => {
    if (rejectReason.length >= 3) {
      setButtonDisabled(true);
      try {
        const response = await fetch(`${domain}payrun/status-update/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            pay_schedule_id: viewPayrunDetails.id,
            reason: rejectReason,
            status: "Rejected",
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          setButtonDisabled(false);
          handleCloseDialogBox();
          setViewPayRunScreen(false);
          reCallApi(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setButtonDisabled(false);
      }
    } else {
      setRejectReasonError(true);
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };
  const handleCloseAddDialogBox = () => {
    setOpenAddDialog(false);
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <Mui.Grid xs={8}>
          <Mui.Stack direction="Row">
            <BackArrow
              actionFunction={() => {
                setViewPayRunScreen(false);
                dispatch(setGlobalSearchValue(""));
              }}
            />
            <Mui.Typography
              sx={{ paddingTop: 1, fontSize: 25, fontWeight: "bold" }}
            >
              Pay runs - {companyName} -{summaryName}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid xs={4}>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 1,
            }}
          >
            <Mui.Box
              sx={{
                width: 200,
                height: 15,
                backgroundColor: getStatusColor(viewPayrunDetails.status),
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: themes.backgroundWhite,
                p: 2,
                fontSize: 13,
              }}
            >
              {viewPayrunDetails.status}
            </Mui.Box>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        {summaryName === "Cost Structure" ? (
          <Mui.Grid xs={5}>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={8}>
                <Mui.Stack direction="row">
                  <Mui.Typography
                    sx={{ fontSize: 20, color: themes.colorShadow }}
                  >
                    Period :{" "}
                  </Mui.Typography>
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      padding: 2,
                    }}
                  >
                    {viewPayrunDetails.start_date +
                      " to " +
                      viewPayrunDetails.end_date}
                  </span>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Stack direction="row">
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    {totalWorkingHours}
                  </Mui.Typography>{" "}
                  &nbsp;
                  <Mui.Typography
                    sx={{
                      paddingTop: 0.3,
                      fontSize: 18,
                      color: themes.colorShadow,
                    }}
                  >
                    days
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              component={Mui.Paper}
              elevation={4}
              sx={{ border: `1px solid ${themes.primaryIndicator}` }}
            >
              <Mui.Grid
                xs={4}
                sx={{ borderRight: `4px solid ${themes.primaryIndicator}` }}
              >
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {amountNumberFormat(payrollCost)}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    fontSize: 18,
                    color: themes.colorShadow,
                    textAlign: "center",
                  }}
                >
                  Payroll cost
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={4}
                sx={{ borderRight: `4px solid ${themes.primaryIndicator}` }}
              >
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {amountNumberFormat(employeeNetPay)}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    fontSize: 18,
                    color: themes.colorShadow,
                    textAlign: "center",
                  }}
                >
                  Employee's net pay
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {amountNumberFormat(totalDeduction)}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    fontSize: 18,
                    color: themes.colorShadow,
                    textAlign: "center",
                  }}
                >
                  Total Deductions
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        ) : (
          <Mui.Grid xs={5}>
            <Mui.Grid container>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{ fontSize: 18, color: themes.colorShadow }}
                >
                  Payroll Period
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{ fontSize: 18, color: themes.colorShadow }}
                >
                  Total Working Days
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              component={Mui.Paper}
              elevation={4}
              sx={{ border: `2px solid ${themes.primaryIndicator}` }}
            >
              <Mui.Grid
                xs={6}
                sx={{ borderRight: `4px solid ${themes.primaryIndicator}` }}
              >
                <Mui.Stack direction="Row">
                  <Mui.Typography
                    sx={{
                      textAlign: "center",
                      padding: 3,
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {viewPayrunDetails.start_date} {" To "}
                    <span sx={{ paddingLeft: 1 }}>
                      {viewPayrunDetails.end_date}
                    </span>
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{
                    textAlign: "center",
                    padding: 3,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {totalWorkingHours}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        )}
        <Mui.Grid xs={7}>
          <Mui.Stack>
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(true);
                }}
                actionName="Reject"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={() => setOpenAddDialog(true)}
                actionName="Approve"
                typeName="button"
              />{" "}
              &nbsp;&nbsp;
              {viewPayrunDetails.status !== "Draft" ? (
                <CustomButton
                  actionFuntion={() => {
                    setOpenSidePeek(true);
                  }}
                  actionName="View Log"
                  typeName="button"
                />
              ) : null}
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={7}></Mui.Grid>
              <Mui.Grid xs={4}>
                <CustomAutoComplete
                  dataList={summaryDetails}
                  selectedValue={summaryName}
                  updateDataFunction={handleSummaryDetails}
                  placeHolder="Please select the summary"
                  fullWith
                />
              </Mui.Grid>
              <Mui.Grid xs={1}>
                &nbsp;&nbsp;
                <Mui.IconButton
                  size="small"
                  onClick={() => setDownloadReportDetails(true)}
                >
                  <DownloadForOffline
                    size="large"
                    sx={{ color: "#25B7D3", fontSize: 35 }}
                  />
                </Mui.IconButton>
                &nbsp;
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>{renderComponent()}</Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Reason for their rejection"
      >
        <Mui.Typography sx={{ fontSize: 19 }}>
          Reason for rejection for them {"  "}
          <span style={{ color: themes.txtErrorColor }}>
            {viewPayrunDetails.pay_schedule_name}
          </span>
        </Mui.Typography>
        <Mui.TextField
          multiline
          type="multiline"
          floatingLabelText="MultiLine and FloatingLabel"
          rows={3}
          maxRows={10}
          onChange={(event) => {
            setRejectReason(event.target.value);
            if (rejectReasonError) {
              setRejectReasonError(false);
            }
          }}
          error={rejectReasonError ? true : false}
          helperText={
            rejectReasonError ? "Minimum 5 characters required" : false
          }
          fullWidth
        />
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingTop: 2,
          }}
        >
          <CustomButton
            actionFuntion={() => handleCloseDialogBox()}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => rejectButtonConfigurations()}
            actionName="Yes"
            typeName="button"
            disableAction={buttonDisabled}
          />
        </Mui.Grid>
      </GlobalDialogBox>
      {openSidePeek ? (
        <PayrunLog
          setOpenSidePeek={setOpenSidePeek}
          openSidePeek={openSidePeek}
          viewPayrunDetails={viewPayrunDetails}
        />
      ) : null}
      <GlobalDialogBox
        handleCloseDialog={handleCloseAddDialogBox}
        open={openAddDialog}
        title="Confirmation Required"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
          Are you sure you want to approve this {"  "}
          <span style={{ color: themes.txtErrorColor }}>
            {viewPayrunDetails.pay_schedule_name}
          </span>
          ?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenAddDialog(false)}
              actionName="Cancel"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => approveButtonConfigurations()}
              actionName="Confirm"
              typeName="button"
              disableAction={buttonDisabled}
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
