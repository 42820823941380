import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useSelector } from "react-redux";
import { PaymentMode } from "pages/PayRoll/PayRollHeaders";
import { customAPI } from "services/utils/CustomAPI";
import { showToast } from "services/utils/Status";

export const AddPayRollGroup = ({
  setDisplayScreen,
  reCallApi,
  companyId,
  setAddAction,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const { result: payrunCycleData, loading: payrunCycleLoading } = useFetch(
    `${domain}pay-run-cycles/`
  );
  const { result: bankDetailsData, loading } = useFetch(
    companyId !== 0 ? `${domain}source-bank/?company=${companyId}` : null
  );
  const [updatePayrunCycle, setUpdatePayrunCycle] = useState(true);
  const [payrunCycleDetails, setPayrunCycleDetails] = useState([]);
  const [paymentBankDetails, setpaymentBankDetails] = useState([]);
  const [payrunCycleName, setPayrunCycleName] = useState("");
  const [paymentBankName, setPaymentBankName] = useState("");
  const [paymentModeName, setPaymentModeName] = useState("");
  const [payrollGroupName, setPayrollGroupName] = useState("");
  const {
    setValue,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    setValue("company", companyId);
  }, [companyId, setValue]);

  useEffect(() => {
    const addnewLabelKeyPayrunCycle = () => {
      let data = payrunCycleData.map((pay) => {
        return {
          ...pay,
          label: pay.name,
        };
      });
      let labelWithBankDetails = bankDetailsData.map((pay) => {
        return {
          ...pay,
          label: pay.bank_name,
        };
      });
      setpaymentBankDetails(labelWithBankDetails);
      setPayrunCycleDetails(data);
      setUpdatePayrunCycle(true);
    };
    if (updatePayrunCycle && !payrunCycleLoading && !loading) {
      addnewLabelKeyPayrunCycle();
    }
  });

  const onSubmit = async (data) => {
    let payload = { ...data, users: [] };
    const response = await customAPI(
      `${domain}payroll-group/`,
      "POST",
      payload
    );
    if (response.ok) {
      showToast("success", "Payroll group created successfully");
      setDisplayScreen("ListView");
      reCallApi(true);
      reset();
      setAddAction(false);
    }
  };

  const handleCycleName = (event, data) => {
    if (data) {
      setPayrunCycleName(data?.label);
      setValue("payrun_cycle", data?.label);
      setValue("pay_run_cycle", data.id);
      setPayrollGroupName(
        data?.label + "-" + paymentBankName + "-" + paymentModeName
      );
      setValue(
        "name",
        data?.label + "-" + paymentBankName + "-" + paymentModeName
      );
    }
  };

  const handlePaymentBank = (event, data) => {
    if (data) {
      setPaymentBankName(data.label);
      setValue("payment_bank", data.label);
      setValue("source_bank_detail", data.id);
      setPayrollGroupName(
        payrunCycleName + "-" + data?.label + "-" + paymentModeName
      );
      setValue(
        "name",
        payrunCycleName + "-" + data?.label + "-" + paymentModeName
      );
    }
  };

  const handlePaymentMode = (event, data) => {
    if (data) {
      setPaymentModeName(data.label);
      setValue("mode_of_payment", data.label);
      setPayrollGroupName(
        payrunCycleName + "-" + paymentBankName + "-" + data?.label
      );
      setValue(
        "name",
        payrunCycleName + "-" + paymentBankName + "-" + data?.label
      );
    }
  };

  const handlePayrollGroupName = (event) => {
    if (event.target.value.length <= 255) {
      setPayrollGroupName(event.target.value);
      setValue("name", event.target.value);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingBottom: {
            xs: 5,
            sm: 5,
            md: 5,
            lg: 5,
            xl: 5,
          },
        }}
      >
        <Mui.Stack direction="row">
          <BackArrow
            actionFunction={() => {
              setDisplayScreen("ListView");
              setAddAction(false);
            }}
          />
          <Mui.Typography
            sx={{
              paddingLeft: 1,
              fontSize: 20,
              fontWeight: "bold",
              paddingTop: 1,
            }}
          >
            Payroll Group
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid
          container
          sx={{
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 24,
                fontWeight: 800,
                font: "bold",
                textAlign: "center",
              }}
            >
              Create Payroll Group
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              paddingTop: -1,
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
              borderRadius: "0 0 10px 10px",
            }}
          >
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payrun Cycle
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={payrunCycleDetails}
                  rules={{
                    ...register("payrun_cycle", {
                      required: "Payrun cycle is required",
                    }),
                  }}
                  selectedValue={payrunCycleName}
                  updateDataFunction={handleCycleName}
                  placeHolder="Select pay cycle"
                  errorMessage={
                    errors?.payrun_cycle?.message
                      ? errors?.payrun_cycle?.message
                      : ""
                  }
                  textBoxError={errors?.payrun_cycle ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payment Bank
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={paymentBankDetails}
                  rules={{
                    ...register("payment_bank", {
                      required: "Payment bank is required",
                    }),
                  }}
                  selectedValue={paymentBankName}
                  updateDataFunction={handlePaymentBank}
                  placeHolder="Select payment bank"
                  errorMessage={
                    errors?.payment_bank?.message
                      ? errors?.payment_bank?.message
                      : ""
                  }
                  textBoxError={errors?.payment_bank ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Payment Mode
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={PaymentMode}
                  rules={{
                    ...register("mode_of_payment", {
                      required: "Payment mode is required",
                    }),
                  }}
                  selectedValue={paymentModeName}
                  updateDataFunction={handlePaymentMode}
                  placeHolder="Select pay cycle"
                  errorMessage={
                    errors?.mode_of_payment?.message
                      ? errors?.mode_of_payment?.message
                      : ""
                  }
                  textBoxError={errors?.mode_of_payment ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Group Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={payrollGroupName}
                  rules={{
                    ...register("name", {
                      required: "Payroll group name is required",
                      minLength: {
                        value: 3,
                        message: "Minium length is 3 characters",
                      },
                      validate: {
                        atleastOneCharacterWithSpaceReg: (value) => {
                          if (
                            atleastOneCharacterWithSpace.test(value) ||
                            value === ""
                          ) {
                            return true;
                          } else {
                            return "Atleast one alphabet required";
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={handlePayrollGroupName}
                  placeHolder="Enter payroll group name"
                  errorMessage={
                    errors?.name?.message ? errors?.name?.message : ""
                  }
                  textBoxError={errors.name ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 3,
                  paddingBottom: 2,
                }}
              >
                <CustomButton
                  actionFuntion={() => setDisplayScreen("ListView")}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp; &nbsp;
                <CustomButton actionName="Create" typeName="submit" />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
