import React, { useState, useEffect } from "react";
import {
  TextField,
  Popover,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import DateRangeIcon from "@mui/icons-material/DateRange";
import moment from "moment";
import { DateRange } from "react-date-range";
import { useSelector } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { leaveManagamentPlaceholder } from "services/constants/PlaceHolder";

const dateFormat = "DD/MM/YYYY";
const initialDate = new Date();

export const DateRangePicker = ({ handleStartDate, selectedDateRange }) => {
  const { dateRange, startDate, endDate } = useSelector(
    (state) => state.tokenReducer
  );
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [inputValue, setInputValue] = useState(selectedDateRange);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [tempStartDate, setTempStartDate] = useState(initialDate);
  const [tempEndDate, setTempEndDate] = useState(initialDate);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  useEffect(() => {
    setInputValue(selectedDateRange);
  }, [selectedDateRange]);

  useEffect(() => {
    if (dateRange && startDate && endDate) {
      let start_initial = startDate.split(/\//);
      let end_initial = endDate.split(/\//);
      let start_date = [
        start_initial[1],
        start_initial[0],
        start_initial[2],
      ].join("/");
      let end_date = [end_initial[1], end_initial[0], end_initial[2]].join("/");
      setFromDate(new Date(start_date));
      setToDate(new Date(end_date));
      setTempStartDate(new Date(start_date));
      setTempEndDate(new Date(end_date));
      setInputValue(
        `${moment(new Date(start_date)).format(dateFormat)} - ${moment(
          new Date(end_date)
        ).format(dateFormat)}`
      );
    } else {
      const currentDate = initialDate;
      setFromDate(currentDate);
      setToDate(currentDate);
      setTempStartDate(currentDate);
      setTempEndDate(currentDate);
      setInputValue(
        `${moment(currentDate).format(dateFormat)} - ${moment(
          currentDate
        ).format(dateFormat)}`
      );
    }
  }, [dateRange, startDate, endDate]);

  const onAdornmentClick = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  const onInputChange = (e) => {
    const newValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);
    setInputValue(newValue);
    setTempStartDate(fromDate);
    setTempEndDate(toDate);
  };

  const onApply = () => {
    handleStartDate(
      moment(new Date(tempStartDate)).format("DD/MM/YYYY"),
      moment(new Date(tempEndDate)).format("DD/MM/YYYY")
    );
    setInputValue(
      `${moment(new Date(tempStartDate)).format(dateFormat)} - ${moment(
        new Date(tempEndDate)
      ).format(dateFormat)}`
    );
    setSelectedStartDate(tempStartDate);
    setSelectedEndDate(tempEndDate);
    setDisplayCalendar(false);
  };

  const onCancel = () => {
    if (selectedStartDate && selectedEndDate) {
      setTempStartDate(selectedStartDate);
      setTempEndDate(selectedEndDate);
      setInputValue(
        `${moment(selectedStartDate).format(dateFormat)} - ${moment(
          selectedEndDate
        ).format(dateFormat)}`
      );
    } else {
      setInputValue("");
    }
    setDisplayCalendar(false);
  };

  const onPopoverClose = () => {
    setInputValue(
      `${moment(fromDate).format(dateFormat)} - ${moment(toDate).format(
        dateFormat
      )}`
    );
    setDisplayCalendar(false);
    setAnchorEl(null);
    setTempStartDate(fromDate);
    setTempEndDate(toDate);
  };

  const onSelectDateRanges = ({ selection }) => {
    const { startDate, endDate } = selection;
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const processInputValue = (value) => {
    let [fromDate, toDate] = value.split("-").map((elm) => elm.trim());
    fromDate = moment(fromDate, dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;
    toDate = moment(toDate, dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;
    return { fromDate, toDate };
  };

  const handleManualDateInput = () => {
    const { fromDate, toDate } = processInputValue(inputValue);
    if (fromDate && toDate) {
      setTempStartDate(fromDate);
      setTempEndDate(toDate);
      setSelectedStartDate(fromDate);
      setSelectedEndDate(toDate);
      setInputValue(
        `${moment(fromDate).format(dateFormat)} - ${moment(toDate).format(
          dateFormat
        )}`
      );
    } else if (inputValue === "") {
      setTempStartDate(null);
      setTempEndDate(null);
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setInputValue("");
    }
  };

  return (
    <>
      <TextField
        label="Date"
        placeholder={leaveManagamentPlaceholder.dateFormat}
        value={inputValue}
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        onChange={onInputChange}
        onBlur={handleManualDateInput}
        sx={{ minWidth: "100%" }}
      />
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={onPopoverClose}
      >
        <DateRange
          ranges={[
            {
              startDate: tempStartDate,
              endDate: tempEndDate,
              key: "selection",
            },
          ]}
          onChange={onSelectDateRanges}
          scroll={{ enabled: true }}
          showMonthAndYearPickers={true}
          showDateDisplay={false}
        />
        <br />
        <div
          style={{
            float: "right",
          }}
        >
          <Button
            color="error"
            size="small"
            onClick={onCancel}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={onApply}
            sx={{ marginRight: 1 }}
          >
            Apply
          </Button>
        </div>
      </Popover>
    </>
  );
};
