import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { teamZoneLoanHistory } from "pages/TeamZone/Approve/Headers";
import { MoreVert, RemoveRedEye } from "@mui/icons-material";
import { LoanSkip } from "./SidePeek/LoanSkip";
import { ReviseSalary } from "./SidePeek/ReviseSalary";
import { Prepayment } from "./SidePeek/Prepayment";
const keys = [
  "applied_date",
  "department_name",
  "user_name",
  "principal_amount",
  "tenure_months",
  "interest_rate",
  "monthly_installment",
  "loan_type",
  "interest_type",
  "status",
];

export const ActiveLoans = ({
  setViewLoanDetails,
  setViewLoan,
  departmentId,
  userId,
  departmentChange,
  setDepartmentChange,
  userChange,
  setUserChange,
  loanScreen,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loanData, setLoanData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSidePeekSkip, setOpenSidePeekSkip] = useState(false);
  const [openSidePeekRevise, setOpenSidePeekRevise] = useState(false);
  const [prepaymentLoan, setPrepaymentLoan] = useState(false);
  const [editLoanDetails, setEditLoanDetails] = useState(null);
  const {
    result: loanDetails,
    loading,
    setLoad: reCallApi,
  } = useFetch(
    `${domain}active-loans/?department_id=${departmentId}&selected_user_id=${userId}`
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if ((departmentChange || userChange) && loanScreen === "ActiveLoans") {
      reCallApi(true);
      setDepartmentChange(false);
      setUserChange(false);
    }
  }, [
    departmentChange,
    loanScreen,
    reCallApi,
    setDepartmentChange,
    setUserChange,
    userChange,
  ]);

  useEffect(() => {
    if (updateData && loanDetails?.length !== 0 && loanDetails) {
      setLoanData(loanDetails);
      setUpdateData(false);
    }
  }, [loanDetails, updateData]);

  useEffect(() => {
    const filteredResults = loanDetails?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setLoanData(filteredResults);
    setPage(0);
  }, [globalSearchValue, loanDetails]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.TableContainer
          sx={{
            marginTop: 2,
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "auto",
          }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    height: "15px",
                    padding: "3px 5px",
                  },
                }}
              >
                {teamZoneLoanHistory.map((head) =>
                  head.name !== "Status" ? (
                    <Mui.TableCell>
                      <Mui.Typography fontWeight="bold">
                        {head.name}
                      </Mui.Typography>
                    </Mui.TableCell>
                  ) : null
                )}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {loanData.length === 0 ? (
                <Mui.TableRow>
                  <Mui.TableCell colSpan={12}>
                    <Mui.Alert sx={{ paddingTop: 1 }} severity="info">
                      No data available
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                loanData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item) => (
                    <Mui.TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "15px",
                          padding: "7px 5px",
                        },
                      }}
                    >
                      {teamZoneLoanHistory.map((head) =>
                        head.name !== "Action" ? (
                          head.name !== "Status" ? (
                            <Mui.TableCell>
                              {item[head.key] ? item[head.key] : "N/A"}
                            </Mui.TableCell>
                          ) : null
                        ) : head.name !== "Status" ? (
                          <Mui.TableCell>
                            <Mui.Stack direction="row">
                              <Mui.IconButton
                                size="small"
                                onClick={() => {
                                  setViewLoanDetails(item);
                                  setViewLoan(true);
                                }}
                              >
                                <RemoveRedEye size="small" />
                              </Mui.IconButton>
                              <Mui.IconButton
                                onClick={(event) => {
                                  setAnchorEl(event.currentTarget);
                                  setEditLoanDetails(item);
                                }}
                              >
                                <MoreVert />
                              </Mui.IconButton>
                              <Mui.Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                elevation={1}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <Mui.MenuItem
                                  sx={{ width: "100%" }}
                                  onClick={() => {
                                    setAnchorEl(null);
                                    setOpenSidePeekSkip(true);
                                  }}
                                >
                                  Skip
                                </Mui.MenuItem>
                                <Mui.MenuItem
                                  sx={{ width: "100%" }}
                                  onClick={() => {
                                    setAnchorEl(null);
                                    setOpenSidePeekRevise(true);
                                  }}
                                >
                                  Revise
                                </Mui.MenuItem>
                                <Mui.MenuItem
                                  sx={{ width: "100%" }}
                                  onClick={() => {
                                    setAnchorEl(null);
                                    setPrepaymentLoan(true);
                                  }}
                                >
                                  Prepayment
                                </Mui.MenuItem>
                              </Mui.Menu>
                            </Mui.Stack>
                          </Mui.TableCell>
                        ) : null
                      )}
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
          {loanData?.length > 25 && (
            <Mui.TablePagination
              className="custom-pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={loanData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
        {openSidePeekSkip ? (
          <LoanSkip
            openSidePeek={openSidePeekSkip}
            setOpenSidePeek={setOpenSidePeekSkip}
            editLoanDetails={editLoanDetails}
          />
        ) : null}
        {openSidePeekRevise ? (
          <ReviseSalary
            setOpenSidePeekRevise={setOpenSidePeekRevise}
            openSidePeekRevise={openSidePeekRevise}
            editLoanDetails={editLoanDetails}
          />
        ) : null}
        {prepaymentLoan ? (
          <Prepayment
            setPrepaymentLoan={setPrepaymentLoan}
            prepaymentLoan={prepaymentLoan}
            editLoanDetails={editLoanDetails}
          />
        ) : null}
      </Mui.Grid>
    </React.Fragment>
  );
};
