import React, { useCallback, useContext, useEffect, useState } from "react";
import { AddCircle, Delete } from "@mui/icons-material";
import * as Mui from "@mui/material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { showToast } from "services/utils/Status";
import { themes } from "services/constants";
import { TaskContext } from "../TaskContext";
import { Popup } from "./Popup";
import { projectError } from "services/constants/ErrorMessages";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import { projectSuccess } from "services/constants/SuccessMessages";
import sessionHandling from "services/utils/notificationUtils";

export const TaskMaterial = ({ setActiveTap }) => {
  const [materialDetails, setMaterialDetails] = useState([]);
  const [idNumber, setIdNumber] = useState(1);
  const [material, setMaterial] = useState([]);
  const [alreadyAddedMaterial, setAlreadyAddedMaterial] = useState([]);
  const { editTask, domain, token } = useContext(TaskContext);

  const fetchTaskMaterials = useCallback(
    async (materialsDetails) => {
      try {
        const response = await fetch(
          `${domain}material_task/?taskid=${editTask.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setAlreadyAddedMaterial(res);
          setIdNumber(res?.length === 0 ? 1 : res[res?.length - 1].id + 1);
          let materials = materialsDetails;
          res.forEach((element) => {
            const returnData = materials.filter((data) => {
              return data.type !== element.type;
            });
            materials = returnData;
          });
          setMaterialDetails(materials);
          setMaterial([
            ...res,
            {
              id: 99999,
              type: "",
              unit: "",
              quantity: "",
              inv_quantity: "",
              rate: "",
              amount: "",
            },
          ]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, editTask.id, token]
  );

  useEffect(() => {
    const fetchAllMaterials = async () => {
      try {
        const response = await fetch(`${domain}material/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setMaterialDetails(res);
          fetchTaskMaterials(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchAllMaterials();
  }, [domain, editTask, fetchTaskMaterials, token]);

  const addNewRow = () => {
    let newRow = {
      id: idNumber,
      type: "",
      unit: 0,
      quantity: 0,
      inv_quantity: 0,
      rate: 0,
      amount: 0,
    };
    setMaterial([...material, newRow]);
    setIdNumber(idNumber + 1);
  };

  const updateMaterialDetails = (id, label, value) => {
    const amountLabel = "amount";
    const updatedData = material.map((element) => {
      if (element.id === id) {
        if (label === "rate" || label === "quantity") {
          return {
            ...element,
            [amountLabel]:
              parseInt(value) *
              (label === "rate"
                ? parseInt(element.quantity)
                : parseInt(element.rate)),
            [label]: value,
          };
        } else {
          return { ...element, [label]: value };
        }
      }
      return element;
    });
    setMaterial(updatedData);
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const addMaterials = async () => {
    const materialsDetails = material.filter((mat) => {
      return mat.id !== 99999 && mat.type !== "";
    });
    try {
      const response = await fetch(`${domain}material_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          taskid: editTask.id,
          materials: materialsDetails,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setActiveTap("ListView");
        showToast("success", projectSuccess.taskMaterial);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      if (error.message.includes("already exists")) {
        showToast("error", projectError.fetchError);
      } else {
        showToast("error", error.message);
      }
    }
  };

  const deleteMaterialId = async (MaterialId) => {
    const filterData = alreadyAddedMaterial.filter((data) => {
      return data.id === MaterialId;
    });
    if (filterData.length > 0) {
      try {
        const response = await fetch(`${domain}material_task/`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            taskid: editTask.id,
            material_id: MaterialId,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("error", projectError.taskMaterial);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  return (
    <React.Fragment>
      <Mui.Stack sx={{ paddingTop: 20 }}>
        <Popup open={true}>
          <Mui.TableContainer
            sx={{ mt: 1, width: "100%", maxHeight: 510, minHeight: 510 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow maxWidth="xl" align="left">
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      width: "15%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  >
                    Types
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  >
                    Unit
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  >
                    Quantity
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  >
                    Inv Quantity
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  >
                    Rate
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  >
                    Amount
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  >
                    Action
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {material
                  .sort((a, b) => a.id - b.id)
                  .map((data, index) => (
                    <Mui.TableRow
                      key={data.id}
                      maxWidth="xl"
                      align="left"
                      hover={true}
                    >
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        {data.id !== 99999 ? (
                          <Mui.Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            value={data.type}
                            options={materialDetails}
                            ListboxProps={{ style: { maxHeight: "150px" } }}
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.type
                                  ?.toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderOption={(props, option) => {
                              return <li {...props}>{option.type}</li>;
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                updateMaterialDetails(
                                  data.id,
                                  "type",
                                  value.type
                                );
                                setMaterialDetails(
                                  materialDetails.filter((data) => {
                                    return data.type !== value.type;
                                  })
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                value={data.type}
                                placeholder={projectPlaceholder.selectMaterial}
                                size="small"
                                fullWidth
                              />
                            )}
                          />
                        ) : null}
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        {data.id !== 99999 ? (
                          <Mui.TextField
                            size="small"
                            onKeyPress={handleKeyPress}
                            value={data.unit}
                            onChange={(event) =>
                              updateMaterialDetails(
                                data.id,
                                "unit",
                                event.target.value
                              )
                            }
                          />
                        ) : null}
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        {data.id !== 99999 ? (
                          <Mui.TextField
                            size="small"
                            value={data.quantity}
                            onKeyPress={handleKeyPress}
                            onChange={(event) =>
                              updateMaterialDetails(
                                data.id,
                                "quantity",
                                event.target.value
                              )
                            }
                          />
                        ) : null}
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        {data.id !== 99999 ? (
                          <Mui.TextField
                            size="small"
                            onKeyPress={handleKeyPress}
                            value={data.inv_quantity}
                            onChange={(event) =>
                              updateMaterialDetails(
                                data.id,
                                "inv_quantity",
                                event.target.value
                              )
                            }
                          />
                        ) : null}
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        {data.id !== 99999 ? (
                          <Mui.TextField
                            size="small"
                            value={data.rate}
                            onKeyPress={handleKeyPress}
                            onChange={(event) =>
                              updateMaterialDetails(
                                data.id,
                                "rate",
                                event.target.value
                              )
                            }
                          />
                        ) : null}
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        {data.id !== 99999 ? (
                          <Mui.TextField
                            size="small"
                            onKeyPress={handleKeyPress}
                            value={data.amount}
                            onChange={(event) =>
                              updateMaterialDetails(
                                data.id,
                                "amount",
                                event.target.value
                              )
                            }
                          />
                        ) : null}
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        <Mui.IconButton
                          onClick={() => {
                            if (data.id === 99999) {
                              addNewRow();
                            } else {
                              setMaterial(
                                material.filter(
                                  (matData) => matData.id !== data.id
                                )
                              );
                              deleteMaterialId(data.id);
                            }
                          }}
                        >
                          {data.id === 99999 ? <AddCircle /> : <Delete />}
                        </Mui.IconButton>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <br />
          <Mui.Grid
            container
            sx={{ flexDirection: "row", justifyContent: "flex-end" }}
          >
            <Mui.Grid xs={10}></Mui.Grid>
            <Mui.Grid xs={1}>
              <CustomButton
                actionFuntion={() => setActiveTap("ListView")}
                actionName="Cancel"
                typeName="button"
              />
            </Mui.Grid>
            <Mui.Grid xs={1}>
              <CustomButton
                actionFuntion={() => addMaterials()}
                actionName="Add"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        </Popup>
      </Mui.Stack>
    </React.Fragment>
  );
};
