import React, { useState, useEffect, useContext } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import moment from "moment";
import { Loader } from "components/Loader";
import { ProjectReportContext } from "../ProRepContext/projectContext";
import sessionHandling from "services/utils/notificationUtils";
import { DateRangePicker } from "pages/MyZone/Status/DateRangePicker";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { tableRowSx } from "services/constants";
import { DownloadForOffline } from "@mui/icons-material";

export const keys = [
  "user_name",
  "project.projectname",
  "task_code",
  "taskname",
  "task_owner.username",
  "stask_status",
  "date",
  "start_time",
  "end_time",
  "hours",
];

const UserTaskReport = ({ updateShowReport }) => {
  const { allUsers } = useContext(ProjectReportContext);
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(
    userDetails?.role === "Admin" ? "All" : userDetails?.username
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedAssigneeId, setSelectedAssigneeId] = useState(
    userDetails?.role === "Admin" ? "" : userDetails?.id
  );
  const [selectedProject, setSelectedProject] = useState("All");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState(
    `${moment(new Date()).format("DD/MM/YYYY")} - ${moment(new Date()).format(
      "DD/MM/YYYY"
    )}`
  );
  const [allProjectDetails, setAllProjectDetails] = useState([]);
  const [allUserRepDetails, setAllUserRepDetails] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );
  const [indTotWorkingHours, setIndTotWorkingHours] = useState("");
  const [indTotPresentHours, setIndTotPresentHours] = useState("");
  const [userMasterDetails, setUserMasterDetails] = useState(allUserRepDetails);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const selUsrId =
          selectedAssignee === "All" ? 99999 : selectedAssigneeId;

        const response = await fetch(
          `${domain}get-project-based-user/?user_id=${selUsrId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const AllProjectsData = res.map((obj) => ({
            ...obj,
            label: obj.project_name,
          }));
          setAllProjectDetails(AllProjectsData);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchProjectDetails();
  }, [domain, token, selectedAssignee, selectedAssigneeId]);

  useEffect(() => {
    const getIndividualWorkingHours = async () => {
      try {
        let searchStartDate = startDate.split("/").reverse().join("-");
        let searchEndDate = endDate.split("/").reverse().join("-");
        const response = await fetch(
          `${domain}individual_details_working_hours/?user_id=${selectedAssigneeId}&start_date=${searchStartDate}&end_date=${searchEndDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setIndTotPresentHours(res?.weekly_working_hours);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (selectedAssignee !== "All") {
      getIndividualWorkingHours();
    }
  }, [domain, token, selectedAssignee, selectedAssigneeId, startDate, endDate]);

  useEffect(() => {
    const fetchUsertaskDetails = async () => {
      try {
        setIsLoading(true);
        const selProId = selectedProject === "All" ? 99999 : selectedProjectId;
        const selUsrId =
          selectedAssignee === "All" ? 99999 : selectedAssigneeId;
        let searchStartDate = startDate.split("/").reverse().join("-");
        let searchEndDate = endDate.split("/").reverse().join("-");

        const response = await fetch(
          `${domain}user-task-hours?project_id=${selProId}&user_id=${selUsrId}&task_id=99999&start_date=${searchStartDate}&end_date=${searchEndDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setAllUserRepDetails(res?.tasks);
          setIndTotWorkingHours(res?.total_working_hours);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsertaskDetails();
  }, [
    selectedAssignee,
    selectedProject,
    domain,
    token,
    selectedAssigneeId,
    selectedProjectId,
    startDate,
    endDate,
  ]);

  const handleStartDate = async (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedDateRange(`${startDate} - ${endDate}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDetailedUserReport = () => {
    let dataPreparation = [];
    userMasterDetails?.forEach((element) => {
      let overAllData = {
        username: element?.user_name,
        projectname: element?.project?.projectname,
        taskcode: element?.task_code,
        taskname: element?.taskname,
        taskowner: element?.task_owner.username,
        status: element?.task_status,
        Date: element?.date,
        starttime: element?.start_time,
        endtime: element?.end_time,
        hours: element?.hours,
      };
      dataPreparation = [...dataPreparation, overAllData];
    });
    const DetailedReport = ["User Task Report"];
    const Space = [""];
    const Date = ["Date"];
    const User = ["User Name"];
    const Project = ["Project Name"];
    const ProjectWorkingHour = ["Total Working Hours"];
    const ProjectPresentHour = ["Total Present Hours"];
    DetailedReport.push("");
    Date.push(`${startDate} to ${endDate}`);
    User.push(selectedAssignee);
    Project.push(selectedProject);
    ProjectWorkingHour.push(indTotWorkingHours ? indTotWorkingHours : "00:00");
    if (selectedAssignee !== "All") {
      ProjectPresentHour.push(
        indTotPresentHours ? indTotPresentHours : "00:00"
      );
    }
    Space.push("");
    const items = dataPreparation;
    const TableHead = ["Assignee"];
    TableHead.push("Project Name");
    TableHead.push("Task Id");
    TableHead.push("Task Name");
    TableHead.push("Task Owner");
    TableHead.push("Status");
    TableHead.push("Date");
    TableHead.push("Start Time");
    TableHead.push("End Time");
    TableHead.push("Hours");

    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv.unshift(Space.join(","));
    if (selectedAssignee !== "All") {
      csv.unshift(ProjectPresentHour.join(","));
    }
    csv.unshift(ProjectWorkingHour.join(","));
    csv.unshift(Project.join(","));
    csv.unshift(User.join(","));
    csv.unshift(Date.join(","));
    csv.unshift(DetailedReport.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "DetailedUserReport.csv";
    document.body.appendChild(element);
    element.click();
  };

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  useEffect(() => {
    const filteredResults = allUserRepDetails.filter((item) =>
      keys.some((key) =>
        getNestedValue(item, key)
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setUserMasterDetails(filteredResults);
    setPage(0);
  }, [allUserRepDetails, globalSearchValue]);

  return (
    <React.Fragment>
      <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
        <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
          <Mui.Stack direction="row">
            <BackArrow actionFunction={() => updateShowReport(false)} />
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                mt: 1.5,
              }}
            >
              User Task Report
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              allUsers?.find((option) => option?.label === selectedAssignee) ||
              selectedAssignee ||
              ""
            }
            options={allUsers}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options.filter((option) =>
                option.label
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option.label}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.label) {
                setSelectedAssignee(value.label);
                setSelectedProject("All");
                setSelectedAssigneeId(value.id);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="User Name"
                sx={{ background: "white" }}
                name="username"
                placeholder="Select User Name"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              allProjectDetails?.find(
                (option) => option?.label === selectedProject
              ) ||
              selectedProject ||
              ""
            }
            options={allProjectDetails}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options?.filter((option) =>
                option?.label
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option?.label}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.label) {
                setSelectedProject(value.label);
                setSelectedProjectId(value.project_id);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="Project"
                sx={{ background: "white" }}
                name="projects"
                placeholder="Select Project"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3.5} lg={2}>
          <DateRangePicker
            handleStartDate={handleStartDate}
            selectedDateRange={selectedDateRange}
          />
        </Mui.Grid>
        <Mui.Grid item lg={5.4}></Mui.Grid>
        <Mui.Grid item xs={2} sm={2} md={2} lg={0.5} sx={{ marginTop: -1.3 }}>
          <Mui.IconButton
            onClick={() => {
              getDetailedUserReport();
            }}
            sx={{
              backgroundColor: themes.primary,
              "&:hover": {
                backgroundColor: themes.primary,
              },
            }}
            disabled={userMasterDetails?.length === 0 ? true : false}
          >
            <DownloadForOffline
              fontSize="large"
              sx={{
                color: themes.blueColor,
              }}
            />
          </Mui.IconButton>
        </Mui.Grid>
      </Mui.Grid>

      {isLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          <Mui.TableContainer sx={{ paddingRight: 2, mt: 2 }}>
            <Mui.Table sx={{ border: `2px solid ${themes.sidebarDivider}` }}>
              <Mui.TableHead sx={{ backgroundColor: themes.primary }}>
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Assignee
                  </Mui.TableCell>
                  {selectedProject === "All" ? (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Project Name
                    </Mui.TableCell>
                  ) : (
                    ""
                  )}
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Task Id
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Task Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Task Owner
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Status
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Date
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Start Time
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    End Time
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Hours
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {userMasterDetails.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={12}>
                      <Mui.Alert severity="info">
                        There is a no available task
                      </Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  userMasterDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item, index) => (
                      <>
                        <Mui.TableRow sx={tableRowSx}>
                          <Mui.TableCell>
                            {item?.user_name ? item?.user_name : "N/A"}
                          </Mui.TableCell>
                          {selectedProject === "All" ? (
                            <Mui.TableCell>
                              {item?.project?.projectname}
                            </Mui.TableCell>
                          ) : (
                            ""
                          )}
                          <Mui.TableCell>
                            {item?.task_code ? item?.task_code : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.taskname ? item?.taskname : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.task_owner.username
                              ? item?.task_owner.username
                              : "N/A"}
                          </Mui.TableCell>

                          <Mui.TableCell>{item?.task_status}</Mui.TableCell>
                          <Mui.TableCell>
                            {item?.date ? item?.date : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.start_time ? item?.start_time : "00:00"}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.end_time ? item?.end_time : "00:00"}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.hours ? item?.hours : "00:00"}
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </>
                    ))
                )}
              </Mui.TableBody>
              {userMasterDetails.length !== 0 ? (
                <Mui.TableBody>
                  {selectedAssignee !== "All" ? (
                    <Mui.TableRow sx={tableRowSx}>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      {selectedProject === "All" ? (
                        <Mui.TableCell></Mui.TableCell>
                      ) : (
                        ""
                      )}

                      <Mui.TableCell>
                        <Mui.Typography
                          sx={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          Total Present Hours
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {indTotPresentHours ? indTotPresentHours : "00:00"}
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ) : null}
                  <Mui.TableRow sx={tableRowSx}>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    {selectedProject === "All" ? (
                      <Mui.TableCell></Mui.TableCell>
                    ) : (
                      ""
                    )}

                    <Mui.TableCell>
                      <Mui.Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        Total Working Hours
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {indTotWorkingHours ? indTotWorkingHours : "00:00"}
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableBody>
              ) : null}
            </Mui.Table>
          </Mui.TableContainer>
          {userMasterDetails.length > 25 ? (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={userMasterDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </>
      )}
    </React.Fragment>
  );
};

export default UserTaskReport;
